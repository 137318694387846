import React, {Component} from 'react';
import moment from 'moment';

export default function DatePickers(props){
    
    let{date_added} = props
     date_added = moment(date_added).valueOf();
    let currentime = moment(props.serverTime).valueOf();
    let closetime = moment(props.closeTime).valueOf();
    let duration = ''
    console.log("+++++++++++++++++++++++++++++++++",date_added,currentime,closetime);
    if(date_added >= currentime){
         duration = moment.duration((date_added - currentime))
           
    }else{
         duration = (props.auctiontype==='listing') ? moment.duration((currentime - closetime)) : moment.duration((closetime-currentime));
    }
    let countdown = duration.days() ? (duration.days() + "D ") : '';
        if(props.auctiontype ==='auction'){
            countdown += duration.hours() ? duration.hours() + "H " : '';
            countdown += duration.minutes() ? duration.minutes() + "M " : '';
            // countdown += duration.seconds() + "S ";
        }
        countdown = (props.auctiontype ==='listing') ? (currentime-closetime) > 0 ? countdown : "closed":(closetime-currentime) > 0 ? countdown : "closed";
        console.log("+++++++++++++++++++++++++++++++++",currentime);
    return countdown;
}