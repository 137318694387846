import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './Buyerdashboard.css';
import axios from 'axios';
import { connect } from 'react-redux';
import { Suspense } from 'react';
import ReactDOM from 'react-dom'
import Loader from '../../../components/Loader/Loader';
import {properResult} from '../../../util/capsAndSpaces'
import {NOTIFICATION} from "../../../actions/index"
const ViewTwo = React.lazy(() => import('../../../components/Cards/ViewTwo'));

class Buyerdashboard extends Component {
    state = {
        error   :   '',
        data:[],
        dataToSend  :   [],
        totalpage : 0,
        page_no :   1 ,
        subUrl  :   "all",
        limit: 3,
        isLoading   :   true,
        preapprove  :   1
    }
   
    handleAxios(alldata){
        let totalpage = alldata.data.pageLimit ? Math.ceil(alldata.data.pageLimit/this.state.limit) : 0;
        let tempArr = this.state.dataToSend.concat(...alldata.data.results)
        tempArr = properResult(tempArr)
            this.setState({
                dataToSend : tempArr,
                totalpage : totalpage,
                isLoading:true,
                preapprove : (alldata.data.preapprove)?alldata.data.preapprove[0].preqalified:"0"
            })
    }
    
    getDashboardDetails(subUrl,cbk){
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";

            let params = {
                limit   : this.state.limit ,
                page_no : this.state.page_no
            }
            
            let completeUrl=`${url}mobileapi/${subUrl}/${params.limit}/${params.page_no}/${this.props.user.userdata.id}`;
            if(subUrl !== '')
                axios.get(completeUrl , params )
                .then(cbk)
    }
    componentDidMount(){
        let subUrl = this.state.subUrl;
        window.addEventListener('scroll', this.onScroll, false);
        this.getDashboardDetails(subUrl,this.handleAxios.bind(this))        
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
      }
    onScroll = () => {
        let self = this.state;
        let page_no = self.page_no ? self.page_no : 2;
        let cardDiv = ReactDOM.findDOMNode(this).querySelector('.content-main')
        if(cardDiv.getBoundingClientRect().bottom + 200 <= window.innerHeight && 
            self.isLoading && self.totalpage > page_no){
            this.setState({
              page_no   :   page_no+1,
              isLoading :   false
            },()=>{
                this.getDashboardDetails(this.state.subUrl,this.handleAxios.bind(this))
              
          })  
        }
      }
    callagain=()=>{
        this.setState({
            dataToSend : []
        })
        this.getDashboardDetails("my-won",this.handleAxios.bind(this))
    }
    tabeChange = (name) => event => {
        event.preventDefault();
        this.all_menu.className = "active"
        this.active_menu.className = ""
        this.won_menu.className="";
        this.lost_menu.className=""
        
        this.state.dataToSend = this.state.data;
        var subUrl = "all"
            if(name==="active"){
                this.all_menu.className = ""
                this.active_menu.className = "active"
                this.won_menu.className="";
                this.lost_menu.className="";
                subUrl="active"
                this.state.dataToSend = this.state.data.filter((ele)=>{
                     if(ele.market_status === "active") return ele
                })
            }
            
            if(name==="won"){
                this.all_menu.className = ""
                this.active_menu.className = ""
                this.won_menu.className="active";
                this.lost_menu.className="";
                subUrl = "my-won";
                this.state.dataToSend = this.state.data.filter((ele)=>{
                     if(ele.market_status === "won") return ele
                })
            }
            if(name==="lost"){
                this.all_menu.className = ""
                this.active_menu.className = ""
                this.won_menu.className="";
                this.lost_menu.className="active";
                subUrl = "mybids_search/lost";
                this.state.dataToSend = this.state.data.filter((ele)=>{
                     if(ele.market_status === "lost") return ele
                })
            }
            this.setState({
                subUrl  :   subUrl,
                dataToSend  :   [],
                isLoading : false,
                page_no : 1
            },()=>{
                //console.log(this.state.subUrl)
                this.getDashboardDetails(this.state.subUrl,this.handleAxios.bind(this))
            })
           
    }
    preapproveRequest = () => {
        let self=this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(`${url}mobileapi/sendpreapproveemail`,{}).then((response)=>{
            if(response.data.success === "yes"){
                this.props.dispatch(NOTIFICATION({msg:"Request for approval sent successfully"}))
            }else{
                let failedreason = (response.data&&response.data.failedreason) ? response.data.failedreason : "err"
                this.props.dispatch(NOTIFICATION({msg:failedreason,msgLink:'/dashboard/buyerprofile#preapprove'})) 
            }
        }).catch((error)=>{
            self.setState({
                error:"Try Later"
            })
        })
    }
    render() {
        return (
            <div className="col-md-9">
                
                    <div className="flex justify-between pre-txt">
                        <span className="karla fw-600 text-black text-uppercase">MY Auctions</span>
                        {this.state.preapprove === 0 ?
                      <span> <button className="btn btn-orange karla fw-600" onClick={this.preapproveRequest}>
                        Get Pre-Approved</button></span>
                      :''}
                      
                        {this.state.preapprove === 2 ?
                      <span> <button className="btn btn-grey karla fw-600" >
                       Financing Pre-Approval Pending</button></span>
                      :''}

                        {this.state.preapprove === 1 ?
                      <span> <button className="btn btn-green karla fw-600" >
                       Financing Pre-Approved</button></span>
                      :''} 

                    </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">
                        <div className="tab-connect">
                            <ul className="nav nav-tabs">
                                <li className="active" ref={el => this.all_menu = el} ><a data-toggle="tab" href="#home" onClick={this.tabeChange('all')}>ALL</a></li>
                                <li ref={el => this.active_menu = el} ><a data-toggle="tab" href="#menu1" onClick={this.tabeChange('active')}>ACTIVE</a></li>
                                <li ref={el => this.won_menu = el} ><a data-toggle="tab" href="#menu2" onClick={this.tabeChange('won')}>WON</a></li>
                                <li ref={el => this.lost_menu = el} ><a data-toggle="tab" href="#menu3" onClick={this.tabeChange('lost')}>LOST</a></li>
                            </ul>
                            <span className="ul-border"></span>
                        </div>
                        <div className="tab-content">
                            <div id="home" className="tab-pane fade in active">
                            <Suspense fallback={<Loader/>}>
                                <ViewTwo 
                                user={this.props.user} 
                                projToDisplay={this.state.dataToSend} 
                                callagain = {this.callagain}
                                />
                                {!this.state.isLoading && <Loader></Loader>}
                            </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Buyerdashboard);