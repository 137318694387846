import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

class Footer extends Component {
  render() {
    let { user, loggedIn } = this.props;
    let role = user && user.userdata ? user.userdata.role : '';
    return (
      <footer className="show-fotter navbar-default">
        <div className="container-fluid foot-bg pad-30">
          <div className="container">
            <div className="footer-above">
              <div className="row">
                <div className="col-md-4 col-sm-12 col-xs-12">
                  <div className="News-letter">
                    {/* <p>SHOWPADS LA</p> */}
                    <p className="text-cap">DoorRise</p>
                  </div>
                </div>
                <div className="col-md-8 col-sm-12 col-xs-12">
                  <div className="flex justify-between main-fle">
                    { loggedIn && false &&
                      <div className="foot-link ft-before">
                        <ul>
                          {/* <li className="main-li">Site Links</li> */}
                          {/* { role === "buyer" && <li><Link to={"/search/buyer"}>Buy </Link></li>}
                          { role === "seller" && <li><Link to={"/search/seller"}>Sell </Link></li>}
                          { role === "seller" && <li><Link to={"/dashboard/post"}> Add Listings</Link></li>} */}
                        </ul>
                      </div>
                    }
                    <div className="foot-link ft-after flex justify-between">
                    
                      <ul className="pr-30">
                      {role==='buyer' ? <li> <Link to={loggedIn ? '/search/buyer' : '/search/buyer'}>Find Properties</Link></li>:''}
                        {role==='seller' 
                              ? 
                              <li><Link to={loggedIn ? '/dashboard/post' : '/search/seller'}>Add a Listing</Link></li>  
                              : role === 'realtor' 
                                ? <li><Link to={loggedIn ? '/dashboard/pocketListing' : '/login'}>Post a Pocket Listing</Link></li>
                                : ''
                        }
                        
                        <li><Link to={loggedIn ? `/pricing/${role}` : `/pricing/buyer` }>Pricing</Link></li>                        
                        <li><Link to={"/infovideo/buyer"}>FAQ</Link></li>
                        <li><Link to={"/blog"}>Blog</Link></li>
                        {/* <li><Link to={"/privacy"}>Privacy Policy</Link></li> */}
                      </ul>
                      <ul className="pr-30">
                      {role==='seller'?<li><Link to={loggedIn ? '/search/seller' : '/search/buyer'}>Find Buyers</Link></li>:''}
                        <li><Link to={loggedIn ? `/infovideo/${role}` : 'ourteam'}>About Us</Link></li>
                        <li><a href="https://showpads.com/" target="_blank">Rental Services</a></li>
                        <li><Link to={"/terms"}>Terms and Conditions</Link></li>
                        <li><Link to={"/privacy"}>Privacy Policy</Link></li>
                        <li><Link to={"/disclaimer"}>Disclaimer</Link></li>
                      </ul>
                    </div>
                    <div className="foot-link ft-after flex justify-between">
                      <ul className="pr-30">
                        <li className="main-li de"><i class="far fa-address-book"></i>&nbsp;&nbsp; DoorRise</li>
                        <li className="foot-addr de">
                          <address>
                            8549 Wilshire Blvd #381
                              <br />
                            Beverly Hills, CA 90211
                          </address>
                        </li>
                        <li><i class="fas fa-mobile-alt de"></i>&nbsp;&nbsp; 310-461-4308</li>
                        <li className=" foot-addr ">Follow us</li>
                          <li className="foot-link social">
                            <ul>
                              <li>
                                <div className="flex soci justify-contente-bet">
                                  <a href="https://www.facebook.com/doorrisehomes/"><i className="fab fa-facebook"></i></a>
                                  <a href="https://www.youtube.com/channel/UCfoKNvQedIz_WTQxTwop2Zg/"><i className="fab fa-youtube"></i></a>
                                  <a href="https://www.instagram.com/doorrisehomes/"><i className="fab fa-instagram"></i></a>
                                </div>
                              </li>
                            </ul>
                          </li>
                      </ul>
                      <div className="foot-link social">
                      {/* <ul className="">
                          <li className="foot-addr">Follow us</li>
                          <li>
                            <div className="flex soci justify-contente-bet">
                              <i className="fab fa-facebook"></i>
                              <i className="fab fa-youtube"></i>
                              <i className="fab fa-instagram"></i>
                            </div>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                    {/* <div className="foot-link social foot-link"> 
                    </div> */}
                  </div>
                  {/* <div className=" social foot-link">
                        <ul className="flex">
                            <li>Follow Us!</li>
                            <li> <i className="fab fa-youtube"></i>                              
                            </li>
                            <li><a href='https://www.facebook.com/gethomesdirect/'><i className="fab fa-facebook-f"></i></a></li>
                            <li><i className="fab fa-twitter"></i></li>
                            <li><i className="fab fa-instagram"></i></li>
                        </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid pad-10 gray">
          <div className="footer-below ">
            <span>
              <p className="font-12 text-input mar-0 text-center">Copyright Showpads, Inc 2020 All Rights Reserved</p>
            </span>
          </div>
        </div>
      </footer>
    )
  }
}


export default Footer;