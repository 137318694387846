import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './Productpost.css';
import us_states from '../../util/us_states'
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import { NOTIFICATION } from  '../../actions/index';
import DateTime from '../../util/Datetime'

import axios from 'axios';
import moment from 'moment';

//Component Import
import Imageview1 from '../../components/Imageview/Imageview1';
import { properResult } from '../../util/capsAndSpaces';
import Autocomplete from '../../components/Autocomplete/Autocomplete';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#6A6A6A",
      color:"white",
      width: 300,
      height: 150,
      fontSize: theme.typography.pxToRem(22),
      border: "1px solid #dadde9",
      
    }
  }))(Tooltip);
class Productpost extends Component {
    state = {
        error: '',
        imgSrc:[],
        title: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        description: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        saletype: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        sellertype: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        propertycondition: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        pricerangemax: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        pricerangemin: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertype: {
            data: [],
            required: true,
            errorMsg: "Required"
        },
        location: {
            data: [],
            required: true,
            errorMsg: "Required"
        },
        bathroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bedroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        min_property_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        min_land_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        auctiontype: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bprice: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        start_date: {
            data: moment().format('YYYY-MM-DD HH:mm:ss'),
            required: true,
            errorMsg: "Required"
        },
        end_date: {
            data: moment().add(5,"days").set({hour:23,minutes:59,second:0}).format('YYYY-MM-DD HH:mm:ss'),
            required: true,
            errorMsg: "Required"
        },
        dual_agent_transaction: {
            data: "",
            required: true,
            errorMsg: "Required"
        } , 
        openHouseDate:{
            data:'',
            required:false,
            errorMsg:'Required'  ,
            changed:false
        },
        deedData: '',
        deedModel: false,
        required: {},
        formError: {},
        selectedFile: [],
        doc_selectedFile:[],
        market_status: {data:"draft"},
        multiple : true,
        avatar:"",
        payModel: false,
        popupModel: false,
        customerid:'',
        paymentid: '',
        deletedimages:[]
    }

    handleValidation(){
        let self = this;
        Object.keys(this.state).map(function(key) { 
            
            if(self.state[key] && self.state[key].required && self.state[key].data === "" ){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
            }
            
        });
        self.setState({
            formError: self.state.formError
        },()=>{console.log(self.state.formError)});
    }

    formSubmit(e) {
        e.preventDefault();
        this.handleValidation();
        const data = new FormData()
        let self = this;
        let params = this.props.match.params;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;

        data.append('pid', params.id);
        data.append('auctionid', params.auction_id)
        if ((Object.keys(self.state.formError)).length > 0 ){
            return false;
        }
        Object.keys(self.state).map(function(key) { 
            if(key==='start_date'||key==="openHouseDate")
                    return (self.state[key] && self.state[key].changed) ? data.append(key, self.state[key].data) : '';

            return (self.state[key]) ? data.append(key,self.state[key].data) : '';
        });
        data.append('deletedImages',self.state.deletedimages)
        if(this.state.selectedFile){
            this.state.selectedFile.map(function(file,index) { 
                data.append('uploaderfile', file, file.name);
            });
        }
        
        if(this.state.doc_selectedFile){
            this.state.doc_selectedFile.map(function(file,index) { 
                data.append('inspect_document', file, file.name);
            });
        }

        if(self.state.deedData !== ""){
            Object.keys(self.state.deedData).map(function(key) { 
                return (self.state.deedData[key]) ? data.append(key,self.state.deedData[key].data) : '';
            });
            [self.state.deedData.selectedFile].map(function(file,index) { 
                data.append('deed_document', file, file.name);
            });
        }
        
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/sellerupdateproject', data)
            .then(function (response) {
                if (response.data.success === "no") {
                    self.setState({
                        error: response.data.failedreason
                    })
                    return false;
                }
                self.props.dispatch(NOTIFICATION({msg: "Successfully Updated!"}));
                self.props.history.push("/dashboard/seller");
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });
    }

    handleChange = name => event => {
        let preState = this.state[name];
        if(this.state.formError[name]){ delete this.state.formError[name] }
        // preState.data = event.target.value;  
        switch(name){
            
            case 'propertype' :
                    delete this.state.formError['location']
            case 'location'   :
                    delete this.state.formError['propertype']
                let limit = (name==='location') ? 3 : 2
                if(this.state.formError[name]){
                    delete this.state.formError[name]
                }
                    
                if(preState.data.length<=limit){
                    if(preState.data.indexOf(event.target.value)<0)
                    {
                        this.state.selectedOptionClass = "selectedOption"
                        preState.data.push(event.target.value);console.log("after push")}
                    }
                else
                    this.state.formError[name] = (limit === 3) ? "You Can only choose up to four types" : "You Can only choose up to three types" 
            break;
            case 'sellertype' :
                    this.state.auctiontype.data = (event.target.value === "Pocket Listing") ? event.target.value : this.state.auctiontype.data
                    //this.state.auctiontype.required = (event.target.value === "Pocket Listing") ? false : true
                    // break;
            default :
                preState.data = event.target.value;
                this.state.selectedOptionClass = ""
                delete this.state.formError['propertype']
                delete this.state.formError['location']
            break;
        }
        // if(name === "start_date" || name === 'end_date' || name==='openHouseDate'){
        //     preState.data = moment( preState.data).format('YYYY-MM-DD HH:mm:ss');
        //     preState.changed = true;
        // }      
        this.setState({
            [name]: preState,
            formError: this.state.formError,
            error: '',
            selectedOptionClass : this.state.selectedOptionClass,
            auctiontype:this.state.auctiontype
        });
        
    };

    onChange(type) {
        let files = this.refs.file.files
        let doc_files = this.refs.document.files
        if (type === "inspect") {
            delete this.state.formError['auctiontype']
            this.state.doc_selectedFile = []
            Object.keys(doc_files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(doc_files[index]);
                this.state.doc_selectedFile.push(doc_files[index])
                reader.onloadend = function (e) {
                    this.setState({
                        doc_selectedFile: this.state.doc_selectedFile,
                        formError: this.state.formError
                    }, () => { console.log(this.state.formError) })
                }.bind(this);
            })
        }
        if (type === "img") {
            Object.keys(files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(files[index]);
                reader.onloadend = function (e) {
                    files[index]['imgsrc'] = reader.result;
                    this.state.selectedFile.push(files[index])
                    this.state.imgSrc.push(reader.result)
                    this.setState({
                        imgSrc: this.state.imgSrc,
                        selectedFile: this.state.selectedFile
                    })
                }.bind(this);
            });
        }
    }

    removeFromArray = (name,ind) => event =>{
        
        let prestate = this.state[name]
        let limit = (name==="location") ? 4 : 3
        if(prestate.data.length <= limit){
            if(this.state.formError[name])
                delete this.state.formError[name]
        }
        prestate.data.splice(ind,1)
        this.setState({
            [name] : prestate
        })
    } 

    handleClick = (name,flag) => event => {
        
        if(this.state.formError[name]){ delete this.state.formError[name] }
        let prestate = this.state[name];
        prestate.data = flag
        this.setState({
            [name] : prestate
        })
    }

    loadProduct =  () =>{
        let self = this;
        let params = this.props.match.params;
        let user_id = this.props.user.userdata.id
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.get(url+'mobileapi/getprodetails/'+params.id+'/'+params.auction_id+'/1/'+user_id,{})
            .then(async function (response) {
                if(response && response.data){
                   //console.log(response.data)
                    response.data.results   =  properResult([response.data.results]);
                    await Object.keys(self.state).map(function(key) {
                        let preData = self.state[key]
                        if(typeof(self.state[key].data) !== "undefined" && ['location','propertype'].indexOf(key) === -1){
                                preData["data"] = response.data.results[0][key];
                        }else if(['location','propertype'].indexOf(key) >= 0){
                            preData.data.push(response.data.results[0][key])
                        }else if(['imgSrc'].indexOf(key) >= 0){
                            console.log( response.data.attachments);
                            response.data.attachments.map((data)=>{
                                preData.push(data.avatarorg)
                            })
                        }
                        self.setState({
                            [key]: preData
                        })
                    });
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    }

    componentDidMount = () => { 
        this.loadProduct()
    }

    imageDelete = (index) => {
        var deleted_images = this.state.deletedimages;
        let imgSrc = this.state.imgSrc;
        let selectedFileIndex = this.state.selectedFile.findIndex((sfile) => {
            return sfile.imgsrc === imgSrc[index];
        });
        this.state.selectedFile.splice(selectedFileIndex, 1);
        deleted_images = deleted_images.concat(this.state.imgSrc.splice(index, 1))
        deleted_images=deleted_images.map(ele=>{
            let a= ele.split('/')
            return a[(a.length-1)]
        })
        // this.state.imgSrc.splice(index, 1)
        this.setState({
            imgSrc: this.state.imgSrc , deletedimages : deleted_images
        },()=>{console.log(this.state.deletedimages)})
    }

    onPlaceChanged = (data) => {
        const preState = this.state.location;
        if (preState.data.indexOf(data.formatted_address) < 0) {
            this.state.selectedOptionClass = "selectedOption";
            data.formatted_address = data.formatted_address.replace(/,/g," ")
            preState.data.push(data.formatted_address); 
        }
        this.setState({
            location: preState,
            selectedOptionClass: this.state.selectedOptionClass,
        });
    }
    handleDateChange = (returnlabel, val) => {
        var returnlabel = returnlabel.toString()
         if(this.state.formError[returnlabel]){delete this.state.formError[returnlabel]}
        var prestate = this.state[returnlabel];
        prestate.data = val;
        prestate.changed = true;
        this.setState({
            [returnlabel]: prestate
        })
    }
    render() {
        return (
            <main>
                <div className="center-part pad-tb-30">
                    <div className="container">
                        <div className="flex justify-center">
                            <div className="col-md-12">
                                <h3 className="karla fw-600 text-black text-center">Edit LISTINGS</h3>
                                    <div className="main-pro">
                                        <div className="content-main box-shadow pad-30">
                                            <form className="site-form" onSubmit={this.formSubmit.bind(this)}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label >Property Address</label>
                                                        <Autocomplete onPlaceChanged={this.onPlaceChanged} />
                                                        <div className="pan-block">
                                                            {this.state.location.data.length > 0 && this.state.location.data.map((ele, ind) => {
                                                                return <span className="token close" data-dismiss="modal" aria-label="Close" key={"location_" + ind}>
                                                                    {ele}
                                                                    <span aria-hidden="true" onClick={this.removeFromArray('location', ind)}>
                                                                        <i className="fas fa-times"></i>
                                                                    </span>
                                                                </span>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["location"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label >Listing Type</label>
                                                        <select className="form-control site-input" id="" name="auctiontype" value={this.state.auctiontype.data} onChange={this.handleChange('auctiontype')} disabled>
                                                            <option value="">Choose Listing Type</option>
                                                            <option value="auction">Auction</option>
                                                            <option value="offers">Deed or Contract</option>
                                                        </select>
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["auctiontype"]}</span>
                                                </div>
                                            </div> 
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label >Preferred Property Type(s)</label>
                                                        <select className="form-control site-input"
                                                            name="propertype"
                                                            value={this.state.propertype.data}
                                                            onChange={this.handleChange('propertype')}>
                                                            <option value=""> {(this.state.propertype.data.length == 0) ? "Choose Property Type" : this.state.propertype.data.join(",")}</option>
                                                            <option className={this.state.propertype.data.indexOf("Single Family") >= 0 && this.state.selectedOptionClass}
                                                                value="Single Family">Single Family</option>
                                                            <option className={this.state.propertype.data.indexOf("Multi-Family") >= 0 && this.state.selectedOptionClass}
                                                                value="Multi-Family">Multi-Family</option>
                                                            <option className={this.state.propertype.data.indexOf("Condo") >= 0 && this.state.selectedOptionClass}
                                                                value="Condo">Condo</option>
                                                            <option className={this.state.propertype.data.indexOf("Commercial") >= 0 && this.state.selectedOptionClass}
                                                                value="Commercial">Commercial</option>
                                                            <option className={this.state.propertype.data.indexOf("Vacant Land") >= 0 && this.state.selectedOptionClass}
                                                                value="Vacant Land">Vacant Land</option>
                                                            <option className={this.state.propertype.data.indexOf("Development Deal") >= 0 && this.state.selectedOptionClass}
                                                                value="Development Deal">Development Deal</option>
                                                            <option className={this.state.propertype.data.indexOf("Contract") >= 0 && this.state.selectedOptionClass}
                                                                value="Contract">Contract </option>
                                                        </select>
                                                        <div className="pan-block">
                                                            {this.state.propertype.data.length > 0 && this.state.propertype.data.map((property_Type, propertyInd) => {
                                                                return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                                    {property_Type === 'Contract' ? 'Deeds and Contract' : property_Type}
                                                                    <span aria-hidden="true" onClick={this.removeFromArray('propertype', propertyInd)}>
                                                                        <i className="fas fa-times"></i>
                                                                    </span>
                                                                </span>
                                                            })}
                                                        </div>
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["propertype"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label >Choose your preferred financing</label>
                                                        <select className="form-control site-input"
                                                            name="saletype"
                                                            value={this.state.saletype.data}
                                                            onChange={this.handleChange('saletype')}>
                                                            <option value="">Choose the type of Sale</option>
                                                            <option value="All cash only">All Cash Only </option>
                                                            <option value="Conventional">Conventional</option>
                                                            <option value="Fha">FHA</option>
                                                            <option value="Va">VA</option>
                                                        </select>
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["saletype"]}</span>
                                                </div>
                                                {/* <div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Preferred Seller Type(s)</label>
                                                                <select  className="form-control site-input" 
                                                                id="" name="sellertype" 
                                                                value={this.state.sellertype.data} 
                                                                onChange={this.handleChange('sellertype')}>
                                                                    <option value="">Choose the type of Seller</option>
                                                                    <option value="Any">Any</option>
                                                                    <option value="Private owner">Private Owner </option>
                                                                    <option value="Pocket listing">Pocket Listing </option>
                                                                    <option value="Reo">REO </option>
                                                                    <option value="Investor institution">Investor/Institution </option>
                                                                </select>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["sellertype"]}</span>
                                                        </div> */}
                                            </div>
                                                    <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                                <label >Preferred Condition</label>
                                                                <select  className="form-control site-input" id="" name="propertycondition" value={this.state.propertycondition.data} onChange={this.handleChange('propertycondition')}>
                                                                    <option value="">Choose a condition of the Property</option>
                                                                    <option value="Any">Any </option>
                                                                    <option value="Excellent completely updated">Excellent - Completely Updated </option>
                                                                    <option value="Good moderate updates">Good - Moderate updates </option>
                                                                    <option value="Average few updates mostly original">Average - Few updates. Mostly Original </option>
                                                                    <option value="Poor repairs and updates needed">Poor - Repairs and updates needed</option>
                                                                    <option value="Investor grade - major repairs and updates needed">Investor grade - major repairs and updates needed </option>
                                                                    <option value="Land value only">Land Value only </option>
                                                                    <option value="Development deal">Development Deal </option>
                                                                </select>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["propertycondition"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" >
                                                                <div className="flex justify-between prc-rang">
                                                                    <div className="" style={{"padding":"0em 1em 0em 0em"}}>
                                                                    <label >{this.state.auctiontype.data==='auction' ? 'Opening Bid' : "Asking Price"}</label>
                                                                        <div className="form-group">
                                                                            <div className="input-group " style={{width:"100%"}}>
                                                                                <input type="text" className="form-control site-input" id="pricerangemin" name="pricerangemin" value={this.state.pricerangemin.data} onChange={this.handleChange('pricerangemin')}
                                                                                    placeholder="Min" />
                                                                                <div className="input-group-addon">$</div>
                                                                            </div>
                                                                        </div>
                                                                        <span style={{color: "red"}}>{this.state.formError["pricerangemin"]}</span>
                                                                    </div>
                                                                    <div className="" style={{"padding":"0em 1em 0em 0em"}}>
                                                                        <label>Open House Date</label>
                                                                        <div className="form-group margin-finne">
                                                                            <DateTime placeholder="Open House Date" value={this.state.openHouseDate.data} handleChange={this.handleDateChange} returnLabel="openHouseDate" />
                                                                        </div>
                                                                        <p style={{ color: "red" }}>{this.state.formError["openHouseDate"]}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    {/* <div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Preferred Property Type(s)</label>
                                                                <select className="form-control site-input" 
                                                                    name="propertype" 
                                                                    value={this.state.propertype.data} 
                                                                    onChange={this.handleChange('propertype')}>
                                                                        <option value=""> { (this.state.propertype.data.length==0)?"Choose Property Type" : this.state.propertype.data.join(",")}</option>
                                                                        <option className={this.state.propertype.data.indexOf("Single Family")>=0 && this.state.selectedOptionClass} 
                                                                                value="Single Family">Single Family</option>
                                                                        <option className={this.state.propertype.data.indexOf("Multi-Family")>=0 && this.state.selectedOptionClass} 
                                                                        value="Multi-Family">Multi-Family</option>
                                                                        <option className={this.state.propertype.data.indexOf("Condo")>=0 && this.state.selectedOptionClass} 
                                                                        value="Condo">Condo</option>
                                                                        <option  className={this.state.propertype.data.indexOf("Commercial")>=0 && this.state.selectedOptionClass} 
                                                                        value="Commercial">Commercial</option>
                                                                        <option className={this.state.propertype.data.indexOf("Vacant Land")>=0 && this.state.selectedOptionClass}  
                                                                        value="Vacant Land">Vacant Land</option>
                                                                        <option className={this.state.propertype.data.indexOf("Development Deal")>=0 && this.state.selectedOptionClass}  
                                                                        value="Development Deal">Development Deal</option>
                                                                        <option className={this.state.propertype.data.indexOf("Contract")>=0 && this.state.selectedOptionClass}  
                                                                        value="Contract">Contract </option>
                                                                    </select>
                                                                <div className="pan-block">
                                                                    {this.state.propertype.data.length> 0 && this.state.propertype.data.map((property_Type,propertyInd)=>{
                                                                        return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                                            {property_Type}
                                                                            <span aria-hidden="true"  onClick={this.removeFromArray('propertype',propertyInd)}>
                                                                                <i className="fas fa-times"></i>
                                                                            </span>
                                                                        </span>
                                                                    })}
                                                                </div>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["propertype"]}</span>
                                                        </div> */}
                                                        {/*<div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Choose prefered location</label>
                                                                 <select class="form-control site-input" 
                                                                name="location"
                                                                value={this.state.location.data} 
                                                                onChange={this.handleChange('location')}>
                                                                    <option  value="">{ (this.state.location.data.length==0)?"Add a Location" : this.state.location.data.join(",")}</option>
                                                                    {us_states.map((data,index)=>(
                                                                            <option className={this.state.location.data.indexOf(data.label)>=0 && this.state.selectedOptionClass}  value={data.label} key={"sate_select"+index}>{data.label}</option>
                                                                    ))}
                                                                </select>                                                             
                                                                <Autocomplete onPlaceChanged={this.onPlaceChanged} /> 
                                                                <div className="pan-block">
                                                                {this.state.location.data.length > 0 && this.state.location.data.map((ele,ind)=>{
                                                                    return <span className="token close" data-dismiss="modal" aria-label="Close" key={"location_"+ind}>
                                                                        {ele} 
                                                                        <span aria-hidden="true" onClick={this.removeFromArray('location',ind)}>
                                                                            <i className="fas fa-times"></i>
                                                                        </span>
                                                                    </span>
                                                                })}
                                                                </div>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["location"]}</span>
                                                        </div> */}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Bathroom(s)</label>
                                                                <select className="form-control site-input" id="" name="bathroom" value={this.state.bathroom.data} onChange={this.handleChange('bathroom')}>
                                                                    <option value="">Choose the number of bath</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5 or more">5+</option>
                                                                </select>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["bathroom"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Bedroom(s)</label>
                                                                <select className="form-control site-input" id="" name="bedroom" value={this.state.bedroom.data} onChange={this.handleChange('bedroom')}>
                                                                    <option value="">Choose the number of bedroom</option>
                                                                    <option value="1">1</option>
                                                                    <option value="2">2</option>
                                                                    <option value="3">3</option>
                                                                    <option value="4">4</option>
                                                                    <option value="5 or more">5+</option>
                                                                </select>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["bedroom"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label >Living Area (Sq ft) (optional)</label>
                                                                <input type="text" className="form-control site-input" id="propsqfootage" 
                                                                name="min_property_sq_footage" 
                                                                value={this.state.min_property_sq_footage.data}
                                                                onChange={this.handleChange('min_property_sq_footage')}
                                                                placeholder="Property Sq Footage "
                                                                min={100}
                                                                max={10000}
                                                                />
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["min_property_sq_footage"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label >Lot Size (Sq ft) (optional)</label>
                                                                <input type="text" className="form-control site-input" id="propsqfootage" 
                                                                name="min_land_sq_footage" 
                                                                value={this.state.min_land_sq_footage.data}
                                                                onChange={this.handleChange('min_land_sq_footage')}
                                                                placeholder="Land Sq Footage" 
                                                                min={100}
                                                                max={30000}
                                                                />
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["min_land_sq_footage"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {/* <div className="col-md-6">
                                                            <div className="form-group selectdiv">
                                                                <label >Listing Type</label>
                                                                <select  className="form-control site-input" id="" name="auctiontype" value={this.state.auctiontype.data} onChange={this.handleChange('auctiontype')} disabled>
                                                                    <option value="">Choose Listing Type</option>
                                                                    <option value="auction">Auction</option>
                                                                    <option value="offers">Deed or Contract</option>
                                                                </select>
                                                            </div>
                                                            <span style={{color: "red"}}>{this.state.formError["auctiontype"]}</span>
                                                        </div> */}
                                                        <div className="">
                                                                <label class="commission-question" htmlFor="exampleInputEmail1">
                                                                    Some of our Sellers require that Buyers work with our assigned 
                                                                    Dual Agents. Would you be comfortable being in a dual agent transaction?
                                                                
                                                                    <span 
                                                                        data-toggle="tooltip" 
                                                                        data-placement="right" 
                                                                        title="" 
                                                                        data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                        className="red-tooltip d-inline-block"
                                                                    > 
                                                                        <svg 
                                                                            version="1.1" 
                                                                            id="Capa_1" 
                                                                            width="15px" 
                                                                            height="15px" 
                                                                            x="0px" 
                                                                            y="0px" 
                                                                            viewBox="0 0 23.625 23.625" 
                                                                            style={{"enableBackground":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                            data-toggle="tooltip" title="aa"
                                                                        >
                                                                            <g>
                                                                                <HtmlTooltip  placement="left"
                                                                                title={
                                                                                        <React.Fragment>
                                                                                            <em> {"A Dual Agent is a single Agent who acts neutrally, managing s all aspects of the purchase transaction for both the Buyer and the Seller, without favoring either side. On our platform, Sellers pay a lower commission for Dual Agency. Buyers pay nothing, and receive a rebate at close of escrow."}</em>
                                                                                            
                                                                                        </React.Fragment>
                                                                                    }>
                                                                                <path 
                                                                                    style={{"fill":"#6A6A6A"}}
                                                                                    d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                                    S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                                    c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                                    l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                                    c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                                    c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                                    c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                                    c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                                    c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                        M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                                    c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                                    c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                                </path>
                                                                                </HtmlTooltip>
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    <span className="check flex justify-start">
                                                                        <label className="con">Yes
                                                                        <input type="radio"  name="radio" 
                                                                        onChange={this.handleClick('dual_agent_transaction',1)} checked={ this.state.dual_agent_transaction.data === 1}/>
                                                                        <span className="checkmark"></span>
                                                                        </label>
                                                                        <label className="con">No
                                                                        <input type="radio"  name="radio"  
                                                                        onChange={this.handleClick('dual_agent_transaction',0)} checked={ this.state.dual_agent_transaction.data === 0}/>
                                                                        <span className="checkmark"></span>
                                                                        </label>
                                                                    </span>
                                                                </label>
                                                                <span style={{ color: "red" }}>{this.state.formError["dual_agent_transaction"]}</span>
                                                            </div>
                                                    </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <Imageview1  multiple={this.state.multiple} 
                                                            imgSrc={this.state.imgSrc} 
                                                            avatar={this.state.avatar}
                                                            imageDelete={this.imageDelete}
                                                        />
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="">
                                                            <div className="form-group post-up" style={{ "display": "inline-grid" }}>
                                                                <label htmlFor="exampleInputEmail1">Choose Image(s) to Upload</label>
                                                                <div className="upload-btn-wrapper ">
                                                                    <button className="btn btn-primary font-15 karla">Upload Image <i className="flaticon-upload-button"></i></button>
                                                                    <input
                                                                        ref="file"
                                                                        type="file"
                                                                        multiple={true}
                                                                        accept="application/image" 
                                                                        onChange={this.onChange.bind(this, "img")}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="hidden">
                                                            <div className="form-group" style={{ display: "inline-grid", "marginBottom": "1em" }}>
                                                                <label htmlFor="exampleInputEmail1">Upload Inspection Report </label>
                                                                

                                                                <div className="upload-btn-wrapper ">
                                                                    <button className="btn btn-primary font-15 karla">Upload <i className="flaticon-upload-button"></i></button>
                                                                    <input
                                                                        type="file"
                                                                        name="myfile"
                                                                        ref="document"
                                                                        accept="application/pdf" 
                                                                        onChange={this.onChange.bind(this, "inspect")} />
                                                                </div>
                                                                <br></br>
                                                                    {this.state.doc_selectedFile.length > 0 && <label style={{color:"green"}}>{this.state.doc_selectedFile[0].name}</label>}
                                                                <br></br>
                                                            </div>
                                                            <div>
                                                            <span style={{ color: "red" }}>{this.state.formError["auctiontype"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row hidden">
                                                    <div className="col-md-12">
                                                        <div className="pro-chk">
                                                            <label className="show-check karla">I agree that any transaction for property on this platform will be listed through Pacific Playa Realty or its partner Brokers.
                                                                <input className="check-height" type="checkbox"/>
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center pad-tb-30">
                                                    <Link to={"/product/view/"+this.props.match.params.id+"/"+this.props.match.params.auction_id}><span className="btn btn-secondary ">Back</span></Link>
                                                    <button type="submit" className="btn btn-secondary ml-4">Submit</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                            </div>
                        </div>                                                
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Productpost); 