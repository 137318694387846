import React, {Component} from 'react';
import './forget_password.css';
import axios from 'axios';
class Forget_password extends Component {
      state={
        email:"",
        error:"",
        classname:""
      }
    
    onChangeHandle = name => event => {
     this.setState({
       email:event.target.value,
       error:""
     });
  };
  handleerror(){
    if(this.state.email===''){
      this.setState({error:"Required",classname:"alert alert-danger"})
      return false;
    }
    return true;
    
  }
  formSubmit(e){
    e.preventDefault()
    if(this.handleerror()){
      let email = {email:this.state.email};
      let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
      var self=this
      url=url+"mobileapi/forgot_password"
      axios.post(url,email).then((response)=>{
        if(response.data.success==="no"){
          this.setState({error:response.data.message,classname:"alert alert-danger"})
        }else{
          this.setState({error:response.data.message,classname:"alert alert-success"})          
        }
      }).catch((error)=>{
        console.log(error)
      })
    }
  }
    render(){
      return(
        <main>
           <div className="snow-pad">
            <div className="center-part">
              <div className="row">
                  <div className="flex justify-center">
                      <div className="col-md-6">
                          <div className="forgot-form">
                          <form action="" className="pad-55 elty">
                              <div className="top-hd for-hd">
                              <h4>Forgot Password</h4>
                              </div>
                              <div className="form-group link-redir text-center ">
                                  <p className="text-dark font-15 pad-lr-20 pad-btm-20">A link to reset your password will be sent to 
                                      your registered Email Id</p>
                              </div>
                              <div className="form-group">
                              <label  >Email address</label>
                              <input 
                              type="email" 
                              className="form-control border" 
                              id="exampleInputEmail1"  
                              placeholder="Enter Your Email id"
                              name="email"
                              value={this.state.email}
                              onChange={this.onChangeHandle("email")}
                              />
                              {/* <span style={{color:"red"}}>{this.state.error}</span> */}
                              
                              </div>
                              {this.state.error ?<div className={this.state.classname}>
                                 {this.state.error}
                              </div>:""}
                              <div className="form-group text-center pad-btm-20 pad-top-20">
                                  <button type="button"  onClick={this.formSubmit.bind(this)} className="btn btn-secondary">Submit</button>
                              </div>                            
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
             </div>
          </div>
        </main>
      )
    }
}
export default Forget_password;