import React, { Component } from 'react';
import { connect } from 'react-redux';
import {NOTIFICATION,PATHNAMEUPDATE,SEARCHPAYLOAD} from '../../actions/index'
import "./SavedSearch.css";
import axios from 'axios';


class SavedSearchList extends React.Component {
    state = {
        savedSearchList: [],
        limit: 10,
        page_no: 0,
        totalPageNo: 0,
        isLoading: true
    }
    getSavedSearch() {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let completeUrl = `${url}mobileapi/savedsearchfetch`;
        let payload = {
            limit: this.state.limit,
            page_no: this.state.page_no
        }
        axios.post(completeUrl, payload).then(response => {
            if (response.data.success === 'yes') {
                self.setState({
                    savedSearchList: (this.state.page_no == 0) ? response.data.data : this.state.savedSearchList.concat(response.data.data),
                    totalPageNo: (response.data.length > this.state.limit) ? Math.ceil(response.data.length / this.state.limit) : 1,
                    isLoading: true
                })
            }
        })
    }
    onScroll = () => {
        let self = this;
        let prePage = self.state.page_no ? self.state.page_no + 1 : 1;

        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 100) && self.state.isLoading &&
            Number(self.state.totalPageNo) > Number(prePage)) {
            this.setState({
                page_no: self.state.page_no + 1,
                isLoading: false,
            }, () => {
                this.getSavedSearch();
            })
        }
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, false);
        this.getSavedSearch();
        this.props.dispatch(PATHNAMEUPDATE({ pathName: "/dashboard/savedsearch" }))
    }
    goSearch = (index) => event => {
        this.state.savedSearchList[index]['location'] = this.state.savedSearchList[index]['location'].split('|')
        this.props.dispatch(SEARCHPAYLOAD({ savedSearch: this.state.savedSearchList[index] }))
        this.props.history.push("/search/" + this.props.user.userdata.role);
    }
    deleteSearch = (index) => event => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let completeUrl = `${url}mobileapi/deletesavedsearch`;
        let payload = {
            id: this.state.savedSearchList.length > 0 ? this.state.savedSearchList[index].id : '',
        }
        axios.post(completeUrl, payload).then(response => {
            if (response.data.success === 'yes') {
                self.state.savedSearchList.splice(index, 1);
                self.setState({
                    savedSearchList: self.state.savedSearchList
                }, () => { self.props.dispatch(NOTIFICATION({ msg: response.data.data })) })
            }
        })
    }
    render() {
        return (
            <>
                <div className="col-md-9">
                    <div className="flex justify-between pre-txt">
                        <span>Save this search</span>
                    </div>
                    <div className="main-pro">
                        <div className="content-main box-shadow pad-30">
                            {this.state.savedSearchList&&this.state.savedSearchList.length>0 ? 
                            this.state.savedSearchList.map((savedsearchele,index)=>{
                                return <div className='card'>
                                <div>{savedsearchele.date_added}</div>
                                <div>{savedsearchele.title}</div>
                                <div class="flex">
                                <div class='gandd'><a href="javascript:void(0)" onClick={this.goSearch(index)}>Go to Search</a></div>
                                <div class="gandd"><a href="javascript:void(0)" onClick={this.deleteSearch(index)}>X</a></div>
                                </div>
                            </div>
                            })
                                 : <>
                                    <div className='card'>
                                        <div></div>
                                        <div>No Saved Search</div>
                                        <div><a href="javascript:void(0)"></a></div>
                                    </div>
                                </>}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
    return { user, loggedIn };
}

export default connect(mapStateToProps)(SavedSearchList);