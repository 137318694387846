import React, {Component} from 'react';
import './ReportBuyerPopup.css';

import axios from 'axios';

class ReportUser extends Component {

    state = {
        deedStatus: [
            {
                label: "Reason 1",
                isChecked: false
            }
            ,{
                label: "Reason 2",
                isChecked: false
            },
            {
                label: "Reason 3",
                isChecked: false
            },
            {
                label: "Other",
                isChecked: false
            }
        ],
        description : '',
        active: '',
        error:''
    }

    closeHandle = () => {
        this.props.reportBuyerClose('close');
    }

    submit = () => {
        let self = this
            ,deedData = '';
        let deedStatus = self.state.deedStatus
        deedStatus.map(status => {
           if (status.isChecked === true)
            deedData = status;
        })
        if(!deedData && !deedData.label){
            self.setState({ error: "Choose any one!" });
            return false
        }
        if(deedData.label === "Other" && self.state.description ===''){
            self.setState({ error: "Description required!" });
            return false
        }
        axios.defaults.headers.common['Authorization'] = self.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {
            reason : deedData.label === "Other" ? self.state.description : deedData.label,
            id:this.props.reportUserId
        }
        axios.post(url + 'mobileapi/reportuser/', payload)
            .then(function (response) {
                if (response.data.success === "yes") {
                    self.props.reportBuyerClose('save');
                }else{
                    self.setState({ error: "Try again!" })
                }
            })
            .catch(function (error) {
                console.log(error,"error from report user")
                self.setState({ error: "Try again!" })
            });
    }

    handleCheckChieldElement = (event) => {
        let deedStatus = this.state.deedStatus
        deedStatus.forEach(status => {
           if (status.label === event.target.value)
               status.isChecked =  event.target.checked
           else    
                status.isChecked = false;
        })
        this.setState({deedStatus: deedStatus,error: ''})
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
            error: '',
        });
    };

    render() {
        let {deedStatus} = this.state;
        return (
            <div className="modal fade mod-place-bid in modal-open d_block" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content show-model bid-hiy">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeHandle}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="text-center karla fw-600"><span>REPORT USER</span></h3>
                        </div>
                        <div className="modal-body">
                            <div className="row flex">
                                <div className="reff text-center fullwidth">
                                    <h4>State your reason to Report this buyer</h4>
                                    <div className="check d-inline">
                                        {deedStatus.map((data,index) => (
                                            <div className="row" key={"box_"+index}>
                                                <label className="con">{data.label}
                                            <input type="radio" name="radio" value={data.label} checked={data.isChecked} onChange={this.handleCheckChieldElement}/>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    {deedStatus[3].isChecked && 
                                        <div className="row">
                                            <textarea className="d-block deed-textarea" placeholder="descripe here.." name="description" onChange={this.handleChange('description')}></textarea>
                                        </div>
                                    }
                                    <div className="row"><span style={{ color: "red",margin: "10px auto" }}>{this.state.error}</span></div>
                                    <div className="row"><button className="btn btn-primary list-pro" onClick={this.submit}>Submit</button></div>
                                    {/* <p className="text-light">Need some help ?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportUser;