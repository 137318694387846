import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './Header.css';
import { connect } from 'react-redux'
import { NOTIFICATION } from '../../actions/index';
import PushNotification from '../../components/PushNotification/PushNotification';
import {messagecount}  from '../../util/socket';
import axios from 'axios'
import { Helmet } from 'react-helmet';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navWide :"",
            profiledrop :"profiledrop",
            profiledropToggle : false ,
            msgcount : 0,
            activeClassName : 'active',
            tab : '',
            title:""
        }
    }

    burgerToggle = () => {
        console.log('BURGER TOGGLE')
        this.setState({
            navWide : (this.state.navWide!=='') ? "" : "navWide" ,
        })
    }

    clickToRedirect = (event) => {

        const {name} = event.target
        console.log(name,"NAMENNA")
        let prop = this.props;
        let link = (prop.user && prop.user.userdata.role==='seller') 
                    ? '/dashboard/seller' : 
                    (prop.user && prop.user.userdata.role==='buyer') 
                    ? '/dashboard/buyer' : '/dashboard/realtor' 
        link = (name === 'profileLink') 
                ? (prop.user && prop.user.userdata.role==='buyer') 
                    ? '/dashboard/buyerprofile' : '/dashboard/profile'
                : link
        link = event.target.name === "logoutLink" ? "/login" : link;
        this.setState({
            profiledropToggle: false,
            tab:(name === 'profileLink') ? 'profile': (name==='dashboardLink') ? 'dash':'',
            navWide:''
        },()=>{
            console.log(this.state)
            this.props.history.push(link)
        })
    }

    myAccountNav = () => {  
        this.setState({
            profiledropToggle: !this.state.profiledropToggle
        })
    }
    snackBarClose=e=>{     
        this.props.dispatch(NOTIFICATION({msg:""}))
    }
    getMessageCount=()=>{
        if(this.props.loggedIn)
        {
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            axios.post(`${url}mobileapi/msgcount`,{})
            .catch(function (error) {
                console.log("Try later");
            });
        }
    }
    handleClickOutside=(e)=>{
        
    }
    componentDidMount(){
        var self = this;
        this.getMessageCount();
        document.addEventListener('mousedown', this.handleClickOutside);
        let count;
        let tab = '';
        const {location : {pathname}} = window
            console.log(pathname,'PPPPPPPATHANAMAN')
            tab = pathname === "/search/seller" 
            ? 'findBuyers'
            :pathname === "/search/buyer" 
                ? 'findProperties' 
                : (pathname==='/infovideo/buyer' || pathname==='/infovideo/seller' || pathname==='/infovideo/realtor') 
                    ? 'infovedio' 
                    : (pathname==='/pricing/buyer' || pathname==='/pricing/seller' || pathname==='/pricing/realtor') 
                        ? 'pricing'
                        : (pathname === '/login') 
                            ? 'login' 
                            : (pathname === '/dashboard/post' || pathname === '/dashboard/pocketListing') ? 'listing'
                :''


            
            this.setState({
                tab:tab

            })
        messagecount((a)=>{
           
                 count = (a && a.count) ? a.count.filter(count => {
                    if (count.to_id === self.props.user.userdata.id) {
                        return count
                    }
                }) : []
            

            this.setState({
                msgcount : (count.length>0 && count[0]) ? count[0].msgCount : 0
            },()=>{console.log(this.state,'STATE CHANGE inHEADER')})
        });
    }
    blurdropdownnav = (e) => {
        this.setState({ profiledropToggle: false, navWide: ""})
    }
    closeToggle = (path,tab) => event => {
        console.log('CLOSE TOGGLE')
        this.setState({
            navWide: "",
            tab:tab
        }, () => {
            this.props.history.push(path)
        });
    }
    hilight=(hilight)=>e=>{
        console.log(hilight,'HILIGHT')
        this.setState({
            tab:hilight
        })
    }
    titlechange(value)
    {
        
       if(value.location.pathname=="/login")
       {
           this.state.title="DoorRise | Login"
       }
       if(value.location.pathname=="/register")
       {
           this.state.title="DoorRise | Register"
       }
       if(value.location.pathname=="/realtorproductpost")
       {
           this.state.title="DoorRise | Realtor ProductPost"
       }
       if(value.location.pathname=="/blog")
       {
           this.state.title="DoorRise | Blog"
       }
       if(value.location.pathname=="/ourteam")
       {
           this.state.title="DoorRise | OurTeam"
       }
       if(value.location.pathname=="/terms")
       {
           this.state.title="DoorRise | Terms"
       }
       if(value.location.pathname=="/privacy")
       {
           this.state.title="DoorRise | Privacy"
       }
       if(value.location.pathname=="/disclaimer")
       {
           this.state.title="DoorRise | Disclaimer"
       }
       if(value.location.pathname=="/product/pocketListing")
       {
           this.state.title="DoorRise | RealtorPocketListing"
       }
       if(value.location.pathname=="/forget_password/")
       {
           this.state.title="DoorRise |  Forget Password"
       }
       if(value.location.pathname=="/preregister")
       {
           this.state.title="DoorRise | Pre-Register"
       }
       if(value.location.pathname=="/")
       {
           this.state.title="DoorRise | Buy and Sell Off-Market Real Estate"
       }

    }
  render() {
    let { user, loggedIn , msg, msgLink, msgColor } = this.props;
    let {activeClassName,tab} = this.state
    let role = user && user.userdata ? user.userdata.role : '';
    return (
      <header>
        {this.titlechange(this.props.history)}
         <Helmet><title>{this.state.title}</title></Helmet>
            <nav className="navbar navbar-default navbar-fixed-top primary border-none">
                <div className="container-fluid pad-10">
                    <div className="container res991">
                        <div className="navbar-header snow-header">
                            <button type="button" 
                                className="navbar-toggle collapsed" 
                                data-toggle="collapse" 
                                data-target="#bs-example-navbar-collapse-1" 
                                aria-expanded="false"
                                onClick={this.burgerToggle} 
                                tabIndex="-1" 
                                onBlur={this.blurdropdownnav} 
                            >
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            {/* <Link className="navbar-brand logo-design" to="/">SHOWPADS LA</Link> */}
                            <Link className="navbar-brand logo-design" to="/" onClick={this.closeToggle('')}>
                                <img src="/images/doorrise-logo.png" className="responsive" />
                            </Link>
                            <a href="tel:310-461-4308" className="hidden C-a" to=""><i class="fas fa-phone-volume fs-18"></i>&nbsp;&nbsp;  310-461-4308</a>
                        </div>
                        <div  className={`${this.state.navWide} collapse navbar-collapse`} id="bs-example-navbar-collapse-1">
                            <ul className="nav navbar-nav navbar-right header-right">
                                <li className="res-call">
                                    <a href="tel:310-461-4308"><i class="fas fa-phone-volume fs-18"></i>&nbsp;&nbsp;  310-461-4308</a>
                                </li>
                                { role === "seller" && role !== "buyer" && role !==""   ? 
                                    <li class={tab === 'listing' ? activeClassName : ''} onClick={this.closeToggle('/dashboard/post','listing')}>
                                            {/* <Link className="btn btn-white" to="/dashboard/post">ADD A LISTING 1</Link> */}
                                            <Link className="btn btn-white" to='/dashboard/post'  onMouseDown={this.closeToggle('/dashboard/post','listing')}>ADD A LISTING</Link>
                                        </li> : 
                                    (role !== 'buyer' && role !== "")?<li  class={tab === 'listing' ? activeClassName : ''} onClick={this.closeToggle('/dashboard/pocketListing','listing')}><Link   className="btn btn-white" to="/dashboard/pocketListing"> POST LISTING </Link></li>:"" }
                                    
                                {role !== "seller" && role !== "realtor"  ? 
                                <li class={tab === 'findProperties' ? activeClassName : ''}><Link to="/search/buyer"  onMouseDown={this.closeToggle("/search/buyer",'findProperties')}>FIND PROPERTIES</Link></li> 
                                : '' }
                                { (role !== "realtor" && role !== "buyer") ? <li  class={tab === 'findBuyers' ? activeClassName : ''} onClick={this.hilight('findBuyers')}><Link to={loggedIn ? "/search/seller" : '/search/seller'} onMouseDown={loggedIn ? this.closeToggle("/search/seller",'findBuyers') : this.closeToggle("/search/seller")}>FIND BUYERS</Link></li>:''}
                                 {/* (role !== "realtor")?<li ><Link to="/search/seller" onMouseDown={this.closeToggle("/search/seller")}>SELL</Link></li> :""
                                 } */}
                                {/* <li className="divider"></li> */}
                                {role==='seller' && !loggedIn?<li ><Link to="/dashboard/post" > < a onMouseDown={this.closeToggle("/dashboard/post",'listing')} href="javascript:void(0)">ADD A LISTING </a></Link></li>:''}
                                <li class={tab === 'infovedio' ? activeClassName : ''} onClick={this.hilight('infovedio')}><Link to={loggedIn ? `/infovideo/${role}` : `/infovideo/buyer` } onMouseDown={this.closeToggle(loggedIn ? `/infovideo/${role}`:`/infovideo/buyer`,'infovedio')}>FAQ & VIDEOS</Link></li>
                                <li class={tab === 'pricing' ? activeClassName : ''} onClick={this.hilight('pricing')}><Link to={loggedIn ? `/pricing/${role}` : `/pricing/buyer` } onMouseDown={this.closeToggle(loggedIn ? `/pricing/${role}`:`/pricing/buyer`,'pricing')}>PRICING</Link></li>
                                
                                {loggedIn ?
                                <li class={tab === 'msg' ? activeClassName : ''} onClick={this.hilight('msg')}>
                                    <Link to='/dashboard/message'>
                                    <i className="fas fa-bell font-20 pointer" onMouseDown={this.closeToggle('/dashboard/message','msg')}>
                                        <span className={this.state.msgcount !==0 ? "noti-dot" : ''}>
                                            {this.state.msgcount !==0 && this.state.msgcount}
                                        </span>
                                    </i>
                                    </Link>
                                </li> : ''
                                }
                                {loggedIn ?
                                <>
                                    <li  >
                                        <Link style={{display:"flex"}} to={"#"} onClick={this.myAccountNav} className="nav-profile pad-8">
                                            <div className="profile head">
                                                <div className="div">
                                                    <span className="text-uppercase" data-original-title="" title="">{`${this.props.user.userdata.first_name[0]}.${this.props.user.userdata.last_name[0]}`}.</span>
                                                </div>
                                                 
                                            </div>
                                            <div className="header-name-display"  tabIndex="-1"  tabIndex="-1"  
                                            onBlur={this.blurdropdownnav}
                                            >{`${this.props.user.userdata.first_name} ${this.props.user.userdata.last_name}`}</div>
                                        </Link>
                                        { this.state.profiledropToggle &&
                                            <div className={this.state.profiledrop} >
                                                <ul>
                                                    <li className={tab==='profile' ? 'active':''}><Link to="/dashboard/buyerprofile"  onMouseDown={this.clickToRedirect} name="profileLink" ><i className="fas fa-user pad-rt-5"></i>Profile</Link></li>
                                                    <li className={tab==='dash' ? 'active':''}><Link to="" name="dashboardLink" onMouseDown={this.clickToRedirect}><i className="fas fa-tachometer-alt pad-rt-5"></i>Dashboard</Link></li>
                                                    <li><a href="javascript:void(0)" name="logoutLink" onMouseDown={this.clickToRedirect}><i className="fas fa-sign-out-alt pad-rt-5"></i>Logout</a></li>
                                                </ul>
                                            </div>
                                        }
                                    </li>
                                        <li className={tab==='profile' ? 'scnd-dash':'scnd-dash'}><Link to="/dashboard/buyerprofile" onMouseDown={this.clickToRedirect} name="profileLink" ><i className="fas fa-user pad-rt-5"></i>Profile</Link></li>
                                        <li className={tab==='dash' ? 'scnd-dash':'scnd-dash'}><Link to="" name="dashboardLink" onMouseDown={this.clickToRedirect}><i className="fas fa-tachometer-alt pad-rt-5"></i>Dashboard</Link></li>
                                        <li className="scnd-dash"><a href="javascript:void(0)" name="logoutLink" onMouseDown={this.clickToRedirect}><i className="fas fa-sign-out-alt pad-rt-5"></i>Logout</a></li>
                                    
                                     </>
                                    :
                                    <li class={tab === 'login' ? activeClassName : ''} onClick={this.hilight('login')}><Link to="/login" onMouseDown={this.closeToggle("/login",'login')}>LOGIN</Link>
                                     
                                   
                                    </li>
                                    
                                }
                                {role !== "seller" && role !== "realtor" && role !== "buyer"?
                                <li class={tab === 'login' ? activeClassName : ''} onClick={this.hilight('preregister')}><Link to="/preregister" onMouseDown={this.closeToggle("/preregister")}>PRE-REGISTRATION</Link>
                                     </li>
                                     :''}
                            </ul>
                            
                        </div>
                       
                        {msg  &&  <PushNotification msgColor={msgColor} msgLink={msgLink} msg={msg} snackBarClose={this.snackBarClose}/>}
                    </div>
                </div>
            </nav>
        </header>
    )
  }
}


function mapStateToProps(state) {
    console.log(state,"HHHHHHHHHHHHHHHHHHHHHHHHHHHH")
    const { user,loggedIn } = state.login;
    const { msg,msgColor,msgLink,headerName } = state.header;
    
    return { user,loggedIn,msg,msgColor,msgLink,headerName };
  }
  
  export default connect(mapStateToProps)(Header);