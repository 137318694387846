import React, { Component } from 'react';
import './Imageview.css';

class Imageview1 extends Component {
    state = {
        error: '',
        activeImage: 0 ,
        initialimage:"",
        url :  process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"

    }

    componentDidMount() {
        if(this.props.avatar!==""){
            this.setState({
                initialimage : this.props.avatar
            })
        }
    }
   
    Click = (event) =>{
        this.setState({
            activeImage: event.target.id
        })
    }
    delete = () => {

        if (this.props.imgSrc.length === 2) {
            this.setState({
                activeImage: 0
            })
        }
        this.props.imageDelete(this.state.activeImage)
    }
    render() {
        let { imgSrc, multiple, avatar,imageDelete,multi_alias } = this.props;
        return (
            <div className="form-group">
                <div id="singlealis" className="flex">
                    <span  className="single-alis">
                        <figure>
                            { imageDelete && imgSrc.length>0 ? <span id={this.state.activeImage} onClick = {this.delete} className="imageDelete">X</span>:""}
                            <img src={(imgSrc[this.state.activeImage]) ? imgSrc[this.state.activeImage] : (this.props.avatar != '') ? `${this.state.url}uploads/product/${this.state.initialimage}` : "/images/No_Image_Available.jpg"} alt="post-1" className="img-responsive" />
                        </figure>
                    </span>
                    <span className={multi_alias?"multi-alis":''} id="style-roll">
                        {multiple && imgSrc.length>1  && imgSrc.map((src, index) => (
                            <figure>
                                <img key={`key${index}`} src={src} alt="post" className="img-responsive" id={index} onClick={this.Click} />
                            </figure>
                        ))}
                    </span>
                </div>
            </div>
        )
    }
}

export default Imageview1;