import React, {Component} from 'react';
import axios from 'axios'
import  './email_forgetpassword.css';
import {NOTIFICATION} from '../../actions/index';
import {connect} from 'react-redux';
class Email_forgetPassword extends Component {
    state={
        password:"",
        confirmpassword:"",
         classname:"",
         error:"",
         code:""
      };
     
      handleerror(){
        if(this.state.confirmpassword ==='' && this.state.password === ''){
          this.setState({error:"Both the fields are required",classname:"alert alert-danger errMsgmargin"})
          return false;
        }else if(this.state.confirmpassword !== this.state.password){
            this.setState({error:"Password did not match",classname:"alert alert-danger errMsgmargin"})
            return false;
        }

        return true;
        
      };
       
        componentDidMount(){
            this.state.code = this.props.match.params.token
        };
      onChangeHandle = name => event => {
          this.setState({
              [name] : event.target.value,
              error:""
          });
          
      };
    formSubmit = (e) => {
        e.preventDefault()
        let body = {
            id: this.state.code,
            new_password: this.state.password
        }
        let url = process.env.BASE_URL ? process.env.BASE_URL : "http://showpads.auctionsoftware.com:7085/";
        var self = this
         url = url + "login/changepassword"
        if (this.handleerror()) {
            axios.post(url, body).then((response) => {
                if (response.data.success !== "no") {
                    self.props.dispatch(NOTIFICATION({ msg: response.data.message }))
                }
            }).catch((error) => {
                console.log(error)
            });
        }
    }
    render(){
        return(
            <main>
            <div className="snow-pad">
             <div className="center-part">
               <div className="row">
                   <div className="flex justify-center">
                       <div className="col-md-6">
                           <div className="forgot-form">
                           <form action="" className="pad-55 elty">
                               <div className="top-hd my-hd">
                               <h4>Enter New password</h4>
                               </div>
                               {/* <div className="form-group link-redir text-center ">
                                   <p className="text-dark font-15 pad-lr-20 pad-btm-20">A link to reset your password will be sent to 
                                       your registered Email Id 111</p>
                               </div> */}
                               <div className="form-group">
                               <label  >Password</label>
                               <input 
                               type="password" 
                               className="form-control border" 
                               id="exampleInputEmail1"  
                               placeholder="Enter new Password"
                               name="password"
                               value={this.state.password}
                                onChange={this.onChangeHandle("password")}
                               />
                               {/* <span style={{color:"red"}}>{this.state.error}</span> */}
                               {/* {this.state.error ?<div className={this.state.classname}>
                                 {this.state.error}
                              </div>:""} */}
                               </div>
                               <div className="form-group">
                               <label  >Confirm Password</label>
                               <input 
                               type="password" 
                               className="form-control border" 
                               id="exampleInputEmail1"  
                               placeholder="Confirm new Password"
                               name="confirmpassword"
                               value={this.state.confirmpassword}
                                onChange={this.onChangeHandle("confirmpassword")}
                               />
                               {/* <span style={{color:"red"}}>{this.state.error}</span> */}
                               {this.state.error ?<div className={this.state.classname}>
                                 {this.state.error}
                              </div>:""}
                               </div>
                               
                               {/* {this.state.error ?<div className={this.state.classname}>
                                  {this.state.error}
                               </div>:""} */}
                               <div className="form-group text-center pad-btm-20 pad-top-20 ">
                                   <button type="button"  
                                    onClick={this.formSubmit} 
                                   className="btn btn-secondary">Submit</button>
                               </div>                            
                               </form>
                           </div>
                       </div>
                   </div>
               </div>
              </div>
           </div>
         </main>
        )
    }
}


function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Email_forgetPassword); 