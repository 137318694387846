import React, {Component} from 'react';
import { connect } from 'react-redux';
import {StripeProvider,Elements} from 'react-stripe-elements';
import "./Stripe.css";
import CheckoutForm from "./checkoutForm";

class Stripe extends React.Component {
    constructor() {
        super();
        this.state = { stripe: null };
    }
    componentDidMount() {
        this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) });
    }
      
    render() {
        return (
            <StripeProvider stripe={this.state.stripe}>
                <Elements>
                   <CheckoutForm 
                        {...this.props}
                   />
                </Elements>
            </StripeProvider>
        );
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Stripe);