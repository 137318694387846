import React, {Component} from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import BuyerSearchBuynow from "../../../components/Cards/Search/BuyerSearch";
import { Suspense } from 'react';
import Loader from '../../../components/Loader/Loader';
import { NOTIFICATION } from '../../../actions/index';

class SavedProperty extends Component {
    state ={
        productDetails : [] ,
        SearchPayload: {},
        totalpage: '',
        isLoading: true,
        page_no:1,
        preapprove : 1
    }
    getWishlistProducts(){
        let self = this,payload = self.state.SearchPayload;
        
        let queryString = new Object();
        
        queryString["role"]     =   "buyer";
        queryString["page_no"]  =   (payload && payload.page_no) ? payload.page_no : 1;
        queryString["limit"]    =   6
        queryString =   JSON.stringify(queryString);
        
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let subUrl = `${url}mobileapi/userWlistData/${this.props.user.userdata.id}?query=${queryString}`
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        axios.get(subUrl).then((response)=>{
            console.log( "tot: ",response)
            let totalpage = response.data.totalResult ? Math.ceil(response.data.totalResult/JSON.parse(queryString).limit) : 0;
            let products = !self.state.isLoading ? self.state.productDetails.concat(response.data.userWlistData) : response.data.userWlistData;
            products = products.length > 0  ? products : [];
            this.setState({
                productDetails : products,
                isLoading : true,
                totalpage:totalpage,
                preapprove : response.data.preqalified
            },()=>{
                document.body.style.overflow = "auto";  
                document.body.style.paddingRight = "0px";
            })
          });
    }
    componentDidMount(){
        window.addEventListener('scroll', this.onScroll, false);
        this.getWishlistProducts();
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
      }
      onScroll = () => {
        let self = this.state;
        let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 1;
        let totalpage = self.totalpage;
    
        if ( (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) && 
            this.state.isLoading && Number(totalpage) > Number(prePage)) {
                document.body.style.overflow = "hidden";  
                document.body.style.paddingRight = "15px";
                self.SearchPayload["page_no"] = prePage+1;
                this.setState({
                isLoading: false,
                SearchPayload: this.state.SearchPayload,
                },()=>{
                this.getWishlistProducts();
            })
        }
      }
      preapproveRequest = () => {
        let self=this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(`${url}mobileapi/sendpreapproveemail`,{}).then((response)=>{
            (response.data.success=="yes")  ? this.props.dispatch(NOTIFICATION({msg:"Request for approval sent successfully"})) 
                                            : (response.data&&response.data.failedreason) 
                                                ? this.props.dispatch(NOTIFICATION({msg:response.data.failedreason,msgLink:'/dashboard/buyerprofile'})) 
                                                : this.props.dispatch(NOTIFICATION({msg:"err"}))
        }).catch((error)=>{
            self.setState({
                error:"Try Later"
            })
        })
    }
    render() {
        return (
            
                        <div className="col-md-9">
                            <div className="flex justify-between pre-txt">
                                <span className="karla fw-600 text-black">SAVED PROPERTIES</span>
                                        {this.state.preapprove == 0 &&
                                            <span> <button className="btn btn-green karla fw-600" onClick={this.preapproveRequest}>Get Pre-Approved</button></span>
                                        }
                            </div>
                            <div className="main-pro">
                                <div className="content-main box-shadow pad-15">
                                    <div className="flex justify-between flex-wrap">
                                    <Suspense fallback={<Loader/>}>
                                        <BuyerSearchBuynow value={this.state.productDetails} savedProperty={true} flex={true}/>
                                    </Suspense>
                                    
                                    </div>
                                    {!this.state.isLoading && <Loader/>}
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>


                    
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(SavedProperty);