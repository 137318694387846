import React, {Component} from 'react';
import './Listing.css';

import axios from 'axios';
import moment from 'moment';
import Popup from '../Popup/Popup';
import { NOTIFICATION } from  '../../actions/index';


class Relisting extends Component {
    state = {
        warning: false
    }

    popupHandler = (data) => {
        if(data === "2"){
            let projectData = this.props.projectData;
            this.loadProduct(projectData);
        }else{
            this.setState({
                warning :  !this.state.warning
            });
        }
    }

    loadProduct =  (data) =>{
        let self = this;
        data.market_status = "draft";
        data.start_date =  moment().format('YYYY-MM-DD HH:mm:ss')
        data.end_date = moment().add(5,"days").set({hour:23,minutes:59,second:0}).format('YYYY-MM-DD HH:mm:ss')
        
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        axios.post(url+'mobileapi/relisting/',data)
            .then((response) => {
                if(response && response.data){
                    self.props.dispatch(NOTIFICATION({msg: "Successfully property posted!"}));
                    self.props.history.push("/dashboard/seller");
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    }

  render(){
    let { warning } = this.state
    return (
      <span className="relisting">
            <button onClick={this.popupHandler} className="btn btn-primary" >Re-Listing</button>
            { warning && 
                <Popup color="green"
                    title="Confirmation"
                    description="If accept we are not clear / delete this property information rather than post a new property based on this property information."
                    yesTo="Cancel" // btn 1
                    noTo="Accept" // btn 2
                    btnCount={2} // 1 or 2
                    popupHandle={this.popupHandler}
                /> 
            }
      </span>  
    )
  }
}

export default Relisting;