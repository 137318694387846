import React, {Component} from 'react';

class SellerFeature extends Component {
  
    render(){
      return (
        
            <div class="feture-card">
                  <div class="profile">
                      <div class="div">
                        <span>C.V</span>
                        <span>BUYER</span>
                      </div>
                    </div>
                <div class="feturecard-body span-block">
                  <h4 class="text-light">Pre Approved</h4>
                  <span>Location : <b>Orange County</b></span>
                  <span>Property Type : <b>Condo</b></span>
                  <span>Expected Budget : <b>$25000 to $30000</b></span>
                  <span>Sale Type : <b> All Cash</b></span>
                </div>
                <button class="btn btn-secondary">Contact Buyer</button>
              </div>   
   
      )
    }
  }


  export default SellerFeature