import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import './Static.css';
import Buyer from '../../components/StaticContent/Buyer';
import Realtor from '../../components/StaticContent/Realtor';
import {NOTIFICATION} from '../../actions/index';
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import { Helmet } from 'react-helmet';
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: '50%',
    left: '50%',
    position:'absolute',
    transform: 'translate(-50%, -50%)'
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 620,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #757d7e",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1, 3, 1, 3)
  }
}));


  


class Priceing extends Component {
  
  state={
    statictabs:'buyer',
    classes: makeStyles(theme => ({
      paper: {
        position: "absolute",
        width: 620,
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #757d7e",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1, 3, 1, 3)
      }
    })),
    modalStyle: getModalStyle(),
    open: false,
  }
  // getModalStyle is not a pure function, we roll the style only on the first render
    handleOpen = () => {
      this.setState({
        open:true
      });
    }
    
  
     handleClose = () => {
      this.setState({
        open:false
      });
    };
  componentDidMount() {
    let { type: params } = this.props.match.params
    this.setState({
      statictabs:params
    })
  }
  changeTabs =(value)=>{
    console.log(value)
    this.setState({
      statictabs:value
    })
  }
  afterLoggedInmsg = (type)=>e=>{
    let msg = ''
    if(type==='seller'){
        msg='If you are interested in selling a property, please log into your Seller Account or Create a Seller Account.'
    }
    if(type==='buyer'){
        msg='To look for properties, you must login in as a Buyer or Create a Buyer account.'
        
    }
    if(type==='realtor'){
        msg='If you are interested in Pocket Listing a property, please log into your Realtor Account or Create a Realtor Account.'
    }
    this.props.dispatch(NOTIFICATION({msg:msg}))
}
  render(){
    let currenttab = this.state.statictabs
   
    let buyer = (currenttab === 'buyer') ? 'active':''
    let seller = (currenttab === 'seller') ? 'active':''
    let realtor = (currenttab === 'realtor') ? 'active':''
    const {loggedIn,user} = this.props
    if(loggedIn && user)
      var {userdata:{role}} = user 
    return (
      <main>
        <Helmet><title>{"DoorRise | InfoVideo"}</title></Helmet>
        <div className="center-part new-pop" >
          <div className="">
            <div className="flex justify-center" style={{"background":"#00ced514"}}>
              <div className="col-md-10">
                <div className="pricing text-center">
                  <div className="before-cont">
                  <h4 className="text-center">FAQ AND VIDEOS </h4>
                    <p>There’s never been a real estate COMMUNITY that was formed on the principle that home Buyers and Sellers are partners...not adversaries. DoorRise is real estate matchmaking, a two-way search engine, that allows qualified Buyers and motivated Sellers to find each other easily, initiate deals, and receive expert service to close quickly and save money</p>
                    {/* <p>HomesDirect brings Buyers and Sellers together for an easy, transparent, win-win experience.</p> */}
                    
                  </div>
                    <ul class="nav nav-tabs" role="tablist">
                      <li className={buyer}><a  aria-controls="home" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('buyer')}>BUYERS</a></li>
                      <li className={seller}><a  aria-controls="profile" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('seller')}>SELLERS</a></li>
                      <li className={realtor}><a  aria-controls="messages" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('realtor')}>REALTORS</a></li>
                    </ul>
                    <div className="tab-content">
                    {currenttab === 'realtor' ? <Realtor
                      loggedIn={loggedIn}
                      role={role}
                      // msg={this.afterLoggedInmsg}
                    /> :''}  
                    {currenttab === 'buyer' ? <Buyer
                      loggedIn={loggedIn}
                      role={role}
                      msg={this.afterLoggedInmsg}
                    /> : currenttab === 'seller' ? 
                      <div  id="profile">
                        <div className="flex justify-between align-center flex-wrap">
                            <h4 className="text-left">SELLER FAQ</h4>
                            <button type="button" className="vid-eo" onClick={this.handleOpen}>Watch Seller Videos</button>
                        </div>
                        <div className="static-accordient">
                            <div className="tabs">
                                <div className="tab">
                                    <input type="radio" id="rd1" name="rd" />
                                    <label className="tab-label" for="rd1">What is DoorRise? </label>
                                    <div className="tab-content">
                                    <p>DoorRise is a robust real estate community where Sellers of homes, income properties, trust deeds and wholesale contracts can quickly find qualified Buyers who are willing to pay a fair price. Even better, Sellers using our platform receive FULL real estate services, at a reduced commission, from experienced local agents.</p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd2" name="rd" />
                                    <label className="tab-label" for="rd2">How is DoorRise different from Listing sites like Zillow?  </label>
                                    <div className="tab-content">
                                    <p>We offer <b> Two-Way Search: </b>  Traditional listing sites provide one-way search where Buyers and Agents do all the searching. Buyers search for properties on DoorRise as well, but we also allow our Sellers to browse our constantly-expanding community of Buyer profiles and present your listing to multiple qualified prospects. Buyers who hear from Sellers directly are much more motivated to make deals. As a result, our Two-way search feature helps listings move at light speed at reduced cost.  </p>
                                       
                                    <p><b>Auction Option:</b> Traditional listing sites don’t allow Sellers to Auction their properties. We do! With Auctions, Sellers can create a feeding frenzy, allowing multiple Buyers to bid the property up to an attractive market price</p>
                                    <p><b>Full Service:</b>  Whether Listing or Auctioning, Sellers need never negotiate directly with Buyers or manage mountains of forms and contracts. We will assign one of our licensed local real estate experts to handle everything from opening offer to final closing.  
                                    </p>
                                    <button className="btn btn-primary">Watch Video for More</button>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd3" name="rd" />
                                    <label className="tab-label" for="rd3">What if I don’t find any buyers on DoorRise? </label>
                                    <div className="tab-content">
                                    <p>Depending on many factors, including a property’s price, location or condition, some listings take longer than others to attract the right Buyer. That’s why we assign every listing to one of our expert local agents.  Assigned Agents list HomeDirect properties on the local MLS and other top listing sites (Like Zillow) to get maximum exposure. </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd4" name="rd" />
                                    <label className="tab-label" for="rd4">How is DoorRise different from a traditional real estate agency? </label>
                                    <div className="tab-content">
                                    <p>Simple. We are NOT a real estate agency.  We are a MARKETPLACE that connects Sellers and Buyers DIRECTLY. Then we assign an expert local Realtor to handle all the important details like offers, negotiations, escrow and closing...all for an affordable commission. Some Sellers prefer discretion.  We’re fine if you don’t want a sign in the yard and endless public open houses (so your neighbors and looky-loos can snoop around). We specialize in private open houses attended by buyers who have already indicated a strong interest to buy and who have been pre-screened and pre-approved for lending. </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd5" name="rd" />
                                    <label className="tab-label" for="rd5">How is the DoorRise process different than For Sale By Owner? </label>
                                    <div className="tab-content">
                                    <p>It’s like night and day.  Unlike For Sale by Owner transactions, our Sellers never worry about marketing their own properties, getting random knocks on the door, getting harassed by agents, negotiating with Buyers or handling contracts. Once Sellers list on our DoorRise, they receive complete Agent support from Listing to Close.   </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd6" name="rd" />
                                    <label className="tab-label" for="rd6">How do I know what to list my property for on DoorRise?</label>
                                    <div className="tab-content">
                                         <p>Pricing is the most critical aspect that will determine the success of a real estate sale.  Setting an attractive asking price, or opening bid price, is the key to generating multiple buyer offers. Each Seller’s Assigned Agent will provide comps and set up an appointment for a physical inspection.  This Inspection report will give a clear picture of the property’s condition from foundation to roof. With this report and the local sales comps, the Agent will recommend an attractive asking or auction price to attract multiple offers. </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd7" name="rd" />
                                    <label className="tab-label" for="rd7">What happens when the listing goes live on DoorRise?</label>
                                    <div className="tab-content">
                                    <p>Each Seller receives an email notification when the listing goes live. Once the listing is live, Sellers can use our user-friendly filters to easily find and notify suitable Buyers about your listing.  Your Assigned Agent will contact each Buyer who indicates interest in your listing, qualify them and coordinate private showings and offers. Once offers are submitted, the Assigned Agent assists Sellers in evaluating offers and opening escrow. </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd8" name="rd" />
                                    <label className="tab-label" for="rd8">Does one Agent represent both the Seller, as well as the Buyer?  </label>
                                    <div className="tab-content">
                                    <p>The Seller determines whether he/she wants one agent to represent both Seller and Buyer. This is called a Dual Agent. Keep in mind, some Buyers are comfortable with Dual agent transactions; some are not.  In the case of a Dual Agent, the Seller commission is only 2.95%. For transactions with two Agents, the commission is 4.25%, which is split between the Seller’s Agent and the Buyer’s Agent. </p>
                                    {/* <div className="text-center">
                                    <button className="btn btn-primary"> Watch Video for More</button>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd9" name="rd" />
                                    <label className="tab-label" for="rd9">Can I pick my own Agent? </label>
                                    <div className="tab-content">
                                    <p>DoorRise has agreements with top local Realtors, who are specially-trained in the needs of our unique marketplace. When Sellers post a property, we assign it to one of these agents.  These agents provide local sales comps, arrange for inspections and interact with Buyers.  If there are any problems with the agent assigned, we will provide a replacement from our preferred agent network. </p>
                                   
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd10" name="rd" />
                                    <label className="tab-label" for="rd10">What’s the difference between a Listing and an Auction? </label>
                                    <div className="tab-content">
                                    <p>With <b>Listings,</b> Sellers set an asking price and a deadline for receiving offers. Interested Buyers are invited to attend a private Open House before placing Offers.  Buyers who don’t attend the open house can still submit offers.  After the offer deadline, Assigned Agents work with Sellers to request the highest and best offers from all Buyers.  Sellers can accept one of the offers, or reject them all. </p>
                                    <p>In an <b>Auction,</b> Sellers set a minimum bid, the private Open House date, the Auction start date, and Auction end date.  Buyers have the opportunity to attend the Private Open House before the auction or they can submit bids based on the listing detail and the inspection report (if available).  During the Auction period, Buyers can see other Buyers’ bids and raise or cancel their bids accordingly.  When the Auction ends, Sellers have the right to accept a bid or reject all bids.
                                    </p>
                                    <div className="text-center">
                                    <button className="btn btn-primary"> Watch Video for More</button>
                                    </div>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd11" name="rd" />
                                    <label className="tab-label" for="rd11">Can I only sell houses using DoorRise?   </label>
                                    <div className="tab-content">
                                    <p>Users can sell or auction any kind of residential real estate. This includes Single Family Homes, Condos, Multi-Family Dwellings, Land, as well as Trust Deeds, and Wholesale Purchase Contracts.  For trust deeds, and contracts, DoorRise does not provide Realtor assistance.  Sale of these products is for more seasoned Sellers.  We simply charge a monthly subscription fee to list these products for sale.  As always, Buyers can search for these specialty deals and and Sellers can search for Buyers  </p>

                                    <div className="text-center">
                                    <button className="btn btn-primary"> Watch Video for More</button>
                                    </div>
                                    </div>


                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd12" name="rd" />
                                    <label className="tab-label" for="rd12">How do I know I’m getting the best price when I’m selling Off-Market on your site?  </label>
                                    <div className="tab-content">
                                    <p>One reason we assign agents to each listing is because they can provide local comparative sales info to help determine the market listing price.  Of course, sales comps are just the starting point. The ultimate value of your property is based on what qualified Buyers are willing to pay.  Your listing doesn’t just appear On DoorRise. We syndicate listings to Zillow and even the MLS so you’ll  get plenty of exposure with buyers.  Offers from Buyers could be less or more than the listing price, but at the end of the day, our aim is to sell each property efficiently at or above the fair market price.</p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd13" name="rd" />
                                    <label className="tab-label" for="rd13">Am I required to have a physical  inspection report when I list with DoorRise?  </label>
                                    <div className="tab-content">
                                    <p>While Inspection reports are not required, they are strongly recommended.  Not only does having a  licensed inspection give Sellers a clear understanding of their property's condition, it is also a strong negotiating tool when dealing with potential Buyers.  Providing Buyers with inspection reports prior to them making offers or bidding on Auctions significantly minimizes the opportunity for Buyers to re-negotiate repairs and other concessions during escrow.   Plus, we refund the cost of the inspection when Sellers use one of our preferred licensed pros.  You can’t lose!  </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd14" name="rd" />
                                    <label className="tab-label" for="rd14">Buyers Receive a copy of the inspection report and Comps? </label>
                                    <div className="tab-content">
                                    <p>We always provide the home inspection report to interested Buyers, when available. Again, we feel the best transactions that close most easily are those where buyer and seller go into the deal with their eyes open.  Buyers will typically conduct their own inspection during the escrow period. If there is an existing report, very little new information should be revealed.   As for Comps, in a dual agent transaction, the Assigned agent will also represent the Buyer, so  the Buyer will also receive a copy of the comps.  If the Buyer is working with another agent, that agent will likely provide comps as well.  Either way, we believe Both Buyer and Seller should enter escrow motivated and fully-informed.  </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd15" name="rd" />
                                    <label className="tab-label" for="rd15">What happens if my property doesn’t sell? </label>
                                    <div className="tab-content">
                                    <p>Typically, if a property doesn’t sell, there is something wrong with the price, based on its location and/or condition.  You have several options. You can reduce the price of your listing, which is usually the best choice, if the property is not attracting buyers.  Of course, you can  leave it at the current price and continue to look for interested Buyers or wait for Buyers to find you.  You can initiate a new auction with a lower minimum bid, or you can offer incentives like funds to the buyer at closing.  At the end of the day, we want you to be happy and if you have realistic expectations about the market value of your property, we will find a way to get your property sold for the best market price.  </p>
                                    </div>
                                </div>
                                <div className="tab">
                                    <input type="radio" id="rd16" name="rd" />
                                    <label className="tab-label" for="rd16">Am I required to use your service providers? </label>
                                    <div className="tab-content">
                                    <p>You are not, but we think you’ll be happy if you do. Our agreements mean they offer the most competitive rates out there, and in many cases, we offer you rebates at close of escrow for trusting our preferred team with your business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center static-btn">
                        <h4>Any other questions, <Link to={{pathname:'/',state:{scrollTo:true}}}>EMAIL US</Link></h4>
                                <p> <b> OR </b></p>
                                {((loggedIn  && role != 'realtor' && role != 'buyer') || (!loggedIn || (!loggedIn && role ==='seller')))
                                      ? 
                                      <button className="btn btn-primary"><Link to={loggedIn?'/dashboard/post':"/productpost"}>START SELLING</Link></button>
                                      :
                                      <button className="btn btn-primary" onClick={this.afterLoggedInmsg('seller')}>START SELLING</button>
                                  
                                  }
                                {/* <Link to={loggedIn?'/dashboard/post':"/productpost"}><button className="btn btn-primary">START SELLING</button></Link> */}
                        </div>
                      </div>
                   :''}                     
                     
                     </div>
                </div>
                {/* <div className="videos-info">
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4>BUYER</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4>SELLER</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4 >LIST</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div> 
          </div>
          <Modal
              aria-labelledby="simple-modal-title"
              className="new-st"
              aria-describedby="simple-modal-description"
              open={this.state.open}
              onClose={this.handleClose}>
              <div style={this.state.modalStyle} className={this.state.classes.paper}>
                    <iframe width="560" className="ifram-wth ssss" height="315" src="https://www.youtube.com/embed/-VWyzqGtVIA?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </Modal>      
        </div>
      </main>
    )
  }
}


function mapStateToProps(state) {
  const { user, loggedIn  } = state.login;
  const { pocketlisting_buyer_search  } = state.header;
  return { user, loggedIn , pocketlisting_buyer_search};
}

export default connect(mapStateToProps)(Priceing);