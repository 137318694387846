import React , { Component } from 'react'
import './BuyerPreference.css'

class BuyerPreference extends Component {
    render(){
        return (
            <div className="center-part">
            <div className="container custom-container">
              <section className="filter-body Buyer-dash ">
                <div className="row">
          
                  <div className="col-md-9">
                      <div className="flex justify-between pre-txt">
                          <span className="karla fw-600 text-black">PREFERENCES</span>
                          <span> <button className="btn btn-green karla fw-600">Get Pre-Approved</button></span>
                     </div>
                      <div className="main-pro">
                        <div className="content-main box-shadow pad-30">
                            <form className="site-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Max Buying Price 
                                            </label>
                                            <input type="text" className="form-control site-input" id="exampleInputEmail1" placeholder="Max Buying Price"/>
                                        </div>                                        
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Preferred locations </label>
                                            <input type="text" className="form-control site-input" id="exampleInputEmail1" placeholder="Preferred location"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group selectdiv">
                                            <label >Preferred Property type</label>
                                            <select name="" className="form-control site-input"  id="">
                                                <option value="City">Preferred Property type</option>
                                            </select>
                                        </div>                                        
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group selectdiv">
                                            <label >Preferred Beds/baths</label>
                                            <select name="" className="form-control site-input"  id="">
                                                <option value="City">Preferred Beds/baths</option>
                                            </select>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Preferred Sale Type (Owner, Agent, Any) </label>
                                                <select name="" className="form-control site-input"  id="">
                                                    <option value="City">Preferred Sale Type (Owner, Agent, Any)</option>
                                                </select>
                                            </div>                                        
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Purchase type</label>
                                                <input type="text" className="form-control site-input" id="exampleInputEmail1" placeholder="Cash"/>
                                            </div>
                                        </div>
                                </div>
                                <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Pre-approval Letter Obtained (Y/N)</label>
                                                <span className="check flex justify-start">
                                                    <label className="con">Yes
                                                        <input type="radio" checked="checked" name="radio"/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                    <label className="con">No
                                                        <input type="radio" checked="checked" name="radio"/>
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </span>
                                            </div>                                        
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Time frame of readiness to purchase </label>
                                                <select name="" className="form-control site-input"  id="">
                                                    <option value="City">Time frame of readiness to purchase </option>
                                                </select>
                                            </div>
                                        </div>
                                </div>
                                <div className="text-left pad-tb-30">
                                    <button type="submit" className="btn btn-primary ">Save</button>
                                 </div>
                            </form>
                        </div>
                      </div>
                  </div>
                </div>
              </section>
            </div>
        </div>
        )
    }
}

export default BuyerPreference;