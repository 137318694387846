import React, {Component , lazy , Suspense} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import "./MyListings.css";
import axios from 'axios';
import Cookies from 'universal-cookie'
import Loader from '../../components/Loader/Loader';
// import {LOGIN,LOGIN_FAILURE,LOGOUT} from '../../actions';
const ViewOne = React.lazy(() => import('../../components/Cards/ViewOne'));


const cookies = new Cookies();
class MyListings extends Component {
    state = {
        SearchPayload: {},
        page_no: 1,
        listingData: [],
        isloading: true,
        totalpage: {},
        role: "seller",
        url: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"
    }
    getListing() {
        let url = `${this.state.url}mobileapi/sh_listings_seller`,
            payload = this.state.SearchPayload,
            self = this;
        payload['role'] = "seller";
        payload['limit'] = 2;
        payload['page_no'] = (payload && payload.page_no) ? payload.page_no : 1
        axios.defaults.headers.common['Authorization'] = (this.props.user) ? this.props.user.data : {};
        axios.post(url, payload).then(response => {
            let stateObj = {}
            stateObj["totalpage"] = Object.keys(response.data).length > 0 ? Math.ceil(response.data.totalproductslength / payload['limit']) : 0;
            let listings = self.state.isloading ? response.data.listings ? self.state.listingData.concat(response.data.listings) :[]: response.data.listings;
            stateObj['listingData'] = (listings && listings.length > 0) ? listings : [];
            stateObj['isloading'] = true;
            self.setState(stateObj, () => {
                document.body.style.overflow = "auto";
                document.body.style.paddingRight = "0px";
            })
        })
    }
    onScroll = () => {
        let self = this.state;
        let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 2;
        console.log(Number(self.totalpage) , Number(prePage) , self.isloading)
        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight) &&
            this.state.isloading && Number(self.totalpage) > Number(prePage)) {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            self.SearchPayload["page_no"] = prePage + 1;
            this.setState({
                isLoading: false,
                SearchPayload: this.state.SearchPayload
            }, () => {
                this.getListing();
            })
        }

    }
    componentDidMount() {
        this.getListing();
        window.addEventListener('scroll', this.onScroll, false);
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.onScroll, false);
    }
    render() {
        return (
            <div className="col-md-9">
                <div class="flex justify-between pre-txt">
                    <span class="karla fw-600 text-black text-uppercase">My Listings</span>
                </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">

                        <Suspense fallback={<Loader />}>
                            <ViewOne projToDisplay={this.state.listingData} history={this.props.history} user={this.props.user} />
                        </Suspense>
                        {this.state.isLoading && <Loader />}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(MyListings);