import React, {Component , lazy , Suspense} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import "./PaymentHistory.css";
import axios from 'axios';
import Cookies from 'universal-cookie'
import Loader from '../../components/Loader/Loader';
class PaymentHistory extends Component {
    state = {
       paymenthistory : [] 
    }
    getPayment() {
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/",
            self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        axios.get(`${url}mobileapi/sh_paymenthistory`).then(response => {
            console.log(response);
            let stateobj = {};
            stateobj["paymenthistory"] = response.data.paymenthistory ?  self.state.paymenthistory.concat(response.data.paymenthistory) : []
            stateobj["paymenthistory"] = (stateobj["paymenthistory"].length > 0) ? stateobj["paymenthistory"] : [];
            this.setState(stateobj);
        })
    }
    onScroll = () => {
        let self = this.state;
        let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 2;
        console.log(Number(self.totalpage) , Number(prePage) , self.isloading)
        if (
            (window.innerHeight + window.scrollY) >= (document.body.offsetHeight) &&
            this.state.isloading && Number(self.totalpage) > Number(prePage)) {
            document.body.style.overflow = "hidden";
            document.body.style.paddingRight = "15px";
            self.SearchPayload["page_no"] = prePage + 1;
            // this.setState({
            //     isLoading: false,
            //     SearchPayload: this.state.SearchPayload
            // }, () => {
            //     this.getListing();
            // })
        }

    }
    componentDidMount() {
        this.getPayment();
        //window.addEventListener('scroll', this.onScroll, false);
    }
    componentWillUnmount(){
        //window.removeEventListener('scroll', this.onScroll, false);
    }
    render() {
        return (
            <div className="col-md-9">
                <div class="flex justify-between pre-txt">
                    <span class="karla fw-600 text-black text-uppercase">Payment History</span>
                </div>
                <div className="main-pro">
                    <div className="row content-main box-shadow pad-30">
                        <div className="table-responsive" id="style-roll">
                            <table className="table">
                                <thead >
                                    <tr className="text-center">
                                        <th>Payment Type</th>
                                        <th>Gateway</th>
                                        <th>Amount($)</th>
                                        <th>Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {/* <tr className="wt">
                                                <td></td>
                                                <td></td>
                                                <td></td> */}
                                    {/* <td></td> */}
                                    {/* </tr> */}
                                    {this.state.paymenthistory.length > 0 ? this.state.paymenthistory.map((payment, index) => {
                                        return <tr
                                            key={"tableData-" + index}
                                            id={index}
                                        // onClick={this.props.isAwardEnable ? this.selectHandler(data.bidid,data.custom_awarded) : ''} 
                                        // style={( this.props.isAwardEnable && data.custom_awarded === "1")? {background:"#cbe4dc"} : {}}
                                        >
                                            <td>{payment.istatus}</td>
                                            <td>{payment.gateway}</td>
                                            <td>${payment.amount}</td>
                                            <td>{payment.paymentdate}</td>
                                            <td>{payment.status}</td>
                                            {/* <td>test</td> */}
                                        </tr>
                                    }) : <tr class="text-center">
                                            <td colSpan={4} class="paddingem-top-1"><label class="text-noproduct-label">No Payment History</label></td>
                                        </tr>}


                                </tbody>
                            </table>
                            <div className="text-center"><span style={{ color: "red" }}></span></div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(PaymentHistory);