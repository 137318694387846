// import React from 'react'
// import Datetime  from 'react-datetime'
// import moment from 'moment';
// import './datetime.css'
// class DateTime extends React.Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         date: "1990-06-05",
//         format: "YYYY-MM-DD",
//         inputFormat: "DD/MM/YYYY",
//         mode: "date"
//       };
//     }
  
//     handleChange = (newDate) => {
//       console.log("newDate", moment(newDate).format());
//       return this.setState({date: newDate});
//     }
  
//     render() {
//       const {placeholder,handleChange,returnLabel,value} = this.props;
//       return <input 
//                     id="datetimepick"
//                     type='datetime-local'
//                     class="form-control site-input date"
//                      placeholder={placeholder ? placeholder : 'Date' } 
//                      onChange={handleChange(returnLabel)} 
//                     //  value={value}
//                      />
      
//     //   <DateTimeField
//     //     dateTime={date}
//     //     format={format}
//     //     viewMode={mode}
//     //     inputFormat={inputFormat}
//     //     onChange={this.handleChange}
//     //  />;

//     }
//   }

//   export default DateTime


import React, { useState } from "react";
import { DateTimePicker, KeyboardDateTimePicker,MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import lightBlue from "@material-ui/core/colors/lightBlue";
import moment from 'moment';
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
const materialTheme = createMuiTheme({
  typography: {
    // ...
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    // ...
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: "white",
      },
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700,
      },
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      dayDisabled: {
        color: lightBlue["100"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"],
      },
    },
  },
});


function InlineDateTimePickerDemo(props) {
  const [selectedDate, handleDateChange] = useState(new Date("2018-01-01T00:00:00.000Z"));
  function cd(val,v){
    console.log(moment(val).format(),v);
    console.log(props)
    props.handleChange(props.returnLabel,moment(val).format('YYYY-MM-DD HH:mm:ss'))
  }
  return (
    <>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* <DateTimePicker
        variant="inline"
        label="Basic example"
        value={selectedDate}
        onChange={handleDateChange}
      /> */}
  <ThemeProvider theme={materialTheme}>
      <DateTimePicker 
        variant="inline"
        label=""
        value={props.value}
        onChange={cd}
        onError={console.log}
        disablePast={true}
        disableToolbar={false}
        ampm={false}
        format="MM-DD-YYYY HH:mm:ss"
      />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
    </>
  );
}

export default InlineDateTimePickerDemo;