import React, {Component} from 'react';
import axios from 'axios';
import './ContactUs.css';
import { Link } from 'react-router-dom';
import {connect} from "react-redux";
import {NOTIFICATION} from '../../actions/index';
class ContactUs extends Component {
    state   =   {
        firstname : {
            data        :  "" ,
            required    :  true,
            error       :  'Required' 

        } ,
        emailAddress : {
            data        :  "" ,
            required    :  true,
            error       :  'Required'
        } ,
        comments : {
            data        :  "" ,
            required    :  true,
            error       :  'Required'
        } ,
        phone : {
            data : '',
            required : true ,
            error : 'Required'
        },
        formError : {} ,
        errorMsg : "" ,
        err_classname : ""
    }
    handleChange = name => event => {
        let prestate    =   this.state[name]
        prestate.data   =   event.target.value 
        if(this.state.formError.hasOwnProperty(name)){
            delete this.state.formError[name]
        }
        this.setState({
            [name] : prestate ,
            formError : this.state.formError
        })
    }
    handleSubmit = (e)  => {
        e.preventDefault()
        let self = this;
        this.handleValidation();
        console.log(this.props)
        if(!Object.keys(this.state.formError).length > 0){
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            let data = {
                fromid : this.state.emailAddress.data ,
                messages : this.state.comments.data ,
                subject : 'Message to Admin' ,
                outbids_ku : 'no' ,
                cutomermail :  this.state.emailAddress.data , 
                userName :this.state.firstname.data ,
                contact_us : 1
            }
            axios.post(`${url}index/contact`,data).then((response)=>{
                let msg ='';
                if(response.data.success==='yes'){
                    self.state.err_classname = "alert-success"
                    msg = response.data.data
                }else{
                    self.state.err_classname = "alert-danger"
                    msg =   response.data.failedreason
                }
                self.props.dispatch(NOTIFICATION({msg:msg}))
                self.state.firstname.data = ""
                self.state.emailAddress.data = ""
                self.state.comments.data = ""
                self.state.phone.data = ""
                self.setState({
                    err_classname : self.state.err_classname ,
                    errorMsg : self.state.errorMsg,
                    firstname : self.state.firstname,
                    emailAddress:self.state.emailAddress,
                    comments : self.state.comments,
                    phone : self.state.phone
                })
            })
        }
    }
    handleValidation(){
        let prestate = this.state;
       Object.keys(prestate).map((ele)  =>  {
           if(prestate[ele].data === '' && prestate[ele].required === true){
               prestate.formError[ele] = prestate[ele].error
           }
           return prestate;
       });
       this.setState({
           formError : prestate.formError
       },()=>{console.log(this.state,"kkkkk")})
    
    }
    render(){
        return (
            <div className="Home-form">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="Con-lft">                                 
                            <h2>For Special Deals and Market Trend Data</h2>
                            <a className="btn btn-secondary" href=' https://www.facebook.com/groups/790237271433607/' >Join our Facebook Group</a>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6 col-md-offset-1 col-sm-offset-0">
                            <div className="">
                                <h4 className="karla">
                                Have Questions or Comments? <br></br>
                                        We're listening!
                                </h4>
                                {this.state.errorMsg !== "" && <h6  style={{padding:"10px"}}className={`alertalert ${this.state.err_classname}`} role="alert">
                                    {this.state.errorMsg}
                                </h6>}
                                <form className="" onSubmit={this.handleSubmit}>
                                    <div className="form-group">
                                        <input type="text" className="form-control site-input" placeholder="First Name" 
                                        value={this.state.firstname.data}
                                        onChange={this.handleChange('firstname')}/>
                                        <span style={{color:"red"}}>{this.state.formError['firstname']}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control site-input" placeholder="Email Address" 
                                        value={this.state.emailAddress.data}
                                        onChange={this.handleChange('emailAddress')}/>
                                        <span style={{color:"red"}}>{this.state.formError['emailAddress']}</span>
                                    </div>
                                    <div className="form-group">
                                        <input type="phone" className="form-control site-input" placeholder="Phone Number" 
                                        value={this.state.phone.data}
                                        onChange={this.handleChange('phone')}/>
                                        <span style={{color:"red"}}>{this.state.formError['phone']}</span>
                                    </div>
                                    <div className="form-group">
                                        <textarea type="text" rows="5" cols="5" className="form-control site-input" placeholder="Comment"
                                        value={this.state.comments.data}
                                        onChange={this.handleChange('comments')}></textarea>
                                        <span style={{color:"red"}}>{this.state.formError['comments']}</span>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary btn-block fw-600">SUBMIT</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>    
            </div>
            )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(ContactUs);