import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
//Router import
import Buyerdashboard from './Buyer/Buyerdashboard';

import Sellerdashboard from './Seller/Sellerdashboard';
import Realtordashboard from './Realtor/Realtordashboard';
import Mymatching from '../mymatching/mymatching';
import Transactiontypematch from '../transactiontypematch/match';

import Profile from '../Profile/Profile';
import Payment from '../Payment/Payment';
import Message from '../Messages/Message';
import BuyerPreference from '../../views/BuyerPreference/BuyerPreference';
import Productpost from '../../views/Productpost/Productpost';
import Productpost2 from '../../views/Productpost/Productpost1 copy';
import SavedProperty from './Buyer/SavedProperty';
import Buyerprofile from  '../../views/Profile/Buyerprofile/Buyerprofile';
import SavedBuyer from  '../../views/SavedBuyer/SavedBuyer'
import Offers from  '../../views/Offers/Offers'
import { Switch, Route } from "react-router-dom";
import { connect } from 'react-redux'
import SellerSearch from '../../components/Cards/Search/SellerSearch';
import  SideNav  from '../../components/SideNav/SideNav';
import Matchbuyer from '../../views/Matchbuyer/Match';
import ChangePassword from '../ChangePassword/change_password';
import MyListings from '../../views/MyListings/MyListings';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import RealtorPocketListing from '../RealtorPocketListing/RealtorPocketListing';
import SavedSearchList from '../../components/SavedSearch/SavedSearchList';
import Interested from '../../views/Interested/interest';
let dashboardRoutes = [
  {
    path: "/match/:id/:auction_id",
    component: Matchbuyer,
    isAuthWant:true 
  },
  {
    path:"/iaminterested",
    component: Interested,
    isAuthWant:true 
  },
   {
    path:'/pocketlisting',
    component:Productpost2
  },
  {
    path: "/savedsearch",
    component: SavedSearchList
  },
  {
    path: "/message/:id/:username/:role",
    component: Message
  },
  {
    path: "/message",
    component: Message
  },
  {
    path: "/payment",
    component: Payment
  },
  {
    path: "/profile",
    component: Profile
  },
  {
    path: "/matchtransactiontype",
    component: Transactiontypematch
  },
  {
    path: "/seller",
    component: Sellerdashboard
  },
  {
    path: "/buyer",
    component: Buyerdashboard
  },
  {
    path: "/preference",
    component: BuyerPreference
  },
  {
    path: "/realtor",
    component: Realtordashboard
  },
  {
    path: "/changepassword",
    component: ChangePassword
  },
  {
    path: "/savedproperty",
    component: SavedProperty
  },
  {
    path: "/post",
    component: Productpost
  },
  {
    path : "/buyerprofile",
    component : Buyerprofile
  },
  {
    path : "/savedbuyer",
    component : SavedBuyer
  },
  {
    path : "/offers",
    component : Offers
  },
  {
    path : "/mylistings",
    component : MyListings
  },
  {
    path:"/mymatch",
    component:Mymatching
  },
  {
    path:'/paymenthistory',
    component:PaymentHistory
  }
];

const switchRoutes = (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      return <Route path={"/dashboard"+prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);
class Dashboard extends Component {
  render() {
    const {user} = this.props;
        return (
            <main>
              <Helmet><title>{"DoorRise | DashBoard"}</title></Helmet>
                <div className="center-part">
                    <div className="container custom-container">
                        <section className="filter-body Buyer-dash ">
                            <div className="row">
                                <div className="col-md-3">
                                    <SideNav userdata={user.userdata}/>
                                </div>
                                {switchRoutes}
                            </div>
                        </section>
                    </div>
                </div>
            </main>
        );
    }
}

function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(Dashboard);
