import React from 'react';
import './Buyer.css';
import { Link } from 'react-router-dom';

const Buyer_pricing =(props)=>{
    let {loggedIn,role,msg} = props
    return (
        <div className="active sta-tabs" id="home">        
            <h4 className="text-left">BUYER’S PRICING</h4>
            <div className="static-accordient">
                <div className="tabs">
                    <ul>
                        <li>FREE for Buyers to use</li>
                        <li>Get $500 Rebate toward closing costs (Escrow, title, lending) </li>
                    </ul>
                  
                </div>
            </div>
            <div className="text-center static-btn" style={{"display":"flex","justify-content":"center","align-items":"center"}}>
            <h4>Any other questions, <Link to={{pathname:'/',state:{scrollTo:true}}}>EMAIL US</Link></h4>
                <p style={{"margin":" 0 20px"}}> <b> OR </b></p>
                {((loggedIn  && role != 'seller' && role != 'realtor') || (!loggedIn || (!loggedIn && role ==='buyer')))
                    ? 
                    <button className="btn btn-primary"><Link to='/search/buyer'>START BUYING</Link></button>
                    :
                    <button className="btn btn-primary" onClick={msg('buyer')}>START BUYING</button>
                
                }
            </div>
        </div>
    )
}

export default Buyer_pricing;