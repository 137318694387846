import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import axios from 'axios';
import SideNav from '../../components/SideNav/SideNav';
import Stripe from '../../components/Payment/Stripe';
import {NOTIFICATION} from '../../actions/index';

import "./Payment.css";

class Payment extends Component {
    state = {
        error: '',
        required: {},
        formError: {},
        cardDetail: {},
        userData: {},
        usercarddetails:{},
        cardName: "---- ---- ---- ----",
        payModel: false,
        subscribetoggle:false
    }
    getusersdata = () => {
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let self = this

        axios.get(url + 'mobileapi/profile/').then(function (response) {
            if (response.data.success === 'yes') {
                if (Object.keys(response.data.cardDetail).length > 0) {
                    self.setState({
                        cardDetail: response.data.cardDetail,
                        cardName: response.data.cardDetail.sources.data[0].last4,
                        userData: response.data.data,
                        usercarddetails : (response.data.usercarddetails&&response.data.usercarddetails.length > 0) ? response.data.usercarddetails[0] : {},
                        subscribetoggle : (response.data.usercarddetails&&(response.data.usercarddetails.paymentid!='' || response.data.usercarddetails.paypal_subscribtion_id !='')) ? true : false
                    });
                }else{
                    self.setState({
                        usercarddetails : (response.data.usercarddetails&&response.data.usercarddetails.length > 0) ? response.data.usercarddetails[0] : {},
                        subscribetoggle : (response.data.usercarddetails&&(response.data.usercarddetails.paymentid!='' || response.data.usercarddetails.paypal_subscribtion_id !='')) ? true : false
                    });
                }               
            }

        })
    }
    StripeBoxHandle = () => {
        this.setState({
            payModel: !this.state.payModel
        }, async () => {
            await this.StripeBoxCloseHandle('save');
        })
    }
    StripeBoxCloseHandle = (type) => {
        switch (type) {
            case "save":
                this.props.dispatch(NOTIFICATION({ msg: "Card Added Successful" }))
                break;
        }
        this.setState({
            payModel: false
        }, () => { this.getusersdata() })
    }
    removecard = () => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL:"http://showpads.auctionsoftware.com/";

        axios.post(url + 'mobileapi/striprremovecard', {}).then((response) => {
            switch (response.data.success) {
                case 'yes':
                    self.props.dispatch(NOTIFICATION({ msg: "Card Removed Successful" }))
                    this.setState({
                        cardDetail: {},
                        cardName: ""
                    });
                    break;
                case 'no':
                    self.props.dispatch(NOTIFICATION({ msg: "Subscription is active , Cannot remove card" }))
                    break;
            }
        })
    }
    unsubscribe = () => {
        let self = this,
            payload = {};
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL:"http://showpads.auctionsoftware.com/";
        let completeUrl = (this.state.usercarddetails.paymentid !='' && this.state.usercarddetails.paymentid) ? `${url}mobileapi/unsubcription/stripe` : `${url}mobileapi/unsubcription/paypal` 
        
        axios.post(completeUrl, payload).then((response) => {
            switch (response.data.success) {
                case 'yes':
                    self.state.userData.paymentid = ''
                    this.setState({userData : self.state.userData},()=>{self.getusersdata()});
                    self.props.dispatch(NOTIFICATION({ msg: "unsubscribed Successful" }))
                    break;
                case 'no':
                    self.props.dispatch(NOTIFICATION({ msg: "Try later!" }))
                    break;
            }
        })
    }
    payModel = () => {
        this.setState({
            payModel: true
        })
    }
    componentDidMount() {
        this.getusersdata();
    }
    render() {
        let {userData} = this.state;
        return (
            <div className="col-md-9">
                <div className="flex justify-between pre-txt">
                    <span className="karla fw-600 text-black text-uppercase">Card Details</span>
                </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">
                        <form className="site-form">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group ">
                                        <input
                                            type="number"
                                            className="form-control site-input"
                                            id="exampleInputEmail1"
                                            placeholder={Object.keys(this.state.cardDetail).length > 0 ?
                                                `**** **** **** ${this.state.cardName}`
                                                :
                                                "---- ---- ---- ----"}
                                            disabled />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <h5 className="karla add-remove-card">

                                            {Object.keys(this.state.cardDetail).length > 0 ?
                                                <div className="row">
                                                    <div className="d-block">
                                                    <i className="fa fa-minus" aria-hidden="true"></i>
                                                    <b>
                                                        <a href="javascript:void(0)" onClick={this.removecard}>REMOVE CARD</a>
                                                    </b>
                                                    </div>
                    
                                                    {/* {userData.paymentid &&
                                                    <p className="d-block">/
                                                    <div className="d-block">
                                                    <b>
                                                        <a href="javascript:void(0)" onClick={this.unsubscribe}>UNSUBCRIBE</a>
                                                    </b>
                                                    </div></p>} */}
                                                </div>
                                                :
                                                <div>
                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                    <b>
                                                        <a href="javascript:void(0)" onClick={this.payModel}>ADD CARD</a>
                                                    </b>
                                                </div>
                                            }
                                        </h5>

                                    </div>
                                </div>
                                {(this.state.subscribetoggle && (this.state.usercarddetails.paymentid != '' || this.state.usercarddetails.paypal_subscribtion_id) != '') ?
                                    <div className="col-12">
                                        <div className="col-md-12 pre-txt">
                                            {/* <span className="karla fw-400 text-black text-uppercase mar-btn-30">Deeds and contracts subscription :</span> */}
                                            <p className="text-dubdetail-label">$34 will be deducted every month upon subscription:
                                                <span>
                                                    <button type="button" className="btn btn-primary_c" onClick={this.unsubscribe}>
                                                    Unsubscribe
                                                    </button>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.payModel &&
                    <Stripe
                        project_id={0}
                        onChangeHandle={this.StripeBoxHandle}
                        StripeBoxCloseHandle={this.StripeBoxCloseHandle}
                        amount={0}
                    />
                }
            </div>
        )
    }
}



function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
    return { user, loggedIn };
  }
  
  export default connect(mapStateToProps)(Payment);