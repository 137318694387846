import React, {Component} from 'react';
import './EmailSubscription.css';
import axios from 'axios';
import {NOTIFICATION} from '../../actions/index'
import {connect} from "react-redux";

class EmailSubscription extends Component {


    formSubmit = (e) =>{
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let token = this.props.match.params && this.props.match.params.token ? this.props.match.params.token : '';
        let self = this;
        console.log(token)
        let completeUrl = `${url}mobileapi/unsuscribestripe`
        axios.post(completeUrl,{token}).then(response=>{
            if(response.data.success==='yes'){
                this.props.dispatch(NOTIFICATION({msg:'Successfully Unsubscribed'}))
                this.props.history.push('/')
            }
        })
    }
    render() {
        return (
            <>
                   <main>
           <div className="snow-pad">
            <div className="center-part">
              <div className="row">
                  <div className="flex justify-center">
                      <div className="col-md-6">
                          <div className="forgot-form">
                          <form action="" className="pad-55 elty">
                              <div className="top-hd for-hd">
                              <h4>Subscription</h4>
                              </div>
                              <div className="form-group link-redir text-center ">
                                  <p className="text-dark font-15 pad-lr-20 pad-btm-20">Would You like to Unsubscribe the Contract and Deeds ?</p>
                              </div>
                              <div className="form-group text-center pad-btm-20 pad-top-20 flex" style={{"justify-content":"space-evenly"}}>
                                  <button type="button"  onClick={this.formSubmit}   className="btn btn-secondary">Accept</button>
                                  <a href='/'><button type="button"   className="btn btn-secondary" style={{"color":"rgba(255, 0, 0, .9)","border": "1px solid rgba(255, 0, 0, .7)"}}>Reject</button></a>
                              </div>                            
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
             </div>
          </div>
        </main>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(EmailSubscription);
