import React, { Component } from 'react';
import { connect } from 'react-redux';
import {NOTIFICATION} from '../../actions/index'
import "./SavedSearch.css";
import axios from 'axios';


class SavedSearchDislay extends React.Component {
    state = {
        searchToggle: false,
        errClass: '',
        errMsg:'',
        title: {
            data: "",
            required: true
        }
    }
    searchToggle = (flag) => event => {
        let title = this.state['title']
        if (!flag)
            title.data = ''

        if (this.state.errMsg)
            this.state.errMsg = ''

        this.setState({ searchToggle: flag, title: title, errMsg: this.state.errMsg })
    }
    handleChange = (name) => event => {
        if (this.state.errClass != '')
            this.state.errClass = ''

        if (this.state.ereMsg != '')
            this.state.errMsg = ''

        let prestate = this.state[name];
        prestate.data = event.target.value;
        this.setState({
            [name]: prestate,
            errClass: this.state.errClass,
            errMsg: this.state.errMsg
        })
    }
    handleValidation() {
        if (this.state.title.data === '') {
            this.setState({ errClass: 'errclass' });
            return false
        }
      
        // return true
    }
    formsubmit(e) {
        e.preventDefault();
        this.handleValidation();
        let filteredvalues = Object.keys(this.props.filter).filter((filterVal, valIndex) => {
            if (filterVal !== 'role' && filterVal != 'page_no' && filterVal != 'limit') {
                return filterVal
            }
        });
 
         if (this.state.title.data != '' && filteredvalues.length === 0) {
            console.log("havebt searched")
            this.setState({
                errMsg: 'You havent Searched anything Yet'
            }, () => {
                let title = this.state['title']
                title.data = '';
                setTimeout(() => {
                    this.setState({ errMsg: '', title: title, searchToggle: false, errClass: '' })
                }, 2000)
            });
            return false;
        }
        let self = this;
        if (this.state.title.data !== '' && this.state.errMsg === "" && this.state.errClass === '') {
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            let completeUrl = `${url}mobileapi/savedSearch`;
            let payload = {};
            let payload_ = JSON.stringify(this.props.filter);
            payload = JSON.parse(payload_);
            payload['title'] = this.state.title.data;
            axios.post(completeUrl, payload).then(response => {
                if (response.data.success === 'yes') {
                    self.props.dispatch(NOTIFICATION({ msg: response.data.data }));
                } else {
                    self.props.dispatch(NOTIFICATION({ msg: response.data.failedreason }))
                }
                self.state.title.data = ''
                self.setState({ searchToggle: false, title: self.state.title });
            });
        }

    }
    render() {
        const {loggedIn} = this.props
        return (
            <>
                <form onSubmit={this.formsubmit.bind(this)} class="savedsearchform">
                    {!this.state.searchToggle && loggedIn  ? <button class=" SavedSearchButtonOne SavedsearchButton savedSearchFormNurron"
                        type="button"
                        onClick={this.searchToggle(true)}
                    >Save this search</button> : ''}
                    {this.state.searchToggle ? <div class="titleinput">
                        <div>
                        <input
                            class={this.state.title.data === '' ? `form-control ${this.state.errClass}` : 'form-control'}
                            type='text'
                            placeholder="Title"
                            value={this.state['title'].data}
                            onChange={this.handleChange('title')}
                        />
                        <span style={{color:'red'}}>{this.state.errMsg}</span>
                        </div>
                        <button class='btn btn-default SavedsearchButton'
                            type='submit'
                        >Save</button>
                        <button class='btn btn-default savedclose'
                            onClick={this.searchToggle(false)}
                        >X</button>
                        
                    </div> : ''}
                </form>
            </>
        );
    }
}

function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
    return { user, loggedIn };
}

export default connect(mapStateToProps)(SavedSearchDislay);