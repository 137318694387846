import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import './Paypal.css';

import axios from 'axios';
import { NOTIFICATION } from '../../actions/index';

class Paypal extends Component {
  constructor() {
    super();
    this.state = { showButton: false, error: '', holderName: '' };
    this.submit = this.submit.bind(this);
    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  async submit(ev) {

    let self = this;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    let payload = {
      "method": "paypal"
    }
    axios.post(url + 'mobileapi/getplanid', payload)
      .then(function (response) {
        if (response.data.error === false) {

          // console.log(response.data)
        } else {
          self.setState({ error: "Try again!" })
        }
      })
      .catch(function (error) {
        self.setState({ error: "Try again!" })
      });
  }

  handleClose = () => {
    this.props.dispatch(NOTIFICATION({ msg: "Successfully subscribed." }))
    this.props.StripeBoxCloseHandle()
  }

  handleNameUpdate = (event) => {
    this.setState({ holderName: event.target.value })

  }

  handleChange = () => {
    this.setState({ error: "" });
  };

  componentDidMount() {
    const {
      isScriptLoaded,
      isScriptLoadSucceed
    } = this.props;

    if (isScriptLoaded && isScriptLoadSucceed) {
      this.setState({ showButton: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed,
    } = nextProps;

    const isLoadedButWasntLoadedBefore =
      !this.state.showButton &&
      !this.props.isScriptLoaded &&
      isScriptLoaded;

    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
  }

  onSuccess = (payment) => {
    console.log('Successful payment!', payment)
    this.props.StripeBoxCloseHandle()
  }

  onError = (error) => {
    console.log('Erroneous payment OR failed to load script!', error)
  }

  onCancel = (data) => {
    console.log('Cancelled payment!', data);
  }

  render() {

    const env = process.env.REACT_APP_PAYPAL_MODE === 'production' ? 'production' : 'sandbox';
    const client = {
      sandbox: process.env.REACT_APP_PAYPAL_KEY,
      production: process.env.REACT_APP_PAYPAL_KEY,
    };
    const paypal = window.paypal;
    const { showButton } = this.state;
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;

    const style = {
      layout: 'horizontal', // horizontal | vertical
      size: 'large', // medium | large | responsive
      shape: 'rect', // pill | rect
      color: 'gold', // gold | blue | silver | white | black
      margin: 5,
      tagline: true,
      fundingicons: false,
      label: "pay"
    };

    const payment = () =>
      paypal.rest.payment.create(env, client, {
        intent: 'authorize',
        payer: {
          payment_method: 'paypal'
        },
        transactions: [
          {
            amount: {
              total: 1,
              currency: "USD",
            },
            payment_options: {
              allowed_payment_method: "INSTANT_FUNDING_SOURCE"
            },
          },
        ],
      });

    const onAuthorize = (data, actions) =>
      actions.payment.execute()
        .then((payment) => {
          this.onSuccess(payment);
        });

    return (
      <div>
        {showButton && isScriptLoaded && isScriptLoadSucceed &&
          <paypal.Button.react
            env={env}
            client={client}
            commit={true}
            style={style}
            payment={payment}
            onAuthorize={onAuthorize}
            onCancel={this.onCancel}
            onError={this.onError}
          />}
      </div>

    )
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(scriptLoader('https://www.paypalobjects.com/api/checkout.js')(Paypal));
