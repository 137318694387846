import React, { Component } from 'react';
import './Proposal.css';
import {NOTIFICATION} from '../../actions/index';
import ReportUser from '../ReportBuyer/ReportBuyerPopup'
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {properResult} from '../../util/capsAndSpaces';
import Popup from '../../components/Popup/Popup';

class Socialmedia extends Component {

    state = {
        OfferData: [],
        error: '',
        reportBuyerPopup : false,
        reportUserId:"",
        counterModel: false,
        counterId: ''
    }

    loadData = () =>{
        let self = this;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let project = self.props.projectData;
        let payload = {
            "project_id": project.id
        }
        axios.post(url + 'mobileapi/getproposal/', payload)
            .then(function (response) {
                if (response && response.data) {
                    self.setState({
                        OfferData: properResult(response.data.data)
                    })
                    if(project.market_status === "sold" ){
                        let maxData = Math.max.apply(Math, response.data.data.map(function(o) { if(o.isaccepted === 1) return o.offer_amount; }))
                        if(maxData) self.props.priceUpdate(maxData);
                    }
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later"
                })
            });
    }

    componentDidMount() {
        this.loadData()
    }

    submitHandler = (event) => {
        
        let param = {
            isaccepted : event.target.name === "accept" ? 1 : 0,
            "id": event.target.id,
            "project_id": event.target.getAttribute('project_id')
        }

        let self = this;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.defaults.headers.common['Authorization'] = this.props.user_token.data;
        axios.post(url + 'mobileapi/updateproposal/', param)
            .then(async (response) => {
                if (response && response.data) {
                    await this.loadData()
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later"
                })
            });
    }

    counterHandler = (data) => {
        
        let param = {
            isCounter: 1,
            id: data.id,
            counterStatus: 'pending',
            offerAmount: data.price
        }

        let self = this;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.defaults.headers.common['Authorization'] = this.props.user_token.data;
        axios.post(url + 'mobileapi/updateproposalcounter/', param)
            .then(async (response) => {
                if (response && response.data) {
                    await this.loadData()
                    this.setState({
                        counterModel :  false
                    });
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later"
                })
            });
    }

    reportUserOpen=(id)=>e=>{
        this.setState({
          reportBuyerPopup : true,
          reportUserId:id
        })
      }
      reportBuyerClose=(type)=>{
        switch(type){
          case 'close':
            break;
          case 'save':
            this.props.dispatch(NOTIFICATION({msg:"Report sent to admin"}))
          break;
        }
        this.setState({
          reportBuyerPopup : false
        }) 
      }

      popupHandle = (data,price,dataObj) => {
        if(data == "2"){
            this.counterHandler({price,id:dataObj.id})
        }else{
            this.setState({
                counterModel :  !this.state.counterModel,
                counterId : (data.target && data.target.id) ? data.target.id : ''
            });
        }
    }
    render() {
        let { OfferData } = this.state;
        return (
            <div className="container">
                {OfferData.length > 0 &&
                    <section className="feture-list">
                        <div className="">
                            <h4 className="feu-tit">OFFERS</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {OfferData &&
                                    OfferData.map((data, index) => (
                                        <div className="main-pro" key={"offers_"+index}>
                                            <div className="list-pro">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="contact">
                                                            <div className="profile">
                                                                <div className="div">
                                                                    <span>A.C</span>
                                                                    <span>BUYER</span>
                                                                </div>
                                                            </div>
                                                            <Link to={"/dashboard/message"}>
                                                                <button className="btn btn-secondary" data-toggle="modal"
                                                                data-target="#buy-sch">Contact Buyer</button>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="pro-data">
                                                            <div className="product">
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <h4 className="tit">Meg</h4>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <h4 className="scrore text-light text-right">{data.username} {data.preqalified ? <span className="preTag">{"Pre-Approved"}</span> : ''}</h4>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="of-prc">
                                                                            <span className="karla">Offer Price : {data.offer_amount || "-"}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-8">
                                                                        <div className="data-list">
                                                                            <span className="text-left">Preferred Location : <b>{data.location || "-"}</b></span>
                                                                            <span className="text-left">Preferred Property Type :
                                                                            <b> {data.propertype || "-"}</b>
                                                                            </span>
                                                                            <span className="text-left">Description : <b> {data.description || "-"}</b></span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <div className="data-list">
                                                                            <span className="">Max. Preferred Purchase Price : <b> {data.pricerangemax || "-"} </b></span>
                                                                            <span className="">Preferred Condition : <b>{data.propertycondition || "-"}</b></span>

                                                                            <span className="">
                                                                                <ul className="ul-list">
                                                                                    <li className="pull-right">
                                                                                        <a href="javascript:void(0)" onClick={this.reportUserOpen(data.id)}>
                                                                                            <img src="/images/icons/flag.svg" alt="img-responsive" />&nbsp;&nbsp;Report User
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row text-center" style={{ color: "green",margin: '10px auto' }}>
                                                                    {data.counter_status === "pending" && <p>Awiting for buyer response.</p>}
                                                                    {data.counter_status === "accept" && <p>Buyer accepted your offer.</p>}
                                                                    {data.counter_status === "reject" && <p style={{color: "green"}}>Buyer not interested your offer.</p>}
                                                                </div>
                                                                <div className="row">
                                                                { data.isaccepted !=1 && data.isaccepted !=0 &&
                                                                    <div className="sel-provw text-center">
                                                                        <button className="btn btn-primary" name="accept" id={data.id} project_id={data.project_id} onClick={this.submitHandler}>INTERESTED </button>
                                                                        {data.counter_status === "none" && <button className="btn btn-primary" name="accept" id={data.id} project_id={data.project_id} onClick={this.popupHandle}>COUNTER</button>}
                                                                        <button className="btn btn-danger" name="reject" id={data.id} project_id={data.project_id} onClick={this.submitHandler}>REJECT</button>
                                                                    </div>
                                                                }
                                                                { data.isaccepted === 1 && <div className="text-center"><span style={{ color: "green" }}>Accepted</span></div>}
                                                                { data.isaccepted === 0 && <div className="text-center"><span style={{ color: "red" }}>Rejected</span></div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            {this.state.reportBuyerPopup && 
                                <ReportUser
                                reportBuyerClose={this.reportBuyerClose}
                                user={this.props.user_tok}
                                reportUserId={this.state.reportUserId}
                                />
                            }
                            {this.state.counterModel && <Popup color="green"
                                title="Confirmation"
                                description = "Please enter your offer price"
                                testBox={true}
                                yesTo="Cancel" // btn 1
                                noTo="Accept" // btn 2
                                btnCount={2} // 1 or 2
                                popupHandle={this.popupHandle}
                                extradata={{id:this.state.counterId}}
                            /> }
                        </div>
                    </section>
                }
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Socialmedia);
