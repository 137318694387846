let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export default function login(state = initialState, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return {
        loggedIn: true,
        user: action.payload
      }
    case 'LOGIN_FAILURE':
      return {}; 
    case 'FORGET_PASSWORD':
      return action.payload
    case "LOGOUT":
      return {};  
    default:
      return state
  }
}