import React, {Component} from 'react';
import './Productpopup.css';
import {connect} from 'react-redux';

class Propductpopup extends Component {
    state={
        checkedProjects : [],
        errmsg : "",
        buttonClassName : "btn-secondary",
        url:""
    }
    checkboxChange=(id)=>event=>{
        if(this.state.checkedProjects.indexOf(id) !== -1){
            this.state.checkedProjects.splice(this.state.checkedProjects.indexOf(id),1);
        }else{
            this.state.checkedProjects.push(id)
        }
        this.setState({
            checkedProjects : this.state.checkedProjects,
            errmsg : "",
            buttonClassName:"btn-secondary"
        });
    }
    selectedValication=()=>{
        
        if(this.state.checkedProjects.length>0)
        {
            this.props.sendProjectsToBuyers(this.state.checkedProjects)
        }else{
            this.state.errmsg = "Select any one  or more projects";
            this.setState({
                errmsg : this.state.errmsg,
                buttonClassName : "btn-secondary-err"
            });
        }
            
        
    }
    componentDidMount() {
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        this.setState({url});

    }
    render(){
        const {close , popupdata } = this.props;
        const {url} = this.state;
        return(
            <div>
                <div className="modal fade mod-place-bid in modal-open d_block" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog productPopUp" role="document">
                        <div className="modal-content show-model bid-hiy selt-propty">
                            <div class="modal-header text-center">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" onClick={close}>&times;</span></button>
                                    <h3 class="text-center karla fw-600"><span>SELECT PROPERTY</span></h3>
                                    <p class="karla">Select the properties you would like to send the buyer</p>
                            </div>
                            <div class="modal-body pro-ty">
                                {popupdata.length > 0 ?
                                    popupdata.map((productelement,productindex)=>{
                                    return <div class="row" key ={`key${productindex}`}>
                                        <div class="Property-list flex box-shadow align-center mar-btn-30 ">
                                            <figure>
                                                <img src={url+"uploads/product/"+productelement.avatar} alt="propperty" class="img-responsive"/>
                                            </figure>
                                        <div class="property-body">
                                            <div class="flex justify-between">
                                            <span>Property Type : {productelement.propertype}</span>
                                            <span className="property-location">Location      : {productelement.location}</span>
                                            </div>
                                            <span>Zoning Type   : {productelement.propertyzone ? productelement.propertyzone : "-"}</span>
                                            <div class="flex justify-between align-center">
                                                <span className="property-con">Property Condition :{productelement.propertycondition}</span>
                                                <span>
                                                    <label className="show-check text-light">&nbsp;&nbsp;SELECT THIS PROPERTY
                                                        <input class="check-height" type="checkbox" onChange={this.checkboxChange(productelement.id)}/>
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </span>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    })
                                
                                    : "No Products"}
                            </div>
                            <div class="modal-footer">
                                <div class = "text-center">
                                    <span style={{color:"red"}}>{this.state.errmsg}</span>
                                    <br></br>
                                    <button 
                                        class={`btn btn-secondary ${this.state.buttonClassName}` }
                                        data-toggle="modal" 
                                        data-target="#buy-sch"
                                        onClick={this.selectedValication}>Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
  }
  
  export default connect(mapStateToProps)(Propductpopup);