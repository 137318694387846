import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import "./Login.css";

import axios from 'axios';
import Cookies from 'universal-cookie'


import {LOGIN,LOGIN_FAILURE,LOGOUT} from '../../actions';

const cookies = new Cookies();
class Login extends Component {
    state = {
        username : {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        password:{
            data: "",
            required: true,
            errorMsg: "Required"
        },
        error: "",
        formError:{},
        checked:false
    }

    componentDidMount(){
        
        let userDatainCookie = cookies.get('userdata')
        if(userDatainCookie){
        if(Object.keys(userDatainCookie).length > 0){
            this.state.username.data = userDatainCookie.username
            this.state.password.data = userDatainCookie.password
            this.setState({
                username : this.state.username , password : this.state.password , checked:true
            });

        }
    }
        localStorage.removeItem('user');
        this.props.dispatch(LOGOUT({}))
    }

    handleValidation(){
        let self = this;
        Object.keys(this.state).map(function(key) { 
            if(self.state[key].required && self.state[key].data === ""){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
            }
            self.setState({
                formError: self.state.formError
            })
        });
    }

    handleChange = name => event => {
        let preState = this.state[name];
        if(this.state.formError[name]){ delete this.state.formError[name] }
        preState.data = event.target.value;
        this.setState({
            [name]:preState,
            formError:this.state.formError,
            error: ''
        });
    };
  
    formSubmit(e){
        e.preventDefault();
        //console.log(cookies.get('userdata'));

        this.handleValidation();
        let self = this;
        let payload = {};
        if ((Object.keys(self.state.formError)).length > 0 ){ return false; }
        Object.keys(self.state).map(function(key) { payload[key] = self.state[key].data });
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/loginsave',payload)
        .then(function (response) {
            if (response.data.success == "no") {
                self.setState({
                    error: response.data.failedreason
                })
                self.props.dispatch(LOGIN_FAILURE({
                    error: response.data.failedreason
                }))
                return false;
            }
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            window.fcWidget.user.setFirstName(response.data.username);
            window.fcWidget.user.setEmail(response.data.userdata.email);
            localStorage.setItem('user', JSON.stringify(response.data));
            self.props.dispatch(LOGIN(response.data))
            if(response.data.userdata.role === "buyer")
                self.props.history.push("/dashboard/buyer");
            else if(response.data.userdata.role === "realtor")
                self.props.history.push("/dashboard/realtor"); 
            else
                self.props.history.push("/dashboard/seller");    
        })
        .catch(function (error) {
            self.setState({
                error: "Try later!"
            })
            return false;
        });
      }

    rememberMeChange(event){
        
        if(event.target.checked){
            cookies.set('userdata',{"username":this.state.username.data,"password":this.state.password.data})
            this.state.checked=true;
            this.setState({
               checked:this.state.checked
            })

        }else{
            let tempCookie = cookies.get('userdata');
            this.state.username.data = tempCookie.username;
            this.state.password.data = tempCookie.password;
            this.state.checked=false;
            this.setState({
                username:this.state.username,password:this.state.password,checked:this.state.checked
            })
            cookies.remove('userdata')
       }
    }
    render() {
        return (
            <main>
                <div className="center-part">
                    <div className="row">
                        <div className="col-md-6 hidden-sm hidden-xs">
                            <div className="googly-img">
                                <figure>
                                    <img src="./images/login-bgnew.jpg" alt="googly" className="img-responsive" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-6 col-xs-12">
                            <div className="alpha-form">
                                <form className="pad-55 elty" onSubmit={this.formSubmit.bind(this)}>
                                    <div className="top-hd">
                                        <h4>Login</h4>
                                    </div>
                                    <div className="form-group">
                                        <label>Email address</label>
                                        <input 
                                        type="text"  
                                        className="form-control border" 
                                        id="exampleInputEmail1" 
                                        placeholder="Enter Your Email" 
                                        name="username"
                                        value={this.state.username.data} 
                                        onChange={this.handleChange('username')}/>
                                        <span style={{color: "red"}}>{this.state.formError["username"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <label >Password</label>
                                        <input type="password" 
                                        className="form-control border" 
                                        id="exampleInputPassword1" 
                                        placeholder="Enter Your Password" 
                                        name="password"
                                        value={this.state.password.data} 
                                        onChange={this.handleChange('password')}/>
                                        <span style={{color: "red"}}>{this.state.formError["password"]}</span>
                                    </div>
                                    <div className="checkbox pad-btm-30 mar-btn-30">

                                        <label className="show-check">&nbsp;&nbsp;Remember Me
                                            <input className="check-height" 
                                            type="checkbox" 
                                            onChange={this.rememberMeChange.bind(this)}
                                            checked={this.state.checked}/>
                                            <span className="checkmark"></span>
                                        </label>
                                        <span className="pull-right">
                                            <Link to='/forget_password' className="active">Forgot Password</Link>
                                        </span>
                                    </div>
                                    {this.state.error ?
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error}
                                    </div>
                                    : ''} 
                                    <div className="form-group text-center pad-btm-20">
                                        <button type="submit" className="btn btn-secondary">Login</button>
                                    </div>
                                    <div className="form-group link-redir text-center ">
                                        <p className="text-gray">Don't have an account?</p>
                                        <Link className="text-light font-15" to={'/register'}><b>Sign Up</b></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Login);