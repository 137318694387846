import React from 'react';
import './Buyer.css';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: '50%',
      left: '50%',
      position:'absolute',
      transform: 'translate(-50%, -50%)',
      outline:0
    };
  }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 620,
    //   backgroundColor: theme.palette.background.paper,
      border: "none",
    //   boxShadow: theme.shadows[5],
    //   padding: theme.spacing(1, 3, 1, 3)
    }
  }));

const Buyer =(props)=>{
    let {loggedIn,msg,role} = props
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
    return (
        <div className="active sta-tabs new-pop" id="home">  
            <div className="flex justify-between align-center flex-wrap">
                <h4 className="text-left">BUYER FAQ</h4>
                <button type="button" className="vid-eo" onClick={handleOpen} >Watch Buyer Videos</button>
            </div>      
            <div className="static-accordient">
                <div className="tabs">
                    <div className="tab">
                        <input type="radio" id="rd1" name="rd" />
                        <label className="tab-label" for="rd1">How is DoorRise different from Listing sites like Zillow?</label>
                        <div className="tab-content">
                        <p>We think Buyers have been working too hard. On traditional listing sites, Buyers do all the searching.  Wouldn’t your odds of success increase if motivated Sellers could search for you? Now, they can.  Our two-way marketplace allows you to post your Profile, so property Sellers can find you and present properties for sale or auction that match your specific interests and qualifications. If interested, you can make an offer or start bidding!</p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd2" name="rd" />
                        <label className="tab-label" for="rd2">What personal information is visible in my Buyer profile? </label>
                        <div className="tab-content">
                        <p>DoorRise is all about creating a level playing field.  Sellers only see the important facts, which are: </p>
                            <ul>
                                <li>Your purchase budget. </li>
                                <li>Your lending pre-approval status.</li>
                                <li>And your preferences regarding location, size, and property condition. </li>
                            </ul>
                        <p>That’s it. Sellers never see Buyers’ actual names or profile images.  All they see are Buyers’ initials or nicknames (so choose it wisely), allowing them to connect with Buyers based sheerly on your preferences and qualifications. </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd3" name="rd" />
                        <label className="tab-label" for="rd3">Will I get phone calls and email spam with false leads?</label>
                        <div className="tab-content">
                        <p>This is why your profile should clearly reflect your actual preferences and qualifications.  This helps Sellers find solid matches for their listings.  You’ll never receive a phone call from a Seller.  You will receive  email notifications each time a  Seller has a property for you to consider.  If you review the property on DoorRise and express interest, our team will contact you to discuss next steps. No spamming. Ever.  </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd4" name="rd" />
                        <label className="tab-label" for="rd4">So is DoorRise a real estate agency?</label>
                        <div className="tab-content">
                        <p>No. DoorRise is a real estate marketplace that connects real estate Sellers and Buyers, directly. For those connections that lead to potential deals, we have partnered with an exclusive group of expert local Realtors who manage the transaction from offer to close.  If you are already working with a real estate agent, you can still use DoorRise.  However, your agent will likely not be able to represent you on any transaction listed on DoorRise. We will assign you to one of our exclusive agents to provide all the support you need to confidently negotiate and purchase a property on our site.</p>
                        <div className="text-center">
                         <button className="btn btn-primary"> Watch Video for More</button>
                         </div>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd5" name="rd" />
                        <label className="tab-label" for="rd5">Can I search for properties on DoorRise?</label>
                        <div className="tab-content">
                        <p>Absolutely! We are a two-sided market. You don’t have to wait for the deals to come to you. You can search for properties that suit your needs, click the “I’m Interested” button and our team will contact you to take next steps, which includes </p>
                            <ul>
                                <li>verifying your lending status (and getting you pre-approved, if necessary) </li>
                                <li>providing you a physical inspection report on your property of interest (when available) and </li>
                                <li>providing recent sales comps.  You can make an offer based on this info or attend a private open house, before making your offer.</li>
                            </ul>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd6" name="rd" />
                        <label className="tab-label" for="rd6">What’s the difference between a Listing and an Auction?</label>
                        <div className="tab-content">
                        <p>With <b>Listings,</b> the Seller sets an asking price, the private open house date for interested buyers, and the deadline for submitting offers. Buyers who don’t attend the open house can submit offers based on the property listing info and the inspection report (if available).  After the deadline for offers, the seller can accept one offer, counter one or more offers, or reject all offers.</p>
                        <p>In an <b>Auction,</b> the Seller sets a minimum bid, the private open house date for interested bidders, the Auction start date, and Auction end date.  If you find a home for auction that suits your needs, you can click the “I’m Interested” link and our team will contact you to take next steps; which includes 
                        </p>
                        <ul>
                            <li>verifying your lending status (and getting you pre-approved, if necessary) </li>
                            <li>providing you a physical inspection report on your property of interest (when available) and </li>
                            <li>providing recent sales comps.  You can bid based on this info or attend a private open house, before bidding. During the Auction period, Buyers can see other Buyers’ bids and raise or cancel your bids accordingly.  When the Auction ends, the winning Bidder is notified.</li>
                        </ul>
                        <div className="text-center">
                         <button className="btn btn-primary"> Watch Video for More</button>
                        </div>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd7" name="rd" />
                        <label className="tab-label" for="rd7">What is a “Pocket Listing”?</label>
                        <div className="tab-content">
                        <p>Another benefit of joining our private Community is that we allow Realtors to list their Off-Market properties to our Buyers.  These “Pocket Listings” are not typically listed on the MLS or other listing sites, which means buyers using DoorRise have almost exclusive access to these listings. Don’t worry. We don’t allow Agents to reach out to Buyers directly. You’ll find their Pocket Listings posted on our site. If interested, simply follow the steps to make an offer. </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd8" name="rd" />
                        <label className="tab-label" for="rd8">Am I required to be Pre-approved for lending before I can make offers, or bid on Auctions? </label>
                        <div className="tab-content">
                        <p>Getting pre-approved is not required, but it is strongly encouraged. Financing is the most important part of successfully closing a purchase and Sellers want Buyers who can stand behind their offers and bids. Getting pre-approved costs you nothing, but having Pre-Approved status makes you look better to Sellers who may get offers from multiple Buyers. You can create a profile before getting pre-approved, but you can’t get an accepted offer or bid without a verified pre-approval from a verified lender.</p>
                        <div className="text-center">
                         <button className="btn btn-primary"> Watch Video for More</button>
                        </div>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd9" name="rd" />
                        <label className="tab-label" for="rd9">Can I only Buy houses using DoorRise? </label>
                        <div className="tab-content">
                        <p>On DoorRise, Users can purchase a wide variety of real estate assets, including: Single Family Homes, Condos, Multi-Family Dwellings, Land, as well as Trust Deeds, and Wholesale Contracts.  For trust deeds and contracts, we do not provide Realtor assistance.  Purchase of these products is for more seasoned Buyers.  Buyers  negotiate terms directly with the Sellers, without the assistance of our Assigned Agents or Service providers.   </p>
                        <div className="text-center">
                         <button className="btn btn-primary"> Watch Video for More</button>
                        </div>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd10" name="rd" />
                        <label className="tab-label" for="rd10">How do I know I’m getting the best price when I’m Buying on your site?</label>
                        <div className="tab-content">
                        <p>Buying real estate requires doing some homework and being familiar with prices in your neighborhoods of interest. When purchasing a property on DoorRise, our Team provides Buyers comparable home sales reports, as well as physical inspection reports. We make sure Buyers have all the information needed to make an educated Bid or Offer. </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd11" name="rd" />
                        <label className="tab-label" for="rd11">How do I know the condition of the property before making an offer?  </label>
                        <div className="tab-content">
                        <p>We specialize in transparent, win-win transactions. That’s why we strongly encourage that Sellers obtain a physical inspection report from a licensed inspector before listing their properties.  Whenever possible, we make these reports available for Buyers to review before making bids and offers. These reports provide Buyers key insights about the property condition on the front end and minimizes surprises after opening escrow.  </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd12" name="rd" />
                        <label className="tab-label" for="rd12">What happens when my Auction bid or Purchase Offer is accepted?  </label>
                        <div className="tab-content">
                        <p>Once your bid or offer is accepted, the path to closing works just like any traditional real estate transaction.  The Agent(s) for the transaction will work with you to deposit your earnest money into escrow, facilitate distribution of all disclosures, arrange any inspections, interact with your lender and assist you and the seller in meeting your various transaction deadlines.  The Assigned Agent(s) will also work with our preferred service providers to make sure all your needs are met before closing. </p>
                        </div>
                    </div>
                    <div className="tab">
                        <input type="radio" id="rd13" name="rd" />
                        <label className="tab-label" for="rd13">Am I required to use your service providers?  </label>
                        <div className="tab-content">
                        <p>You are not, but we think you’ll be happy if you do. Our agreements with top real estate service providers mean they offer the most competitive rates, and in many cases, we offer you rebates at close of escrow for trusting our preferred team with your business.  But you are free to pay for your own service providers if you wish to. When it comes to an Escrow company, you and the Seller must be in agreement.  </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center static-btn">
            <h4>Any other questions, <Link to={{pathname:'/',state:{scrollTo:true}}}>EMAIL US</Link></h4>
                <p> <b> OR </b></p>
                {((loggedIn  && role != 'seller' && role != 'realtor') || (!loggedIn || (!loggedIn && role ==='buyer')))
                    ? 
                    <button className="btn btn-primary"><Link to='/search/buyer'>START BUYING</Link></button>
                    :
                    <button className="btn btn-primary" onClick={msg('buyer')}>START BUYING</button>
                
                }
            </div>
            <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="new-st"
                    open={open}
                    onClose={handleClose}
                >
                    <div style={modalStyle} className={classes.paper}>
                    <iframe className="ssss" width="560" className="ifram-wth" height="315" src="https://www.youtube.com/embed/1V3hCLEn_kE?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Modal>    
        </div>
    )
}

export default Buyer;