import openSocket from 'socket.io-client';
let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
const  socket = openSocket(url);

function subscribeToTimer(cb) {
  socket.on('sliservertime', timestamp => cb(null, timestamp));
}
function Seller_buyer_search(id,role,value,cb){
  socket.emit('searchseller',{value:value,id:id,role:role});
  socket.on('chatResult',nameresult=>cb(nameresult))
}
function GetChatWindowMessages(data,cb){
    socket.emit('getChatMessages',data);
     
}
function GetChatWindowMessages_listen(cb){
  socket.on('getChatMessages_result',(message)=>cb(message))
}
function InsertChatMessage(data){
      socket.emit('insertMessages',data);
      
}
function insertMessageListen(cb){
  socket.on('insertMessages_result',(result_data)=>{
    cb(result_data);
  })
}
function searchMessage_socket(data){
  console.log("searchmessage1111")
  socket.emit('searchMessages',data);
}
function searchMessageListen(cb){
  socket.on('searchMessages_result',(result_data)=>{
    console.log("searcvh recived")
    cb(result_data);
  })
}
function messagecount(cb){
  socket.on('msgCount',(result_data)=>{
    cb(result_data);
  })
}
function bidUpdate(cb) {
  socket.on('bidAddtime', bidUpdateData => cb(null, bidUpdateData));
}

export {  subscribeToTimer ,
          Seller_buyer_search , 
          GetChatWindowMessages ,
          InsertChatMessage ,
          insertMessageListen , 
          GetChatWindowMessages_listen  ,
          searchMessage_socket  ,
          searchMessageListen,
      bidUpdate,
      messagecount
         };
