import React from "react";
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';


class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
        isload : false,
        place: ''
    }
  }

    loader = () => {
        let google = window.google;
        this.autocomplete = new google.maps.places.Autocomplete(
            this.autocompleteInput.current,
            { types: ["address"] }
        );
        this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    }

  componentDidMount() {
      console.log(this.props)
      const {
        isScriptLoaded,
        isScriptLoadSucceed
      } = this.props;
  
      if (isScriptLoaded && isScriptLoadSucceed) {
        this.loader()
        this.setState({ isload: true });
      }
  }

  componentWillReceiveProps(nextProps) {
    const {
      isScriptLoaded,
      isScriptLoadSucceed,
    } = nextProps;

    const isLoadedButWasntLoadedBefore =
      !this.state.isload &&
      !this.props.isScriptLoaded &&
      isScriptLoaded;

    if (isLoadedButWasntLoadedBefore) {
      if (isScriptLoadSucceed) {
        this.loader()
        this.setState({ isload: true });
      }
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    this.setState({ place: "" });
    this.props.onPlaceChanged(place);
  }

  onChangeHandle = (event) => {
    this.setState({ place: event.target.value });
  }

  render() {
    const {placeholder} = this.props
    return (
        <input type="text" className="form-control site-input" id="propsqfootage"
            ref={this.autocompleteInput}
            value={this.state.place}
            onChange={this.onChangeHandle}
            id="autocomplete"
            placeholder={placeholder ? placeholder : "Enter your address"}
            type="text"
        />
    );
  }
}

export default scriptLoader('https://maps.googleapis.com/maps/api/js?key=AIzaSyBIjXAcyJshgc2pm4Ggct1SztAEDnvCMfw&libraries=places')(Autocomplete);

