import React, {Component} from 'react';
import {Link} from 'react-router-dom'

import './OurTeam.css';

class OurTeam extends Component {
  render(){
    return (
        <main>
    <div className="snow-pad">
        <div className="container team">
            <div className="row">
                <div className="heading-title text-center">
                    <h3 className="text-uppercase">We’re About Control and Convenience for You </h3>
                </div>

              
                <div className="about-content">
                    <div className="container">
                        <p>DoorRise Founder, Nick Cates purchased his first home, with his sister, in 1992, a condo near Northridge, CA. Two years later, the aftermath of the 6.7 magnitude Northridge earthquake, and a prolonged housing recession, cut their property value in half.   </p>
                        <p>Since the 90s, Nick has gone from homeowner to real estate investor, to Realtor, closing millions of dollars in local transactions.  He’s seen the local real estate market from every side, through booms and busts. But he found something missing. Though there are dozens of listing sites where Buyers can search for properties, there are no real estate sites that allows buyers and sellers to take full advantage of our fast-changing, social media, on-demand age. Until now.  </p>
                        <p>DoorRise was founded with the simple goal of creating a true, two-sided real estate marketplace. Buyers can search for listings, but more importantly, Sellers considering selling don’t need to find an agent. We provide you with all the tools to name a fair price, then easily find qualified Buyers and present them the opportunity to buy. Nick wanted Buyers and Sellers to have control of starting the process, but knows both sides still want convenience, support, and savings. So his team manages every aspect of the transaction process, from private showings, to contracts, to closing. Plus we provide extra value in the form of cash-back to Buyers and reduced Seller commissions.  Get started today on your DoorRise journey.  Great deals are waiting. </p>
                        <div className="text-center">
                            <Link to="/login"><button class="btn btn-secondary pad-lr-20">LOGIN</button></Link>
                            <br />
                            <br />
                            <Link to="/register" className="">Sign Up</Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
       
    </div>
     </main>
    )
  }
}

export default OurTeam;