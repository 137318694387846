import React, {Component} from 'react';
import './Popup.css';

class CancelDeeds extends Component {

    state = {
        active: '',
        error:'',
        textbox: ''
    }

    closeHandle = () => {
        this.props.popupHandle(0);
    }

    submit = event => {
        let extradata = this.props.extradata ? this.props.extradata : '';
        this.props.popupHandle(event.target.name,this.state.textbox,extradata);
    };

    handleChange = name => event => {       
        this.setState({
            [name]: event.target.value,
        });
    };

    render() {
        let {color,title,description,yesTo,noTo,btnCount,testBox} = this.props;
        return (
            <div className="modal fade mod-place-bid in modal-open d_block top-15em" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog popup-dialog trans-show" role="document">
                    <div className="modal-content show-model bid-hiy">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeHandle}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="text-center karla fw-600"><span>{title}</span></h3>
                        </div>
                        <div className="modal-body">
                            <div className="row flex">
                                <div className="reff  popup-text-center text-center">
                                    <div className="popup-body">
                                        <h4 style={{ color: color ? color : "red" }}>{description}</h4>
                                    </div>

                                    {testBox && <div className="form-group">
                                        <input type={this.props.textType ? this.props.textType : "text"} 
                                            className="form-control site-input" 
                                            placeholder={this.props.textPlaceHolder ? this.props.textPlaceHolder : ""} 
                                            value={this.state.textbox} 
                                            onChange={this.handleChange('textbox')}
                                        />
                                    </div>}


                                    <div className="row"><span style={{ color: "red",margin: "10px auto" }}>{this.state.error}</span></div>
                                    <div className="row popup-ft">
                                        { (btnCount === 1 || btnCount ===2) && <button className="btn btn-primary list-pro d-block" name="1" onClick={this.submit}>{yesTo}</button>}
                                        { (btnCount ===2) && <button className="btn btn-primary list-pro d-block" name="2" onClick={this.submit}>{noTo}</button>}
                                    </div>
                                    {/* <p className="text-light">Need some help ?</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CancelDeeds;