import React, { Component, lazy, Suspense } from 'react';
import './Search.css';

import axios from 'axios';
import Common from '../../../components/Cards/Search/Common'
import Filter from '../../../components/Cards/Search/Filter';
import Loader from '../../../components/Loader/Loader';
import SellerSearch from '../../../components/Cards/Search/SellerSearch'
import  SellerFeature from '../../../components/Cards/Search/SellerFeature'
import SavedSearchDisplay from '../../../components/SavedSearch/SavedSearchDisplay';
import {SEARCHPAYLOAD,NOTIFICATION} from '../../../actions/index'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet';
class Search extends Component {
  state = {
    SearchPayload: {},
    totalpage: {},
    isLoading: true,
    itemTit: 'CATEGORIES',
    location: 'LOCATION',
    priceRange: 'PRICE RANGE',
    sort: [] ,
    page_no:1,
    buyerData : [],
    role : "seller",
    loggedIn : this.props.loggedIn ? true : false, 
    url:process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"
  }
  getBuyers(){
    let self = this
      , payload = (this.props.savedSearch)? this.props.savedSearch : self.state.SearchPayload;
    payload['role'] = "seller";
    payload["page_no"] = (payload && payload.page_no) ? payload.page_no : 1;
    payload["limit"] = 12;
    
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    axios.post(url+'mobileapi/searchfilter', payload)
    .then(function (response) {
        let totalpage = response.data.length ? Math.ceil(response.data.length/payload.limit) : 0;
        let products = !self.state.isLoading ? self.state.buyerData.concat(response.data.data) : response.data.data;
            products = products.length > 0  ? products : [];
        let maxPrice = products.length > 0 ? Math.max.apply(Math, products.map(function(o) { return o.wprice; })) : 1000;
        self.setState({
          totalpage: totalpage,
          buyerData : products,
          isLoading: true,
          maxPrice: maxPrice
        },()=>{
          document.body.style.overflow = "auto";  
          document.body.style.paddingRight = "0px";
          self.props.dispatch(SEARCHPAYLOAD({savedSearch:undefined}))
          self.props.dispatch(NOTIFICATION({headerName:'findBuyers'}))
        })
    })
    .catch(function (error) {
        self.setState({
            error: "Try later!"
        })
        return false;
    });
  }

  changestate(data) {
    
    this.setState({
      SearchPayload: data
      },()=>{
        this.getBuyers();
      })
  }


  componentDidMount() {
    console.log('oooo___________________ooooo')
    
    window.addEventListener('scroll', this.onScroll, false);
    this.getBuyers(null);
  }

  componentWillUnmount() {

    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    let self = this.state;
    let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 2;
    if (
      (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) && 
      this.state.isLoading && Number(self.totalpage) > Number(prePage)) {
        document.body.style.overflow = "hidden";  
        document.body.style.paddingRight = "15px";
        document.body.transition = "all linear 1s";
        self.SearchPayload["page_no"] = prePage+1;
        this.setState({
          isLoading: false,
          SearchPayload: this.state.SearchPayload
        },()=>{
          this.getBuyers();
      })
    }
  }

  filterOnChange  =  event =>  {
    let name = event.target.value;
    let prestate = this.state["SearchPayload"];
    prestate["endDate"]   = (name == 'endDate')   ? 1 : 0;
    prestate["priceLow"]  = (name == 'priceLow')  ? 1 : 0;
    prestate["priceHigh"] = (name == 'PriceHigh') ? 1 : 0;
    prestate["Recent"] = (name == 'Recent') ? 1 : 0;
    prestate["Old"] = (name == 'Old') ? 1 : 0;
    this.setState({
      SearchPayload : prestate
    },()=>{
      this.getBuyers()});
  }

  render() {
    //Dummy data(Feature search)
    let data2 = [1, 2, 3]

    return (
      <main>
        <Helmet><title>{"DoorRise | Search"}</title></Helmet>
        <div className="center-part">
          <Common title="FIND BUYERS" 
            filterLoad={this.state.SearchPayload} 
            maxPrice={1000} 
            oncheckBoxChange={this.changestate.bind(this)}
            userRole={this.state.role}
            loggedIn =  {this.state.loggedIn}
          />
          <div className="container custom-container">
            <section className="filter-body">
              <div className="row">
                <Filter userRole={this.state.role} oncheckBoxChange={this.changestate.bind(this)} />
                <div className="col-md-9">
                {/* <div className="sort width-15 "> */}
                <div className="flex justify-content-bet flex-flow">
                    <select 
                            className=" select-two slect-sort" 
                            id="1" 
                            onChange={this.filterOnChange}>

                      <option value="">Sort By</option>
                      <option value ="priceLow">Price - Low</option>
                      <option value ="PriceHigh">Price - High</option>
                      <option value ="Recent">Recent</option>
                      <option value ="Old">Old</option>
                    </select>
                    {this.props.loggedIn ? <SavedSearchDisplay filter={this.state.SearchPayload} user={this.props.user} loggedIn = {this.props.loggedIn}/> : ''}
                  </div>
                  <div>
                  <Suspense fallback={<Loader/>}>
                    <SellerSearch data={this.state.buyerData} url={this.state.url} />
                  </Suspense>
                  { !this.state.isLoading && <Loader/>}
                  {/* {this.state.buyerData.length <= 0 && 
                    <div className="flex justify-center center">
                      <img src="/images/no-data.png" className="w-100"></img>
                    </div>
                  } */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* <div className="container feture-container">
            <section className="feture-list">
              <div className="pa-d">
                <h4 className="feu-tit">FEATURED LISTINGS</h4>
              </div>
              <div className="flex justify-between pad-btm-7em fetu-width img-widh">
                {data2.map(function (dats, index) {
                  return ( <SellerFeature dat={dats} />)
                })
                }
              </div>
            </section>
          </div> */}
        </div>
      </main>
    )
  }
}


function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  const { savedSearch } = state.header;
  return { user,loggedIn,savedSearch };
}

export default connect(mapStateToProps)(Search);