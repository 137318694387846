export const LOGIN = payload => ({
    type: 'LOGIN_SUCCESS',
    payload
})

export const LOGIN_FAILURE = payload => ({
    type: 'LOGIN_FAILURE',
    payload
})

export const LOGOUT = payload => ({
    type: 'LOGOUT',
    payload
})

export const FORGET_PASSWORD = payload => ({
    type: 'FORGET_PASSWORD',
    payload
})

export const HEADER_POCKETLISTING = payload => ({
    type: 'HEADER_POCKETLISTING',
    payload
});

export const NOTIFICATION = payload => ({
    type: 'NOTIFICATION',
    payload
})

export const PATHNAMEUPDATE = payload => ({
    type: 'PATHNAMEUPDATE',
    payload
})
export const SEARCHPAYLOAD = payload => ({
    type: 'PATHNAMEUPDATE',
    payload
})