import ReactDOM from "react-dom";
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Message.css'
import { Switch, Route,Link } from "react-router-dom";
import { NOTIFICATION,PATHNAMEUPDATE } from  '../../actions/index';
import axios from "axios"
import {
  Seller_buyer_search,
  GetChatWindowMessages,
  InsertChatMessage,
  insertMessageListen,
  GetChatWindowMessages_listen,
  searchMessage_socket,
  searchMessageListen
} from '../../util/socket'



class Message extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      username: {
        data: "",
        required: true,
        errorMsg: "Required"
      },
  
      typeamessage: {
        data: "",
        required: true,
        errorMsg: "Required"
      },
      searchMessage: {
        data: "",
        required: true,
        errorMsg: "Required"
      },
      selectedName: {
        id: "",
        name: "",
        messages: [],
        msgLink:"",
        role: ''
      },
  
      error: '',
      required: {},
      formError: {},
      role: this.props.user.userdata.role,
      endpoint: "",
      socket: "",
      names: [],
      chatHistoryLimit: 6,
      type: "message",
      showChatHistory: true,
      onClickColor : true,
      preapprove : 1
  
    }
  }
  buyerpopupclose = () => {
    this.setState({
      buyerpopup: false
    });
  }
  componentDidMount() {
    var self = this;
    this.state.endpoint = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let user_id = this.props.user.userdata.id
    let role = this.state.role
    let value = ""
    Seller_buyer_search(user_id, role, value, (nameresult) => {
      self.setState({
        names: nameresult.names,
        preapprove : (nameresult['preapprove'])?nameresult['preapprove'][0].preqalified : 1
      });
    })

    insertMessageListen((message) => {
      let prestate_in = this.state.selectedName;
      let user_id = this.props.user.userdata.id
      if(message.toId === user_id){
        let index = this.state.names.findIndex((e) => (e.id === message.fromId) );
        let user = this.state.names[index];
        this.props.dispatch(NOTIFICATION({msg:user.username+" : "+message.message,msgLink:"/dashboard/message/"+user.id+"/"+user.username+"/"+user.role}))
      }
      if((message.toId === this.state.selectedName.id || message.fromId === this.state.selectedName.id) 
        && (message.toId === user_id || message.fromId === user_id) ){
        prestate_in.messages.push({ to_id: message.toId, from_id: message.fromId, message: message.message });
      self.setState({
        selectedName: prestate_in
      }, () => {
        var ele = ReactDOM.findDOMNode(this).querySelector('.overflow_chat');
        if(ele)
          ele.scrollTop = ele.scrollHeight
       
        let index = this.state.names.findIndex((e) => (e.id === message.fromId) );
        if(index >= 0) {
          this.state.names[index].message = message.message;
          this.state.names[index].date_sent = new Date();
          this.state.names[index].read_status = 0;
        }
      })
      }
    });
    GetChatWindowMessages_listen((messages) => {
      var preselectedname = this.state.selectedName
      var messageData = messages.result
      if (messages.data.type === "history") {
        preselectedname.messages.unshift(...messageData);
      } else {
        preselectedname.messages = [];
        preselectedname.messages.push(...messageData);
        preselectedname.messages.reverse()
      }
      this.setState({
        selectedName: preselectedname,
        chatHistoryLimit: (messages.data.limit) ? messages.data.limit : 6
      }, () => {
        var ele = ReactDOM.findDOMNode(this).querySelector('.overflow_chat');
        if (this.state.type !== "history") {
          ele.scrollTop = ele.scrollHeight
        }
      })
    });
    searchMessageListen((message) => {
      var prestate = this.state.selectedName;
      prestate.messages = message.result
      this.setState({
        selectedName: prestate,
      })
    })
    if(this.props.match.params && this.props.match.params.id){
      this.chat_window_content({
        id: this.props.match.params.id,
        username : this.props.match.params.username,
        role: this.props.match.params.role
      }, 'message')
    }
    this.props.dispatch(PATHNAMEUPDATE({pathName:"/dashboard/message"}))
  }

  soc(name, event) {
    var self = this
    var prestate = this.state[name];
    prestate.data = event.target.value;
    this.setState({
      [name]: prestate
    })
   
    let user_id = this.props.user.userdata.id
    let role = this.state.role
    let value = event.target.value 
    Seller_buyer_search(user_id, role, value, (nameresult) => {
      self.setState({
        names: nameresult.names
      })
    })

  }

  chat_window_content(ele, type) {
    var preselectedname = this.state.selectedName
    preselectedname.name = (ele.username) ? ele.username : ele.name
    preselectedname.id = ele.id
    preselectedname.role = ele.role;
    preselectedname.preqalified = ele.preqalified;
    let data = {
      fromId: this.props.user.userdata.id,
      toId: ele.id,
      limit: (type === "message") ? 6 : this.state.chatHistoryLimit,
      type: type,
      role: ele.role,
      preqalified : ele.preqalified
    }
    let index = this.state.names.findIndex((e) => (e.id === ele.id) );
        if(index >= 0) {
          if(this.state.names[index].message) this.state.names[index].read_status = 1;
    }
    GetChatWindowMessages(data)
  }
  typechange(name, event) {
    let prestate = this.state[name]
    prestate.data = event.target.value;
    this.setState({
      [name]: prestate
    })
  }
  sendMessageOnClick() {
    
    let data = {
      toId: this.state.selectedName.id,
      fromId: this.props.user.userdata.id,
      message: this.state.typeamessage.data,
      limit: 1
    }
    InsertChatMessage(data)
    this.state.typeamessage.data = "";
  }
  sendMessageOnClick1(value) {
    
    let data = {
      toId: this.state.selectedName.id,
      fromId: this.props.user.userdata.id,
      message: value,
      limit: 1
    }
    InsertChatMessage(data)
    this.state.typeamessage.data = "";
  }
 
  chatHistory() {
    this.setState({
      selectedName: this.state.selectedName,
      chatHistoryLimit: this.state.chatHistoryLimit + 6,
      type: "history"
    }, () => {
      this.chat_window_content(this.state.selectedName, 'history')
    })
  }
  searchMessage(name, event) {
    
    var prestate = this.state[name];
    prestate.data = event.target.value;
    this.setState({
      [name]: prestate
    })
    
    if (event.target.value === "") {
      this.chat_window_content(this.state.selectedName, this.state.type);
    } else {
      let data = {
        fromId: this.props.user.userdata.id,
        toId: this.state.selectedName.id,
        message: event.target.value
      }
      searchMessage_socket(data);
    }

  }
  chatScroll(event) {
    if (!event.target.scrollTop) {
      this.state.showChatHistory = true;
    } else {
      this.state.showChatHistory = false;
    }
    this.setState({
      showChatHistory: this.state.showChatHistory
    })
  }
  preapproveRequest = () => {
    let self=this;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    axios.post(`${url}mobileapi/sendpreapproveemail`,{}).then((response)=>{
        if(response.data.success === "yes"){
            this.props.dispatch(NOTIFICATION({msg:"Request for approval sent successfully"}))
        }else{
            let failedreason = (response.data&&response.data.failedreason) ? response.data.failedreason : "err"
            this.props.dispatch(NOTIFICATION({msg:failedreason,msgLink:'/dashboard/buyerprofile#preapprove'})) 
        }
    }).catch((error)=>{
        self.setState({
            error:"Try Later"
        })
    })
}
  render() {
    

    return (
      <div className="col-md-9">
        
        <div className="flex justify-between pre-txt">
          <span className="karla fw-600 text-black">MESSAGES</span>
          {this.state.preapprove === 0 && this.props.user.userdata.role === "buyer" &&
            <span><button className="btn btn-green karla fw-600" onClick={this.preapproveRequest}>Get Pre-Approved</button></span>
          }
        </div>
        
        <div className="main-pro">
          <div className="content-main box-shadow pad-30">
            <div className="message-head">
              <div className="row">
                <div className="col-md-5">
                  <div className="search">
                    <form className="navbar-form" role="search">
                      <div className="input-group add-on">
                        <input className="form-control karla" placeholder={(this.state.role === "seller") ? "Search for Buyers" : "Search for Sellers"} name="srch-term"
                          id="srch-term" type="text"
                          onChange={this.soc.bind(this, 'username')}
                          value={this.state.username.data} />
                        <div className="input-group-btn">
                          <button className="btn btn-msg" type="button"><i className="glyphicon glyphicon-search"></i></button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {this.state.selectedName.name !== "" &&
                  <div className="col-md-7">
                    <form className="navbar-form" role="search">
                      <div className="input-group add-on">
                        <input className="form-control karla"
                          placeholder="Search for Messages"
                          name="srch-term"
                          id="srch-term"
                          type="text"
                          value={this.state.searchMessage.data}
                          onChange={this.searchMessage.bind(this, "searchMessage")} />
                        <div className="input-group-btn">
                          <button className="btn btn-msg" type="button"><i className="glyphicon glyphicon-search"></i></button>
                        </div>
                      </div>
                    </form>
                  </div>}
              </div>
            </div>
            <div className="mesage-body">
              <div className="row">
                <div className="col-md-5">
                  <label htmlFor="dropmsg" className="toggle respond msg">Chating
                  <span className="glyphicon glyphicon-menu-down pull-right">
                    </span></label>
                  <input type="checkbox" id="dropmsg" />
                  <div className="div-after message-right-div">
                    {(this.state.names.length > 0) ? this.state.names.map((ele, index) => {
                      return <div className="user-chat box-shadow flex align-center pointer" key={"k_" + index} onClick={this.chat_window_content.bind(this, ele, 'message')} style={ (ele.read_status === 0) ? { backgroundColor: "#eee" } : { backgroundColor: "#fff" } }>
                        <div className="user-im">
                          <div className="div">
                              <span>A.C.</span>
                              <span className="role">{(ele.role === "buyer") ? 
                                          "Buyer" 
                                          : (ele.role === "seller") ? 
                                          "Seller" 
                                          : 
                                          "Realtor" }
                              </span>
                          </div>
                        </div>
                        <div className="user-info" >
                          <h4>{ele.username !== "undefined" ? ele.username : "u***r"} {ele.preqalified ? <span className="pull-right">{"Pre Approved"}</span> : ''}</h4>
                          
                          <span className="ellps">{ele.message}</span>
                          <span className="pull-right">{ ele.date_sent && moment(ele.date_sent).fromNow()}</span>
                        </div>
                      </div>
                    }) : <div className="user-chat box-shadow flex align-center">
                        <div className="user-info" >
                          <h4>No {(this.state.role === 'seller') ? 'Buyers' : 'Sellers'} to be found</h4>
                        </div>
                      </div>}
                  </div>
                </div>
                {this.state.selectedName.name !== "" &&
                  <div className="col-md-7">
                    <div className="chating">
                      <div className="user-chat box-shadow flex align-center">
                        <div className="user-im">
                          <div className="div">
                              <span>A.C</span>
                              <span className="role">{(this.state.selectedName.role === "buyer") ? 
                                          "Buyer" 
                                          : (this.state.selectedName.role === "seller") ? 
                                          "Seller" 
                                          : 
                                          "Realtor" }
                              </span>
                          </div>
                        </div>
                        <div className="user-info">
                          <h4 className="font-15 "><b>{this.state.selectedName.name +"  "}
                          {/* {this.state.selectedName.preqalified && <span className="preTag">{"Pre Approved"}</span>} */}
                          </b></h4>
                        </div>
                      </div>
                      {this.state.showChatHistory && this.state.selectedName.messages.length > 5 && <div className="loadmore">
                        <span className="pointer" onClick={this.chatHistory.bind(this)}>More Chat History</span>
                      </div>}
                      <div className="chat-body">
                        <div className="fixed-center overflow_chat" ref={(el) => { this.messagesEnd = el; }} onScroll={this.chatScroll.bind(this)}>
                          <div>
                            <ul className="ul-list box-shadow">
                              {this.state.selectedName.messages.length > 0 && this.state.selectedName.messages.map((ele, index) => {
                                return (ele.from_id !== this.props.user.userdata.id) ?
                                
                                  <li key={"k_" + index} className="left-chat macro"><a  href={ele.message}  target="_blank" >{ele.message}</a></li>
                                   
                                  :
                                   <li key={"k_" + index} className="right-chat macro">{ele.message}</li>
                              })}
                            </ul>
                          </div>
                        </div>
                        {this.props.user.userdata.role === "buyer"?
                        <div className="pre-content">
                          <p onClick={this.sendMessageOnClick1.bind(this,"I would like more info on this listing.")}>I would like more info on this listing.</p>
                          <p onClick={this.sendMessageOnClick1.bind(this,"I would like to schedule a private showing.")}>I would like to schedule a private showing.</p>
                          <p onClick={this.sendMessageOnClick1.bind(this,"I would like to make a full-priced offer")}>I would like to make a full-priced offer.</p>
                          <p onClick={this.sendMessageOnClick1.bind(this,"I would like to make a less than full priced offer")}>I would like to make a less than full priced offer.</p>
                         </div>
                         :''}
                         {this.props.user.userdata.role === "seller"?
                           <div className="input-group nav-fixed-bottom">
                          <span className="input-group-addon"><img src="/images/icons/smiley.svg" alt="" className="img-responsive" /></span>
                          <input type="text" className="form-control" placeholder="Type a Message..."
                            name="typeamessage"
                            value={this.state.typeamessage.data}
                            onChange={this.typechange.bind(this, "typeamessage")}
                          />
                          <span className="input-group-addon"><img src="/images/icons/msg-send.svg" className="img-responsive" alt="" onClick={this.sendMessageOnClick.bind(this)} /></span>
                        </div>
                        :
                        ''}
                      </div>
                    </div>
                  </div>
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}
export default connect(mapStateToProps)(Message);