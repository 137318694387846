import React, {Component} from 'react';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

import {subscribeToTimer} from '../../../util/socket';
import Timer from '../../Timer/Timer';
import '../Cards.css';
import axios from 'axios'
import moment from 'moment';
import Popup from '../../Popup/Popup';
class BuyerSearchBuynow extends Component {

  constructor(props) {
    super(props);
    subscribeToTimer((err, timestamp) => this.setState({
      timestamp
    }));
    this.state = {
      url: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/",
      carddata: [],
      timestamp: "Loading.." ,
      savedSearchClass : "savedSearchBackground",
      wishlist: [],
      pop:false
    }
  }

  
  addWatchlist=(projectId)=>event=>{
    var self = this;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let params = new Object();
    params["projectId"] = projectId;
    params["buyer"] = 0;
    axios.post(`${url}mobileapi/watchlist`,params).then(function(response){
      let index = self.props.value.findIndex((e) => (e.id === projectId) );
      self.props.value[index].watchdelete = 0;
      self.setState({
        wishlist : self.props.value,
      });
    })
  }
 
  popupHandle = (data) => {
        
    this.setState({
        pop:false
    })
}
openpopup = () => {
        
  // if(this.props.user.userdata.subscribe_status!=="active")
  // {
  this.setState({
      pop:true
  })
  // }
}
  removeWatchlist=(projectId)=>event=>{
    var self = this;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let params = new Object();
    params["projectId"] = projectId;
    params["buyer"] = 0;
    axios.post(`${url}mobileapi/removeFwatchlist`,params).then(function(response){
      let index = self.props.value.findIndex((e) => (e.id === projectId) );
      self.props.value[index].watchdelete = 1;
      if(self.props.savedProperty === true)
        self.props.value.splice(index,1);
      
      self.setState({
        wishlist : self.props.value,
      });
    })
  }
  


  render() {
    let url1 = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let value = this.state.wishlist.length > 0 ? this.state.wishlist : this.props.value;

    
    let {loggedIn} = this.props;
   
    return (
      <div className="flex flex-wrap flex-center">
        {value.length>0 ? value.map((search, index) => (
          <div key={`key${index}`}  className={this.props.flex ? "buyer-grid text-center box-shadow main-mar-pro saved_search_buyer" : "buyer-grid text-center box-shadow main-mar-pro" }>
            {!search.auctionid ?
              <div className="">
                <figure className="img-before">
                  <img src={search.avatar ? url1 + "uploads/product/" + search.avatar : "/images/No_Image_Available.jpg"} alt="product-1" className="img-responsive" />
                </figure>
                <div className="product-span">
                  <span className="pro-hd"><b>Current Bid : ${search.latestbid}</b></span>
                </div>
                <Link to={"/product/view/" + search.id + "/" + search.auctionid}>
                  <div className="grid-body text-left pad-10 span-block flex-hgt">
                    <span>Property Type : <b>{search.propertype}</b></span>
                    <span>Sqft : <b>{search.mark}</b></span>
                    <span>Beds :<b>{search.bedroom}</b> (&) Baths : <b>  {search.bathroom}</b></span>
                    <span>Condition : <b> Completely Updated </b></span>
                  </div>
                  <button className="btn btn-blury btn-block border-radius-0">Make an Offer</button>
                </Link>
              </div>
              :
              <div className="">
                <figure className="img-before">
                  <img src={search.avatar ? (url1 + "uploads/product/" + search.avatar) : "/images/No_Image_Available.jpg"} alt="product-1" className="img-responsive" />
                  <div className="auction-id top-auction gray text-center">
                    {loggedIn&&<span className="tt">
                      { ( search.watchdelete === 1 || search.watchdelete === "")
                          ? 
                          <b className={`watch`} onClick={this.addWatchlist(search.id)}></b>
                          :
                          ( search.watchproject === search.id) && search.watchdelete !== 1 
                          ? <b className={"watch "+this.state.savedSearchClass} onClick={this.removeWatchlist(search.id)}></b> 
                          :
                          <b className={`watch`} onClick={this.addWatchlist(search.id)}></b>
                      }
                        
                    </span>}
                    {/* <h5 className="text-light">
                    {search.auctiontype === 'auction' ? ((new Date(search.date_added).getTime() - new Date().getTime()) <= 0) ? "AUCTION  ENDS IN  : " : "AUCTION  STARTS IN  : " : (search.auctiontype==='listing') ? "POSTED " : ((new Date(search.date_added).getTime() - new Date().getTime()) <= 0) ? " ENDS IN  : ":"STARTS IN :"}
                        <Timer auctiontype={search.auctiontype} date_added={search.date_added} closeTime={search.auctiontype==="listing"?search.date_added:search.date_closed} serverTime={this.state.timestamp.dTime} /> {search.auctiontype==='listing' ? " AGO" : ''}
                    </h5> */}
                    <h5 className="text-light">
                            {search.auctiontype === 'auction' 
                            ? ((new Date(search.date_added).getTime() - new Date().getTime()) <= 0) 
                                ?
                                    <>
                                    AUCTION  ENDS IN  :  
                                    <Timer
                                    auctiontype={search.auctiontype} date_added={search.date_added} closeTime={search.auctiontype==="listing"
                                    ?search.date_added:search.date_closed} serverTime={this.state.timestamp.dTime} />
                                    </> 
                                : `AUCTION  STARTS ON ${moment(search.date_closed).format('YYYY-MM-DD')}` 
                            : (search.auctiontype==='listing' ) 
                                ? "POSTED " 
                                : ((new Date(search.date_added).getTime() - new Date().getTime()) <=0) 
                                    ? 
                                    <>
                                    ENDS IN  :  <Timer
                                auctiontype={search.auctiontype} date_added={search.date_added} closeTime={search.auctiontype==="listing"
                                ?search.date_added:search.date_closed} serverTime={this.state.timestamp.dTime} /></>:<>STARTS IN :<Timer
                                auctiontype={search.auctiontype} date_added={search.date_added} closeTime={search.auctiontype==="listing"
                                ?search.date_added:search.date_closed} serverTime={this.state.timestamp.dTime} />
                                </>} 
                            {search.auctiontype==='listing' 
                            ? 
                                <>
                                    <Timer
                                    auctiontype={search.auctiontype} date_added={search.date_added} closeTime={search.auctiontype==="listing"
                                    ?search.date_added:search.date_closed} serverTime={this.state.timestamp.dTime} />  AGO 
                                </>
                            : ''}
                        </h5>
                  </div> 
                  
                </figure>
                {(search.market_status!=="draft")?<div>
                <div className="product-span">
                  <span className="pro-hd"><b>{search.auctiontype !== 'auction' ? "Asking Price" : search.latestbid > 0 ? "Asking Price" : "Opening Bid"} : ${search.latestbid > 0 ? search.latestbid : search.wprice }</b></span>
                </div>
                </div>
                :
                <div>
                <div className="product-span">
                  <span className="pro-hd"><br></br></span>
                </div>
                </div>
                }
                 
                <Link to={"/product/view/" + search.id + "/" + search.auctionid}>
                  <div className="grid-body text-left pad-10 span-block flex-hgt">
                    <span>Property Type : <b>{search.propertype}</b></span>
                    <span>Address : <b>{search.location}</b></span>
                    <span>Living Area : <b>{search.min_property_sq_footage}</b></span>
                    <span>Beds & Baths : <b>{search.bedroom} & {search.bathroom}  </b></span>
                    <span>Open House Date : <b>{search.openhousedate? (search.openhousedate==='0000-00-00' || search.openhousedate==='0000-00-00 00:00:00') ?'TBD':moment(search.openhousedate).format("MM-DD-YYYY HH:mm:ss"):'TBD'}</b></span>
                  </div>
                  {(search.market_status !== "draft") ?<div> 
                  { (search.auctiontype !== 'pocketlisting' && search.auctiontype !=='offers' && search.auctiontype !=='listing') ? 
                     
                      <button className="btn btn-secondary btn-block border-radius-0">Bid Now</button> 
                      :
                      
                      // (search.auctiontype === 'pocketlisting') ? 
                      // <button className="btn btn-secondary btn-block border-radius-0">View More</button>  : 
                      <button className="btn btn-secondary btn-block border-radius-0">Make An Offer</button>
                  }</div>
                  :
                  <span><button className="btn btn-secondary btn-block border-radius-0">Property Comming Soon</button></span>
                 } 
                </Link>
              </div>
            }
          </div>
        )) :  
          <div className="flex justify-center center w-100">
            <img style={{height:'150px',width:'auto',objectFit:'cover'}} src="/images/no-data.png" className=""></img>
          </div>}
          {this.state.pop ? <Popup color="black"
                                title="Message"
                                description = {"Subscribe so that you will be able to bid on the product and make an offer"}
                                // testBox={true}
                                // yesTo="Cancel" // btn 1
                                // noTo="Accept" // btn 2
                                // btnCount={2} // 1 or 2
                                popupHandle={this.popupHandle}
                                // extradata={{id:this.state.counterId}}
                            /> : ''}
      </div>
      
    )
   
  }
  
}
function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(BuyerSearchBuynow);