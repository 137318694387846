import React, {Component} from 'react';
import {CardNumberElement,CardExpiryElement,CardCvcElement,injectStripe} from 'react-stripe-elements';
import "./Stripe.css";

import axios from 'axios';
import { NOTIFICATION } from  '../../actions/index';
import Paypal from './Paypal';
import PaypalSubscribtion from './PaypalSubscribtion';

class CheckoutForm extends React.Component {
    constructor() {
        super();
        this.state = { 
            stripe: null, 
            error : '',
            holderName:'',
        };
        this.submit = this.submit.bind(this);
    }

    async submit(ev) {
        
        let self = this;
        let {error,token} = await this.props.stripe.createToken({name: this.state.holderName});
        if (!error && this.state.holderName != '') {
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            let payload = {
                "source": token.id,
                "project_id": this.props.project_id,
                "amount": this.props.amount,
                "method": "stripe"
            }
            let linkadd = this.props.subscription ? '1':'0';
            axios.post(url + 'mobileapi/charge/'+linkadd, payload)
                .then(function (response) {
                    if (response.data.success === "yes") {
                        self.props.dispatch(NOTIFICATION({msg: linkadd  === '1' ? "Successfully subscribed." : "Successfully added!" }))
                        self.props.onChangeHandle();
                    } else {
                        self.setState({ error: "Try again!" })
                    }
                })
                .catch(function (error) {
                    self.setState({ error: "Try again!" })
                });
        }else{
            error.message = this.state.holderName === ''  ? "Card holder's name required" : error.message
            this.setState({error: error.message});
        }
      
    }

    handleClose = () =>{
        this.props.StripeBoxCloseHandle()
    }

    handleNameUpdate = (event) => {
        this.setState({holderName: event.target.value})

    }

    handleChange = () => {
          this.setState({error: ""});
    };
      
    render() {
        let { paypalPayModel, PaypalSubscribeModel} = this.props;
        return (
            <div className="modal fade mod-place-bid in modal-open d_block" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog strip-pop" role="document">
                    <div className="modal-content show-model bid-hiy pt-0">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.handleClose}><span aria-hidden="true">&times;</span></button>
                            <h3 className="text-center karla fw-600">Payment Details</h3>
                        </div>
                        <div className="modal-body ">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group ">
                                            <label for="exampleInputEmail1">Card Holder's Name</label>
                                            <input type="text" 
                                                className="form-control site-input"
                                                id="holderName" 
                                                placeholder="Enter The Name On The Card"
                                                name="holderName"
                                                value={this.state.holderName}
                                                onChange={this.handleNameUpdate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Card Number</label>
                                            <CardNumberElement onChange={this.handleChange} className="form-control site-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Expiration (MM/YY)</label>
                                            <CardExpiryElement onChange={this.handleChange} className="form-control site-input" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">CVC</label>
                                            <CardCvcElement onChange={this.handleChange} className="form-control site-input" />
                                        </div>
                                    </div>
                                </div>
                               { this.props.subscription && <div className="row">
                                    <div className="col-md-12 text-center">
                                        <p><b>Note:</b> $34 will be debited for monthly subscription.</p>
                                    </div>
                                </div>}
                                <div className="row">
                                    <div className="text-center"><span style={{ color: "red" }}>{this.state.error}</span></div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <span className="pad-tb-15">
                                            <button className="btn btn-primary" onClick={this.submit}>Save</button>
                                        </span>
                                    </div>
                                </div>
                                { (paypalPayModel || PaypalSubscribeModel) && <div className="col-12 text-center pay-interline">
                                   ( OR )
                                </div>}
                                {(paypalPayModel || PaypalSubscribeModel) && <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group text-center">
                                            {paypalPayModel && <Paypal {...this.props} />}
                                            {PaypalSubscribeModel && <PaypalSubscribtion {...this.props} />}
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default injectStripe(CheckoutForm);