import React, { Component, lazy, Suspense } from 'react';
import './Search.css';

import axios from 'axios';

import Common from '../../../components/Cards/Search/Common';
import Filter from '../../../components/Cards/Search/Filter';
import Loader from '../../../components/Loader/Loader';
import SavedSearchDisplay from '../../../components/SavedSearch/SavedSearchDisplay';

import FeatureProperty from '../../../components/FeaturedProperty/FeaturedProperty';
import { connect } from 'react-redux'
import {HEADER_POCKETLISTING} from '../../../actions/index';
import {properResult} from '../../../util/capsAndSpaces';
import { Helmet } from 'react-helmet';

const BuyerSearchBuynow = lazy(() => import('../../../components/Cards/Search/BuyerSearch') )

class Search extends Component {
  state = {
    SearchPayload: {},
    totalpage: {},
    isLoading: true,
    itemTit: 'CATEGORIES',
    location: 'LOCATION',
    priceRange: 'PRICE RANGE',
    productDetails: [],
    maxPrice: 1000,
    sort: [{ value: "", lable: "-Select-" }, { value: "1", lable: "Time Ending Soon" },
    { value: "2", lable: "Price Low - High" }, { value: "3", lable: "Price High - Low" }],
    url: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/",
    componenty_update_pocketlisting: true,
    featuredProperty: []
  }

  getProducts = () => {
    let self = this
      , payload = (this.props.savedSearch)? this.props.savedSearch : self.state.SearchPayload;
    payload['role'] = "buyer";
    payload["page_no"] = (payload && payload.page_no) ? payload.page_no : 1;
    payload["limit"] = 12;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    axios.post(url + 'mobileapi/searchfilter', payload)
      .then(async function (response) {
        let totalpage = response.data.length ? Math.ceil(response.data.length / payload.limit) : 0;
        let products = !self.state.isLoading ? self.state.productDetails.concat(response.data.data) : response.data.data;
        products = products.length > 0 ? products : [];
        let maxPrice = products.length > 0 ? Math.max.apply(Math, products.map(function (o) { return o.wprice; })) : 1000;
        self.setState({
          totalpage: totalpage,
          productDetails: products,
          isLoading: true,
          maxPrice: maxPrice,
          featuredProperty: await properResult(response.data.featuredProperty)
        }, () => {
          document.body.style.overflow = "auto";
          document.body.style.paddingRight = "0px";
        })
      })
      .catch(function (error) {
        self.setState({
          error: "Try later!"
        })
        return false;
      });
  }

  changestate(payload) {
    this.setState({
      SearchPayload: payload
    },()=>{
      this.getProducts(payload);
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    
    if(this.props.pocketlisting_buyer_search){
      let saletype = this.state["SearchPayload"]
      saletype["saletype"] = "pocketlisting"
      this.setState({
                      SearchPayload : saletype
                    },
                    ()=>{
                            this.props.dispatch(HEADER_POCKETLISTING({pocketlisting_buyer_search:false}))
                          }
                    );
      }
      
    this.getProducts(null);
  }
 componentDidUpdate(){
   if(this.props.pocketlisting_buyer_search){
    let saletype = this.state["SearchPayload"]
    saletype["saletype"] = "pocketlisting"
    this.setState({
      SearchPayload : saletype},()=>{
      this.props.dispatch(HEADER_POCKETLISTING({pocketlisting_buyer_search:false}))
      this.getProducts(null);
      })
    }
 }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }
 
  onScroll = () => {
    let self = this.state;
    let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 2;
    if (
      (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) && 
      this.state.isLoading && Number(self.totalpage) > Number(prePage)) {
        document.body.style.overflow = "hidden";  
        document.body.style.paddingRight = "15px";
        document.body.transition = "all linear 1s";
        self.SearchPayload["page_no"] = prePage+1;
        this.setState({
          isLoading: false,
          SearchPayload: this.state.SearchPayload
        },()=>{
          this.getProducts();
      })
    }
  }
  filterOnChange  =  event =>  {
    let name = event.target.value;
    let prestate = this.state["SearchPayload"];
    prestate["endDate"]   = (name === 'endDate')   ? 1 : 0;
    prestate["priceLow"]  = (name === 'priceLow')  ? 1 : 0;
    prestate["priceHigh"] = (name === 'PriceHigh') ? 1 : 0;
    prestate["Recent"] = (name === 'Recent') ? 1 : 0;
    prestate["Old"] = (name === 'Old') ? 1 : 0;
    this.setState({
      SearchPayload : prestate
    },()=>{
      this.getProducts()});
  }
  render() {
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    return (
      <main>
        <Helmet><title>{"DoorRise | Search"}</title></Helmet>
        <div className="center-part">
          <Common title="FIND PROPERTIES" 
            filterLoad={this.state.SearchPayload} 
            maxPrice={this.state.maxPrice} 
            oncheckBoxChange={this.changestate.bind(this)}
          />
          <div className="container custom-container">
            <section className="filter-body">
              <div className="row">
                <Filter oncheckBoxChange={this.changestate.bind(this)} maxPrice={this.state.maxPrice}/>
                <div className="col-md-9">
                  {/* <div className="sort width-15 "> */}
                  <div className="flex justify-content-bet flex-flow">
                    <select 
                            className=" select-two slect-sort" 
                            id="1" 
                            onChange={this.filterOnChange}>

                      <option value="">Sort By</option>
                      <option value ="endDate">Ending soon</option>
                      <option value ="priceLow">Price - Low</option>
                      <option value ="PriceHigh">Price - High</option>
                      <option value ="Recent">Recent</option>
                      <option value ="Old">Old</option>
                    </select>
                    {this.props.loggedIn ? <SavedSearchDisplay filter={this.state.SearchPayload} user={this.props.user}/> : ''}
                  </div>
                    <Suspense fallback={<Loader/>}>
                      <BuyerSearchBuynow value={this.state.productDetails} SavedProperty={false} />
                    </Suspense>
                  { !this.state.isLoading && <Loader/>}
                  {/* {this.state.productDetails.length <= 0 && 
                    <div className="flex justify-center center">
                      <img src="/images/no-data.png" className="w-100"></img>
                    </div>
                  } */}

                </div>

              </div>
            </section>
          </div>
          {this.state.featuredProperty.length > 0 ?
            <div className="row"><FeatureProperty FeatureProperty={this.state.featuredProperty} url={url} /></div>
            : ''}
        </div>

      </main>
    )
  }
}


function mapStateToProps(state) {
  const { user, loggedIn  } = state.login;
  const { pocketlisting_buyer_search,savedSearch  } = state.header;
  return { user, loggedIn , pocketlisting_buyer_search,savedSearch};
}

export default connect(mapStateToProps)(Search);