import ReactDOM from "react-dom";
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
// import './Offers.css'

import { NOTIFICATION } from '../../actions/index';
import MatchCard from '../../components/Cards/Search/matchcard';
import axios from "axios"




class Offers extends Component {
    state = {
        productDetails:[],
        searchPayload : {},
        totalPage : "",
        isLoading : true,
        page_no : 1,
        tab:"all",
        preapprove : 1

    }
    constructor(props) {
        super(props)

    }
    getProductDetails() {
            console.log("++++++++++++++++++++")     
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"; 
            let self=this;
            let tab = this.state.tab;
            let completeUrl = `${url}mobileapi/mymatch`
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            console.log("user",this.props.user.data)
            axios.post(completeUrl).then((response)=>{
                // console.log("pro++++",response.data);
                let totalpage = response.data.totalResult ? Math.ceil(response.data.totalResult/2) : 0;
                let products = !self.state.isLoading ? self.state.productDetails.concat(response.data.data) : response.data.data;
                products = products.length > 0 ? products : [];
                this.setState({
                    productDetails : products,
                    isLoading : true,
                    totalpage:totalpage,
                })

            })

    }
    componentDidMount() {
        window.addEventListener('scroll',this.onscroll,false)
        this.getProductDetails();
    }
    componentWillUnmount(){
        window.removeEventListener('scroll',this.onscroll,false)
    }
    onscroll = () => {
        let self = this.state;
        let prePage = self.searchPayload["page_no"] ? self.searchPayload["page_no"] : 1;
        let totalpage = self.totalpage;
        if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) &&
            this.state.isLoading && Number(totalpage) > Number(prePage)) {
            self.searchPayload["page_no"] = prePage + 1;
            this.setState({
                isLoading: false,
                searchPayload: this.state.searchPayload,
            }, () => {
                this.getProductDetails();
            })
        }
    }
    tabChange = (tab)=>event=>{
        this.setState({
            tab : tab
        },()=>{this.getProductDetails()})
    }
    addactiveclassName=(tab)=>{
        this.setState({
            tab : tab
        })
    }
    preapproveRequest = () => {
        let self=this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(`${url}mobileapi/sendpreapproveemail`,{}).then((response)=>{
            (response.data.success=="yes")  ? this.props.dispatch(NOTIFICATION({msg:"Request for approval sent successfully"})) 
                                            : (response.data&&response.data.failedreason) 
                                                ? this.props.dispatch(NOTIFICATION({msg:response.data.failedreason,msgLink:'/dashboard/buyerprofile'})) 
                                                : this.props.dispatch(NOTIFICATION({msg:"err"}))
        }).catch((error)=>{
            self.setState({
                error:"Try Later"
            })
        })
    }
    
    render() {
        return (
            <div className="col-md-9">
                <div className="flex justify-between pre-txt">
                    <span className="karla fw-600 text-black">My Property Matches</span>
                    {/* {this.state.preapprove==0 &&
                      <span><a data-toggle="tab" href="/dashboard/buyerprofile#menu1" aria-expanded="true"> <button class="btn btn-green karla fw-600">Get Pre-Approved</button></a></span>
                    } */}
                </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">

                        <MatchCard
                            productDetails = {this.state.productDetails}
                            user = {this.props.user}
                        />
                    </div>
                    <div>
                        <h4><div className="text-center pad-tb-30">
                            
                        </div></h4>
                    </div>
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
    return { user, loggedIn };
}

export default connect(mapStateToProps)(Offers);