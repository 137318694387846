import React, {Component} from 'react';
import './Blog.css';
import axios from 'axios';

class Blog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blog: [],
        };
        this._increase();
    }

    wpPost(){
        let self = this;
        let url ="http://167.99.149.11/blogtest/";
        axios.get(`${url}/wp-json/wp/v2/posts?_embed`)
        .then(function (response) {
          if(response && response.data){self.setState({
            blog : response.data, 
          })}
        })
        .catch(function (error) {
          console.log("Try later");
        });
    }
    _increase() {
        if(this.state){
            let blog = this.state.blog ? this.state.blog : [];
            if(blog.length > 0){let left = blog.shift()
            blog.push(left)
            this.setState({
                blog: blog
            })}
        }
        setTimeout(this._increase.bind(this), 10000);
    }
    componentDidMount() {
        this.wpPost();
    }
    render() {
        let blogs = this.state.blog.length > 0 ? this.state.blog.slice(0, 3) : [];
        // let {blog:blogs} = this.state
        return (
            <div class='container'>
                <h4 className="blog-tit text-center">Blog</h4>
            <div className="flex justify-betwen blog-carosal">
                {blogs && blogs.map((data,index)=>(
                    <div className="sell-limity" key={"sell-limit-"+index}>
                        <figure className="b2-img">
                            <a href={data.link}><img src={data._embedded['wp:featuredmedia'][0].source_url} className="img-responsive" alt="property-1" />
                            <span className="iim"></span>
                            </a>
                        </figure>
                        <div className="limity-body">
                            <h4>{data.title.rendered}</h4>
                            <p className="Roboto" dangerouslySetInnerHTML={{__html:data.excerpt.rendered}}></p>
                            <a href={data.link}><span>Read More  <i className="fas fa-arrow-right"></i></span></a>
                        </div>
                    </div>
                ))}
            </div>
            </div>
        )
    }
}

export default Blog;