import React, { Component } from 'react';
import './Productpost.css';
import { connect } from 'react-redux';
import { NOTIFICATION,PATHNAMEUPDATE } from '../../actions/index';
import Postproduct from './Productpost';

class Productpost_before extends Component {
    render() {
        return (
            <main>
                <div class="center-part pad-tb-30">
                    <div class="container">
                        <div class="">
                            <div class="col-md-12">
                                {/* <h3 class="karla fw-600 text-black text-center">POST A PROPERTY</h3> */}
                                <div class="main-pro">
                                    {/* <div class="content-main box-shadow pad--both-100"> */}
                                    <Postproduct props_={this.props} />
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Productpost_before); 