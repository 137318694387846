import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './SideNav.css';

import { connect } from 'react-redux';
import {PATHNAMEUPDATE} from '../../actions/index'


let pathName;

class SideNav extends Component {
    state = {
        error: '',
        buyer:[
            {name:"My Offers",path:"/dashboard/offers"},
            {name:"My Auctions",path:"/dashboard/buyer"},
            {name:"My Property Matches" , path:"/dashboard/mymatch"},
            {name:"Find Properties",path:"/search/buyer"},
            {name:"Saved Properties",path:"/dashboard/savedproperty"},
            {name:"Saved Searches" , path:"/dashboard/savedsearch"},
            {name:"Messages",path:"/dashboard/message"},
            {name:"Profile",path:"/dashboard/buyerprofile"},
            {name:"Payment History" , path:"/dashboard/paymenthistory"},
            {name:"My Interested",path:"/dashboard/iaminterested"}
            ],
        seller:[
            {name:"Find Buyers",path:"/search/seller"},
            {name:"My Listings",path:"/dashboard/mylistings"},
            {name:"My Auctions",path:"/dashboard/seller"},
            // {name:"Add A Listing",path:"/dashboard/post"},
            {name:"Saved Buyers",path:"/dashboard/savedsearch"},
            {name:"Messages",path:"/dashboard/message"},
            {name:"Profile",path:"/dashboard/profile"},
            {name:"Change Password",path:"/dashboard/changepassword"},
            // {name:"Saved Searches",path:"/dashboard/message"},
            {name:"Payment Details" , path:"dashboard/payment"},
            {name:"Payment History" , path:"/dashboard/paymenthistory"}
            ],
        realtor: [
            { name: "My Listings", path: "/dashboard/realtor" },
            { name: "Post Listing", path: "/dashboard/pocketlisting" },
            { name: "Profile", path: "/dashboard/profile" },
            { name: "Change Password", path: "/dashboard/changepassword" },
            { name: "Messages", path: "/dashboard/message" },
            {name:"Payment Details",path:"/dashboard/payment"},
        ],
        displayList:[],
        checked:0,
        active:"active"
    }

    loadNavData(path) {
        let userInLocalStorage = localStorage.getItem('user');
        var user = JSON.parse(userInLocalStorage);
        let index = "";
        let location = path ? path : window.location.pathname;
        switch (user.userdata.role) {
            case 'seller':
                this.state.seller.map((ele, ind) => {
                    if (ele.path === location) {
                        index = ind
                    }
                });
                this.setState({
                    displayList: this.state.seller,
                    checked: index
                });
                break;
            case 'buyer': 
                this.state.buyer.map((ele, ind) => {
                    if (ele.path === location) {
                        index = ind
                    }
                });
                this.setState({
                    displayList: this.state.buyer, checked: index
                });
                break;
            
            case 'realtor':
                this.state.realtor.map((ele, ind) => {
                    if (ele.path === location) {
                        index = ind
                    }
                });
                
                this.setState({
                    displayList: this.state.realtor,
                    checked: index
                });
                break;
        }
    }

    tabChange(t){
        this.setState({checked:t})
    }

    componentWillReceiveProps(newProps){
        if(newProps.pathName !== "")
        {  
            this.loadNavData(newProps.pathName);
            this.props.dispatch(PATHNAMEUPDATE({pathName:""}))
        }
    }

    sellerLi(){
        return  <div>
                    {this.state.seller.map((ele,ind)=>{
                        return <li key={"k_"+ind} className={this.state.checked === ind ? this.state.active : 'sidenav'} onClick={this.tabChange.bind(this,ind)}><Link to={{pathname:ele.path}}>{ele.name}</Link></li>    
                    })}
                 </div>
            }
    buyerLi(){
        return  <div>
                        {this.state.buyer.map((ele,ind)=>{
                        return <li  key={"k_"+ind} className={this.state.checked === ind ? this.state.active : 'sidenav'} onClick={this.tabChange.bind(this,ind)}><Link to={{pathname:ele.path}}>{ele.name}</Link></li>    
                        })}
                </div>
            }
    realtorLi() {
        return <div>
            {this.state.realtor.map((ele, ind) => {
                return <li key={"k_" + ind} className={this.state.checked === ind ? this.state.active : 'sidenav'} onClick={this.tabChange.bind(this, ind)}><Link to={{ pathname: ele.path }}>{ele.name}</Link></li>
            })}
        </div>
    }
    render() {
        let role = this.props.userdata ? this.props.userdata.role : ''
        let {preqalified} = this.props.userdata ? this.props.userdata.preqalified : ''
        return (
            <div>
                <div className="nav-dash box-shadow">
                    <h3 className=" text-center"><span>DASHBOARD</span></h3>
                    <div className={(role === "buyer" && preqalified) ? "profile dashboard_profile dash":"profile dashboard_profile"}>
                        <div className="div"> 
                            <span className="text-uppercase">{`${this.props.user.userdata.first_name[0]}.${this.props.user.userdata.last_name[0]}`}.</span>
                            <span>{(role === "buyer") ? 
                                        "Buyer" 
                                        : (role === "seller") ? 
                                        "Seller" 
                                        : 
                                        "Realtor" }</span> 
                        </div> 
                    </div>
                    <label htmlFor="drop" className="toggle respond">Menu 
                    <span className="glyphicon glyphicon-menu-down pull-right"></span></label>
                    <input type="checkbox" id="drop" />
                    <div className="dash-ul flex justify-center">
                        <ul className="ul-list">
                            {(role === "buyer")?this.buyerLi():
                            (role === "seller") ? 
                            this.sellerLi() : this.realtorLi()}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    
}



function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    let pathName = state.header.pathName ? state.header.pathName : ''
    return { user,loggedIn,pathName};
}

export default connect(mapStateToProps)(SideNav);
// export default SideNav;