import React, { Component } from 'react';
// import Slider from '@material-ui/lab/Slider';
import {connect} from 'react-redux'
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import './Search.css'

function CheckBox({ name, value,value1, tick, onCheck }) {
  
  return (
    <label className="show-check">
      <strong>{value}</strong>{value1}
      <input
        className="check-height"
        name={name}
        type="checkbox"
        value={value+value1}
        checked={tick || false}
        onChange={onCheck}
      />
      <span className="checkmark"></span>
    </label>
  );
}

function CheckBoxList({ name, check, options, isCheckedAll, onCheck , word}) {

  const checkBoxOptions = (
    <div className="checkbox-list">
      {options.map((option, index) =>(
          <li key={"checkbox-"+index}>
            <CheckBox key={index} name={option.name} value={option.displayName?option.displayName:option.value} value1={option.value1} tick={option.checked} onCheck={(e) => onCheck(name, check, option.value, e.target.checked)} />
          </li>
        ))}
    </div>
  );
  if(name==='saletype'){
    return (
      <div className="checkbox-list">
      {checkBoxOptions}
        <li>
          <CheckBox name={name} value={word} tick={isCheckedAll} onCheck={(e) => onCheck(name, check, 'all', e.target.checked)} />
        </li> 
      </div>
    );
  }
  return (
    <div className="checkbox-list">
      <li>
        <CheckBox name={name} value={word} tick={isCheckedAll} onCheck={(e) => onCheck(name, check, 'all', e.target.checked)} />
      </li> 
      {checkBoxOptions}
    </div>
  );
}
class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/",
      location:"",
      priceRange: ['',''],
      handleSqFootageSlider : ['',''],
      handleLandSqFootageSlider:  ['',''],
      isAllChecked1: false,
      propertype: [
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Single Family"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Multi-Family"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Condo"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Commercial"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Vacant Land"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          value: "Development Deal"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          displayName : "Trust Deed",
          value: "Contract"
        },
        {
          name: "propertype",
          data: "",
          checked: false,
          displayName : "Wholesale Contract",
          value: "Contract"
        }
      ],
      buyerfinance: [
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "Conventional"
        },
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "All Cash"
        },
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "FHA"
        },
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "VA"
        },
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "Hard Money"
        },
        {
          name: "buyerfinance",
          data: "",
          checked: false,
          value: "Seller-Financed"
        }
      ],
      location_arr : [
        "All Areas",
        "LA - Downtown", 
            "LA - South LA", 
            "LA - Mid City, Beverly Hills, Culver City",
            "LA - Hollywood, East Hollwood, WeHo",
            "LA - Westside, Santa Monica, Beaches", 
            "LA - North, Glendale, Highland Park, Pasadena, etc", 
            "LA - Eastern Cities",
            "SF East - NoHo, Burbank, Sunland, etc",
            "SF South- Universal City, Studio City, Van Nuys, Encino, Sherman Oaks etc.", 
            "SF Central - Van Nuys, Sepulveda, Reseda, etc",
            "SF North - North Hills, Granada Hills, Northridge, etc.", 
            "SF West - Woodland Hills, Tarzana, Chatsworth, Calabasas, etc.",
            "SB- Inglewood, El Segundo, Hawthorn",
            "SB - West, Beach Cities",
            "SB - Central, Carson, Torrance, etc.",
            "SB - South, Palos Verdes, Rolling Hills, San Pedro, etc",
            "LONG BEACH AREA",  
            "ORANGE COUNTY",
            "RIVERSIDE COUNTY",
            "CANYON COUNTRY AREA",
            "SANTA CLARITA/VALENCIA AREA",
            "PALMDALE AREA",
            "LANCASTER AREA"
    ],
      isAllChecked: false,
      saletype: [
        
        {
          name: "saletype",
          data: "make an offer",
          checked: false,
          displayName:"listing",
          value: "listing"
        },
        {
          name: "saletype",
          data: "",
          displayName:"auction",
          checked: false,
          value: "auction"
        }
      ],
      isAllsellerTypeChecked : false,
      sellertype : [
        {
          name:'sellertype',
          data:'',
          displayName:'Private Owner',
          checked:false,
          value:'auction'
        },
        {
          name:'sellertype',
          data:'',
          displayName:'Realtor Listing',
          checked:false,
          value:'pocketlisting'
        }
      ],
      isAllCheckedcondition: false,
      condition:[
        {
          name: "condition",
          data:"",
          checked: false,
          value:"Excellent",
          value1:"- Completely Updated",
          
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Good ",
          value1:"- Moderate updates",
         
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Average ",
          value1:"- Few updates. Mostly Original",
         
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Poor - ",
          value1:"Repairs and updates needed"
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Investor Grade ",
          value1:"- Major repairs and updates needed"
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Land Value only"
        },
        {
          name: "condition",
          data:"",
          checked: false ,
          value:"Development Deal "
        }
      ],
      isAllCheckedbedroom: false,
      bedroom:[
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"1"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"2"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"3"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"4"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"5"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"6"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"7"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"8"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"9"
        },
        {
          name: "bedroom",
          data:"",
          checked: false ,
          value:"10+"
        },
      ],
      isAllCheckedbathroom: false,
      bathroom:[
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"1"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"2"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"3"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"4"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"5"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"6"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"7"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"8"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"9"
        },
        {
          name: "bathroom",
          data:"",
          checked: false ,
          value:"10+"
        },
      ] , 
      state : this.props.state
    }
  }
  
  componentDidMount(){
    if(this.props.pocketlisting_buyer_search){
      this.state.saletype[1].checked = true
      this.setState({
        saletype : this.state.saletype
      })
    }
  }
componentWillUpdate(){
  if(this.props.pocketlisting_buyer_search){
      this.state.isAllChecked1        = false;
      this.state.isAllChecked         = false; 
      this.state.isAllCheckedcondition = false
      this.state.isAllCheckedbedroom  = false;
      this.state.isAllCheckedbathroom = false;
      this.state.isAllsellerTypeChecked = false;
      this.state.propertype = this.state.propertype.map((ele,ind)=>{
        ele.checked = false;
        return ele
      });
      this.state.saletype = this.state.saletype.map((ele,ind)=>{
        if(ele.value!='pocketlisting')
          ele.checked = false;

        return ele
      });
      this.state.condition = this.state.condition.map((ele,ind)=>{
        ele.checked = false;
        return ele
      });
      this.state.bedroom = this.state.bedroom.map((ele,ind)=>{
        ele.checked = false;
        return ele
      });
      this.state.bathroom = this.state.bathroom.map((ele,ind)=>{
        ele.checked = false;
        return ele
      });
      this.state.sellertype = this.state.sellertype.map((ele,ind)=>{
        ele.checked = false;
        return ele
      });
      this.setState({
        isAllChecked1        :  this.state.isAllChecked1,
        isAllChecked         :  this.state.isAllChecked  ,
        isAllCheckedcondition : this.state.isAllCheckedcondition,
        isAllCheckedbedroom  :  this.state.isAllCheckedbedroom,
        isAllCheckedbathroom :  this.state.isAllCheckedbathroom,
        isAllsellerTypeChecked : this.state.isAllsellerTypeChecked,
        propertype           :  this.state.propertype,
        buyerfinance          :this.state.buyerfinance,
        saletype             :  this.state.saletype,
        condition            :  this.state.condition,
        bedroom              :  this.state.bedroom,
        bathroom             :  this.state.bathroom,
        sellertype           :  this.state.sellertype
      })
    }
}
  filterdata(totalobj) {
    let returnobj = JSON.stringify(totalobj);
    returnobj = JSON.parse(returnobj)
    Object.keys(returnobj).map(function (key, value) {
      if (typeof (returnobj[key]) == "object") {
        let temp = []
        let objectstring = '';
        let length = totalobj[key].length
        returnobj[key].map((val, index) => {
          if (val.checked)
            temp.push(val.value)
        })
        if (temp.length == length)
          objectstring = ''
        else
          objectstring = temp.toString()
        returnobj[key] = objectstring;
      }
      else if (typeof (totalobj[key]) == "string") {
        returnobj[key] = returnobj[key]
      } else if (typeof (returnobj[key]) == "boolean") {
        returnobj[key] = returnobj[key]
      }
    });
    return returnobj
  }


  async generatePayload(name) {
    let self = this
    let whole = this.state
    let sortlisteddata = this.filterdata(whole)
    let payload = {
      auctiontype: (sortlisteddata.sellertype) ? sortlisteddata.sellertype : '',
      bathroom: (sortlisteddata.bathroom) ? sortlisteddata.bathroom : '',
      bedroom: (sortlisteddata.bedroom) ? sortlisteddata.bedroom : '',
      location: (sortlisteddata.location) ? sortlisteddata.location : '',
      min_land_sq_footage: (name === 'landfootageslider') ? whole.handleLandSqFootageSlider[0] : '',
      max_land_sq_footage: (name === 'landfootageslider') ? whole.handleLandSqFootageSlider[1] : '',
      max_property_sq_footage: (name === 'sqfootageslider') ? whole.handleSqFootageSlider[1] : '',
      min_property_sq_footage: (name === 'sqfootageslider') ? whole.handleSqFootageSlider[0] : '',
      propertycondition: (sortlisteddata.condition) ? sortlisteddata.condition : '',
      propertype: (sortlisteddata.propertype) ? sortlisteddata.propertype : '',
      saletype:(sortlisteddata.buyerfinance)?sortlisteddata.buyerfinance:'',
      pricerangemin: (name === 'priceslider') ? whole.priceRange[0] : '',
      pricerangemax: (name === 'priceslider') ? whole.priceRange[1] : '',
      // saletype: (sortlisteddata.saletype) ? sortlisteddata.saletype : '',
    }
    
    this.props.oncheckBoxChange(payload);
  }

  onCheckBoxChange(name, check, checkName, isChecked) {
    
    let prestate = this.state[name]
    
    let isAllChecked = (checkName === 'all' && isChecked);
    let isAllUnChecked = (checkName === 'all' && !isChecked);

    prestate = prestate.map((checkBox, index) => { 
      if (checkBox.name === name && checkBox.value === checkName) 
        checkBox.checked = !checkBox.checked;
      if(isAllChecked)  
        checkBox.checked = true;
      if(isAllUnChecked) 
        checkBox.checked = false; 
      return checkBox;
    })
    let isAllSelected = (prestate.findIndex((item) => item.checked === false) === -1) || isAllChecked;
    this.setState({
      [name]: prestate,
      [check]: isAllSelected,
    }, () => {
      this.generatePayload(name)
    });
  } 

  handleChange = name => e => {
    if (name === 'location' && e.target.value === 'All Areas') {
    
      this.setState({
        [name]: "",
      },()=>{
       this.generatePayload(name)
       console.log("------------", this.state.location)
      })
     
    } else {
      this.setState({
        [name]: e.target.value,
      },()=>{
       this.generatePayload(name)
       console.log("------------", this.state.location)
      })
    }
  }

  handlePriceSlider = (event, newValue) => {
    this.setState({
      priceRange: newValue,
    },()=>{
        this.generatePayload('priceslider')
    })
  };

  handleSqFootageSlider = (event, newValue) => {
    this.setState({
        handleSqFootageSlider: newValue,
    },()=>{
        this.generatePayload('sqfootageslider')
    })
  };
  
  handleBetweenPrice = name => e => {
    var prevState = this.state.priceRange
   
    if (name === "from") {
        prevState.shift()
        prevState.unshift(e.target.value)
        // let new_to = old_to.unshift(e.target.value)
        this.setState({
          priceRange : prevState,
        }, ()=> {
          if (prevState[0] && prevState[1])  {
            this.generatePayload('priceslider')
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('landfootageslider')
            console.log("this state check ", this.state)
            }
          
        })
      
    } else if (name === "to") {
      
        prevState.pop()
        prevState.push(e.target.value)
        this.setState({
          priceRange : prevState,
        }, () => {
          if (prevState[0] && prevState[1])  {
          this.generatePayload('priceslider')
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('landfootageslider')
            console.log("this state check ", this.state)
            }
        })
    }

  }

  handleBetweenLot = name => e => {
    var prevState = this.state.handleLandSqFootageSlider
    if (name === "from") {
        prevState.shift()
        prevState.unshift(e.target.value)
        // let new_to = old_to.unshift(e.target.value)
        this.setState({
          handleLandSqFootageSlider : prevState,
        }, ()=> {
          if (prevState[0] && prevState[1])  {
          this.generatePayload('landfootageslider')
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('landfootageslider')
            console.log("this state check ", this.state)
            }
        })
      
    } else if (name === "to") {
      
        prevState.pop()
        prevState.push(e.target.value)
        this.setState({
          handleLandSqFootageSlider : prevState,
        }, () => {
          if (prevState[0] && prevState[1])  {
          this.generatePayload('landfootageslider')
          console.log("this state check ", this.state)
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('landfootageslider')
            console.log("this state check ", this.state)
            }
        })
      

    }

  }


  handleBetweenLiving = name => e => {
    var prevState = this.state.handleSqFootageSlider
    if (name === "from") {
        prevState.shift()
        prevState.unshift(e.target.value)
        // let new_to = old_to.unshift(e.target.value)
        this.setState({
          handleSqFootageSlider : prevState,
        }, ()=> {
          if (prevState[0] && prevState[1])  {
          this.generatePayload('sqfootageslider')
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('sqfootageslider')
          }
        })
      
    } else if (name === "to") {
      
        prevState.pop()
        prevState.push(e.target.value)
        this.setState({
          handleSqFootageSlider : prevState,
        }, () => {
          if (prevState[0] && prevState[1])  {
          this.generatePayload('sqfootageslider')
          console.log("this state check ", this.state)
          }
          if (prevState[0] === "" && prevState[1]=== "")  {
            this.generatePayload('sqfootageslider')
          }
        })
      

    }

  }

  handleLandSqFootageSlider = (event, newValue) => {
    this.setState({
        handleLandSqFootageSlider: newValue,
    },()=>{
        this.generatePayload('landfootageslider')
    })
  };
  onPlaceChanged = (data) => {
    let preState = this.state.location;
    // if (preState.indexOf(data.formatted_address) < 0) {
    //   preState.push(data.formatted_address);
    // }
    console.log(data)
    preState=data
    this.setState({
      loction: preState,
    });
  }
  numToLetters=(labelValue)=>{
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    : Math.abs(Number(labelValue));
    // return a
  }
  render() {
    let { userRole } = this.props;
    let { priceRange, location_arr, handleSqFootageSlider , handleLandSqFootageSlider } = this.state 
    return (
      // onClick={(e) => this.props.value([1,2])}
      <div className="col-md-3">
        <h3 className="filter-tit"><span>FILTER BY</span></h3>
              <label for="dropfilter" class="toggle respond filter">More Filters 
              <span class="glyphicon glyphicon-menu-down pull-right"></span></label>
              <input type="checkbox" id="dropfilter" />
        <div className="category fill">
          <div className="accordion" id="accordion">
            <div className="option">
              <input type="checkbox" id="toggle1" className="toggle" />
              <label className="title" htmlFor="toggle1" checked="checked" >LOCATION
                      </label>
              <div className="content">
                <ul style={{padding: "0 16px"}}>
                  <li>
                    <label className="w-100">
                      <div class="form-group selectdiv buy-sch">
                      {/* <Autocomplete onPlaceChanged={this.onPlaceChanged} placeholder = {' Choose A Search Area'}/> */}
                      <select
                            name = "location"
                            className="form-control site-input" id="propsqfootage"
                            value={this.state.location}
                            onChange={this.handleChange('location')}
                            defaultValue={{ label: "Select Dept", value: '' }}
                            placeholder={"Search by location"}
                            type="text" 
                        >
                        {location_arr.map((x,y) => <option key={y}>{x}</option>)}
                        </select>  
                         {/* <input type="text" className="form-control site-input" id="autocomplete" placeholder="Search by location" onChange={this.handleChange('location')} value={this.state.location} autocomplete="off" /> */}
                           <div class="pan-block"></div>
                      </div>
                      {/* <input type="text" name="location" className="form-control" placeholder="Search by location" onChange={this.handleChange('location')} /> */}
                    </label>
                  </li>
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle2" className="toggle" />
              <label className="title" htmlFor="toggle2" checked="checked" >
                LISTING TYPE
              </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="saletype" check="isAllChecked" options={this.state.saletype} isCheckedAll={this.state.isAllChecked} word="all" onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>

            <div className="option">
              <input type="checkbox" id="toggle_2" className="toggle" />
              <label className="title" htmlFor="toggle_2" checked="checked" >
                SELLER TYPE
              </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="sellertype" check="isAllsellerTypeChecked" options={this.state.sellertype} isCheckedAll={this.state.isAllsellerTypeChecked} word="all" onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>

            <div className="option">
              <input type="checkbox" id="toggle3" className="toggle" />
              <label className="title" htmlFor="toggle3">
                PROPERTY TYPE
                      </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="propertype" check="isAllChecked1" options={this.state.propertype} isCheckedAll={this.state.isAllChecked1} word="Any" onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle10" className="toggle" />
              <label className="title" htmlFor="toggle10">
              PREFERRED BUYER FINANCING 
                      </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="buyerfinance" check="isAllChecked1" options={this.state.buyerfinance} isCheckedAll={this.state.isAllChecked1} word="Any" onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle4" className="toggle" />
              <label className="title" htmlFor="toggle4">
                PRICE RANGE
                      </label>
              <div className="content ranger">
                <div data-role="main" className="price-range ui-content" >
                  <form action="" className="inline">
                    <div className="range-slider " data-role="rangeslider">
                    <div id="sf_price" className="sf_category B-da" aria-labelledby="headingOne" data-parent="#sf-6" style={{"padding-left": '17px',width: '100%'}}>
                                <div className="card-body">
                                    <label className="w-100 price-rang mt-2">
                                        <div className="flex justify-between">
                                          <div className="flex justify-start align-center">
                                            From :  <input 
                                            type="text" 
                                            className="form-control P-input" 
                                            id="amount" 
                                            placeholder="$" 
                                            value={this.state.priceRange[0]}
                                            onChange={this.handleBetweenPrice('from')} 
                                            readonly />
                                          </div>
                                          <div className="flex justify-end align-center">
                                            To : <input 
                                            type="text" 
                                            className="form-control P-input" 
                                            id="amount1" 
                                            placeholder="$" 
                                            value={this.state.priceRange[1]} 
                                            onChange={this.handleBetweenPrice('to')}
                                            readonly />
                                          </div>
                                    </div>
                            </label>
                        </div>
                    </div>
                    {/*  <span className="range">{this.numToLetters(priceRange[0])}</span>
                      <Slider
                        name="priceRange"
                        value={this.state.priceRange}
                        onChange={this.handlePriceSlider}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={100}
                        max={10000000}
                        step={100}
                      />
                      <span className="range">{this.numToLetters(priceRange[1])}</span> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="option fill">
              <input type="checkbox" id="toggle5" className="toggle" />
              <label className="title" htmlFor="toggle5">
                CONDITION
              </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="condition" check="isAllCheckedcondition" options={this.state.condition} word="" isCheckedAll={this.state.isAllCheckedcondition} onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle6" className="toggle" />
              <label className="title" htmlFor="toggle6">
                BEDROOMS
                      </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="bedroom" check="isAllCheckedbedroom" options={this.state.bedroom} word="Any" isCheckedAll={this.state.isAllCheckedbedroom} onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle7" className="toggle" />
              <label className="title" htmlFor="toggle7">
                BATHROOMS
                      </label>
              <div className="content">
                <ul>
                  <CheckBoxList name="bathroom" check="isAllCheckedbathroom" options={this.state.bathroom} word="Any" isCheckedAll={this.state.isAllCheckedbathroom} onCheck={this.onCheckBoxChange.bind(this)} />
                </ul>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle8" className="toggle" />
              <label className="title" htmlFor="toggle8">
                LIVING AREA 
                      </label>
              <div className="content ranger">
                <div data-role="main" className="price-range ui-content" >
                  <form action="" className="inline">
                    <div className="range-slider " data-role="rangeslider">
                    <div id="sf_price" className="sf_category B-da" aria-labelledby="headingOne" data-parent="#sf-6" style={{"padding-left": '17px',width: '100%'}}>
                                <div className="card-body">
                                    <label className="w-100 price-rang mt-2">
                                        <div className="flex justify-between">
                                          <div className="flex justify-start align-center">
                                            From:  <input 
                                            type="text" 
                                            className="form-control P-input" 
                                            id="amount"  
                                            placeholder="" 
                                            value={this.state.handleSqFootageSlider[0]}
                                            onChange={this.handleBetweenLiving('from')} 
                                            readonly />
                                          </div>
                                          <div className="flex justify-end align-center">
                                            To : <input type="text" 
                                            className="form-control P-input" 
                                            id="amount1" 
                                            placeholder="" 
                                            value={this.state.handleSqFootageSlider[1]}
                                            onChange={this.handleBetweenLiving('to')} 
                                            readonly />
                                          </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                      {/* <span className="range">{this.numToLetters(handleSqFootageSlider[0])}</span>
                      <Slider
                        name="handleSqFootageSlider"
                        value={this.state.handleSqFootageSlider}
                        onChange={this.handleSqFootageSlider}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={100}
                        max={50000}
                        step={100}
                      />
                      <span className="range">{this.numToLetters(handleSqFootageSlider[1])}</span> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="option">
              <input type="checkbox" id="toggle9" className="toggle" />
              <label className="title" htmlFor="toggle9">
                LOT SIZE
              </label>
              <div className="content ranger">
                <div data-role="main" className="price-range ui-content" >
                  <form action="" className="inline">
                    <div className="range-slider " data-role="rangeslider">
                    <div id="sf_price" className="sf_category B-da" aria-labelledby="headingOne" data-parent="#sf-6" style={{"padding-left": '17px',width: '100%'}}>
                                <div className="card-body">
                                    <label className="w-100 price-rang mt-2">
                                        <div className="flex justify-between">
                                          <div className="flex justify-start align-center">
                                            From :  <input
                                             type="text" 
                                             className="form-control P-input" 
                                             id="amount" 
                                             placeholder="" 
                                             value={this.state.handleLandSqFootageSlider[0]}
                                             onChange={this.handleBetweenLot('from')} 
                                             readonly />
                                             
                                          </div>
                                          <div className="flex justify-end align-center">
                                            To : <input 
                                            type="text" 
                                            className="form-control P-input" 
                                            id="amount1" 
                                            placeholder=""
                                            value={this.state.handleLandSqFootageSlider[1]}
                                            onChange={this.handleBetweenLot('to')}  
                                            readonly />
                                          </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                      {/* <span className="range">{this.numToLetters(handleLandSqFootageSlider[0])}</span>
                      <Slider
                        name="handleLandSqFootageSlider"
                        value={this.state.handleLandSqFootageSlider}
                        onChange={this.handleLandSqFootageSlider}
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                        min={100}
                        max={50000}
                        step={100}
                      /> */}
                      
                {/* <span className="range">{this.numToLetters(handleLandSqFootageSlider[1])}</span> */}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const { user, loggedIn  } = state.login;
  const { pocketlisting_buyer_search  } = state.header;
  return { user, loggedIn , pocketlisting_buyer_search};
}

export default connect(mapStateToProps)(Filter);