import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';
import './Paypal.css';

import axios from 'axios';
import { NOTIFICATION } from '../../actions/index';

class Paypal extends Component {
    constructor() {
        super();
        this.state = { showButton: false, error: '', plan_id: '' };
        window.React = React;
        window.ReactDOM = ReactDOM;
    }

    getPlanID = () => {

        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {
            "method": "paypal"
        }
       
        axios.post(url + 'mobileapi/getplanid', payload)
            .then(function (response) {
                if (response.data.success === true) {
                    console.log(response.data.data)
                    self.setState({ plan_id: response.data.data.id })
                } else {
                    self.setState({ error: "Try again!" })
                }
            })
            .catch(function (error) {
                self.setState({ error: "Try again!" })
            });
    }

    handleClose = () => {
        this.props.dispatch(NOTIFICATION({ msg: "Successfully subscribed." }))
        this.props.StripeBoxCloseHandle()
    }

    componentDidMount = async () => {
        const {
            isScriptLoaded,
            isScriptLoadSucceed
        } = this.props;
        await this.getPlanID();
        if (isScriptLoaded && isScriptLoadSucceed) {
            this.setState({ showButton: true });
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            isScriptLoaded,
            isScriptLoadSucceed,
        } = nextProps;

        const isLoadedButWasntLoadedBefore =
            !this.state.showButton &&
            !this.props.isScriptLoaded &&
            isScriptLoaded;

        if (isLoadedButWasntLoadedBefore) {
            if (isScriptLoadSucceed) {
                this.setState({ showButton: true });
            }
        }
    }

    onSuccess = (subscribed) => {
        let payload = {}, self = this;
        payload['subscriptionID'] = subscribed['subscriptionID'];
        payload['orderID'] = subscribed['orderID'];
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.defaults.headers.common['Authorization'] = self.props.user.data;
        axios.post(`${url}mobileapi/updatepaypalSubscription`, payload).then(response => {

            if (response.data.success === 'yes') {
                self.props.dispatch(NOTIFICATION({ msg: 'Subscribed Successfully' }));
                self.props.onChangeHandle();
                // self.props.StripeBoxCloseHandle();
            }
            else
                self.props.dispatch(NOTIFICATION({ msg: 'err' }));
        })

    }

    onError = (error) => {
        this.props.StripeBoxCloseHandle()
    }

    onCancel = (data) => {
        this.props.StripeBoxCloseHandle()
    }

    render() {

        // const env = process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';
        const env = 'sandbox';
        const client = {
            sandbox: 'AXA5n138SEhGmFVHffE-8E1jVx0ZMHURemoMGYlzCFwMCcCZEPpQa800X8U-cNyYL4u1cEYjGdduTsej',
            production: 'xxxXXX',
        };
        // const paypal = window.paypal;
        const { showButton } = this.state;
        const { isScriptLoaded, isScriptLoadSucceed } = this.props;

        const style = {
            layout: 'horizontal',
            size: 'large',
            shape: 'rect',
            color: 'gold',
            margin: 5,
            tagline: true,
            fundingicons: false,
            label: "pay"
        };

        const createSubscription = (data, actions) => {
            console.log(data)
            return actions.subscription.create({ 'plan_id': this.state.plan_id })
        }
        
        
            // console.log(paypal)
        let PayPalButton = showButton && isScriptLoaded && isScriptLoadSucceed && window.paypal.Buttons.driver('react', { React, ReactDOM });

        return (
            <div>
                {showButton && isScriptLoaded && isScriptLoadSucceed &&
                    <PayPalButton
                        env={env}
                        client={client}
                        style={style}
                        createSubscription={createSubscription}
                        onApprove={this.onSuccess}
                        onCancel={this.onCancel}
                        onError={this.onError}
                    />}
            </div>

        )
    }
}

function mapStateToProps(state) {
    const { user, loggedIn } = state.login;
    return { user, loggedIn };
}

export default connect(mapStateToProps)(scriptLoader('https://www.paypal.com/sdk/js?client-id=AXA5n138SEhGmFVHffE-8E1jVx0ZMHURemoMGYlzCFwMCcCZEPpQa800X8U-cNyYL4u1cEYjGdduTsej&vault=true')(Paypal));
