import React, {Component} from 'react';
import './Proposal.css';

import axios from 'axios';
import { NOTIFICATION } from  '../../actions/index';

class Proposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      offer_amount: '',
      financing: '',
      formError: {},
      msg: '',
      error:'',
    }
  }

  validator = () => {
    console.log("validatoe")
    console.log(this.props)
    this.state.formError={};
    if (!isFinite(String(this.state.offer_amount)))
      this.state.formError["offer_amount"] = "Number's only allowed"
    if (this.state.offer_amount <= 0)
    {
      if(this.props.projectData.auctiontype!=='listing'){
        this.state.formError["offer_amount"] = "Offer amount is must be greater than zero.";
      }
    }
    
    if (this.state.financing == "")
      this.state.formError["financing"] = "Choose Financing Conventional Loan.";
    if (this.state.description == "")
      this.state.formError["description"] = "Description required.";
    this.setState({
        formError: this.state.formError,
    })
  }

  formSubmit = e => {
    e.preventDefault();
    
    if(this.props.customerid === ''){
      this.props.payModel();
      return false;
    }
    this.validator();
    let self = this;
    if (Object.keys(self.state.formError).length === 0) {
      let user = this.props.user;
      let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
      let payload = {
        "description": self.state.description,
        "offer_amount": self.state.offer_amount,
        "financing": self.state.financing,
        "buyer_id": user.id,
        "project_id": this.props.projectData.id
      }
      axios.defaults.headers.common['Authorization'] = this.props.user_token.data;
      axios.post(url + 'mobileapi/mobileproposal/', payload)
        .then(function (response) {
          if (response && response.data) {
            self.props.dispatch(NOTIFICATION({msg:response.data.data}))
            self.setState({
              // msg: response.data.data,
              description: '',
              financing: '',
              offer_amount:'',
              error: response.data.success
            })
          }
        })
        .catch(function (error) {
          self.setState({
            error: "Try later"
          })
        });
    }else{
      this.validator();
    }
  }

  handleChange = name => event => {       
    this.setState({
        [name]: event.target.value,
    });
  };

  render(){
    const{projectData} = this.props;
    return (
      <div className="container">
        <div className="site-form">
          <form className="site-form" onSubmit={this.formSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="text-light" style={{ fontSize: "22px"}}>
                    {projectData.auctiontype ==='listing' ? <b>Additional Info</b> : <b>SUBMIT YOUR PROPOSAL</b>}
                  </label>
                  <textarea className="form-control karla site-input site-textarea" 
                    cols="30" 
                    rows="6" 
                    placeholder="Add any details you would like the Seller to know."
                    name="description"
                    value={this.state.description} 
                    onChange={this.handleChange('description')}
                  >
                  </textarea>
                </div>
                <span style={{color: "red"}}>{this.state.formError["description"]}</span>
              </div>
            </div>
            {projectData.auctiontype!='listing'? 
            <>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Offer Amount</label>
                  <input type="number" 
                    className="form-control site-input" 
                    placeholder="Offer Amount" 
                    value={this.state.offer_amount} 
                    onChange={this.handleChange('offer_amount')}
                  />
                </div>
                <span style={{color: "red"}}>{this.state.formError["offer_amount"]}</span>
              </div>
              <div className="col-md-6">
                <div className="form-group selectdiv">
                  <label htmlFor="exampleInputEmail1">Type of Financing Conventional Loan</label>
                  <select className="form-control site-input" 
                    name="financing"
                    value={this.state.financing} 
                    onChange={this.handleChange('financing')}
                  >
                    <option value="">Choose Financing Conventional Loan</option>
                    <option value="FHA Loan">FHA Loan</option>
                    <option value="VA Loan">VA Loan</option>
                    <option value="Housing Assistance Program">Housing Assistance Program</option>
                    <option value="All Cash">All Cash</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <span style={{color: "red"}}>{this.state.formError["financing"]}</span>
              </div>
            </div>
            </>: 
            <div className="row">
            <div className="col-md-6">
                <div className="form-group selectdiv">
                  <label htmlFor="exampleInputEmail1">Type of Financing Conventional Loan</label>
                  <select className="form-control site-input" 
                    name="financing"
                    value={this.state.financing} 
                    onChange={this.handleChange('financing')}
                  >
                    <option value="">Choose Financing Conventional Loan</option>
                    <option value="FHA Loan">FHA Loan</option>
                    <option value="VA Loan">VA Loan</option>
                    <option value="Housing Assistance Program">Housing Assistance Program</option>
                    <option value="All Cash">All Cash</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <span style={{color: "red"}}>{this.state.formError["financing"]}</span>
              </div>
            </div>}
            <div className="text-center">
              <span style={this.state.error === "yes" ? {color: "green"} : {color: "red"}}>{this.state.msg}</span>
            </div>
            <div className="text-left pad-tb-30">
              <button type="submit" className="btn btn-primary fw-600" >{projectData.auctiontype!='listing'?'Submit Offer':'Interested'}</button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Proposal;