import React, {Component} from 'react';
import './Popup.css';

class videoPopup extends Component {

    state = {
        active: '',
        error:''
    }

    closeHandle = () => {
        this.props.videoPopupHandle(1);
    }

    render() {
        return (
            <div className="modal fade mod-place-bid in modal-open d_block" id="videoPopup" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog popup-dialog" role="document">
                    <div className="modal-content show-model bid-hiy">
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeHandle}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div className="modal-body video-body">
                            <video controls>
                                <source  src="./videos/1.mp4" type="video/mp4"/>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default videoPopup;