import ReactDOM from "react-dom";
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Cards.css'

import { NOTIFICATION } from '../../actions/index';
import axios from "axios"
import { Link } from 'react-router-dom'
import Popup from '../../components/Popup/Popup';




class OffersCard extends Component {
    state = {
        counterModel:false,
        counterId: '',
        offeredPrice: ''
    }
    constructor(props) {
        super(props)

    }

    componentDidMount() {
       
    }   

    counterHandler = (data) => {
        
        let param = {
            isCounter: 0,
            id: data.id,
            counterStatus: data.status === 2 ? 'accept' : 'reject',
        }

        let self = this;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        axios.post(url + 'mobileapi/updateproposalcounter/', param)
            .then(async (response) => {
                if (response && response.data) {
                    this.setState({
                        counterModel :  false
                    });
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later"
                })
            });
    }

    popupHandle = (data,price,dataObj) => {
        if(data == "2"){
            this.counterHandler({price,id:dataObj.id,status : 2})
        }else if(data == "1"){
            this.counterHandler({price,id:dataObj.id, status : 1})
        }
        else{
            let bprice = (data.target && data.target.name) ? data.target.name : 0;
            this.setState({
                counterModel :  !this.state.counterModel,
                counterId : (data.target && data.target.id) ? data.target.id : '',
                popupDescription: "Are you intrested in $ "+bprice+" pricing offered by the seller ?"
            });
        }
    }

    render() {
        const {productDetails} = this.props;
        let url1 = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
        return (
            
            <div class="tab-content">
                <div id="home" class="tab-pane fade in active">
                    {productDetails.length>0 ? productDetails.map((product, productIndex) => {
                        return <div class="bids-data" key={"product_key_"+productIndex}>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="col-md-3 pad-left-0 pro-card-hgt buyer-img">
                                        <figure>
                                            <img src={`${url1}uploads/product/${product.avatar}`} alt="my-bids-img"
                                                class="img-responsive" />
                                        </figure>
                                    </div>
                                    <div class="col-md-9">
                                        <div class="bids-details">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="flex justify-between bids-tit">
                                                        {/* <!-- <span class="">Property Name</span> --> */}
                                                        {product.awarded == 1 ? <span class="">Status : <b
                                                            class="text-green fw-400"> Accepted </b></span> : product.awarded == 0 && product.market_status=="sold" ? <span class="">Status : <b
                                                                class="text-red fw-400"> Rejected </b></span> : <span class="">Status : <b
                                                                    class="text-red fw-400"> Pending </b></span>}

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 span-block">
                                                    <span class="">Property Type : <b class="fw-400">{product.propertype}</b></span>
                                                    <div class="flex twin-bath">
                                                        <span>Beds : <b>{product.bedroom}</b></span>
                                                        <span>Baths : <b>{product.bathroom}</b></span>
                                                    </div>
                                                    <span class="">Location : <b class="fw-400">{product.location}</b></span>
                                                </div>
                                                {(product.awarded === 1 && product.market_status === "sold") ? <div class="col-md-4">
                                                    <div class="flex align-end justify-center ">
                                                        <div class="award-img text-center">
                                                            <figure>
                                                                <img src="/images/icons/premium-badge.svg" alt="badge" class="img-responsive" />
                                                            </figure>

                                                        </div>
                                                    </div>
                                                </div> : ""}
                                                {product.counter_status === "pending" &&<div class="award-img alxou mora-split text-center pad-lft-20">
                                                        <button className="btn btn-secondary"  id={product.proposalid} name={product.seller_counter_price} onClick={this.popupHandle}>View Counter</button>
                                                </div>}
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6 alxou-line">
                                                    <span class="flex"><b class="karla">
                                                        Offered Price : ${product.offeredprice}</b></span>
                                                    <span></span>
                                                </div>
                                                <div class="col-md-6 award-img alxou mora-split text-center pad-lft-20">
                                                    <Link to={"/product/view/" + product.pid + "/" + product.auctionid}>
                                                        <button className="btn btn-secondary">View Details </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <div className="flex justify-center center">
                    <img style={{height:'150px',width:'auto',objectFit:'cover'}} src="/images/no-data.png"></img>
                </div>}
                </div>
                {this.state.counterModel && <Popup color="green"
                    title="Confirmation"
                    description = {this.state.popupDescription}
                    testBox={false}
                    yesTo="Cancel" // btn 1
                    noTo="Accept" // btn 2
                    btnCount={2} // 1 or 2
                    popupHandle={this.popupHandle}
                    extradata={{id:this.state.counterId}}
                /> }
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}


export default connect(mapStateToProps)(OffersCard);