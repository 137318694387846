import React, {Component} from 'react';
import './PushNotification.css';
import { Link } from 'react-router-dom';

class PushNotification extends Component {
    snackBarClose = () => {
        this.props.snackBarClose()
    } 
    render(){
        let {msgColor,msgLink,msg} = this.props;
        return (
            <div>{msgLink==="/dashboard/buyerprofile#preapprove"?
            <div id="snackbar" style={msgColor ? {backgroundColor:msgColor} : {}}>
                <a data-toggle="tab" href="/dashboard/buyerprofile#preapprove" aria-expanded="true">{msg}</a>
                <i onClick={this.snackBarClose} class="fa fa-times snackbarClose "></i>
            </div>
            :
            <div id="snackbar" style={msgColor ? {backgroundColor:msgColor} : {}}>
                { msgLink ?  <Link to={msgLink}> {msg} </Link> : msg }
                <i onClick={this.snackBarClose} class="fa fa-times snackbarClose "></i>
            </div>
            }</div>

        )
    }
}

export default PushNotification;