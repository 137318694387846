import React, {Component} from 'react';
import './Deed.css';
class Deed extends Component {
    state = {
        purchasePrice: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        numberOfMonth: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        emi:{
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertyValue:{
            data: "",
            required: true,
            errorMsg: "Required"
        },
        isAgreed: true,
        formError: {},
        selectedFile: null,
        customerid: ''
    }

    componentDidMount = () => { 
        document.body.style.overflow = "hidden";  
        document.body.style.paddingRight = "15px";
    }

    closeHandle = () =>{
        this.props.closeHandle();
    }

    handleValidation = () =>{
        let self = this;
        Object.keys(this.state).map(function(key) { 
            
            if(self.state[key] && self.state[key].required && self.state[key].data === "" ){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required";
            }
            
        });
        if(!this.state.selectedFile){
            self.state.formError["selectedFile"] = "Required";
        }

        self.setState({
            formError: self.state.formError
        });
    }

    formSubmit = (e) => {
        e.preventDefault();
        this.handleValidation();
        if ((Object.keys(this.state.formError)).length > 0 ){
            return false;
        }
        delete this.state.isAgreed;
        delete this.state.formError;
        this.props.deedSubmitHandle(this.state);
    }


    handleChange = name => event => {
        let preState = this.state[name];
        if(this.state.formError[name]){ delete this.state.formError[name] }
        preState.data = event.target.value;
        this.setState({
            [name]: preState,
            formError: this.state.formError,
        });
    };

    handleCheckBox = name => event => {       
        this.setState({
            [name]: !this.state.isAgreed,
        },()=>{
            this.state.formError["isAgreed"] = this.state.isAgreed ? '' : "Required";
        });
    };

    handleselectedFile = event => {
        delete this.state.formError["selectedFile"];
        this.setState({
            selectedFile: event.target.files[0],
            formError: this.state.formError
        })
    }

    render() {
        return (
            <div className="modal fade mod-place-bid in modal-open d_block" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog" role="document">
                    <div className="modal-content show-model bid-hiy">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeHandle}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h3 className="text-center karla fw-600"><span>POST A DEED/CONTRACT</span></h3>
                        </div>
                        <div className="modal-body ">
                            <div className="row">
                                <div className="reff text-left">
                                    <div className="check flex justify-center">
                                        <form className="site-form" style={{ width: "55%" }}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="exampleInputEmail1">Original Purchase Price</label>
                                                        <input type="number" 
                                                            className="form-control site-input" 
                                                            id="purchasePrice" 
                                                            placeholder="Enter the original Purchase Price" 
                                                            name="purchasePrice"
                                                            value={this.state.purchasePrice.data}
                                                            onChange={this.handleChange('purchasePrice')}
                                                        />
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["purchasePrice"]}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="exampleInputEmail1">Number of Months</label>
                                                        <input type="number" 
                                                            className="form-control site-input" 
                                                            id="numberOfMonth" 
                                                            placeholder="Enter the number of months" 
                                                            name="numberOfMonth"
                                                            value={this.state.numberOfMonth.data}
                                                            onChange={this.handleChange('numberOfMonth')}
                                                        />
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["numberOfMonth"]}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="exampleInputEmail1">Monthly payment</label>
                                                        <input type="number" 
                                                            className="form-control site-input" 
                                                            id="emi" 
                                                            placeholder="Select a monthly payment" 
                                                            name="emi"
                                                            value={this.state.emi.data}
                                                            onChange={this.handleChange('emi')}
                                                        />
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["emi"]}</span>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label for="exampleInputEmail1">Estimated Property Value</label>
                                                        <input type="number" 
                                                            className="form-control site-input" 
                                                            id="propertyValue" 
                                                            placeholder="What is the estimated value of the Property"
                                                            name="propertyValue"
                                                            value={this.state.propertyValue.data}
                                                            onChange={this.handleChange('propertyValue')}
                                                        />
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["propertyValue"]}</span>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="seller-model">
                                        <div className="flex justify-between">
                                            <h4>Upload your Deed/Contract to continue</h4>
                                            <div className="upload-btn-wrapper ">
                                                <button className="btn btn-primary font-15 karla">
                                                    Upload
                                                <img src="/images/file-upload.svg" className="img-responsive" alt="" />
                                                </button>
                                                <input type="file" name="myfile" accept="application/pdf"  onChange={this.handleselectedFile} />
                                            </div>
                                        </div>
                                        <div>
                                            {this.state.selectedFile&& <p style={{color:"green"}}>{this.state.selectedFile.name}</p>}
                                            <span style={{ color: "red" }}>{this.state.formError["selectedFile"]}</span>
                                        </div>
                                        <div className="checkbox flex justify-left pad-tb-20 pad-0 ">
                                            <label className="show-check">&nbsp;&nbsp;I agree that I will handle my own transactions. Showpads is not responsible and offer no advice or assistance.
                                            <input className="check-height" 
                                                type="checkbox" checked={this.state.isAgreed} 
                                                onChange={this.handleCheckBox('isAgreed')} 
                                            />
                                            <span className="checkmark"></span>
                                            </label>
                                        </div>
                                        <div className="col-12"><span style={{ color: "red" }}>{this.state.formError["isAgreed"]}</span></div>
            
                                        <div className="text-center">
                                            <button className="btn btn-secondary pad-10" onClick={this.formSubmit} >Proceed to payment</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Deed;