//let user = JSON.parse(localStorage.getItem('user'));
const initialState = {};

export default function header(state = initialState, action) {
  switch (action.type) {
    case 'HEADER_POCKETLISTING' :
        return action.payload
    case 'NOTIFICATION' :
      return action.payload
    case 'PATHNAMEUPDATE' :
      return action.payload  
    case 'SEARCHPAYLOAD' : 
      return action.payload
    default:
      return state
  }
}