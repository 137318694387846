import React, {Component} from 'react';
import {connect} from "react-redux";
import axios from 'axios';
import {NOTIFICATION} from '../../../actions/index';
import us_states from '../../../util/us_states';
import './Buyerprofile.css';

import Stripe from '../../../components/Payment/Stripe';
import {PATHNAMEUPDATE} from "../../../actions/index"
import {properResult} from '../../../util/capsAndSpaces';
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
class Buyerprofile extends Component {
      state = {
          tabname : "profile",
          profileTabActive : "active",
          preferencesTabActive : "",
          userDetails : {
              first_name : {
                  data : "",
                  required : true,
                  errmsg : "Required"
              },
              last_name : {
                  data : "",
                  required : true,
                  errmsg : "Required"
              },
              aboutme : {
                  data : "",
                  required : true,
                  errmsg : "Required"
              },
              phone : {
                  data : "",
                  required : true,
                  errmsg : "Required"
              },
              username : {
                  data : "",
                  required : true,
                  errmsg : "Required"
              },
              email : {
                data : "",
                required : false,
                errmsg : "Required"
            }
          },
          changePassword : 
          {
            currentpassword : {
                                data : "",
                                required : true,
                                errmsg : "Required"
                              },
            newpassword : {
                            data : "",
                            required : true,
                            errmsg : "Required"
                          },
            confirmnewpassword  : {
                                    data : "",
                                    required : true,
                                    errmsg : "Required"
                                  }
          },
          preferences :
          {
            saletype  : {
                          data : "",
                          required : true,
                          errmsg : "Required"
                        },
            sellertype  : {
                            data : "",
                            required : true,
                            errmsg : "Required"
                          },
            propertycondition :  {
                                  data : "",
                                  required : true,
                                  errmsg : "Required"
                                },
            pricerangemax : {
                              data : "",
                              required : true,
                              errmsg : "Required"
                            },
            propertype  : {
                            data :[],
                            required : true,
                            errmsg : "Required"
                          },
            location  : {
                          data :[],
                          required : true,
                          errmsg : "Required"
                        },
            bedroom : {
                        data : "",
                        required : true,
                        errmsg : "Required"
                      },
            bathroom  : {
                          data : "",
                          required : true,
                          errmsg : "Required"
                        },
            min_property_sq_footage : {
                                        data : "",
                                        required : true,
                                        errmsg : "Required"
                                      },
          min_land_sq_footage : {
                                  data : "",
                                  required : true,
                                  errmsg : "Required"
                                },
          propertyzone  : {
                            data : "",
                            required : true,
                            errmsg : "Required"
                          },
          purchasetimeline  : {
                                data : "",
                                required : true,
                                errmsg : "Required"
                              }, 
          letter_obtained :  {
                                data : "",
                                required : true,
                                errmsg : "Required"
                              },
          selectedFile: null, 
          },
          location_arr : [
            "All Areas",
            "LA - Downtown", 
            "LA - South LA", 
            "LA - Mid City, Beverly Hills, Culver City",
            "LA - Hollywood, East Hollwood, WeHo",
            "LA - Westside, Santa Monica, Beaches", 
            "LA - North, Glendale, Highland Park, Pasadena, etc", 
            "LA - Eastern Cities",
            "SF East - NoHo, Burbank, Sunland, etc",
            "SF South- Universal City, Studio City, Van Nuys, Encino, Sherman Oaks etc.", 
            "SF Central - Van Nuys, Sepulveda, Reseda, etc",
            "SF North - North Hills, Granada Hills, Northridge, etc.", 
            "SF West - Woodland Hills, Tarzana, Chatsworth, Calabasas, etc.",
            "SB- Inglewood, El Segundo, Hawthorn",
            "SB - West, Beach Cities",
            "SB - Central, Carson, Torrance, etc.",
            "SB - South, Palos Verdes, Rolling Hills, San Pedro, etc",
            "LONG BEACH AREA",  
            "ORANGE COUNTY",
            "RIVERSIDE COUNTY",
            "CANYON COUNTRY AREA",
            "SANTA CLARITA/VALENCIA AREA",
            "PALMDALE AREA",
            "LANCASTER AREA"
        ],
          formError : {},
          preapprove : 1,
          cardDetail:{},
          cardName : "---- ---- ---- ----",
          payModel: false,
      }
      tabChange=(tabtype)=>e=>{
          // this.state.profileTabActive  = (tabtype==="profile") ? 'active' : ""
          // this.state.preferencesTabActive  = (tabtype==="preferences") ? 'active' : ""
          this.setState({
              tabname : tabtype,
              profileTabActive : (tabtype==="profile") ? 'active' : "",
              preferencesTabActive : (tabtype==="preferences") ? 'active' : ""
          });
      }
      getusersdata=()=>{
          axios.defaults.headers.common['Authorization'] = this.props.user.data;
          let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
          let self = this
          
          axios.get(url+'mobileapi/profile/').then(function(response){
              if(response.data.success === 'yes'){
                let altereddata  = properResult([response.data.data])
                response.data.data = (altereddata) ? altereddata[0] : [];
                  Object.keys(response.data.data).map((resdata,resindex)=>{
                    if (resdata   === "first_name"
                      || resdata  === "last_name"
                      || resdata  === "aboutme"
                      || resdata  === "username"
                      || resdata  === "phone"
                      || resdata  === "email") {
                          self.state.userDetails[resdata]=  {
                              data : response.data.data[resdata],
                              required : true,
                              errmsg : "Required"
                          } 
                      }else{
                        if(self.state.preferences[resdata]){
                          switch(resdata){
                            case 'location':
                                self.state.preferences[resdata].data = response.data.data[resdata].split(',')
                            break;
                            case 'propertype':
                                self.state.preferences[resdata].data = response.data.data[resdata].split(',')
                            break;
                            case 'saletype':
                            case 'sellertype':
                            case 'purchasetimeline':
                            case 'propertycondition':
                                response.data.data[resdata] = response.data.data[resdata].charAt(0).toUpperCase() + response.data.data[resdata].slice(1);
                                response.data.data[resdata] = response.data.data[resdata].split('_').map((ele,ind)=>{
                                  return ele.charAt(0).toUpperCase() + ele.slice(1);
                                }).join(" ")
                                self.state.preferences[resdata].data = response.data.data[resdata]
                            break;
                            default:
                              self.state.preferences[resdata].data = response.data.data[resdata]
                            break;

                          }
                        }else{
                          switch(resdata){
                            case "taxfile":
                                self.state.preferences['letter_obtained'].data = (response.data.data[resdata] != "" ) ? 'y' : 'n'
                                if(self.state.preferences['letter_obtained'].data === "y"){
                                  self.state.preferences['selectedFile']  = {};
                                  self.state.preferences['selectedFile'].data = response.data.data[resdata] 
                                }
                            break;
                            default:
                            break;
                          }
                        }
                      }
                      return resdata;
                  });
              if(Object.keys(response.data.cardDetail).length > 0){
                self.state['cardDetail'] = response.data.cardDetail
                self.state['cardName'] = response.data.cardDetail.sources.data[0].last4
              }
                  self.setState({
                  userDetails : self.state.userDetails,
                  preapprove : (response.data.preapprove)?response.data.preapprove[0].preqalified:"0",
                  cardDetail : self.state.cardDetail,
                  cardName : self.state.cardName,
                  preferences : self.state.preferences
                })
              }   
              
          })
      }
      handleChange=(name ,formname)=>event=>{
        if(this.state.formError[name]){delete this.state.formError[name]}
          switch(formname){
            case 'userdetail':
                var prestate        =   this.state.userDetails[name];
                prestate.data   =   event.target.value;
                this.setState({
                    [name] : prestate,
                    formError   :   this.state.formError
                });    
            break;
            case 'changepassword':
                var prestate        =   this.state.changePassword[name];
                prestate.data   =   event.target.value;
                this.setState({
                    [name] : prestate,
                    formError   :   this.state.formError
                });    
            break;
            case 'preferences':
                if(name==="letter_obtained" && event.target.value === "n"){
                  this.state.preferences['selectedFile'] = null;
                }
                var prestate        =   this.state.preferences[name];
                //prestate.data       =   event.target.value;
                switch(name){
                      case 'propertype' :
                          delete this.state.formError['location']
                      case 'location'   :
                          delete this.state.formError['propertype']
                          let limit = (name==='location') ? 3 : 2
                          if(this.state.formError[name])
                              delete this.state.formError[name]
                          if(prestate.data.length<=limit){
                              if(prestate.data.indexOf(event.target.value)<0)
                              {
                                  this.state.selectedOptionClass = "selectedOption"
                                  prestate.data.push(event.target.value)
                              }
                          }
                          else
                              this.state.formError[name] = (limit === 3) ? "You Can only choose up to four types" : "You Can only choose up to three types" 
                      break;
                      default :
                          prestate.data = event.target.value;
                          this.state.selectedOptionClass = ""
                          delete this.state.formError['propertype']
                          delete this.state.formError['location']
                      break;
                  }
                this.setState({
                    [name] : prestate,
                    formError   :   this.state.formError
                },()=>{console.log(this.state)});

            break;
          };
          
          
      }
      mapAndError=(data)=>{
        Object.keys(data).map((mandatoryEle,mandatoryIndex)=>{
          if( data[mandatoryEle]  && data[mandatoryEle].data === "" &&
              data[mandatoryEle].required === true
            ){
                this.state.formError[mandatoryEle] = data[mandatoryEle].errmsg;
            }
      });
      }
      handleValidation=(formName)=>{
          switch(formName){
              case 'userdetail':
                  this.mapAndError(this.state.userDetails);
                
              break;
              case 'changepassword':
                  this.mapAndError(this.state.changePassword);
              break;
              case 'preferences':
                  this.mapAndError(this.state.preferences);
              break;
          }
          this.setState({
                          formError : this.state.formError
                        },()=>{console.log(this.state.formError)})

      }
      userDetailSubmit=()=>e=>{
          e.preventDefault();
          this.handleValidation('userdetail');
          var self    =   this;
          if(Object.keys(this.state.formError).length == 0){
              axios.defaults.headers.common['Authorization'] = this.props.user.data;
              let url = process.env.REACT_APP_BASE_URL ? 
                          process.env.REACT_APP_BASE_URL 
                          : 
                          "http://showpads.auctionsoftware.com/";

              var data = {};
              Object.keys(this.state.userDetails).map((userdetailEle,userdetailIndex)=>{
                  data[userdetailEle] = this.state.userDetails[userdetailEle].data
              });
              axios.post(url+'mobileapi/dashboardProfileUpdate/buyer', data)
                  .then(function (response) {
                      self.props.dispatch(NOTIFICATION({msg:"Update Successful"}))
                  });
          }
      }
      changePasswordSubmit=()=>e=>{
        e.preventDefault();
        this.handleValidation('changepassword');
        var self    =   this;
        if(Object.keys(this.state.formError).length == 0){
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            let url = process.env.REACT_APP_BASE_URL ? 
                        process.env.REACT_APP_BASE_URL 
                        : 
                        "http://showpads.auctionsoftware.com/";

            var data = {};
            Object.keys(this.state.changePassword).map((userdetailEle,userdetailIndex)=>{
                data[userdetailEle] = this.state.changePassword[userdetailEle].data
            });
            axios.post(url+'mobileapi/change_password', data)
                .then(function (response) {
                  console.log(response)
                  if(response.data.success=="true"){
                    self.props.dispatch(NOTIFICATION({msg:"Password Changed Successfuly"}))
                  }else{
                    self.props.dispatch(NOTIFICATION({msg:response.data.status_text}))
                  }
                });
        }
      }
      preferencesSubmit=e=>{
        e.preventDefault();
        this.handleValidation('preferences');
        var self    =   this;
        const data = new FormData();
        if(Object.keys(this.state.formError).length == 0){
          axios.defaults.headers.common['Authorization'] = this.props.user.data;
          let url = process.env.REACT_APP_BASE_URL ? 
                      process.env.REACT_APP_BASE_URL 
                      : 
                      "http://showpads.auctionsoftware.com/";
          Object.keys(this.state.preferences).map(function(userdetailEle,userdetailIndex) { 
            return (self.state.preferences[userdetailEle]) ? data.append(userdetailEle,(self.state.preferences[userdetailEle].data)) : '';
        });
          if(this.state.preferences.selectedFile){
              if(!this.state.preferences.selectedFile.data)
                data.append('file', this.state.preferences.selectedFile, this.state.preferences.selectedFile.name);
          }
          console.log(this.state.preferences.location.data,"111")
          data.append('user_id',this.props.user.userdata.id)
          axios.post(url+'mobileapi/updatepreferences', data)
              .then(function (response) {
                console.log(data,"111")
                if(response.data.success=="true"){
                  self.props.dispatch(NOTIFICATION({msg:"Updated Successfully"}))
                }else{
                 self.props.dispatch(NOTIFICATION({msg:response.data.status_text}))
                }
              });
      }
      }
      removeFromArray = (name,ind) => event =>{
          
        let prestate = this.state.preferences[name]
        let limit = (name==="location") ? 4 : 3
        if(prestate.data.length <= limit){
            if(this.state.formError[name])
                delete this.state.formError[name]
        }
        prestate.data.splice(ind,1)
        this.setState({
            [name] : prestate
        })
    }
    handleselectedFile = event => {
      this.state.preferences.selectedFile = event.target.files[0]
      this.setState({
        preferences: this.state.preferences,
      })
  }

  StripeBoxHandle = () =>{
    this.setState({
        payModel : !this.state.payModel
    },async ()=>{
        await this.StripeBoxCloseHandle('save');
    })
}
StripeBoxCloseHandle = (type) =>{
  switch(type){
    case "save":
        this.props.dispatch(NOTIFICATION({msg:"Card Added Successful"}))
      break;
  }
  console.log('typew',type)
  this.setState({
      payModel : false
  },()=>{this.getusersdata()})
}
payModel = () => {
  this.setState({
      payModel : true
  })
}
removecard  = () => {
  let self = this;
  axios.defaults.headers.common['Authorization'] = this.props.user.data;
  let url = process.env.REACT_APP_BASE_URL ? 
              process.env.REACT_APP_BASE_URL 
              : 
              "http://showpads.auctionsoftware.com/";

  axios.post(url+'mobileapi/striprremovecard', {}).then((response)=>{
    switch(response.data.success){
      case 'yes':
          self.props.dispatch(NOTIFICATION({msg:"Card Removed Successful"}))
          this.setState({
            cardDetail : {},
            cardName : ""
          });
        break;
      case 'no':
          self.props.dispatch(NOTIFICATION({msg:"Subscription is active , Cannot remove card"}))
        break;
    }
  })
}
    componentDidMount(){
        this.props.dispatch(PATHNAMEUPDATE({pathName:"/dashboard/buyerprofile"}))
        this.getusersdata();
    }
    preapproveRequest = () => {
      let self=this;
      axios.defaults.headers.common['Authorization'] = this.props.user.data;
      let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
      axios.post(`${url}mobileapi/sendpreapproveemail`,{}).then((response)=>{
          if(response.data.success=="yes"){
              this.props.dispatch(NOTIFICATION({msg:"Request for approval sent successfully"}))
          }else{
              console.log(response,"response")
              let failedreason = (response.data&&response.data.failedreason) ? response.data.failedreason : "err"
              this.props.dispatch(NOTIFICATION({msg:failedreason,msgLink:'/dashboard/buyerprofile#preapprove'})) 
          }
      }).catch((error)=>{
          self.setState({
              error:"Try Later"
          })
      })
  }
  onPlaceChanged = (e) => {
    let data = e.target.value
    const preState = this.state.preferences.location;
    if (preState.data.indexOf(data) < 0) {
      this.state.selectedOptionClass = "selectedOption"
      // data.formatted_address = data.formatted_address.replace(/,/g," ")
      preState.data.push(data);
    }
    this.state.preferences.location = preState
    this.setState({
      preferences: this.state.preferences,
      selectedOptionClass: this.state.selectedOptionClass,
    });

  }
  
    render(){
      let { location_arr } = this.state 
        return (
            <div>
                <div className="col-md-9">
                  
                    <div className="flex justify-between pre-txt">
                      <span className="karla fw-600 text-black">PROFILE</span>
                      {this.state.preapprove === 0 ?
                      <span><button className="btn btn-orange karla fw-600" onClick={this.preapproveRequest}>
                        {/* onClick={this.preapproveRequest} */}
                        Get Pre-Approved </button></span>
                         :''}
                      
                        {this.state.preapprove === 2 ?
                      <span> <button className="btn btn-grey karla fw-600" >
                      Financing Pre-Approval Pending</button></span>
                      :''}     

                        {this.state.preapprove === 1 ?
                      <span> <button className="btn btn-green karla fw-600" >
                        Financing Pre-Approved</button></span>
                      :''}      

                    </div>
                
              <div className="main-pro">
                  <div className="content-main box-shadow pad-30">
                      <div className="tab-connect">
                          <ul className="nav nav-tabs">
                              <li className={`${this.state.profileTabActive}`}><a data-toggle="tab" href="#home" aria-expanded="false" onClick={this.tabChange("profile")}>PROFILE</a></li>
                              <li className={`${this.state.preferencesTabActive}`}><a data-toggle="tab" href="#menu1"  aria-expanded="true" onClick={this.tabChange("preferences")}>PREFERENCES</a></li>
                          </ul>
                      </div>
                      <div className="tab-content">
                        {/* Profile Tab  */}
                          <div id="home" className="tab-pane fade in active">
                              {(    this.state.tabname === "profile" &&  
                                    Object.keys(this.state.userDetails).length>0) &&
                              <form className="site-form">
                                  <div className="row">
                                  <div className="col-md-12">
                                    <h4 className="karla pad-tb-20"><b>PERSONAL INFO</b></h4>
                                  </div>
                                  
                                </div>
                                  <div className="row">
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="first_name">First Name</label>
                                              <input 
                                                type="text" 
                                                className="form-control site-input" 
                                                id="first_name" placeholder="Enter Your First Name" 
                                                value={this.state.userDetails.first_name.data}
                                                onChange={this.handleChange('first_name','userdetail')}/>
                                          </div> 
                                          <span style={{color:"red"}}>{this.state.formError['first_name']}</span>                                       
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="last_name">Last Name</label>
                                              <input 
                                                    type="text" 
                                                    className="form-control site-input" 
                                                    id="last_name" 
                                                    placeholder="Enter Your Last Name"
                                                    value={this.state.userDetails.last_name.data}
                                                    onChange={this.handleChange('last_name','userdetail')}/>
                                          </div>
                                          <span style={{color:"red"}}>{this.state.formError['last_name']}</span>
                                      </div>
                                  </div>
                                  <div className="row">
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="username">Add User Name</label>
                                              <input 
                                                    type="text" 
                                                    className="form-control site-input" 
                                                    id="username" 
                                                    placeholder="Add Your Initials"
                                                    value={this.state.userDetails.username.data}
                                                    onChange={this.handleChange('username','userdetail')}/>
                                          </div>  
                                          <span style={{color:"red"}}>{this.state.formError['username']}</span>                                      
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="phone">Enter Your Mobile Number</label>
                                              <input 
                                                    type="text" 
                                                    className="form-control site-input" 
                                                    id="phone" 
                                                    placeholder="Mobile Number"
                                                    value={this.state.userDetails.phone.data}
                                                    onChange={this.handleChange('phone','userdetail')}/>
                                          </div>
                                          <span style={{color:"red"}}>{this.state.formError['phone']}</span>
                                      </div>
                                  </div>
                                  <div className="row">
                                  <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="email">Email</label>
                                              <input 
                                                    type="text" 
                                                    className="form-control site-input" 
                                                    id="email" 
                                                    placeholder="Email"
                                                    value={this.state.userDetails.email.data}
                                                    disabled={true}/>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="form-group">
                                              <label htmlFor="aboutme">About Me</label>
                                              <textarea 
                                                        className="form-control site-input site-textarea" 
                                                        id="aboutme" 
                                                        cols="30" 
                                                        rows="6" 
                                                        placeholder="Anything you would like potential sellers to know about you?"
                                                        value={this.state.userDetails.aboutme.data}
                                                        onChange={this.handleChange('aboutme','userdetail')}
                                                        ></textarea>
                                          </div>  
                                          <span style={{color:"red"}}>{this.state.formError['aboutme']}</span>                                      
                                      </div>
                                  </div>
                                  <div className="text-center pad-tb-30">
                                      <button 
                                                type="submit" 
                                                className="btn btn-primary " 
                                                onClick={this.userDetailSubmit()}>
                                        Save
                                    </button>
                                   </div>
                                   <hr></hr>
                                  <div className="row">
                                      <div className="col-md-12">
                                        <h4 className="karla pad-tb-20"><b>CHANGE PASSWORD</b></h4>
                                      </div>
                                    </div>
                                  <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group">
                                                  <label htmlFor="change_password">Current Password</label>
                                                  <input 
                                                        type="password" 
                                                        className="form-control site-input" 
                                                        id="change_password" 
                                                        placeholder="Enter Your Current Password"
                                                        value={this.state.changePassword.currentpassword.data}
                                                        onChange={this.handleChange('currentpassword','changepassword')}
                                                        />
                                              </div>  
                                              <span style={{color:"red"}}>{this.state.formError['currentpassword']}</span>                                      
                                          </div>
                                  </div>
                                  <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group">
                                                  <label htmlFor="new_password">New Password</label>
                                                  <input 
                                                        type="password" 
                                                        className="form-control site-input" 
                                                        id="new_password" 
                                                        placeholder="Enter Your New Password"
                                                        value={this.state.changePassword.newpassword.data}
                                                        onChange={this.handleChange('newpassword','changepassword')}/>
                                              </div>  
                                              <span style={{color:"red"}}>{this.state.formError['newpassword']}</span>                                      
                                          </div>
                                          <div className="col-md-6">
                                              <div className="form-group">
                                                  <label htmlFor="password">Confirm Password</label>
                                                  <input 
                                                        type="password" 
                                                        className="form-control site-input" 
                                                        id="password" 
                                                        placeholder="Re-Enter Your Password"
                                                        value={this.state.changePassword.confirmnewpassword.data}
                                                        onChange={this.handleChange('confirmnewpassword','changepassword')}/>
                                              </div>
                                              <span style={{color:"red"}}>{this.state.formError['confirmnewpassword']}</span>
                                          </div>
                                  </div>
                                  <div className="text-center pad-tb-30">
                                      <button type="submit" className="btn btn-primary " 
                                              onClick={this.changePasswordSubmit()}>Save</button>
                                   </div>
                                   <hr></hr>
                                  <div className="row">
                                      <div className="col-md-12">
                                        <h4 className="karla pad-tb-20"><b>PAYMENT INFO</b></h4>
                                      </div>
                                    </div>
                                  <div className="row">
                                          <div className="col-md-6">
                                              <div className="form-group ">
                                              <input 
                                                    type="number" 
                                                    className="form-control site-input" 
                                                    id="cc" 
                                                    //value={this.state.cardName}
                                                    placeholder={ Object.keys(this.state.cardDetail).length>0 ?
                                                                  `**** **** **** ${this.state.cardName}`
                                                                : 
                                                                  "---- ---- ---- ----"}
                                                    disabled/>
                                              </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div className="form-group">
                                                  <h5 className="karla add-remove-card">
                                                      
                                                      {Object.keys(this.state.cardDetail).length > 0 ? 
                                                      <div>
                                                        <i className="fa fa-minus" aria-hidden="true"></i>
                                                        <b>
                                                        <a href="javascript:void(0)" onClick={this.removecard}>REMOVE CARD</a> 
                                                        </b>
                                                      </div>
                                                      : 
                                                      <div>
                                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                                        <b>
                                                        <a href="javascript:void(0)" onClick={this.payModel}>ADD CARD</a>
                                                        </b>
                                                      </div>
                                                      }
                                                  </h5>
                                            </div>
                                          </div>
                                  </div>
                              </form>  
                              } 
                          </div>
                        {/* End pf Profile Tab */}
                        {/* Preferences Tab */}
                          {/* {this.state.tabname=== "preferences"  &&  */}
                          <div id="menu1" className="pad-top-30">
                          
                              <div className="site-form">
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                      <label >Choose your preferred financing</label>
                                      <select className="form-control site-input" 
                                      name="saletype" 
                                      value={this.state.preferences.saletype.data} 
                                      onChange={this.handleChange('saletype','preferences')}>
                                          <option value="">Choose the type of Sale</option>
                                          <option value="All Cash Only">All Cash Only </option>
                                          <option value="Conventional">Conventional</option> 
                                          <option value="FHA">FHA</option> 
                                          <option value="VA">VA</option>
                                      </select>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["saletype"]}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                        <label >Preferred Seller Type(s)</label>
                                        <select  className="form-control site-input" 
                                        id="" name="sellertype" 
                                        value={this.state.preferences.sellertype.data} 
                                        onChange={this.handleChange('sellertype','preferences')}>
                                            <option value="">Choose the type of Seller</option>
                                            <option value="Any">Any</option>
                                            <option value="Private Owner">Private Owner </option>
                                            <option value="Pocket Listing">Pocket Listing </option>
                                            <option value="REO">REO </option>
                                            <option value="Investor/Institution">Investor/Institution </option>
                                        </select>
                                      </div>
                                      <span style={{color: "red"}}>{this.state.formError["sellertype"]}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                        <label >Preferred Condition</label>
                                        <select  
                                                className="form-control site-input" 
                                                id="" 
                                                name="propertycondition" 
                                                value={this.state.preferences.propertycondition.data} 
                                                onChange={this.handleChange('propertycondition','preferences')}>
                                            <option value="">Choose a condition of the Property</option>
                                            <option value="Any">Any </option>
                                            <option value="Excellent Completely Updated">Excellent - Completely Updated </option>
                                            <option value="Good Moderate updates">Good - Moderate updates </option>
                                            <option value="Average Few updates Mostly Original">Average - Few updates. Mostly Original </option>
                                            <option value="Poor Repairs and updates needed">Poor - Repairs and updates needed</option>
                                            <option value="Investor grade major repairs and updates needed">Investor grade - major repairs and updates needed </option>
                                            <option value="Land Value only">Land Value only </option>
                                            <option value="Development Deal">Development Deal </option>
                                        </select>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["propertycondition"]}</span>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group"><label>Max Buying Price</label>
                                        <input 
                                                type="text" 
                                                className="form-control site-input" 
                                                id="exampleInputEmail1" 
                                                placeholder="Max Buying Price"
                                                value={this.state.preferences.pricerangemax.data}
                                                onChange={this.handleChange('pricerangemax','preferences')}/>
                                      </div>
                                      <span style={{color: "red"}}>{this.state.formError["pricerangemax"]}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                      <label >Preferred Property Type(s)</label>
                                      <select className="form-control site-input" 
                                          name="propertype" 
                                          value={this.state.preferences.propertype.data} 
                                          onChange={this.handleChange('propertype','preferences')}>
                                              <option value=""> { (this.state.preferences&&this.state.preferences.propertype&&this.state.preferences.propertype.data&&this.state.preferences.propertype.data.length==0)
                                                                  ?"Choose Property Type" 
                                                                  : 
                                                                  (this.state.preferences.propertype&&this.state.preferences.propertype.data.length>0)?this.state.preferences.propertype.data.join(","):"Choose property type"}</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Single Family")>=0 && this.state.selectedOptionClass} 
                                                      value="Single Family">Single Family</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Multi-Family")>=0 && this.state.selectedOptionClass} 
                                              value="Multi-Family">Multi-Family</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Condo")>=0 && this.state.selectedOptionClass} 
                                              value="Condo">Condo</option>
                                              <option  className={this.state.preferences.propertype.data.indexOf("Commercial")>=0 && this.state.selectedOptionClass} 
                                              value="Commercial">Commercial</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Vacant Land")>=0 && this.state.selectedOptionClass}  
                                              value="Vacant Land">Vacant Land</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Development Deal")>=0 && this.state.selectedOptionClass}  
                                              value="Development Deal">Development Deal</option>
                                              <option className={this.state.preferences.propertype.data.indexOf("Contract")>=0 && this.state.selectedOptionClass}  
                                              value="Contract">Contract </option>
                                          </select>
                                          <div className="pan-block">
                                              {this.state.preferences.propertype.data.length> 0 && this.state.preferences.propertype.data.map((property_Type,propertyInd)=>{
                                                  return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                      {property_Type}
                                                      <span aria-hidden="true"  onClick={this.removeFromArray('propertype',propertyInd)}>
                                                          <i className="fas fa-times"></i>
                                                      </span>
                                                  </span>
                                              })}
                                          </div>
                                  </div>
                                  <span style={{color: "red"}}>{this.state.formError["propertype"]}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                            <label >Preferred Location(s)</label>
                                            {/* <select className="form-control site-input" 
                                            name="location"
                                            value={this.state.preferences.location.data} 
                                            onChange={this.handleChange('location','preferences')}>
                                                <option  value="">{ (this.state.preferences&&this.state.preferences.location&&this.state.preferences.location.data&&this.state.preferences.location.data.length==0)?"Add a Location" :(this.state.preferences.location && this.state.preferences.location.data)? this.state.preferences.location.data.join(","):"Choose Location"}</option>
                                                {us_states.map((data,index)=>(
                                                        <option className={this.state.preferences.location.data.indexOf(data.label)>=0 && this.state.selectedOptionClass}  value={data.label} key={"sate_select"+index}>{data.label}</option>
                                                    ))}
                                            </select>*/}
                                            {/*<Autocomplete onPlaceChanged={this.onPlaceChanged} />  */}
                                            <select
                                                name = "location"
                                                className="form-control site-input" id="propsqfootage"
                                                value={this.state.preferences.location}
                                                defaultValue={"" }
                                                onChange={this.onPlaceChanged}
                                                placeholder={"Search by location"}
                                                type="text" 
                                            >
                                            {location_arr.map((x,y) => <option key={y}>{x}</option>)}
                                              </select> 
                                            <div className="pan-block">
                                            {this.state.preferences.location.data.length > 0 && this.state.preferences.location.data.map((ele,ind)=>{
                                                return <span key={'k'+ind} className="token close" data-dismiss="modal" aria-label="Close">
                                                    {ele} 
                                                    <span aria-hidden="true" onClick={this.removeFromArray('location',ind)}>
                                                        <i className="fas fa-times"></i>
                                                    </span>
                                                </span>
                                            })}
                                            </div>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.formError["location"]}</span>
                                      </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                          <label >Bedroom(s)</label>
                                          <select className="form-control site-input" id="" 
                                          name="bedroom" 
                                          value={this.state.preferences.bedroom.data} onChange={this.handleChange('bedroom','preferences')}>
                                              <option value="">Choose the number of baths</option>
                                              <option value="1">1</option>
                                              <option value="2">2</option>
                                              <option value="3">3</option>
                                              <option value="4">4</option>
                                              <option value="5 or more">5 or more</option>
                                          </select>
                                      </div>
                                      <span style={{color: "red"}}>{this.state.formError["bedroom"]}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                        <label >Bathroom(s)</label>
                                        <select className="form-control site-input" id="" name="bathroom" 
                                        value={this.state.preferences.bathroom.data} 
                                        onChange={this.handleChange('bathroom','preferences')}>
                                            <option value="">Choose the number of bedroom</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5 or more">5 or more</option>
                                        </select>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["bathroom"]}</span>
                                    </div>
                                  </div>
                                  <div className="row" id="preapprove">
                                    <div className="col-md-6">
                                    <div className="form-group ">
                                        <label >Min.Property Sq. Footage</label>
                                        <input type="number" 
                                            className="form-control site-input" 
                                            id="propsqfootage" 
                                            name="min_property_sq_footage" 
                                            value={this.state.preferences.min_property_sq_footage.data}
                                            onChange={this.handleChange('min_property_sq_footage','preferences')}
                                            placeholder="10-10000"
                                            min={100}
                                            max={10000}
                                        />
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["min_property_sq_footage"]}</span>
                                    </div>
                                    <div className="col-md-6" >
                                    <div className="form-group ">
                                        <label >Min.Land Sq. Footage</label>
                                        <input type="number" className="form-control site-input" id="propsqfootage" 
                                        name="min_land_sq_footage" 
                                        value={this.state.preferences.min_land_sq_footage.data}
                                        onChange={this.handleChange('min_land_sq_footage','preferences')}
                                        placeholder="10-30000"
                                        min={100}
                                        max={30000}
                                          />
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["min_land_sq_footage"]}</span>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                        <label >Preferred Property Zoning</label>
                                        <select name="" className="form-control site-input" id="" 
                                        name="propertyzone" 
                                        value={this.state.preferences.propertyzone.data} 
                                        onChange={this.handleChange('propertyzone','preferences')}>
                                            <option value="">Select Preferred Property Zoning</option>
                                            <option value="Arizona">Arizona</option>
                                            <option value="California">California</option>
                                        </select>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["propertyzone"]}</span>
                                    </div>
                                    <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                          <label >Purchase Timeline</label>
                                          <select name="" className="form-control site-input" id="" 
                                          name="purchasetimeline" 
                                          value={this.state.preferences.purchasetimeline.data} 
                                          onChange={this.handleChange('purchasetimeline','preferences')}>
                                              <option value="">Set a Purchase Timeline</option>
                                              <option value="Immediately">Immediately </option>
                                              <option value="Next 1 to 3 Months">Next 1 to 3 Months</option>
                                              <option value="Next 3 to 6 Months">Next 3 to 6 Months</option>
                                              <option value="Next 6 to 12 Months">Next 6 to 12 Months </option>
                                              <option value="1 Year or more">1 Year or more</option>
                                          </select>
                                      </div>
                                      <span style={{color: "red"}}>{this.state.formError["purchasetimeline"]}</span>
                                    </div>
                                  </div>
                                  <div className="row" >
                                      <div className="col-md-6">
                                      <div className="form-group selectdiv">
                                            <label >Pre-approval Letter Obtained </label>
                                            <select 
                                                    name="" 
                                                    className="form-control site-input" 
                                                    id="" 
                                                    name="letter_obtained" 
                                                    value={this.state.preferences.letter_obtained.data} 
                                                    onChange={this.handleChange('letter_obtained','preferences')}>
                                                <option value="">Choose an option</option>
                                                <option value="y">Yes</option>
                                                <option value="n">No</option>
                                            </select>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span>
                                        </div>
                                        {this.state.preferences.letter_obtained.data==='y' && <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Upload your Pre-approval Letter</label> 
                                                            <div className="upload-btn-wrapper">
                                                                <button className="btn btn-primary karla font-15 fw-500 ">
                                                                Upload Document &nbsp;
                                                                <img src="./images/icons/upload-button.svg" />
                                                               
                                                         
                                                                    {this.state.preferences.selectedFile && <label style={{color:"green"}}>{this.state.preferences.selectedFile.name}</label>}
                                                                    {this.state.preferences.selectedFile && <label style={{color:"green"}}>{this.state.preferences.selectedFile.data}</label>}
                                                      
                                                                <input type="file" name="file" accept="application/pdf" onChange={this.handleselectedFile}/>
                                                                </button>
                                                            </div>
                                                            {/* <a href="javascript:void(0)"> view Document</a> */}
                                                            {/* <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span> */}
                                                        </div>
                                                    </div>}
                                                    {this.state.preferences.letter_obtained.data==='n' && <div className="col-md-6">
                                                        <div className="form-group">
                                                          
                                                            <div className="upload-btn-wrapper">
                                                            <label><br></br>If you need lending assistance, please email us at <a>buyers@doorrise.com</a></label>
                                                            </div>
                                                            {/* <a href="javascript:void(0)"> view Document</a> */}
                                                            {/* <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span> */}
                                                        </div>
                                                    </div>}
                                                   
                                                    {/* <label><br></br>If you need lending assistance, please email us at <a>buyers@doorrise.com</a></label> */}
                                  </div>
                                  <div className="text-center pad-tb-30">
                                      <button type="submit" className="btn btn-primary" onClick={this.preferencesSubmit}>Save</button>
                                    </div>
                              </div>  
                              
                          </div> 
                          
                    {/* End of Preferences Tab  */}
                      </div>
                  </div>
                  { this.state.payModel && 
                    <Stripe 
                        project_id={0} 
                        onChangeHandle={this.StripeBoxHandle}
                        StripeBoxCloseHandle={this.StripeBoxCloseHandle}
                        amount={0}
                    /> 
                }
                  <div>
                    <h4><div className="text-center pad-tb-30">
                        
                      </div></h4>
                  </div>
              </div>
            </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Buyerprofile);