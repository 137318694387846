import React, {Component} from 'react';
import './Home.css';
import { Link } from 'react-router-dom';
import ContactUs from '../../views/ContactUs/ContactUs'
import axios from 'axios';
import { connect } from 'react-redux';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Blog from '../../components/Blog/Blog';
import Popup from '../../components/Popup/Popup';
import VideoPopup from '../../components/Popup/videoPopup';
import FeatureProperty from '../../components/FeaturedProperty/FeaturedProperty';
import {properResult} from '../../util/capsAndSpaces';
import {NOTIFICATION} from '../../actions/index';
import Stripe from '../../components/Payment/Stripe';
import AOS from 'aos';

// import "animate.css/animate.min.css";
// import ScrollAnimation from 'react-animate-on-scroll';
class Home extends Component {
    componentDidMount(){
        AOS.init({
            offset: 120, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 400, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom',
        })

      }
    state = {
        productDetails: [],
        orginaltestimonaldata: [],
        featuredProperty: [],
        activeclassindex: 0,
        from: 0,
        to: 3,
        perSliderClick: 1,
        blog: [],
        product:[],
        pop: false,
        pop1:false,
        stripeModal:false,
        msg: "",
        msg1:"you will be charged a monthly fee for subscription so that you will be able to bid or make an offer on the products, Pay now button below",
        videoPop: false,
        isSell: false,
        isBuy: false,
        isRealtor: false,
        payModel: false,
        customerid:'',
        paymentid:''
        
    }
    validateUser = () => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user && this.props.user.userdata?self.props.user.data:'';
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {
            "userid": self.props.user && self.props.user.userdata ? self.props.user.userdata.id:''
        }
        axios.post(url + 'mobileapi/userdata', payload)
            .then(function (response) {
                if (response.data.success === "yes") {
                    self.setState({ customerid: 
                                    (response.data.data.customerid && response.data.data.paymentid) 
                                    ? response.data.data.customerid : response.data.data.paypal_order_id 
                                    ? response.data.data.paypal_order_id : '', paymentid: response.data.data.customerid 
                                    ? response.data.data.customerid : response.data.data.paypal_subscribtion_id 
                                    ? response.data.data.paypal_subscribtion_id : ''})
                } else {
                    self.setState({ error: "Try again!" })
                }
            })
            .catch(function (error) {
                self.setState({ error: "Try again!" })
            });
    }
    StripeBoxHandle = () => {
        this.setState({
            payModel: !this.state.payModel,
        }, async () => {
            await this.validateUser();
        })
    } 
    StripeBoxCloseHandle = () => {
       
        this.setState({
            
            stripeModal: false
        })
    } 
    getDetails() {
        let self = this;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.get(`${url}mobileapi/getDetailshome`)
            .then(async function (response) {
                if (response.data.success === "yes") {
                    self.setState({
                        orginaltestimonaldata: response.data.data,
                        activeclassindex: Math.floor(response.data.data.length / 2),
                        featuredProperty: (response.data.featureProperty && response.data.featureProperty.length > 0) ?  await properResult(response.data.featureProperty) : []
                    },()=>{
                        const {location} = self.props;
                        if(location && location.state && location.state.scrollTo){
                            window.document.getElementsByClassName('contus')[0].click()
                        }
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
  sellproduct()
  {
    let self = this;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    axios.post(url+'mobileapi/seller_home_product')
        .then(function (response) {
                self.setState({
                    product:response.data
                })
                console.log("value++",self.state.product);
        }).catch(function (error) {
                self.setState({
                    error : "Try later"
                })
            });
  }

    testimonalScroll=(index)=>event=>{
            let length = this.state.orginaltestimonaldata.length;
            let from = 0,to = 3;
            if (this.state.perSliderClick === index) return false;
            if(index === 0){
                let out = this.state.orginaltestimonaldata.pop()
                this.state.orginaltestimonaldata.unshift(out)
            }else if(index === (length-1)){
                this.state.orginaltestimonaldata.push(this.state.orginaltestimonaldata.shift());
                to = (length);
                from = (length-3) >= 0 ? (length-3)  : 0;
            }else{
                from = this.state.perSliderClick === index - 1 ? index : index - 1
                to = this.state.perSliderClick === index - 1 ? index + 3: index + 2; 
            }


             this.setState({
                orginaltestimonaldata : this.state.orginaltestimonaldata,
                from: from,
                to: to,
                perSliderClick:index 
             })
    }
    componentDidMount(){
        
        this.getDetails();
        this.sellproduct();
    }

    sellingclick=()=>{
        if(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'buyer'){
            this.setState({
                pop : true,
                msg : 'Thanks For showing intrest , Kindly sign up into a new account with role as Seller.'
            })  
        }
        
    }
    buyingClick=()=>{
        if(this.props.loggedIn && this.props.user && (this.props.user.userdata.role==='seller' || this.props.user.userdata.role==='realtor') )
        {
            this.setState({
                pop : true,
                msg : 'Thanks For showing intrest , Kindly sign up into a new account with role as Buyer.'
            })    
        }
    }
    popupHandle = (data,price,dataObj) => {
        
        this.setState({
            pop:false,
            msg : 'Thanks for showing intrest '
        })
    }
    popupHandle1 = (data) => {
        this.setState({
            pop1:false,
        })
        if(data=="1")
       {
        this.setState({
            stripeModal:true,
        })
       }
    }

    videoPopupHandle = (data) => {
        this.setState({
            videoPop: !data ? true : false
        })
    }

    clickHandle = (event) =>{
        let name = event.target.name;
        let isBuy = name === 'isBuy' ? true : false
        let isSell = name === 'isSell' ? true : false
        let isRealtor = name === 'isRealtor' ? true : false
        this.setState({
            isBuy: isBuy,
            isSell: isSell,
            isRealtor: isRealtor
        })
    }
    openpopup = (event) =>
    {
        this.setState({
            pop1:true
        })
    }

    focusOffer = (source) => event => {
        console.log('focus offer')
        let bannerLearnMore = document.getElementsByClassName("banner-v")[0].offsetTop;
        bannerLearnMore = bannerLearnMore- 70;
        let contact = document.getElementsByClassName("Home-form")[0].offsetTop;
        contact = contact - 70;
        let size = (source === 'contact') ? contact : bannerLearnMore
        window.scroll({
            top: size,
            behavior: 'smooth'
        })
    }
    closeSecDevide = (type) => event => {
        console.log(type);
        if(type==='buy')
            this.setState({isBuy:false});
        else if(type==='sell')
            this.setState({isSell:false});
        else if(type==='realtor')
            this.setState({isRealtor:false});
    }
    afterLoggedInmsg = (type)=>e=>{
        let msg = ''
        if(type==='seller'){
            msg='If you are interested in selling a property, please log into your Seller Account or Create a Seller Account.'
        }
        if(type==='buyer'){
            msg='To look for properties, you must login in as a Buyer or Create a Buyer account.'
            
        }
        if(type==='realtor'){
            msg='If you are interested in Pocket Listing a property, please log into your Realtor Account or Create a Realtor Account.'
        }
        this.props.dispatch(NOTIFICATION({msg:msg}))
    }
  render() {
    let user = this.props.user ? this.props.user.userdata : '';
    const {loggedIn}= this.props;
    
    
    let {from,to, isBuy, isSell, isRealtor} = this.state;
    let testimonalDisplay = this.state.orginaltestimonaldata ? this.state.orginaltestimonaldata.slice(from,to) : [];
    let url  = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"
    let { data } = this.state.product;
    return (
        
      <main id="main">
        <div className="container-fluid sp_banner">
            <div className="container">
                <div className="row w-res-mode">
                 <div className="main-h">
                    <h1 className="">Buy & Sell Off-Market L.A. Real Estate</h1>
                    <h3 className="">Find A Deal. Get Full Service. Save a Ton.</h3>
                 </div>
                    <div className="col-sm-9 col-sm-offset-2 sm-pd">
                         <div className="banner-box">
                            <div className="row">
                                <div className="col-sm-12 res-pd">
                                    <div className="mian-cont">
                                        
                                        <p className="main-p ">Super-Low Commissions for Sellers </p>
                                        <p className="main-p ">Cash Back at Close for Buyers</p>
                                            {/* <p className="main-p">
                                            We handle everything from<br/>
                                            OFFER to CLOSE</p> */}
                                        {/* <p className="main-span ">Get Deep Discounts & Rebates!</p> */}
                                        <div className="main-flex">                                            <button className={ isBuy ? "btn btn-default btn-primary karla" : "btn btn-default btn-primary btn-whi-ban karla" } name="isBuy" onClick={this.clickHandle} >BUY NOW</button>
                                            <Link to='/infovideo/buyer' className="hidden L-a">Learn More</Link>
                                           <button className={ isSell ? "btn btn-default btn-primary karla" : "btn btn-default btn-primary btn-whi-ban karla" } name="isSell" onClick={this.clickHandle} >SELL NOW</button>
                                           <Link to='/infovideo/seller' className="hidden L-a">Learn More</Link>
                                          {/* {user.role=="buyer" && user.subscribe_status!="active" ? <button className={"btn btn-default btn-primary btn-whi-ban karla" } name="isSell" onClick={this.openpopup} >SubScripe</button>
                                           :''} */}
                                      </div>
                                        {/* <p className="main-span  ">
                                        {user && user.role==='realtor' ? '':<button  className={ isRealtor ? "btn btn-default btn-primary karla" : "btn btn-default btn-primary btn-whi-ban karla" } name="isRealtor" onClick={this.clickHandle} >Post A Pocket Listing</button>}
                                            <Link to={"/dashboard/post"} className="text-white">Add Pocket Listings</Link>
                                        </p> */}
                                            <Link to="/infovideo/buyer"><h4 class="fw-600 learn">Learn More</h4></Link>
                                            {/* <Link to='/infovedio/faq' onClick={this.focusOffer('bannerLearnMore')} className="learn">Learn More</Link> */}
                                            {/* <Link to='/infovideo/buyer' className="learn">Learn More</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                         
                    </div>
                </div>
            </div>
        </div>
        { isBuy  && <section className="container">
                <div className="select-sec posi-relative">
                    <div className="sec-divided buy">
                        <div className="close-icon text-right">
                            <span className="" onClick={this.closeSecDevide('buy')}><i class="fa fa-times" aria-hidden="true"></i></span>
                        </div>
                        <div className="sec-divided-flex">
                            <span>
                            {/* 1<sup class="fs-15">ST</sup> */}
                                <p className="karla">STEP  1</p>
                                <h4>Post Your Purchase  Criteria  and 
Buyer Qualifications </h4>
                            </span>
                            <span>
                                <p className="karla">STEP  2</p>
                                <h4>Search for Listings, Wholesale Contracts and Deeds </h4>
                            </span>
                            <span>
                                <p className="karla">STEP  3</p>
                                <h4>Receive new  Listings  
from Actual Sellers</h4>
                            </span>
                            <span>
                                <p className="karla"> STEP  4</p>
                                <h4>Our Team helps you make bids and offers</h4>
                            </span>
                        </div>
                        <div className="divion-button">
                        {loggedIn && user && user.role!='buyer'
                                ? <button className="btn btn-default btn-primary" onClick={this.afterLoggedInmsg('buyer')}>START BUYING</button>
                                : 
                                <Link to={"/search/buyer"}><button className="btn btn-default btn-primary">START BUYING</button></Link>
                            }
                            
                            <p>Rebates on Closing Costs!</p>
                        </div>
                    </div>
            </div>
        </section>}
        { isRealtor  && <section className="container">
                <div className="select-sec posi-relative">
                    <div className="sec-divided buy">
                        <div className="close-icon text-right">
                            <span className="" onClick={this.closeSecDevide('realtor')}><i class="fa fa-times" aria-hidden="true"></i></span>
                        </div>
                        <div className="sec-divided-flex">
                            <span>
                            {/* 1<sup class="fs-15">ST</sup> */}
                                <p className="karla">STEP  1</p>
                                <h4>Create Your Profile</h4>
                            </span>
                            <span>
                                <p className="karla">STEP  2</p>
                                <h4>Post Your Listing </h4>
                            </span>
                            <span>
                                <p className="karla">STEP  3</p>
                                <h4>Buyers find your listing and Initiate Interest </h4>
                            </span>
                            <span>
                                <p className="karla"> STEP  4</p>
                                <h4>Set Up Showings and negotiate Offers</h4>
                            </span>
                        </div>
                        <div className="divion-button">
                        {loggedIn && user && user.role!='realtor'
                                ? <button className="btn btn-default btn-primary" onClick={this.afterLoggedInmsg('realtor')}>Add Pocket Listing</button> 
                                : 
                                <Link to={loggedIn?"/dashboard/pocketlisting":"/realtorproductpost"}><button className="btn btn-default btn-primary">Add Pocket Listing</button></Link>
                            }
                            <Link to='/infovideo/realtor'><p>Learn more</p></Link>
                            {/* <p>Referral Fee Applies</p> */}
                        </div>
                    </div>
            </div>
        </section>}
        { isSell  && <section className="container">
                <div className="select-sec posi-relative">
                    <div className="sec-divided sell">
                        <div className="close-icon text-right">
                            <span className="" onClick={this.closeSecDevide('sell')}><i class="fa fa-times" aria-hidden="true"></i></span>
                        </div>
                        <div className="sec-divided-flex">
                            <span>
                                <p className="karla"> STEP  1</p>
                                <h4>Post your listing, wholesale contract or trust deed </h4>
                            </span>
                            <span>
                                <p className="karla"> STEP  2</p>
                                <h4>Receive Free sales comps and a physical inspection report</h4>
                            </span>
                            <span>
                                <p className="karla"> STEP  3</p>
                                <h4>Search Our Community 
and connect with
Qualified  Buyers </h4>
                            </span>
                            <span>
                                <p className="karla"> STEP  4</p>
                                <h4>Initiate a deal. 
Our Team will 
handle the rest. </h4>
                            </span>
                        </div>
                        <div className="divion-button">
                            {loggedIn && user && user.role!='seller'
                                ? <button className="btn btn-default btn-primary" onClick={this.afterLoggedInmsg('seller')}>START SELLING</button>
                                : 
                                <Link to={loggedIn?'/dashboard/post':"/productpost"}><button className="btn btn-default btn-primary">START SELLING</button></Link> 
                            }
                            
                            <p>Commissions as low as 3%</p>
                        </div>
                    </div>
            </div>
        </section>}
        {/* <br></br><br></br> */}
 {/* <div style={{"padding":"0 60px","maxWidth":"100%","margin":"0 auto"}}>
{data !=undefined ?
  <ItemsCarousel
    infiniteLoop={true}
    gutter={6}
    activePosition={'center'}
    chevronWidth={60}
    disableSwipe={false}
    alwaysShowChevrons={true}
    numberOfCards={4}
    slidesToScroll={1}
    outsideChevron={true}
    showSlither={true}
    firstAndLastGutter={true}
    activeItemIndex={this.state.activeItemIndex}
    requestToChangeActive={value => this.setState({ activeItemIndex: value })}
    rightChevron={<button>{'>'}</button>}
    leftChevron={<button>{'<'}</button>}
  >
    {console.log("valuee",data)}
    {data.map((data, i) =>
         (data.avatar===null ?   
           
            <div>
                <div key={i}
                    style={{
                        height: 250,
                        background:"url(/images/No_Image_Available.jpg)"
                    }}>
                </div>
            </div>
            
            :
            <div>
                <p className="activep">active</p>
                <div
                key={i}
                style={{
                  height: 250,
                  background:"url("+url+"/uploads/product/"+data.avatar+")"
                }}>
                </div>
            
            </div>)
            
    )}
  </ItemsCarousel>
  :""}
</div>  */}
        <div className="container-fluid hiw snd-bg karla com-ty banner-v pb-3">

            <div className="row hiw-tit lato">
                <div className="col-sm-12 txt-center">
                        <h2>Sweet Deals. Full Service. Big Savings.</h2>
                    {/* <ScrollAnimation duration={1} delay={3}  animateIn='bounceInRight'animateOut='bounceOutRight'>
                    </ScrollAnimation> */}
                    <h4 className="">Join our Exclusive Network of Buyers and Sellers. Get Expert Service and Incentives.</h4>
                </div>                
            </div>   
            <div className="row checklist">
                <ul class="complete">
                    <h5>Buyer Benefits</h5>
                    <li><b>Get 1st Dibs </b> BEFORE Listings hit the market</li>
                    <li><b>Deals Come</b> directly from Sellers</li>
                    <li><b>Get Professional Service</b> at every step </li>
                    <li><b>Get Cash Back</b> at Closing!  </li>
                    <li className="list-btn">
                    {loggedIn && user && user.role!=='buyer' 
                        ?  <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('buyer')}>BUY NOW</button>
                        :
                        <Link to="/search/buyer">
                            <button type="button" class="btn btn-default btn-primary btn-whi-ban karla mb-xs5">BUY NOW</button>
                        </Link>
                    }
           </li>
                </ul>
                <ul class="complete">
                    <h5>Seller Benefits</h5>
                    <li><b>Find Buyers</b> BEFORE you list with an Agent </li>
                    <li><b>Name Your Price</b> and get Instant Offers </li>
                    <li><b>Get Professional Service</b> at every step </li>
                    <li><b>Sell Fast</b> and  <b>Slash Commissions</b></li>
                    <li className="list-btn">
                        <Link to={loggedIn?'/dashboard/post':"/productpost"}>
                            <button type="button" class="btn btn-default btn-primary btn-whi-ban karla">SELL NOW</button>
                        </Link>
                    </li>
                </ul>
            </div>        
            

            <div className="row hiw-1 hidden">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="hiw-box">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="hiw-t2">SELL OR AUCTION YOUR PROPERTY</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                    <div className="bg-wht">
                                        <ul className="list-group">
                                            <li className="list-group-item">1. Get FREE Sales Comps</li>
                                            <li className="list-group-item">2. Set Your Asking Price </li>
                                            <li className="list-group-item">3. Browse for Qualified Buyers</li>
                                            <li className="list-group-item">4. Initiate the DEAL. We'll Close It For You</li>
                                            <li className="list-group-item text-light fw-600">5. Low Commissions. Cash Back at Close</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 hiw-links">
                                        <div className="col-sm-6 col-md-6 col-lg-5 col-xs-6">
                                            <span>
                                                {/* <img src="./images/icons/play-icon.svg" alt=""/> */}
                                                <svg version="1.1" id="Capa_1" x="0px" y="0px"
                                                    width="17" height="17" viewBox="0 0 314.068 314.068" style={{background:"new 0 0 314.068 314.068"}}>
                                                <g>
                                                    <g id="_x33_56._Play">
                                                        <g>
                                                            <path d="M293.002,78.53C249.646,3.435,153.618-22.296,78.529,21.068C3.434,64.418-22.298,160.442,21.066,235.534
                                                                c43.35,75.095,139.375,100.83,214.465,57.47C310.627,249.639,336.371,153.62,293.002,78.53z M219.834,265.801
                                                                c-60.067,34.692-136.894,14.106-171.576-45.973C13.568,159.761,34.161,82.935,94.23,48.26
                                                                c60.071-34.69,136.894-14.106,171.578,45.971C300.493,154.307,279.906,231.117,219.834,265.801z M213.555,150.652l-82.214-47.949
                                                                c-7.492-4.374-13.535-0.877-13.493,7.789l0.421,95.174c0.038,8.664,6.155,12.191,13.669,7.851l81.585-47.103
                                                                C221.029,162.082,221.045,155.026,213.555,150.652z"/>
                                                        </g>
                                                    </g>
                                                </g>
                                                </svg>
                                                </span>
                                            <span className="pointer" onClick={()=>this.videoPopupHandle(0)}>WATCH A VIDEO</span>
                                        </div>
                                        <div className="col-sm-1">
                                            <span className="divider"></span>
                                        </div>
                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                                                {(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'buyer') ?
                                                    <button type="button" className="btn btn-default btn-primary" onClick={this.sellingclick}>START SELLING</button>
                                                    :
                                                    <Link to={(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'seller') ? "/dashboard/post" :(this.props.loggedIn) ? "/dashboard/pocketlisting" : '/register'}><button type="button" className="btn btn-default btn-primary" >START SELLING</button></Link>
                                                }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img src="./images/pic-1.png" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row hiw-2  hidden">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <img src="./images/hiw-2.png" className="img-responsive" alt=""/>
                        </div>
                        <div className="col-sm-6">
                            <div className="hiw-box">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="hiw-t2">BUYERS FIND DEALS QUICKLY</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                    <div className="bg-wht">
                                        <ul className="list-group">
                                            <li className="list-group-item">1. Search for Deals and... </li>
                                            <li className="list-group-item">2. Receive Deals from Actual Sellers</li>
                                            <li className="list-group-item">3. Initiate interest.</li>
                                            <li className="list-group-item">4. We'll handle everything from Offer to Closing.</li>
                                            <li className="list-group-item text-light fw-600">5. GET Cash Back For Closing Costs</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12 hiw-links">
                                        <div className="col-sm-6 col-md-6 col-lg-5 col-xs-6">
                                        <span><svg version="1.1" id="Capa_1" x="0px" y="0px" width="17" height="17" viewBox="0 0 314.068 314.068"><g><g id="_x33_56._Play"><g><path d="M293.002,78.53C249.646,3.435,153.618-22.296,78.529,21.068C3.434,64.418-22.298,160.442,21.066,235.534
                                        c43.35,75.095,139.375,100.83,214.465,57.47C310.627,249.639,336.371,153.62,293.002,78.53z M219.834,265.801
                                        c-60.067,34.692-136.894,14.106-171.576-45.973C13.568,159.761,34.161,82.935,94.23,48.26
                                        c60.071-34.69,136.894-14.106,171.578,45.971C300.493,154.307,279.906,231.117,219.834,265.801z M213.555,150.652l-82.214-47.949
                                        c-7.492-4.374-13.535-0.877-13.493,7.789l0.421,95.174c0.038,8.664,6.155,12.191,13.669,7.851l81.585-47.103
                                        C221.029,162.082,221.045,155.026,213.555,150.652z"></path></g></g></g></svg></span>
                                        {/* <a href="javascripy:void(0)">WATCH A VIDEO</a></div> */}
                                        <span className="pointer" onClick={()=>this.videoPopupHandle(0)}>WATCH A VIDEO</span></div>
                                        <div className="col-sm-1"><span className="divider"></span></div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                                                {(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'buyer')
                                                    ?
                                                    <Link to='/search/buyer'><button type="button" className="btn btn-default btn-primary">START BUYING</button></Link>
                                                    :
                                                    (this.props.loggedIn) 
                                                    ?
                                                    <button type="button" className="btn btn-default btn-primary" onClick={this.buyingClick}>START BUYING</button>
                                                    :
                                                    <Link to="/register"><button type="button" className="btn btn-default btn-primary" >START BUYING</button></Link>
                                                }
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row hiw-3  hidden">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="hiw-box">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <h3 className="hiw-t2">AGENTS: SELL YOUR POCKET LISTINGS</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="bg-wht">
                                            <p> Post your L.A./So Call listings here and watch Buyers come running!</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 hiw-links">
                                         <div className="col-sm-6 col-md-6 col-lg-5 col-xs-6"><span><svg version="1.1" id="Capa_1" x="0px" y="0px" width="17" height="17" viewBox="0 0 314.068 314.068"><g><g id="_x33_56._Play"><g><path d="M293.002,78.53C249.646,3.435,153.618-22.296,78.529,21.068C3.434,64.418-22.298,160.442,21.066,235.534
                                    c43.35,75.095,139.375,100.83,214.465,57.47C310.627,249.639,336.371,153.62,293.002,78.53z M219.834,265.801
                                    c-60.067,34.692-136.894,14.106-171.576-45.973C13.568,159.761,34.161,82.935,94.23,48.26
                                    c60.071-34.69,136.894-14.106,171.578,45.971C300.493,154.307,279.906,231.117,219.834,265.801z M213.555,150.652l-82.214-47.949
                                    c-7.492-4.374-13.535-0.877-13.493,7.789l0.421,95.174c0.038,8.664,6.155,12.191,13.669,7.851l81.585-47.103
                                    C221.029,162.082,221.045,155.026,213.555,150.652z"></path></g></g></g></svg></span>
                                    {/* <a href="javascripy:void(0)">WATCH A VIDEO</a> */}
                                    <span className="pointer" onClick={()=>this.videoPopupHandle(0)}>WATCH A VIDEO</span>
                                    </div>
                                        <div className="col-sm-1"><span className="divider"></span></div>
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                                                <div className="col-sm-6 col-md-6 col-lg-6 col-xs-6">
                                                    {(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'buyer') ?
                                                        <button type="button" className="btn btn-default btn-primary" onClick={this.sellingclick}>START SELLING</button>
                                                        :
                                                        <Link to={(this.props.loggedIn && this.props.user && this.props.user.userdata.role === 'seller') ? "/dashboard/post" : (this.props.loggedIn) ? "/product/pocketListing" : '/register'}><button type="button" className="btn btn-default btn-primary" >START SELLING</button></Link>
                                                    }

                                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <img src="/images/home-ava.png" className="img-responsive" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        
        </div>

        <div className="container-fluid hiw karla com-ty banner-v">

            <div className="row hiw-tit lato">
                <div className="col-sm-12 txt-center">
                    <h2 className="mt-0"> We Put L.A. Buyers and Sellers In Control</h2>
                    <h4>The power of our network helps Buyers and Sellers find and close deals FAST </h4>
                </div>
            </div>
            
            <div className="learn-video">
                <div className="player text-center" wqcontrols>
                   
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/yyhfz7Ur-i8" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    {/* <video controls>
                        <source  src="./videos/1.mp4" type="video/mp4"/>
                    </video> */}
                </div> 
                <div class="services-bt flex">
                    <div class="text-center ss lt">
                        {loggedIn && user && user.role!=='buyer' 
                        ?  <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('buyer')}>BUY NOW</button>
                        :
                        <Link to="/search/buyer">
                            <button type="button" class="btn btn-default btn-primary btn-whi-ban karla mb-xs5">BUY NOW</button>
                        </Link>
                    }
                        <Link to="/infovideo/buyer"><h4 class="fw-600 d-smn">Learn More</h4></Link>
                    </div>
                    <div class="text-center rt">
                        
                        {loggedIn && user && user.role!='seller'
                                ? <button className="btn btn-default btn-primary" onClick={this.afterLoggedInmsg('seller')}>SELL NOW</button>
                                : 
                                <Link to={loggedIn?'/dashboard/post':"/productpost"}><button className="btn btn-default btn-primary">SELL NOW</button></Link> 
                            }
                        
                        <Link to="/infovideo/seller"><h4 class="fw-600">Learn More</h4></Link>
                    </div>
                </div>                         
            </div>                                            
        
        </div>                                            

        <div className="container-fluid bg-white">
            <div className="container blog-cnt lato">
                <div className="row blog-tit lato">
                    <div className="col-sm-12 txt-center">
                        <h2 className="mt-0"> Find Deals. Get More.<span className="d-b"></span> Pay Less. </h2>
                        {/* <h4>Our videos, blog posts, and quizzes provide the latest real estate tips and info.</h4> */}
                    </div>
                </div>
                <div className="identify-diff flex justify-between flex-wrap">
                    <div className="D-card">
                        <h4 className="karla">BUYERS WIN</h4>
                        <div className="card-body">
                            <ul>
                                <li><b>1)</b>Post Your Property Preferences</li>
                                <li><b>2)</b>Receive Listings from Real Sellers</li>
                                {/* <li>Set Your Asking Price and Connect with Qualified Buyers</li> */}
                                <li><b>3)</b>Tour listings and determine your Offer Price</li>
                                <li><b>4)</b>We handle everything from Offer to Closing</li>
                                <li className="D-fot text-center list txt-dark" style={{"justify-content": "center"}}>Get Cash Back for Closing Costs</li>
                            </ul>
                        </div>
                        <div className="card-fot">
                            <div class="services-bt flex">
                                <div class="text-center lt">
                                    {loggedIn && user && user.role!=='buyer' 
                                    ?
                                    <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('buyer')}>BUY NOW</button>
                                    :
                                    <a href="/search/buyer">
                                        <button type="button" class="btn btn-default btn-primary btn-whi-ban karla">BUY NOW</button>
                                    </a>
                                    }
                                    {/* <h4 class="fw-600"><Link to="/infovideo/buyer">Learn More </Link></h4> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="D-card">
                        <h4 className="karla">SELLERS PROFIT</h4>
                        <div className="card-body">
                            <ul>
                                <li><b>1)</b> Post  Your Pre-Listing</li>
                                <li><b>2)</b> Get a Free Property Inspection Report</li>
                                <li><b>3)</b> Set Your Asking Price and Notify Buyers</li>
                                <li><b>4)</b> We handle everything from Showings to Closing</li>
                                <li className="D-fot text-center list txt-dark"  style={{"height":"15px","justify-content": "center"}}>Commission As Low as 3%</li>
                                <li className="D-fot text-center list txt-dark" style={{"justify-content": "center"}} >Rebates on Closing Costs</li>
                            </ul>
                        </div>
                        <div className="card-fot">
                            <div class="services-bt flex">
                                <div class="text-center lt">
                                    {loggedIn && user && user.role !='seller'
                                    ? <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('seller')}>SELL NOW</button>
                                    :
                                    <Link to={loggedIn ? "/dashboard/post" : '/productpost'}>
                                        <button type="button" class="btn btn-default btn-primary btn-whi-ban karla">SELL NOW</button>
                                    </Link>
                                    }
                                    {/* <h4 class="fw-600"><Link to="/infovideo/seller">Learn More </Link></h4> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="D-card">
                        <h4 className="karla">REALTORS CONNECT</h4>
                        <div className="card-body">
                            <ul>
                                <li><b>1)</b>Post Your Listings</li>
                                <li><b>2)</b>Buyers Connect with you directly</li>
                                {/* <li>Make A Deal and Close Change final note to: Referral Fee Applies.Make The Realtor item. </li> */}
                                <li className="D-fot text-center list txt-dark" style={{"justify-content": "center"}}>Referral Fee Applies</li>
                            </ul>
                        </div>
                        <div className="card-fot">
                            <div class="services-bt flex">
                                <div class="text-center lt">
                                {loggedIn && user && user.role !='realtor' 
                                ?  <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('realtor')}>SELL NOW</button>
                                :
                                <Link to={loggedIn ? "/realtorproductpost" : '/productpost'}>
                                    <button type="button" class="btn btn-default btn-primary btn-whi-ban karla">SELL NOW</button>
                                </Link>
                            }
                                    {/* <h4 class="fw-600"><Link to="/infovideo/realtor">Learn More </Link></h4> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="D-card">
                        <h4 class="text-uppercase karla">Investors Thrive</h4>
                        <div className="card-body">
                            <ul>
                                <li><b>1)</b>Post your wholesale contract or trust deed</li>
                                <li><b>2)</b>Find and notify potential Buyers</li>
                                <li><b>3)</b>Negotiate with interested Buyers directly</li>
                                <li className="D-fot text-center list txt-dark" style={{"justify-content": "center"}}>Subscription Fee Applies</li>
                            </ul>
                        </div>
                        <div className="card-fot">
                            <div class="services-bt flex">
                                <div class="text-center lt">
                                {loggedIn && user && user.role !='seller'
                                    ? <button type="button" class="btn btn-default btn-primary btn-whi-ban karla" onClick={this.afterLoggedInmsg('seller')}>SELL NOW</button>
                                    :
                                    <Link to={loggedIn ? "/dashboard/post" : '/productpost'}>
                                        <button type="button" class="btn btn-default btn-primary btn-whi-ban karla">SELL NOW</button>
                                    </Link>
                                    }
                                    {/* <h4 class="fw-600"><Link to="/infovideo/seller">Learn More </Link></h4> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center"> <h4 class="fw-600"><Link to="/infovideo/seller">Learn More </Link></h4></div> 
            </div>
        </div>

        <div className="container-fluid bg-light">
            <div className="container lato services-cnt">
                <div className="row serv-tit lato">
                    <div className="col-sm-12 txt-center">
                        <h2 className="text-capitalize mt-0">You find the deal </h2>
                        <h4>Our Team Handles the Rest</h4>
                    </div>
                </div>

                <div className="row services">
                    <Link to='/ourteam'>
                        <div className="col-sm-4 serv-data">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-xs-3">
                                <span><embed src="./images/icons/services-1.svg" type="image/svg+xml"></embed>
                                   
                                    </span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>TOP LOCAL AGENTS</h4>
                                <p>You find the deal. Our Local real estate pros will prepare all the contracts, provide support and insure an efficient, win-win result.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/ourteam'>                              
                    <div className="col-sm-4 serv-data">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-xs-3">
                                <span><embed src="./images/icons/escrow.svg" type="image/svg+xml"></embed></span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>ESCROW & LEGAL</h4>
                                <p>Our expert partners make sure every "i" is dotted and "t" is crossed, so your deal is closed professionally and transparently.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/ourteam'> 
                    <div className="col-sm-4 serv-data im-3">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-xs-3">
                                <span><embed src="./images/icons/services-3.svg" type="image/svg+xml"></embed></span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>HOME INSPECTIONS </h4>
                                <p>It's good to know what you're buying (or selling). Our preferred pros provide detailed reports on demand.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="row services">
                <Link to='/ourteam'> 
                    <div className="col-sm-4 serv-data">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-xs-3">
                                <span><embed src="./images/icons/lending.svg" type="image/svg+xml"></embed></span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>LENDING</h4>
                                <p>Solid Lending can make or break 
                                    a deal. Our preferred lenders 
                                    identify issues and provide 
                                    solutions, saving everyone time 
                                    and money.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/ourteam'> 
                    <div className="col-sm-4 serv-data">
                        <div className="row">
                            <div className="col-sm-3 col-md-3 col-xs-3">
                                <span>
                                <embed src="./images/icons/insurance.svg" type="image/svg+xml"></embed>
                                </span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>INSURANCE</h4>
                                <p>Your big investment needs 
                                    protection. Our insurance pros 
                                    will find you the policy to suit 
                                    your specific needs.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                    <Link to='/ourteam'>
                    <div className="col-sm-4 serv-data im-3">
                        <div className="row">
                            <div className="col-sm-3 col-xs-3 col-md-3">
                                <span>
                                   <h2>?</h2>
                                </span>
                            </div>
                            <div className="col-sm-9 col-md-9 col-xs-9">
                                <h4>ANYTHING ELSE?</h4>
                                <p>Sometimes needs arise unexpectedly. 
                                    Rest easy. Whatever the issue, our partner
                                    providers have you covered.</p>
                            </div>
                        </div>
                    </div>
                    </Link>
                </div>

                <div className="services-bt ss flex">
                    

                    <div className="text-center lt">
                        {/* <h4 className="fw-600">BUYERS</h4> */}
                        {(this.props.loggedIn && this.props.user && this.props.user.userdata.role==='buyer') 
                            ?
                            <Link to='/search/buyer'><button type="button" className="btn btn-default btn-secondary">BUY NOW</button></Link> 
                            : 
                            (this.props.loggedIn) 
                                ?
                                <button type="button" className="btn btn-default btn-secondary" onClick={this.afterLoggedInmsg('buyer')}>BUY NOW</button>
                                :
                                <Link to='/search/buyer'><button type="button" className="btn btn-default btn-secondary">BUY NOW</button></Link>
                        }
                    </div>

                    <div className="text-center rt">
                        {/* <h4 className="fw-600">SELLERS</h4> */}
                        {(this.props.loggedIn && this.props.user && (this.props.user.userdata.role==='buyer' || this.props.user.userdata.role==='realtor')) ?
                            <button type="button" className="btn btn-default btn-secondary" onClick={this.afterLoggedInmsg('seller')}>SELL NOW</button> : 
                            <Link to={(this.props.loggedIn && this.props.user && this.props.user.userdata.role==='seller')?"/dashboard/post":(this.props.loggedIn)?"/product/pocketListing":"/productpost"}>
                                <button type="button" className="btn btn-default btn-secondary">SELL NOW</button></Link>
                        }
                    </div>
                </div>

            </div>
        </div>
         {/* {testimonalDisplay && testimonalDisplay.length>0 ? 
        <div className="our-client">
            <div className="client-cmt">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 txt-center ">
                            <h2 className="client-review">What Clients Are Saying</h2>                        
                        </div>
                        <div className="col-md-12 flex justify-between client-sec">
                            {testimonalDisplay && testimonalDisplay.length>0 && testimonalDisplay.map((element,index)=>{
                                return <div className="client-card" key={"client-card-"+index}>
                                <div className="star">
                                        <Box component="fieldset" mb={element.starrating} borderColor="transparent">
                                            <Rating value={Number(element.starrating)} precision={0.5} readOnly />
                                        </Box>
                                </div>
                                    <p className="karla">{element.testimonal}</p>
                                <div className="flex">
                                    <figure>
                                        <img src={`${url}uploads/testimonials/${element.avatar}`} alt="" className="img-responsive"/>
                                    </figure>
                                    <div className="snd-card">
                                        <b className="karla">{element.name}</b>
                                        <p>{element.designation} at <span>{element.companyname}</span></p>
                                    </div>
                                </div>
                            </div>
                            })}
                            
                        </div>
                            <div className="nav-dots col-md-12">
                                {this.state.orginaltestimonaldata.length>0 && this.state.orginaltestimonaldata.map((element,index)=>{
                                   return <span key={"cd-"+index} className={index===this.state.activeclassindex ? "c-1 active" : "c-1" } onClick={this.testimonalScroll(index)}></span>
                                })}
                            </div>
                    </div>
                </div>
            </div>
        </div>
         : ''
        }                */}

        <div className="container-fluid bg-sf-light hidden">
            <div className="container blog-cnt lato">
                <div className="row blog-tit lato">
                    <div className="col-sm-12 txt-center">
                        <h2>Get More. Pay Less.</h2>
                        {/* <h4>Our videos, blog posts, and quizzes provide the latest real estate tips and info.</h4> */}
                    </div>
                </div>
                <div className="row ">
                    <div className="col-sm-12 col-md-12 pad-0">
                        <Blog />
                    </div>
                </div>
            </div>
        </div>

        <div className="fixed-bottom contus" onClick={this.focusOffer('contact')}>
            <div className="after-class">
            <h4>Contact US</h4>
            </div>
        </div>

        {this.state.featuredProperty.length > 0 ? 
            <div className="row"><FeatureProperty FeatureProperty = {this.state.featuredProperty} url = {url}/></div> 
        : '' }
        <ContactUs />
        {this.state.pop ? <Popup color="black"
                                title="Start Selling"
                                description = {this.state.msg}
                                // testBox={true}
                                // yesTo="Cancel" // btn 1
                                // noTo="Accept" // btn 2
                                // btnCount={2} // 1 or 2
                                popupHandle={this.popupHandle}
                                // extradata={{id:this.state.counterId}}
                            /> : ''}
         {this.state.pop1 ? <Popup color="black"
                                title="Subscribe"
                                description = {this.state.msg1}
                                // testBox={true}
                                yesTo="Pay Now" // btn 1
                                // noTo="Accept" // btn 2
                                btnCount={1} // 1 or 2
                                popupHandle={this.popupHandle1}
                                // extradata={{id:this.state.counterId}}
                            /> : ''}

        {this.state.videoPop && <VideoPopup videoPopupHandle={this.videoPopupHandle}/>}
        { this.state.stripeModal? 
                    <Stripe 
                        project_id={0} 
                        onChangeHandle={this.StripeBoxHandle}
                        StripeBoxCloseHandle={this.StripeBoxCloseHandle}
                        amount={50000}
                        subscription={true}
                        dispatch = {this.props.dispatch}
                        PaypalSubscribeModel = {true}
                    /> 
               :""} 
      
      </main>
    )
  }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Home);