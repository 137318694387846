import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import './Register.css';
import Tooltip from '@material-ui/core/Tooltip';
import {connect} from 'react-redux';
import axios from 'axios';
import us_states from '../../util/us_states'
import { withStyles } from "@material-ui/core/styles";
import { NOTIFICATION } from  '../../actions/index';
import Autocomplete from '../../components/Autocomplete/Autocomplete';
import Popup from '../../components/Popup/Popup'
const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#6A6A6A",
      color:"white",
      width: 300,
      height: 75,
      fontSize: theme.typography.pxToRem(22),
      border: "1px solid #dadde9",
      
    }
  }))(Tooltip);
class Register extends Component {
    state = {
        firstName : {
            data: "",
            required: true,
            errorMsg: "Required",
            role:"all",
        },
        lastName : {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        username : {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        role : {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Please select role."
        },
        email: {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        emailrepeat: {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        userPhone: {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        password: {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Required"
        },
        confirmpassword: {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Required"
        },
        saletype: {
            data:[],
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        sellertype: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        propertycondition: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        pricerangemax: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        pricerangemin: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        propertype: {
            data: [],
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        location: {
            data: [],
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        bathroom: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        bedroom: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        min_property_sq_footage: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        min_land_sq_footage: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        propertyzone: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        purchasetimeline: {
            data: "",
            role:"buyer",
            required: false,
            errorMsg: "Required"
        },
        letter_obtained: {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Required"
        },
        pre_approval_letter: {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Required"
        },
        buyeragreement1: {
            data: "",
            role:"all",
            required: false,
            errorMsg: "Required",
            changed:false
        },
        buyeragreement2: {
            data: "",
            role:"all",
            required: true,
            errorMsg: "Required"
        },
        selleraggrement: {
            data: "",
            role:"seller",
            required: false,
            errorMsg: "Required",
            changed:false
        },
        realtoraggrement: {
            data: "",
            role:"seller",
            required: false,
            errorMsg: "Required",
            changed:false
        },
        site_id:  {
            data: "AS0001",
        },
        hasRealtor:{
            data: "",
            required: false,
            role:'buyer',
            errorMsg: "Required"
        },
        dualAgent:{
            data: "",
            required: false,
            errorMsg: "Required"
        },
        agency_affiliation:{
            data: "0",
            required: true,
            errorMsg: "Required"
        },
        license_number :{
            data: "",
            required: false,
            errorMsg: "Required"
        },
        selectedFile: null,
        error:'',
        required: {},
        buyeragreement3:'',
        buyerFormErrorCount:0,
        formError: {},
        buyerpopup:false,
        selectedOptionClass : "",
        termsToggle:false,
        termsMsg : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi vero dignissimos eius officia illum. Perspiciatis distinctio maxime quo, officiis dignissimos delectus sed fuga aspernatur iusto nisi nihil modi placeat nesciunt.",
        message:"Dual Agency means ONE Agent or Broker manages the entire transaction, on behalf of both the Buyer and the Seller, not advocating for, or favoring one party over the other.  This is different than a traditional Cooperating Broker transaction, where each side is represented by their own Agent or Broker.",
        location_arr : [
            "All Areas",
            "LA - Downtown", 
            "LA - South LA", 
            "LA - Mid City, Beverly Hills, Culver City",
            "LA - Hollywood, East Hollwood, WeHo",
            "LA - Westside, Santa Monica, Beaches", 
            "LA - North, Glendale, Highland Park, Pasadena, etc", 
            "LA - Eastern Cities",
            "SF East - NoHo, Burbank, Sunland, etc",
            "SF South- Universal City, Studio City, Van Nuys, Encino, Sherman Oaks etc.", 
            "SF Central - Van Nuys, Sepulveda, Reseda, etc",
            "SF North - North Hills, Granada Hills, Northridge, etc.", 
            "SF West - Woodland Hills, Tarzana, Chatsworth, Calabasas, etc.",
            "SB- Inglewood, El Segundo, Hawthorn",
            "SB - West, Beach Cities",
            "SB - Central, Carson, Torrance, etc.",
            "SB - South, Palos Verdes, Rolling Hills, San Pedro, etc",
            "LONG BEACH AREA",  
            "ORANGE COUNTY",
            "RIVERSIDE COUNTY",
            "CANYON COUNTRY AREA",
            "SANTA CLARITA/VALENCIA AREA",
            "PALMDALE AREA",
            "LANCASTER AREA"
        ],
    }

    userBaseHandle = () => {
        let self = this;
        let preState = self.state;
        console.log(preState)
        Object.keys(preState).map((key,index) => { 
            if(preState[key] && preState[key].role){
                if(preState[key].role === "buyer")
                    {
                        console.log(key)
                        preState[key].required = preState.buyerpopup === false ? false : true;
                        delete preState.formError[key];
                    }
                    
            }
        })  
        self.setState(preState,()=>{
            this.handleValidation()
        });
    }

    handleValidation = async () =>{
        let self = this;
        self.state.buyerFormErrorCount = 0;
        Object.keys(this.state).map(function(key) {
            let condition = ((key === 'buyeragreement1' && self.state.role.data ==='buyer') || (key === 'buyeragreement2' && self.state.role.data ==='buyer') || (key=== 'selleraggrement' && self.state.role.data ==='seller') || (key==='realtoraggrement' && self.state.role.data ==='realtor'))
            if(condition)
            {
                console.log('\n \n key : \n \n '+key+'\n \n '+self.state[key].changed)
                if(!self.state[key].changed){
                    self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : 'Required'
                }

            }

            if(self.state[key] && self.state[key].required && self.state[key].data === ""){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
                if( self.state[key].role === "buyer" ) {
                    self.state.buyerFormErrorCount++;
                 };
            }
            self.setState({
                formError: self.state.formError,
                buyerFormErrorCount : self.state.buyerFormErrorCount
            },()=>{console.log(self.state.formError)})
        });
        return false
    }

    formSubmit(e) {
        e.preventDefault();
        this.handleValidation();
        const data = new FormData()
        let self = this;
        if ((Object.keys(self.state.formError)).length > 0 ){
            //this.props.dispatch(NOTIFICATION({msg:"Fill the mandatory fields"}))
            return false;
        }
        Object.keys(self.state).map(function(key) { 
            return (self.state[key]) ? data.append(key,(self.state[key].data)) : '';
        });
        if(this.state.selectedFile){
            
            data.append('taxexcempt_file', this.state.selectedFile, this.state.selectedFile.name);
        }
       
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/preregister', data)
            .then(function (response) {
                if (response.data.success == "no") {
                    self.setState({
                        error: response.data.failedreason
                    })
                    return false;
                }
                self.props.dispatch(NOTIFICATION({msg:"Pre Registration Successful"}))
                self.props.history.push("/");
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });
                let email = {email:this.state.email};
                url=url+"mobileapi/checkvalidemail"
                axios.post(url,email).then((response)=>{
                  if(response.data.success !=="no"){
                    // this.setState({error:response.data.message,classname:"alert alert-danger"})
                    this.state.formError['email'] = 'This email already exists. Please use different email';
                }
                }).catch((error)=>{
                  console.log(error)
                })
              

            
    }

    handleChange = name => event => {
        let preState = this.state[name];
        let r_state = this.state['realtoraggrement'];
        let s_state = this.state['selleraggrement'];
        let b1_state = this.state['buyeragreement1'];
        let b2_state = this.state['buyeragreement2'];
        if(this.state.formError[name])
            delete this.state.formError[name]
        if(event.target.type != 'checkbox'){
            r_state.required = false;
            s_state.required = false;
            b1_state.required = false;
            b2_state.required = false;
            delete this.state.formError['realtoraggrement'];
            delete this.state.formError['selleraggrement']
            delete this.state.formError['buyeragreement1']
            delete this.state.formError['buyeragreement2']
            
        }else{
            event.target.value = (event.target.checked)?event.target.checked:""
            preState.data = event.target.value;
            if(event.target.checked){
                preState.required = true;
            } 
        }
        switch(name){
            case 'propertype' :
                delete this.state.formError['location']
            case 'location'   :
                delete this.state.formError['propertype']
                let limit = (name==='location') ? 3 : 2
                if(this.state.formError[name])
                    delete this.state.formError[name]
                    
                if(preState.data.length<=limit){
                    if(preState.data.indexOf(event.target.value)<0)
                    {
                        this.state.selectedOptionClass = "selectedOption"
                        preState.data.push(event.target.value)
                    }
                }
                else
                    this.state.formError[name] = (limit === 3) ? "You Can only choose up to four types" : "You Can only choose up to three types" 
            break;
            case 'saletype':
                delete this.state.formError['saletype']
                if(preState.data.length<=3){
                    if(preState.data.indexOf(event.target.value)<0)
                    {
                        this.state.selectedOptionClass = "selectedOption"
                        preState.data.push(event.target.value)
                    }
                }
            break;
            default :
                preState.data = event.target.value;
                this.state.selectedOptionClass = ""
                delete this.state.formError['propertype']
                delete this.state.formError['location']
            break;
        }
        if(name === 'agency_affiliation')
            this.state.license_number.required='true';

        if(name==='role'){
            s_state.required = false;
            r_state.required = false;
            b1_state.required = false;
            b2_state.required = false;
            if(event.target.value ==='seller'){
                s_state.required = true;
            }
            if(event.target.value ==='realtor'){
                r_state.required = true;
            }
            if(event.target.value ==='realtor'){
                b1_state.required = true;
            }
        }
        let checked = event.target.checked;
        let condition = (name === 'buyeragreement1' && checked) || (name === 'buyeragreement2' && checked) || (name === 'selleraggrement' && checked)||(name === 'realtoraggrement' && checked)
        if(condition){
            preState.changed = true
        }else{
            preState.changed = false
        }
        this.setState({
            [name]: preState,
            realtoraggrement : r_state,
            selleraggrement : s_state,
            buyeragreement1 : b1_state,
            // buyeragreement3:b1_state,
            formError: this.state.formError,
            buyerpopup : (event.target.value === 'buyer' && event.target.type === 'select-one') ? true : this.state.buyerpopup,
            dualmsgpop:false,
            error: '',
            selectedOptionClass : this.state.selectedOptionClass,
            license_number:this.state.license_number
        },()=>{
            if(this.state.buyerpopup){
                document.body.style.overflow = "hidden";
            } 
        });
    };

    handleselectedFile = event => {
        this.setState({
            selectedFile: event.target.files[0],
        })
    }
    setshows = () =>{
    this.setState({
        dualmsgpop:true
    })
    }
    buyerpopupclose = () =>{
        document.body.style.overflow = "auto";
        let role = this.state.role
            role["data"] = "";
        this.setState({
            buyerpopup:false,
            role: role
        }, async ()=>{
            await this.userBaseHandle();
        }); 
    }
    buyerpopupSubmit = async () =>{
        document.body.style.overflow = "auto";
        await this.userBaseHandle();
        console.log(this.state.buyerFormErrorCount)
        if (this.state.buyerFormErrorCount === 0)
            this.setState({
                buyerpopup:false
            }); 
    }
    
    removeFromArray = (name,ind) => event =>{
        
        let prestate = this.state[name]
        let limit = (name==="location") ? 4 : 3
        if(prestate.data.length <= limit){
            if(this.state.formError[name])
                delete this.state.formError[name]
        }
        prestate.data.splice(ind,1)
        this.setState({
            [name] : prestate
        })
    } 
    priceBlur = (pricerange) => event => {
        let minPrice = this.state.pricerangemin.data ? this.state.pricerangemin.data : 0,
            maxPrice = this.state.pricerangemax.data ? this.state.pricerangemax.data : 0;
        switch (pricerange) {
            case 'pricerangemin':
                if (maxPrice && Number(minPrice) > Number(maxPrice)) {
                    this.state.formError['pricerangemin'] = 'Minimum price should be lesser than Maximum price';
                }
                break;
            case 'pricerangemax':
                if (minPrice && Number(minPrice) > Number(maxPrice)) {
                    this.state.formError['pricerangemax'] = 'Maximum price should be greater than Minimum price';
                }
                break;
            default:
                break;
        }
        this.setState({
            formError: this.state.formError
        })
    }

    onPlaceChanged = (e) => {
        let data = e.target.value
        const preState = this.state.location;
        if (preState.data.indexOf(data) < 0) {
            this.state.selectedOptionClass = "selectedOption";
            // data = data.replace(/,/g," ")
            preState.data.push(data); 
        }
        this.setState({
            location: preState,
            selectedOptionClass: this.state.selectedOptionClass,
        });
    }
    termsToggle=()=>{
        this.setState({termsToggle:true})
    }
    popupHandle=()=>{
        this.setState({termsToggle:false})
    }
    popupHandle=()=>{
        this.setState({dualmsgpop:false})
    }
    handleClick = (name, flag) => event => {
        if (this.state.formError[name]) {
            delete this.state.formError[name]
        }
        let prestate = this.state[name];
        let dualAgent = this.state.dualAgent;
        dualAgent.required = (name === 'hasRealtor' && flag === 'y') ? true : false
        prestate.data = flag;
        this.setState({
            [name]: prestate,
            dualAgent: dualAgent,
            formError: this.state.formError
        })
    }
    handleChangeCheckbox = (name) => event =>{
        let formError = this.state.formError
        let preState = this.state[name]
        let checked = event.target.checked;
        let name = name;
        let condition = (name === 'buyeragreement1' && checked)||(name === 'selleraggrement' && checked)||(name === 'realtorggrement' && checked)
        if(condition){
           formError[name] = preState.errorMsg
        }
        formError = formError[name] ? delete formError[name] : formError;
        this.setState({
            [name] : preState,
            formError : formError
        });
    }
    render() {
        let {location_arr} = this.state
        return (
            <main>
                <div className="center-part">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="googly-img">
                                <figure className="reg-hgt">
                                    <img src="./images/googly.png" alt="googly" className="img-responsive" />
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="alpha-form">
                                <form className="pad-55 elty" onSubmit={this.formSubmit.bind(this)}>
                                    <div className="top-hd reg-hd">
                                        <h4>Join The Community</h4>
                                    </div>
                                    <div className="form-group">
                                        <label >First Name</label>
                                        <input type="text" 
                                            name="firstName" 
                                            className="form-control border" 
                                            id="firstName" 
                                            placeholder="Enter Your First Name" 
                                            value={this.state.firstName.data} 
                                            onChange={this.handleChange('firstName')}
                                        />
                                        <span style={{color: "red"}}>{this.state.formError["firstName"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <label >Last Name</label>
                                        <input type="text" 
                                            name="lastName" 
                                            className="form-control border" 
                                            id="lastName" 
                                            placeholder="Enter Your Last Name" 
                                            value={this.state.lastName.data} 
                                            onChange={this.handleChange('lastName')}
                                        />
                                        <span style={{color: "red"}}>{this.state.formError["lastName"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <label >User Name</label>
                                        <input type="text" 
                                            name="username" 
                                            className="form-control border" 
                                            id="username" 
                                            placeholder="Enter Your Name" 
                                            value={this.state.username.data} 
                                            onChange={this.handleChange('username')}
                                        />
                                        <span style={{color: "red"}}>{this.state.formError["username"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <label >Email Address</label>
                                        <input type="email" 
                                            name="email" 
                                            className="form-control border" 
                                            id="email" 
                                            placeholder="Enter Your Emailss" 
                                            value={this.state.email.data} 
                                            onChange={this.handleChange('email')}
                                        />
                                        <span style={{color: "red"}}>{this.state.formError["email"]}</span>
                                    </div>
                                    <div className="form-group">
                                        <label >Confirm Email Address</label>
                                        <input type="email" name="emailrepeat" className="form-control border" id="emailrepeat" value={this.state.emailrepeat.data} onChange={this.handleChange('emailrepeat')}
                                            placeholder="Retype Your Email" />
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        <input type="number" name="userPhone" className="form-control border" id="userPhone" value={this.state.userPhone.data} onChange={this.handleChange('userPhone')}
                                            placeholder="Enter Your Phone Number" />
                                        <span style={{color: "red"}}>{this.state.formError["userPhone"]}</span>      
                                    </div>
                                    {/* <div className="form-group">
                                        <label >Password</label>
                                        <input type="password" name="password" className="form-control border" id="password" value={this.state.password.data} onChange={this.handleChange('password')}
                                            placeholder="Enter Your Password" />
                                        <span style={{color: "red"}}>{this.state.formError["password"]}</span>     
                                    </div>
                                    <div className="form-group">
                                        <label >Confirm Password</label>
                                        <input type="password" name="confirmpassword" className="form-control border" id="confirmpassword" value={this.state.confirmpassword.data} onChange={this.handleChange('confirmpassword')}
                                            placeholder="Retype Your Password" />
                                        <span style={{color: "red"}}>{this.state.formError["confirmpassword"]}</span>    
                                    </div>
                                    {this.state.role.data === 'realtor'?<div className="form-group">
                                        <label >Agency Affiliation</label>
                                        <select className="form-control border"  id="type" name="role" value={this.state.agency_affiliation.data} onChange={this.handleChange('agency_affiliation')}>
                                            <option name="agency_affiliation" value="1">Yes</option>
                                            <option name="agency_affiliation" value="0">No</option>
                                            
                                        </select>
                                        <span style={{color: "red"}}>{this.state.formError["agency_affiliation"]}</span>
                                    </div>:''}
                                    {this.state.agency_affiliation.data === '1' ? <div className="form-group">
                                        <label >License number</label>
                                        <input type="text" name="license_number" className="form-control border" id="license_number" value={this.state.license_number.data} onChange={this.handleChange('license_number')}
                                            placeholder="Enter your License number" />
                                        <span style={{color: "red"}}>{this.state.formError["license_number"]}</span>    
                                    </div> : ''}
                                    
                                    
                                    <div className="form-group">
                                        <label >Select Role</label>
                                        <select className="form-control border"  id="type" name="role" value={this.state.role.data} onChange={this.handleChange('role')}>
                                            <option name="Buyer" value="">I am a</option>
                                            <option name="Buyer" value="buyer">Buyer</option>
                                            <option name="Seller" value="seller">Seller</option>
                                            <option name="Realtor" value="realtor">Realtor</option>
                                        </select>
                                        <span style={{color: "red"}}>{this.state.formError["role"]}</span>
                                    </div>
                                    { this.state.role.data === "buyer"? <div className="buyer" id="buyer">
                                        <div className="checkbox">
                                            <label className="show-check mar-tb-7">I agree to the <a target="_blank"   href="/terms">Terms of Service</a> which includes being represented by the real estate broker assigned by DoorRise for any transaction engaged in using this platform.
                                                <input className="check-height" type="checkbox" name="buyeragreement1" value={this.state.buyeragreement1.data} onChange={this.handleChange('buyeragreement1')}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            
                                        </div>
                                        <span style={{color: "red"}}>{this.state.formError["buyeragreement1"]}</span>
                                        <div className="checkbox ">
                                            <label className="show-check mar-tb-7"> I understand that, in order to make offers on listings or bid on auctions, I must obtain proof of mortgage pre-approval from an NMLS-licensed lender and provide proof of available closing funds. 
                                            {/* <span className="text-gray">(Optional)</span> */}
                                                {/* <input className="check-height" type="checkbox" name="buyeragreement2" value={this.state.buyeragreement2.data} onChange={this.handleChange('buyeragreement2')}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            
                                        </div>
                                        <span style={{color: "red"}}>{this.state.formError["buyeragreement2"]}</span>
                                    
                                    <div className="checkbox">
                                    <label className="show-check mar-tb-7">I would like help obtaining mortgage pre-approval. Please contact me.  
                                    
                                        <input className="check-height" type="checkbox" name="buyeragreement3" value={this.state.buyeragreement3}/>
                                        <span className="checkmark"></span>
                                    </label>
                                    
                                </div>
                                
                            </div> : '' }
                                    { this.state.role.data === "seller"?  <div className="" id="seller">
                                        <div className="checkbox">
                                            <label className="show-check mar-tb-7">&nbsp;&nbsp;Sellers agree to the <a  target="_blank"   href="/terms">Terms of Service</a>, including exclusively using Doorrise's preferred real estate broker for any listing posted on this site.
                                                <input className="check-height" type="checkbox" 
                                                name="selleraggrement" 
                                                value={this.state.selleraggrement.data} 
                                                onChange={this.handleChange('selleraggrement')}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            <span style={{color: "red"}}>{this.state.formError["selleraggrement"]}</span>
                                        </div>
                                    </div> :'' }
                                    { this.state.role.data === "realtor" ? <div className="" id="realtor">
                                        <div className="checkbox">
                                            <label className="show-check mar-tb-7">&nbsp;&nbsp; Realtors agree to <a  target="_blank"   href="/terms">Terms of Service</a>, including working with Doorrise's preferred real estate broker and paying the cooperating broker commission listed on the MLS if a Buyer is secured for a listing posted on this site.&nbsp;&nbsp; 
                                                <input className="check-height" type="checkbox" 
                                                 name="realtoraggrement" 
                                                 value={this.state.realtoraggrement.data} 
                                                 onChange={this.handleChange('realtoraggrement')}/>
                                                <span className="checkmark"></span>
                                            </label>
                                            <span style={{color: "red"}}>{this.state.formError["realtoraggrement"]}</span>
                                        </div>
                                    </div> :"" }*/}
                                    {this.state.error ?
                                    <div className="alert alert-danger" role="alert">
                                        {this.state.error}
                                    </div>
                                    : ''}      
                                    <div className="form-group text-center pad-btm-15 pad-top-15">
                                        <button type="submit" className="btn btn-secondary">Pre-register</button>
                                    </div>
                                    <div className="form-group link-redir text-center ">
                                        <p className="text-gray">Already have an account with us?</p>
                                        <Link className="text-light fs-16"to="/login"><b >Sign In</b></Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.buyerpopup ?
                <div id="buyer" className="popup">
                    {/* <div className="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"> */}
                        <div className="modal-dialog modal-lg" role="document">
                            <div className="modal-content show-model">
                                <div className="modal-header">
                                    <button type="button" className="close" onClick={this.buyerpopupclose} data-dismiss="modal" aria-label="Close"><span  aria-hidden="true">&times;</span></button>
                                    <h3 className="text-center karla fw-600"><span>SET UP YOUR PROPERTY PREFERENCES</span></h3>
                                </div>
                                <div className="modal-body">
                                    <div className="">
                                        <div className="content-main pad-30">
                                            <div className="site-form">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                       
                                                        <div className="form-group selectdiv">
                                                            <label >Preferred Seller Type(s)</label>
                                                            <select  className="form-control site-input" 
                                                            id="" name="sellertype" 
                                                            value={this.state.sellertype.data} 
                                                            onChange={this.handleChange('sellertype')}>
                                                                <option value="">Choose the type of Seller</option>
                                                                <option value="Any">Any</option>
                                                                <option value="Private Owner">Private Owner </option>
                                                                <option value="Pocket Listing">Pocket Listing </option>
                                                                {/* <option value="REO">REO </option> */}
                                                                <option value="Investor/Institution">Investor/Institution </option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["saletype"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                            <label >Preferred Condition</label>
                                                            <select  className="form-control site-input" id="" name="propertycondition" value={this.state.propertycondition.data} onChange={this.handleChange('propertycondition')}>
                                                                <option value="">Choose a condition of the Property</option>
                                                                <option value="Any">Any </option>
                                                                <option value="Excellent - Completely Updated">Excellent - Completely Updated </option>
                                                                <option value="Good - Moderate updates">Good - Moderate updates </option>
                                                                <option value="Average - Few updates. Mostly Original">Average - Few updates. Mostly Original </option>
                                                                <option value="Poor - Repairs and updates needed">Poor - Repairs and updates needed</option>
                                                                <option value="Investor grade - major repairs and updates needed">Investor grade - major repairs and updates needed </option>
                                                                <option value="Land Value only">Land Value only </option>
                                                                <option value="Development Deal">Development Deal </option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertycondition"]}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Preferred Search Location(s)</label>
                                                           {/* <Autocomplete onPlaceChanged={this.onPlaceChanged} />  */}
                                                                <select
                                                                    name = "location"
                                                                    className="form-control site-input" id="propsqfootage"
                                                                    value={this.state.location}
                                                                    defaultValue={"" }
                                                                    onChange={this.onPlaceChanged}
                                                                    placeholder={"Search by location"}
                                                                    type="text" 
                                                                >
                                                                {location_arr.map((x,y) => <option key={y}>{x}</option>)}
                                                                 </select>  
                                                            <div className="pan-block">
                                                            {this.state.location.data.length > 0 && this.state.location.data.map((ele,ind)=>{
                                                                return <span className="token close" data-dismiss="modal" aria-label="Close">
                                                                    {ele} 
                                                                    <span aria-hidden="true" onClick={this.removeFromArray('location',ind)}>
                                                                        <i className="fas fa-times"></i>
                                                                    </span>
                                                                </span>
                                                            })}
                                                            </div>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["location"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Price Range</label>
                                                            <div className="flex prc-rang">
                                                                <div className="form-inline ">
                                                                    <div className="form-group">
                                                                        <div className="input-group ">
                                                                            <input type="text" className="form-control site-input" id="pricerangemin" name="pricerangemin" value={this.state.pricerangemin.data} onChange={this.handleChange('pricerangemin')} onBlur={this.priceBlur('pricerangemin')}
                                                                                placeholder="Min" />
                                                                            <div className="input-group-addon">$</div>
                                                                        </div>
                                                                    </div>
                                                                    <span style={{color: "red"}}>{this.state.formError["pricerangemin"]}</span>
                                                                </div>
                                                                <div className="form-inline">
                                                                    <div className="form-group sha-none">
                                                                        <label className="karla" >To</label>
                                                                    </div>
                                                                </div>
                                                                <div className="form-inline">
                                                                    <div className="form-group">
                                                                        <div className="input-group">
                                                                            <input type="text" className="form-control site-input" id="pricerangemax" name="pricerangemax" value={this.state.pricerangemax.data} onChange={this.handleChange('pricerangemax')} onBlur={this.priceBlur('pricerangemax')}
                                                                                placeholder="Max" />
                                                                            <div className="input-group-addon">$</div>
                                                                        </div>
                                                                    </div>
                                                                    <span style={{color: "red"}}>{this.state.formError["pricerangemax"]}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Bedroom(s)</label>
                                                            <select className="form-control site-input" id="" 
                                                            name="bedroom" 
                                                            value={this.state.bedroom.data} onChange={this.handleChange('bedroom')}>
                                                                <option value="">Choose the number of bedroom</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10 or more">10 or more</option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["bedroom"]}</span>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Bathroom(s)</label>
                                                            <select className="form-control site-input" id="" name="bathroom" 
                                                            value={this.state.bathroom.data} 
                                                            onChange={this.handleChange('bathroom')}>
                                                                <option value="">Choose the number of bathroom</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10 or more">10 or more</option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["bathroom"]}</span>
                                                    </div>
                                                    
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Min. Living Area Required (sq ft)</label>
                                                            <input type="number" 
                                                                className="form-control site-input" 
                                                                id="propsqfootage" 
                                                                name="min_property_sq_footage" 
                                                                value={this.state.min_property_sq_footage.data}
                                                                onChange={this.handleChange('min_property_sq_footage')}
                                                                placeholder="Min. Living Area (sq ft)"
                                                                min={100}
                                                                max={10000}
                                                            />
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["min_property_sq_footage"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Min. Lot size Required (sq. ft)</label>
                                                            <input type="number" className="form-control site-input" id="propsqfootage" 
                                                            name="min_land_sq_footage" 
                                                            value={this.state.min_land_sq_footage.data}
                                                            onChange={this.handleChange('min_land_sq_footage')}
                                                            placeholder="Min. Land Area (sq. ft)"
                                                            min={100}
                                                            max={30000}
                                                             />
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["min_land_sq_footage"]}</span>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Preferred Property Zoning</label>
                                                            <select name="" className="form-control site-input" id="" 
                                                            name="propertyzone" 
                                                            value={this.state.propertyzone.data} 
                                                            onChange={this.handleChange('propertyzone')}>
                                                                <option value="">Select Preferred Property Zoning</option>
                                                                <option value="0">SFR</option>
                                                                <option value="1">Multi-Family 1-4 </option>
                                                                <option value="2">Multi-Family 5 or More</option>
                                                                <option value="3">Mixed Use</option>
                                                                <option value="4">Commercial</option>
                                                                <option value="5">Any</option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertyzone"]}</span>
                                                    </div>

                                                    {/* <div className="col-md-6">
                                                     <div className="form-group selectdiv">
                                                            <label >Choose your preferred financing</label>
                                                            <select className="form-control site-input" 
                                                            name="saletype" 
                                                            value={this.state.saletype.data} 
                                                            onChange={this.handleChange('saletype')}>
                                                                <option value="">Choose the type of Sale</option>
                                                                <option value="All Cash Only">All Cash Only </option>
                                                                <option value="Conventional">Conventional</option> 
                                                                <option value="FHA">FHA</option> 
                                                                <option value="VA">VA</option>
                                                            </select>
                                                        </div>
                                                        </div> */}
                                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Choose your preferred financing</label>
                                                <select className="form-control site-input"
                                                    name="saletype"
                                                    value={this.state.saletype.data}
                                                    onChange={this.handleChange('saletype')}>
                                                    <option value="">Choose your preferred financing</option>
                                                    <option className={this.state.saletype.data.indexOf("Any") >= 0 && this.state.selectedOptionClass}
                                                        value="Any">Any </option>
                                                            
                                                    <option className={this.state.saletype.data.indexOf("Conventional") >= 0 && this.state.selectedOptionClass}
                                                        value="Conventional">Conventional</option>
                                                    <option className={this.state.saletype.data.indexOf("All Cash") >= 0 && this.state.selectedOptionClass}
                                                       value="All Cash only">All Cash</option>
                                                    <option className={this.state.saletype.data.indexOf("FHA") >= 0 && this.state.selectedOptionClass}
                                                        value="FHA">FHA</option>
                                                    <option className={this.state.saletype.data.indexOf("VA") >= 0 && this.state.selectedOptionClass}
                                                        value="VA">VA</option>
                                                    <option className={this.state.saletype.data.indexOf("Hard Mone") >= 0 && this.state.selectedOptionClass}
                                                        value="Hard Money">Hard Money</option>
                                                    <option className={this.state.saletype.data.indexOf("Seller-Financed") >= 0 && this.state.selectedOptionClass}
                                                        value="Seller-Financed">Seller-Financed</option>
                        
                                                </select>
                                                <div className="pan-block">
                                                    {this.state.saletype.data.length > 0 && this.state.saletype.data.map((saletype, saleInd) => {
                                                        return <span className="token close" data-dismiss="modal" aria-label="Close" key={saleInd}>
                                                            {saletype}
                                                            <span aria-hidden="true" onClick={this.removeFromArray('saletype', saleInd)}>
                                                                <i className="fas fa-times"></i>
                                                            </span>
                                                        </span>
                                                    })}
                                                </div>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["saletype"]}</span>
                                        </div>     
                                                </div>


                                                
                                                <div className="row">
                                                <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Preferred Property Type(s)</label>
                                                            <select class="form-control site-input" 
                                                                name="propertype" 
                                                                value={this.state.propertype.data} 
                                                                onChange={this.handleChange('propertype')}>
                                                                    <option value=""> { (this.state.propertype.data.length==0)?"Choose Property Type" : this.state.propertype.data.join(",")}</option>
                                                                    <option class={this.state.propertype.data.indexOf("Single Family")>=0 && this.state.selectedOptionClass} 
                                                                            value="Single Family">Single Family</option>
                                                                    <option class={this.state.propertype.data.indexOf("Multi-Family")>=0 && this.state.selectedOptionClass} 
                                                                    value="Multi-Family">Multi-Family</option>
                                                                    <option class={this.state.propertype.data.indexOf("Condo")>=0 && this.state.selectedOptionClass} 
                                                                    value="Condo">Condo</option>
                                                                    <option  class={this.state.propertype.data.indexOf("Commercial")>=0 && this.state.selectedOptionClass} 
                                                                    value="Commercial">Commercial</option>
                                                                    <option class={this.state.propertype.data.indexOf("Vacant Land")>=0 && this.state.selectedOptionClass}  
                                                                    value="Vacant Land">Vacant Land</option>
                                                                    <option class={this.state.propertype.data.indexOf("Development Deal")>=0 && this.state.selectedOptionClass}  
                                                                    value="Development Deal">Development Deal</option>
                                                                    <option class={this.state.propertype.data.indexOf("Contract")>=0 && this.state.selectedOptionClass}  
                                                                    value="Contract">Contract </option>
                                                                </select>
                                                            <div className="pan-block">
                                                                {this.state.propertype.data.length> 0 && this.state.propertype.data.map((property_Type,propertyInd)=>{
                                                                   return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                                        {property_Type}
                                                                        <span aria-hidden="true"  onClick={this.removeFromArray('propertype',propertyInd)}>
                                                                            <i className="fas fa-times"></i>
                                                                        </span>
                                                                    </span>
                                                                })}
                                                            </div>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertype"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Purchase Timeline</label>
                                                            <select name="" className="form-control site-input" id="" 
                                                            name="purchasetimeline" 
                                                            value={this.state.purchasetimeline.data} 
                                                            onChange={this.handleChange('purchasetimeline')}>
                                                                <option value="">Set a Purchase Timeline</option>
                                                                <option value="Immediately">Immediately </option>
                                                                <option value="Next 1 to 3 Months">Next 1 to 3 Months</option>
                                                                <option value="Next 3 to 6 Months">Next 3 to 6 Months</option>
                                                                <option value="Next 6 to 12 Months">Next 6 to 12 Months </option>
                                                                <option value="1 Year or more">1 Year or more</option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["purchasetimeline"]}</span>
                                                    </div>
                                                </div>
                                                <div className="row position-relative">
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Pre-approval Letter Obtained </label>
                                                            <select name="" className="form-control site-input" id="" name="letter_obtained" value={this.state.letter_obtained.data} onChange={this.handleChange('letter_obtained')}>
                                                            <option value="">Choose an option</option>
                                                                <option value="y">Yes</option>
                                                                <option value="n">No</option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        {/* display-flex radio-div-padding */}
                                                    <div className="form-group ">
                                                        <label >Do you currently have a Realtor?
                                                            <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent?" 
                                                                class="red-tooltip"
                                                            >
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g >
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                       <em> {"Some Sellers prefer Buyers willing to work with a single Dual Agent, as it reduces their commission expenses"}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </label>
                                                            {/* <select 
                                                                name="" 
                                                                className="form-control site-input" 
                                                                id="" name="letter_obtained" 
                                                                value={this.state.hasRealtor.data} 
                                                                onChange={this.handleChange('hasRealtor')}
                                                            >
                                                                <option value="">Choose an option</option>
                                                                <option value="y">I'm comfortable with a Single Agent for me and a seller</option>
                                                                <option value="n">Prefer to have my own Buyer's Agent.</option>
                                                            </select> */}
                                                            <span className="check flex justify-start">
                                                                <label className="con">Yes
                                                        <input type="radio" name="hasRealtor"
                                                                        onClick={this.handleClick('hasRealtor', 'y')} 
                                                                        />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                                <label className="con">No
                                                        <input type="radio" name="hasRealtor"
                                                                        onClick={this.handleClick('hasRealtor', 'n')} 
                                                                        />
                                                                    <span className="checkmark"></span>
                                                                </label>
                                                            </span>
                                                            <span style={{ color: "red" }}>{this.state.formError["hasRealtor"]}</span>
                                                        </div>
                                                        {this.state.hasRealtor.data === 'y' && <div className="">
                                                            <div className="form-group">
                                                                <label> Some sellers require buyers to enter into a <a href='javascript:void(0)' onClick={this.setshows}>dual agency relationship</a> using the agent assigned by DoorRise for the transaction. Do you agree to participate in such transactions, if required by a seller? </label>
                                                                <span class="check flex justify-start" data-original-title="" title="">
                                                                    <label class="con">Yes
                                                                        <input
                                                                            type="radio"
                                                                            //checked="checked" 
                                                                            name="dualAgent"
                                                                            onClick={this.handleClick('dualAgent', 'y')}
                                                                        />
                                                                        <span class="checkmark" data-original-title="" title="" ></span>
                                                                    </label>
                                                                    <label class="con">No
                                                                        <input
                                                                            type="radio"
                                                                            // checked="checked" 
                                                                            name="dualAgent"
                                                                            onClick={this.handleClick('dualAgent', 'n')}
                                                                        />
                                                                        <span class="checkmark" data-original-title="" title=""></span>
                                                                    </label>
                                                                </span>
                                                            </div>
                                                            <span style={{ color: "red" }}>{this.state.formError["dualAgent"]}</span>
                                                        </div>}
                                                    </div>
                                                    {/* position-absolute top-negative */}
                                                    {this.state.letter_obtained.data==='y' && <div className="col-md-12 ">
                                                        <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <label >Upload your Pre-approval Letter</label> 
                                                            <div className="upload-btn-wrapper">
                                                                <button className="btn btn-primary karla font-15 fw-500 ">
                                                                Upload Document &nbsp;
                                                                <img src="./images/icons/upload-button.svg" />
                                                                
                                                                 <div>
                                                                 {this.state.selectedFile&& <label style={{color:"green",width:"180px",overflow:"hidden"}}>
                                                                     {this.state.selectedFile.name}</label>}
                                                                 </div>
                                                                    
                                                            
                                                                <input type="file" accept="application/pdf" onChange={this.handleselectedFile}/>
                                                                </button>
                                                            </div>
                                                            {/* <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span> */}
                                                        </div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                        <p>
                                                        To obtain pre-approval status, please upload a copy of a current pre-approval letter from a verified lending source.  If you need lending assistance, please email us at <a>buyers@doorrise.com</a>

                                                        </p>
                                                        </div>
                       
                                                    </div>}
                                                </div>
                                                {/* <div className="row"> */}
                                                    {/* {this.state.letter_obtained.data==='y' && <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label >Upload your Pre-approval Letter</label> 
                                                            <div className="upload-btn-wrapper">
                                                                <button className="btn btn-primary karla font-15 fw-500 ">
                                                                Upload Document &nbsp;
                                                                <img src="./images/icons/upload-button.svg" />
                                                                </button>
                                                                <br></br>
                                                                    {this.state.selectedFile&& <label style={{color:"green"}}>{this.state.selectedFile.name}</label>}
                                                                <br></br>
                                                                <input type="file" accept="application/pdf" onChange={this.handleselectedFile}/>
                                                            </div> */}
                                                            {/* <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span> */}
                                                        {/* </div>
                                                    </div>} */}
                                                    {/* {this.state.hasRealtor.data ==='y' && <div className="col-md-6">
                                                    <div className="form-group">
                                                    <label for="exampleInputEmail1"> Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? </label>
                                                    <span class="check flex justify-start" data-original-title="" title="">
                                                        <label class="con">Yes
                                                        <input type="radio" hecked="checked" name="radio" />
                                                        <span class="checkmark" data-original-title="" title=""></span>
                                                        </label>
                                                        <label class="con">No
                                                        <input type="radio" checked="checked" name="radio" />
                                                        <span class="checkmark" data-original-title="" title=""></span>
                                                        </label>
                                                    </span>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["letter_obtained"]}</span>
                                                    </div>} */}
                                                {/* </div> */}
                                                <div className="text-center pad-tb-30">
                                                    <button type="button" className="btn btn-secondary " onClick={this.buyerpopupSubmit}>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </div> */}
                </div>: ''}
                {this.state.dualmsgpop ? <Popup color={"black"} title={"MESSAGE"} description={this.state.message} popupHandle={this.popupHandle}/>:''}
                {this.state.termsToggle ? <Popup color={"black"} title={"Terms And Conditions"} description={this.state.termsMsg} popupHandle={this.popupHandle}/>:''}
            </main>
        )
    }
}


function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
  }
  
  export default connect(mapStateToProps)(Register);