import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './FeaturedProperty.css'

class FeatureProperty extends Component {
    state = {
        featuredProperty : [],
        pathName : '/'
    }
    componentDidMount() {
        console.log("component didmount feature listngs",window.location)
        this.setState({
            featuredProperty: this.props.FeatureProperty,
            pathName:window.location.pathname
        });
        this._increase();
    }
    arrowClick = (direction) => event => {
        if (direction === 'right') {
            let popedElement = this.props.FeatureProperty.pop();
            this.props.FeatureProperty.unshift(popedElement)
            this.state.featuredProperty.pop()
            this.state.featuredProperty.unshift(popedElement)
        }
        if (direction === 'left') {
            let shiftedElement = this.state.featuredProperty.shift();
            this.state.featuredProperty.push(shiftedElement)
        }
        this.setState({
            featuredProperty: this.state.featuredProperty
        })
    }
    _increase() {
        if(this.state){
            let featuredProperty = this.state.featuredProperty ? this.state.featuredProperty : [];
            if(featuredProperty.length > 0){
                let left = featuredProperty.shift()
                featuredProperty.push(left)
                this.setState({
                    featuredProperty: featuredProperty
                })
            }
        }
        setTimeout(this._increase.bind(this), 10000);
    }
    render() {
        const {url} = this.props;
        let featuredProperty = this.state.featuredProperty.length > 0 ? this.state.featuredProperty.slice(0, 3) : [];
        return (
            <div className="container feture-container feat-list-cnt feature-cnt">
                <section className="feture-list">
                    <div className="pa-d">
                        <h4 className={this.state.pathName === '/' ? "feu-tit text-center" : "feu-tit text-align-left"}>FEATURED LISTINGS</h4>
                    </div>
                    <div className="flex justify-start fetu-width img-widh">
                        {/* <i className="fa fa-chevron-left center-arrow-featurelisting-left" onClick={this.arrowClick('left')}></i> */}
                        { featuredProperty && featuredProperty.map((data, index) => (
                                <div className="buyer-grid text-center box-shadow" key={index}>
                                    <Link to={`/product/view/${data.id}/${data.auctionid}`}>
                                    <figure>
                                        <img src={`${url}uploads/product/${data.avatar}`} alt="product-1" className="img-responsive" />
                                    </figure>
                                    <div className="feture-title">
                                        <span className=""><b>{data.title}</b></span>
                                    </div>
                                    <div className="feat-list text-left line-height-30 font-15">
                                        <div className="">
                                            <span>Location : <b>{data.location}</b></span>
                                        </div>
                                        <div className="">
                                            <span className="">Property Type : <b>{data.propertype}</b></span>
                                        </div>
                                        <div className="">
                                            <span className="">Total Sq. Footage : <b>{data.min_property_sq_footage}</b></span>
                                        </div>
                                        <div className="">
                                            <span className=""># of Bedrooms : <b>{data.bedroom}</b></span>
                                            <span className="pull-right">
                                                <span className=""># of Bathrooms : <b>{data.bathroom}</b></span>
                                            </span>
                                        </div>
                                    </div>
                                    </Link>
                                </div>

                            ))
                        }
                        {/* <i className="fa fa-chevron-right center-arrow-featurelisting-right" onClick={this.arrowClick('right')}></i> */}
                    </div>
                </section>
            </div>
        )
    }
}

    export default FeatureProperty