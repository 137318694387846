import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import * as serviceWorker from './serviceWorker';

import axios from "axios";

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';

import App from './App';
import './index.css';

axios.defaults.headers.common['axios-react'] = '1';
//init
const hist = createBrowserHistory();
const loggerMiddleware = createLogger();
const store = createStore(rootReducer,applyMiddleware(
	thunkMiddleware,
	loggerMiddleware
))

hist.listen(location => {
	window.scrollTo(0, 0);
});

ReactDOM.render(
	<Provider store={store}>	
	  <Router history={hist}>
	    <App history={hist}></App>
	  </Router>
	</Provider>	,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();