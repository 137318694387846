import React, {Component,useEffect} from 'react';
import { Redirect } from "react-router-dom";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './Productview.css';
import {
    Seller_buyer_search,
    GetChatWindowMessages,
    InsertChatMessage,
    insertMessageListen,
    GetChatWindowMessages_listen,
    searchMessage_socket,
    searchMessageListen
  } from '../../util/socket'
import axios from 'axios';
import _ from 'lodash/core';
import {subscribeToTimer,bidUpdate} from '../../util/socket';
import Timer from '../../components/Timer/Timer';
import Bidhistory from '../../components/Bidhistory/Bidhistory'
import Proposal from '../../components/Proposal/Proposal';
import Selleroffers from '../../components/Proposal/Selleroffers';
import Stripe from '../../components/Payment/Stripe';
import Canceldeeds from '../../components/Deed/Canceldeeds';
import Relisting from '../../components/Listing/Relisting';
import { properResult } from '../../util/capsAndSpaces';
import { NOTIFICATION } from  '../../actions/index';
import moment from 'moment';
import { result } from 'nsmarty';

let count = 0;
let imageTimer = null;
/**
 * 
 * 
 * @class ProductView
 * @extends {Component}
 */
class ProductView extends Component {

    constructor(props) {
        super(props);
        subscribeToTimer((err, timestamp) => this.setState({
            timestamp
        }));
        bidUpdate((err, data) => {
            // console.log(data)
            let userId = this.props.user.userdata.id
                , bidUpdateData = this.state.bidUpdates[data.id] || {}
                , bidUpdates = {}
                , iamin = data.bpop_biddedUsers.findIndex((element) => { return element === userId });
            
            iamin = (userId === data.bpop_cbidder) ? 1 : iamin;
            if (data.status !== "failed") {
                
                if((userId === data.bpop_cbidder))
                    bidUpdateData["nextBid"] = data.bpop_cuser_nextbid

                if((bidUpdateData.nextBid < data.bpop_nextbid ))
                    bidUpdateData["nextBid"] = data.bpop_nextbid_org;

                if(userId === data.bpop_higher)
                    bidUpdateData["bidStatus"] = (iamin > -1) ? data.bpop_bidstatus : ''; 
                else       
                    bidUpdateData["bidStatus"] = (iamin > -1) ? "Youre losing this item,<span class='hovpopup'> user u****d has placed a higher<span  class='hvpop pointer'   style='color:red;text-decoration:underline;'>  autobid </span>  <span class='hovermsg'> A confidential maximum bid youre willing to pay. The ShowPads auction platform will automatically place bids </span> on your behalf if someone outbids you.</span>" : '';
                   
                bidUpdateData["highest_bider"] = false;
                
                if(userId === data.bpop_higher)
                    bidUpdateData["highest_bider"] = true

                bidUpdateData["bidIncrement"] = (userId === data.bpop_cbidder) ? data.bpop_cuser_nextbid : data.bpop_increment;
                bidUpdateData["bidCount"] = data.bpop_bidcount;
                bidUpdateData["wprice"] = data.bpop_wprice;
                bidUpdate['cbidtext'] = "Current Price";
            }
            bidUpdateData["bidMsg"] = (userId === data.bpop_cbidder) ? data.error : '';
            bidUpdates[data.id] = bidUpdateData;
            return bidUpdates;
        });
        this.state = {
            data: [],
            bidUpdates : {},
            bidAmount: '',
            location:'',
            attachments:[],
            activeImage: 0,
            payModel: false,
            bidModal: false,
            Display:"123",
            bidHisroty: false,
            notLegal: true,
            formError: {},
            isAgreed: true,
            error:'',
            projectID : this.props.match.params.id,
            user: {},
            url: '',
            deedModel: false,
            fancyBth : false
        }
        this._increase();
        this.makeAnOffer = React.createRef();
    }

    Click = (event) =>{
        this.setState({
            activeImage: event.target.id
        })
    }

    _increase() {
        if(this.state){
            let attachments = this.state.data.attachments ? this.state.data.attachments : [];
            let left = attachments.shift()
            attachments.push(left)
            this.setState({
                attachments: attachments
            })
        }
        imageTimer = setTimeout(this._increase.bind(this), 10000);
    }

    StripeBoxHandle = () =>{
        this.setState({
            payModel : !this.state.payModel
        },async ()=>{
            await this.loadProduct();
            await this.StripeBoxCloseHandle();
        })
    }  

    StripeBoxCloseHandle = () =>{
        this.setState({
            payModel : false
        })
    } 

    payModel = () => {
        this.setState({
            payModel : true
        })
    }

    bidClick = (event) =>{
        let { customerid } = this.state.user;
        // if(customerid !='') enable to get the card details from the pre qualified buyers
        if(true)
        {
            this.setState({
                bidModal : !this.state.bidModal
            },()=>{
                if(this.state.bidModal){
                    document.body.style.overflow = "hidden";  
                    document.body.style.paddingRight = "15px";
                    this.checkLegalBid();
                }
                else{ 
                    document.body.style.overflow = "auto";  
                    document.body.style.paddingRight = "0px";
                }
            })
        }
        else {
            this.setState({
                payModel : true
            })
        }
              
    }

    bidHistoryClick = (event) =>{
        this.setState({
            bidHisroty : !this.state.bidHisroty
        },()=>{
            if(this.state.bidModal){
                document.body.style.overflow = "hidden";  
                document.body.style.paddingRight = "15px";
            }
            else{ 
                document.body.style.overflow = "auto";  
                document.body.style.paddingRight = "0px";
            }
        })
              
    }

    checkLegalBid = () =>{
        let self = this;
        let user_id = this.props.user.userdata.id
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/checkBidRestriction/'+user_id,{})
            .then(function (response) {
                if(response && response.data && response.data.legal ){
                    self.setState({
                        notLegal : false
                    })
                }else{
                    self.setState({
                        error : "Please contact admin, your bid not allowed"
                    })
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    }
   

    loadProduct =  () =>{
        let self = this;
        let params = this.props.match.params;
        let user_id = this.props.user.userdata.id
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.get(url+'mobileapi/getprodetails/'+params.id+'/'+params.auction_id+'/1/'+user_id,{})
            .then(function (response) {
                let location=response.data.results.location;
                if(response && response.data){
                    let bidUpdateData = {}, bidUpdate = {};
                    if(response.data.results['auctiontype'] != 'pocketlisting'){
                        bidUpdateData["nextBid"] = response.data.biddetails.next_bid;
                        bidUpdateData["bidIncrement"] = response.data.incrementamt;
                        bidUpdateData["bidCount"] = response.data.results.bids;
                        bidUpdateData["bidStatus"] = response.data.biddetails.bidtopstatus;
                        bidUpdateData["cbidtext"] = response.data.biddetails.cbidtext;
                        bidUpdateData["wprice"] = response.data.results.wprice;
                        bidUpdateData["sprice"] = response.data.results.sprice;
                        bidUpdateData["highest_bider"] = (response.data.biddetails.highbid_user_id === user_id) ? true : false;
                        bidUpdate[params.id] = bidUpdateData
                                                                                                                                                                                                                                                                                                              } 
                    bidUpdateData["userid"] = response.data.results.userid;
                    bidUpdateData["username"] = response.data.results.username;
                    bidUpdateData["userrole"] = response.data.results.userrole;
                    response.data.results   =  properResult([response.data.results]);
                    // response.data.results   =  properResult1([response.data.results]);
                    response.data.results   =  response.data.results[0]
                    if(self.props.user.userdata.role === 'listing'){
                        self.state.fancyBth = false;
                    }    
                    else {
                        self.state.fancyBth = true;
                    }
                    
                    self.setState({
                        data: response.data,
                        location:location,
                        attachments: response.data.attachments.slice(0,4),
                        bidUpdates : bidUpdate,
                        user: response.data.user[0],
                        url: url,
                        fancyBth : self.state.fancyBth
                    })
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    }

    componentDidMount() {
          this.loadProduct();
    }

    handleChange = name => event => {       
        this.setState({
            [name]: event.target.value,
        },()=>{
            this.state.formError["bidAmount"] = "";
            let nextBid = this.state.bidUpdates[this.state.projectID].nextBid;
            if(!isFinite(String(this.state.bidAmount)))
                this.state.formError["bidAmount"] = "Number's only allowed"
            if(this.state.bidAmount < Number(nextBid))    
                this.state.formError["bidAmount"] = "Bid amount is must be greater than  "+nextBid+" or more";
        });
    };


    handleCheckBox = name => event => {       
        this.setState({
            [name]: !this.state.isAgreed,
        },()=>{
            this.state.formError["isAgreed"] = this.state.isAgreed ? '' : "Required";
        });
    };

    formSubmit = e => {
        e.preventDefault();
        let isThis = this;
        let self = this.state;
        let product = self.data.results;
        let bidDetail = self.data.biddetails;
        let bidUpdates = this.state.bidUpdates[this.state.projectID];
        if (self.bidAmount >= Number(bidUpdates.nextBid) && self.isAgreed) {
            let user = this.props.user.userdata
            axios.defaults.headers.common['Authorization'] = this.props.user.data;
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            let payload = {
                "wsprice" : self.bidAmount,
                "id" : product.id,
                "testbid_incre" : bidUpdates.bidIncrement,
                "userid" : user.id,
                "phone" : user.phone,
                "bid_increment" : bidUpdates.bidIncrement,
                "next_bid" : bidUpdates.nextBid,
                "spc_startp" : product.startprice,
                "realtime_nbid" : bidUpdates.nextBid,
                "last_name" : "",
                "first_name" : "",
                "email" : user.email
            }

            axios.post(url + 'mobileapi/mobileconfirmSlibuy/', payload)
                .then(function (response) {
                    if (response && response.data) {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    isThis.setState({
                        error: "Try later"
                    })
                });
        }else{
            this.state.formError["bidAmount"] = "";
            let nextBid = this.state.bidUpdates[this.state.projectID].nextBid;
            this.state.formError["isAgreed"] = this.state.isAgreed ? '' : "Required";
            if(this.state.bidAmount < Number(nextBid))    
                this.state.formError["bidAmount"] = "Bid amount is must be greater than  "+nextBid+" or more";
                isThis.setState({
                    formError: this.state.formError
                })
            }
    }

    nextBtnHandle = (event) =>{
        let action = event.target.id
            ,attachments = this.state.data.attachments ? this.state.data.attachments : []
            ,left = attachments.shift();
        attachments.push(left)
        this.setState({
            attachments: attachments
        })
    }
    
    focusOffer = () => {
        window.scroll({top: 700,
            left: 100,
            behavior: 'smooth'})
    }

    cancelDeedsHandle = (event) => {
        this.setState({
            deedModel : !this.state.deedModel,
        },()=>{
            if(this.state.deedModel){
                document.body.style.overflow = "hidden";  
                document.body.style.paddingRight = "15px";
            }
            else{ 
                document.body.style.overflow = "auto";  
                document.body.style.paddingRight = "0px";
                if(event === 1){ this.loadProduct();}
            }
        })
    }

    priceUpdate = (price) => {
        this.state.bidUpdates[this.state.data.results.id || 0].wprice = price;
        this.setState({
            bidUpdates : this.state.bidUpdates,
        })
    }

    bucketListHandle = () => {
        let self = this;
        let user_id = this.props.user.userdata.id
        let project_id = this.props.match.params.id;
        let payload = {
            "description": "",
            "offer_amount": 0,
            "financing": "",
            "buyer_id": user_id,
            "project_id": project_id,
            "isaccepted" : 1
        }
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/showintresting/',payload)
            .then(function (response) {
                self.props.dispatch(NOTIFICATION({msg: "Your request has been successfully sent. You will be contacted by a member of our team to help you submit your offer."}))
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    }
 sendMessageOnClick2(id,msg,pid,aid,uid,location,agentid)
    {
    
        let data = {
          toId:id,
          fromId: uid,
          message:msg,
          limit: 1
        }
        
        InsertChatMessage(data)
        let data1 = {
            toId:id,
            fromId: uid,
            message:location,
            limit: 1
          }
          
          InsertChatMessage(data1)
     let data2 = {
              toId:id,
              fromId: uid,
              message:"/product/view/"+pid+"/"+aid,
              limit: 2
            }
            
            InsertChatMessage(data2)
        this.props.dispatch(NOTIFICATION({msg: "Your request has been successfully sent to seller."}))
        let payload = {
           "uid":uid,
           "pid":pid,
           "aid":aid,
           "agentid":agentid
        }
        console.log("payload",payload);
        let self=this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/update_intresting/',payload)
            .then(function (response) {
                console.log("++++++",response)
                if(response.data.success)
                {
                    self.loadProduct();
                    
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
        
    }
    render() {
       
        let { results } = this.state.data;
        let user_id =  this.props.user.userdata.id;
        let { attachments, bidModal, notLegal, bidHisroty, formError, deedModel, payModel } = this.state;
            results = results ? results : "";
        let biddetails = this.state.bidUpdates[results.id || 0];
            attachments = this.state.attachments.length > 0 ? this.state.attachments.slice(0, 4) : [];
        let mainImage = attachments[this.state.activeImage] ? attachments[this.state.activeImage].avatarorg : '';
        let date_added_sec = new Date(results.date_added).getTime();
        let currentdate_sec = new Date().getTime();
       
        
        return (
            <main>
                <div className="center-part">
                    <div className="container">
                        <section className="filter-body Buyer-dash ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pro-pagi">
                                        <h5 className="karla fs-14">BUY PROPERTIES</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="product-slider">
                                        <div className="slider-a">
                                            <figure>
                                                <img src={mainImage || "/images/No_Image_Available.jpg"} alt="main-slider" className="img-responsive" />
                                            </figure>
                                        </div>
                                        <div className="slider-b">
                                            <div className="scnd-slider">
                                                <span className="backBtn" id="backBtn" onClick={this.nextBtnHandle}></span>
                                                {attachments && attachments.length > 0 && attachments.map((images, index) => (
                                                    <figure key={"images-" + index}>
                                                        <img src={images ?  images.avatarorg :  "/images/No_Image_Available.jpg"} alt="sub-slider" className="img-responsive" id={index} onClick={this.Click} />
                                                    </figure>
                                                ))}
                                                <span className="nextBtn" id="nextBtn" onClick={this.nextBtnHandle}></span>
                                            </div>
                                        </div>
                                    </div>
                                   
                                </div>
                                {/* <button className="btn btn-primary"  onClick={this.sendMessageOnClick1(results.user_id,"I'am interested in,",results.id,results.auctionid)}>I'M INTERESTED </button> */}
                                <div className="col-md-5">
                                    <div className="product-timer primary pad-10 text-center">
                                       
                                        {results.auctiontype==='listing' && results.market_status!=="draft"?<span className="flex justify-center text-white karla timer-font">
                                        <b className="justify-center">
                                        {/* {biddetails && (results.market_status !== "draft") ? (results.auctiontype === "auction") ? ( biddetails.bidCount > 0 ) ? "Asking Price" : "Starting Price" : "Asking Price" : (results.auctiontype === "listing")? "Asking Price":'Offered At'} : ${biddetails ? biddetails.wprice :  results.bprice} 11 */}
                                        </b>
                                        </span>:''}
                                        <span className="flex justify-center text-white karla timer-font">
                                            {/* <img src="/images/icons/speed.svg" className="img-responsive" /> */}
                                            {results.auctiontype==="auction" && results.market_status!=="draft" 
                                            ? 
                                            <>
                                                <img src="/images/icons/speed.svg" className="img-responsive" />
                                                {((date_added_sec - currentdate_sec) <= 0) ? "AUCTION ENDS IN :" : "AUCTION STARTS IN :"}
                                                <b className="justify-center">
                                                    <Timer auctiontype={results.auctiontype} date_added={results.date_added} closeTime={results.auctiontype==='listing' ? results.date_added : results.date_closed} serverTime={this.state.timestamp ? this.state.timestamp.dTime : ''} /> 
                                                </b>
                                            </>: results.bprice && results.market_status!=="draft" ?`Asking Price: $${results.bprice}`:'Property Comming Soon'}
                                        </span>
                                    </div>
                                    <div className="product-hd">
                                        <span className="flex justify-between align-center">
                                            <div>
                                                <h4>{this.state.location}</h4>
                                                {results.inspectionreport != '' && results.inspectionreport && false ?<a className="karla" target="_blank" href={this.state.url+"uploads/InspectionDocument/"+results.inspectionreport}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 10 12.144">
                                                        <g id="download-button" transform="translate(-38.25 0)">
                                                            <g id="file-download" transform="translate(38.25)">
                                                                <path id="Path_706" data-name="Path 706" d="M48.25,4.286H45.393V0H41.107V4.286H38.25l5,5Zm-10,6.429v1.429h10V10.715Z" transform="translate(-38.25)" fill="#063b7b" />
                                                            </g>
                                                        </g>
                                                    </svg>DOWNLOAD PROPERTY REPORT
                                                </a> : ""}
                                            </div>
                                            <div>
                                                {/* {(this.props.user && this.props.user.userdata.role != "buyer"&& this.props.user.userdata.role != "realtor" && this.props.loggedIn) && <Link to={"/dashboard/message"}>
                                                    <button className="btn btn-secondary ">Contact Buyer</button>
                                                </Link>} */}
                                                {(this.props.user && this.props.user.userdata.role === "buyer" && results.auctiontype === 'listing'&& this.props.loggedIn && results.user_id !==this.props.user.userdata.id) && <Link to={ this.props.loggedIn ? `/dashboard/message/${results.userid}/${results.username}/${results.userrole}` :"/dashboard/message"}>
                                                    <button className="btn btn-secondary ">Contact Seller </button>
                                                </Link>}
                                            </div>
                                        </span>
                                    </div>
                                    <div className="proper-data span-block">
                                    <span>{biddetails && (results.market_status !== "draft")
                                        ? (results.auctiontype === "auction") 
                                            ? ( biddetails.bidCount > 0 ) 
                                                ? "Pricing" : "Opening Bid" 
                                            : "Pricing" 
                                        : 'Pricing'} 
                                        : <b>${biddetails ? biddetails.wprice : results.bprice}</b></span>
                                        <div className="flex justify-between view-elip">
                                        
                                            <span>Type : <b>{results.propertype}</b></span>
                                            
                                            {(this.props.loggedIn ) && (results.auctiontype === "auction") && (results.auctiontype !== "pocketlisting") && (results.market_status!=="draft") && <span onClick={this.bidHistoryClick}>
                                                    <img src="/images/icons/bid-history.svg" clas="img-responsive" alt="" />
                                                    <b className="text-light font-15 karla pointer">BID HISTORY</b>
                                            </span>}
                                        </div>
                                        {/* { (results.auctiontype !== "pocketlisting") && <span>Address : <b>{results.location}</b></span>} */}
                                        {/* { (results.auctiontype !== "pocketlisting") && <span>Seller Type : <b>{results.sellertype}</b></span>} */}
                                        {/* { (results.auctiontype !== "pocketlisting") && <span>Preferred Sale Type : <b>{results.saletype}</b></span>} */}
                                        
                                        {/* <span>Condition : <b>{results.propertycondition}</b></span> */}
                                        <div className="flex twin-bath">
                                            <span>Bedrooms : <b>{results.bedroom}</b></span>
                                            <span>Bathrooms : <b>{results.bathroom}</b></span>
                                        </div>
                                        <span> Property Condition :  <b>{results.propertycondition}</b></span>
                                        <span> Preferred Buyer Financing :  <b>{results.saletype}</b></span>
                                        <span> Preferred Transaction Type :  <b>{results.saletype_optional}</b></span>
                                        <span>Living Area : <b>{results.min_property_sq_footage} Sq. Ft</b></span>
                                        <span>Lot Size : <b>{results.min_land_sq_footage} Sq. Ft</b></span>
                                        {results.market_status!=="draft"?<div>
                                        <span>Zoning : <b>{results.propertyzone ?results.propertyzone:'-'}</b></span>
                                        <span>Description : <b>{results.description}</b></span>
                                        {/* {console.log("++",this.state.Display)} */}
                                        {results.iaminterest===null && this.props.user.userdata.role==="buyer"?
                                          <span><button className="btn btn-primary" onClick={() => { this.sendMessageOnClick2(results.user_id,"I'am interested in",results.id,results.auctionid,user_id,results.location,results.agent_id) }}  >I'M INTERESTED</button></span>        
                                          :''}
                                        <br></br>
                                        {/* <Link to={ this.props.loggedIn ? `/dashboard/message/${results.userid}/${results.username}/${results.userrole}` :"/dashboard/message"} >
                                                    <button className="btn btn-secondary ">Contact Seller </button>
                                        </Link> */}
                                        {results.auctiontype === "auction" && this.props.user.userdata.role==="buyer"?
                                        <span><button className="btn btn-primary" onClick={this.bidClick} >BID NOW </button></span>:""}
                                       </div>:''}
                                        {/* <span>Open House date : <b>{results.openhousedate? (results.openhousedate==='0000-00-00' || results.openhousedate==='0000-00-00 00:00:00') ?'TBD':moment(results.openhousedate).format("YYYY-MM-DD HH:mm:ss"):'TBD'}</b></span> */}
                                    </div>

                                    { ["sold","closed"].indexOf(results.market_status) === -1 
                                    && (this.props.user.userdata.role !== "seller" || ["open","seller"].indexOf(results.market_status) >= 0 ) && results.auctiontype !=="listing" && this.state.fancyBth && results.market_status!=="draft" &&
                                    <div className="fancy-btn">
                                       
                                        <h4> {biddetails && (results.market_status !== "draft") ? (results.auctiontype === "auction") ? ( biddetails.bidCount > 0 ) ? "Asking Price" : "Starting Price" : "Asking Price" : 'Asking Price'} : ${biddetails ? biddetails.wprice :  results.bprice}</h4>
                                       
                                        { ( results.auctiontype !== "offers" ) 
                                            ? (results.auctiontype === "auction" && results.market_status!=="draft") 
                                                ? ( results.user_id !== user_id ) 
                                                    ? ((date_added_sec - currentdate_sec) <= 0) ? <button className="btn btn-primary" onClick={this.bidClick} >BID NOW </button> : <button className="btn btn-primary"  onClick={this.bucketListHandle}>I'M INTERESTED</button> 
                                                    : 
                                                    <button className="btn btn-primary"  onClick={this.bidHistoryClick} >BID HISTORY </button>
                                                :
                                                (this.props.user && this.props.user.userdata.role === "buyer" && results.market_status!=="draft")  
                                                    ? <button className="btn btn-primary"  onClick={this.bucketListHandle}>I'M INTERESTED</button> 
                                                :  ''   
                                            :
                                            (this.props.user && this.props.user.userdata.role === "buyer") 
                                                ? results.market_status !== "open" 
                                                        ? <button className="btn btn-primary" > Deal Closed </button> 
                                                        :
                                                        <button className="btn btn-primary" onClick={this.focusOffer}>SUBMIT OFFER </button> 
                                                : 
                                                results.market_status !== "open" && results.market_status !== "draft" 
                                                ? <button className="btn btn-primary" > Deal Closed </button> 
                                                : <button className="btn btn-primary"  onClick={this.cancelDeedsHandle}> Cancel / Close Contract </button>
                                        }
                                    </div>}
                                    {
                                        results.auctiontype === 'listing' && this.props.user&&this.props.user.userdata.role === "buyer" && this.props.loggedIn && false ? 
                                        <>
                                        <div className="" style={{display: "flex","justify-content": "center"}}>
                                            <button class="btn btn-primary" onClick={this.bucketListHandle}>I'M INTERESTED</button>
                                        </div>
                                        </> : ''
                                    }
                                    


                                    {((results.market_status === "draft" || results.market_status === "sold" || results.market_status === "closed") && this.props.user.userdata.role === "seller") && <div className="row">
                                        <Link to={"/editpost/"+results.id+"/"+results.auctionid}> <button className="btn btn-primary" style={{marginRight: "40px"}} >Update Info</button> </Link>
                                        {  (results.market_status === "closed" || results.market_status === "sold") && <Relisting projectData={results} {...this.props}/>}
                                    </div>}
                                  
                                    {biddetails && <div className="bidstatus" style={{ "color": (biddetails && biddetails.highest_bider) ? "green" : "red" }}>
                                        <span dangerouslySetInnerHTML={{__html:biddetails.bidStatus}}></span>
                                    </div>}
                                </div>
                            </div>
                        </section>
                    </div>
                   { results && results.user_id != user_id && results.market_status === "open" && (results.auctiontype ==="listing") && <Proposal dispatch={this.props.dispatch} projectData={results} user={this.props.user.userdata} customerid={this.state.user.customerid} payModel={this.payModel} user_token = {this.props.user}/>}
                    <div className="container">
                    </div>
                </div>
                { payModel && 
                    <Stripe 
                        project_id={results.id} 
                        onChangeHandle={this.StripeBoxHandle}
                        StripeBoxCloseHandle={this.StripeBoxCloseHandle}
                        amount={50000}
                        dispatch = {this.props.dispatch}
                    /> 
                }
                {bidModal &&
                    <div className="modal fade mod-place-bid in modal-open d_block" id="bidModal" tabIndex="0" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content show-model">
                                <div className="modal-header">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.bidClick}><span aria-hidden="true">&times;</span></button>
                                    <h3 className="text-center karla fw-600"><span>SUBMIT YOUR BID</span></h3>
                                </div>
                                <div className="modal-body pad-30">
                                    <form onSubmit={this.formSubmit.bind(this)}>
                                        <div className="row">
                                            <div className="bid-submit flex justify-around">
                                                <span>
                                                    <h4 className="karla font-20 text-black">Enter the amount you would like to Bid</h4>
                                                    <div class="flex justifycontent-between">
                                                    <span className="karla font-size-1_rem">Starting Bid : ${biddetails.sprice}</span>
                                                    <span className="karla font-size-1_rem">Current Bid : ${biddetails.wprice}</span>
                                                    </div>
                                                </span>
                                                <span>
                                                    <div className="form-group bid-amt">
                                                        <div className="input-group box-shadow">
                                                            <input type="text" className="form-control site-input" id="bidAmount" name="bidAmount" value={this.state.bidAmount} onChange={this.handleChange('bidAmount')} />
                                                            <div className="input-group-addon">USD</div>
                                                        </div>
                                                        <span> {"Enter $" + biddetails.nextBid + " or more"} </span>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="text-center"><span style={{ color: "red" }}>{this.state.formError["bidAmount"]}</span></div>
                                            <div>
                                                <div className="checkbox flex justify-center pad-lt-30">
                                                    <label className="show-check">&nbsp;&nbsp;I agree that I have seen the property in person and reviewed the report.
                                                    <input className="check-height" type="checkbox" name="isAgreed" checked={this.state.isAgreed} onChange={this.handleCheckBox('isAgreed')} />
                                                        <span className="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div className="text-center"><span style={{ color: "red" }}>{this.state.formError["isAgreed"]}</span></div>
                                                {biddetails ?
                                                    <div className="bid_error pad-10 text-center" style={{ "color": (biddetails && biddetails.highest_bider) ? "green" : "red" }}> <span dangerouslySetInnerHTML={{__html:biddetails.bidStatus}}></span> </div>
                                                    : ''
                                                }
                                                <div className="flex justify-center pad-20 mar-top-0">
                                                    <button type="submit" className="btn btn-primary" disabled={notLegal}>PLACE BID</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                }
                { bidHisroty && <Bidhistory product_id={results.id} isAwardEnable={(results.user_id === user_id && results.market_status === "seller") ? true : false} close={this.bidHistoryClick}/> }
                { deedModel && <Canceldeeds product_id={results.id} close={this.cancelDeedsHandle} user={this.props.user} />}
            </main>
    )
  }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(ProductView);