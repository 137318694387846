import React, { Component } from 'react';
import './Search.css';
// import Slider from '@material-ui/core/Slider'; 
import { withStyles, makeStyles } from '@material-ui/core/styles'; 
import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import Placeselector from '../../../components/Placeselector/Placeselector';

import us_states from '../../../util/us_states';


// const AirbnbThumbComponent=(props) =>{
//   return (
//     <span {...props}>
//       <span className="bar" />
//       <span className="bar" />
//       <span className="bar" />
//     </span>
//   );
// }
// const AirbnbSlider = withStyles({
//   root: {
//     color: '#3a8589',
//     height: 3,
//     padding: '13px 0',
//   },
//   thumb: {
//     height: 27,
//     width: 27,
//     backgroundColor: '#fff',
//     border: '1px solid currentColor',
//     marginTop: -12,
//     marginLeft: -13,
//     boxShadow: '#ebebeb 0px 2px 2px',
//     '&:focus,&:hover,&$active': {
//       boxShadow: '#ccc 0px 2px 3px 1px',
//     },
//     '& .bar': {
//       // display: inline-block !important;
//       height: 9,
//       width: 1,
//       backgroundColor: 'currentColor',
//       marginLeft: 1,
//       marginRight: 1,
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 3,
//   },
//   rail: {
//     color: '#d8d8d8',
//     opacity: 1,
//     height: 3,
//   },
// })(Slider);
class Common extends Component {
  state = {
    priceRange: ['',''],
    neighborhood: [],
    propertyType: '',
}

  handlePriceSlider = (event, newValue) => {
    this.setState({
      priceRange: newValue, 
    })
  };    
  handleChange = name => e => {
    this.setState({
      propertyType: e.target.value,
    })
  }

  handleBetween = name => e => {
    var prevState = this.state.priceRange
    if (name === "from") {
        prevState.shift()
        prevState.unshift(e.target.value)
        // let new_to = old_to.unshift(e.target.value)
        this.setState({
          priceRange : prevState,
        }, ()=> {
          console.log("---this state check ", this.state)
        })
      
    } else if (name === "to") {
      
        prevState.pop()
        prevState.push(e.target.value)
        this.setState({
          priceRange : prevState,
        }, () => {
          console.log("this state check ", this.state)
        })
      

    }

  }
  handleSearchClick = () => {
    let prePayload = this.props.filterLoad;
    prePayload["location"] = this.state.neighborhood
    prePayload["pricerangemin"] = this.state.priceRange[0]
    prePayload["pricerangemax"] = this.state.priceRange[1]
    prePayload["propertype"] = this.state.propertyType
    this.props.oncheckBoxChange(prePayload);
  }
  // onPlaceChanged = (data) => {
 
  //   const preState = this.state.neighborhood;
  //   if (preState.indexOf(data.formatted_address) < 0) {
  //     preState.push(data.formatted_address);
  //   }
  //   console.log("---------dadadata", preState)
  //   this.setState({
  //     neighborhood: preState,
  //   });
  //   console.log("---check statee", this.state  )
  // }
    onPlaceChanged = (data) => {
 
    const preState = this.state.neighborhood;
    if (preState.indexOf(data) < 0) {
      preState.push(data);
    }
    this.setState({
      neighborhood: preState,
    });
  }
  removeFromArray = (name, ind) => event => {
    let prestate = this.state[name]
    prestate.splice(ind, 1)
    this.setState({
      [name]: prestate
    })
  }
  numToLetters=(labelValue)=>{
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? Math.abs(Number(labelValue)) / 1.0e+9 + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.abs(Number(labelValue)) / 1.0e+6 + "M"
    // Three Zeroes for Thousands
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.abs(Number(labelValue)) / 1.0e+3 + "K"

    : Math.abs(Number(labelValue));
    // return a
  }



   

  render() {
    const {pathname} =window.location;
    let {priceRange} = this.state
    const imgTogge = pathname === '/search/buyer' ? true : false
    return (
      <section className="">
        <div className={imgTogge ? "find-banner" : "sch-banner"}>
          <div className="sch-content"></div>
          <div className="find-card">
            <div className="container">
              <div className="inner-card">
                <h3 className="text-center"><span>{this.props.title}</span></h3>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group selectdiv buy-sch">
                      <Placeselector onPlaceChanged={this.onPlaceChanged} placeholder = {' Choose A Search Area'}/>
                      <div className="pan-block">
                        {this.state.neighborhood.length > 0 && this.state.neighborhood.map((ele, ind) => {
                          return <span className="token close" data-dismiss="modal" aria-label="Close">
                            {ele}
                            <span aria-hidden="true" onClick={this.removeFromArray('neighborhood', ind)}>
                              <i className="fas fa-times"></i>
                            </span>
                          </span>
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group selectdiv buy-sch">
                      <select className="form-control select-two" value={this.state.propertyType} onChange={this.handleChange("propertyType")}>
                        <option value="">Choose Property Type</option> 
                        <option value="Single Family">Single Family</option>
                        <option value="Multi-Family">Multi-Family</option>
                        <option value="Condo">Condo</option>
                        <option value="Commercial">Commercial</option>
                        <option value="Vacant Land">Vacant Land</option>
                        <option value="Development Deal">Development Deal</option>
                        <option value="Trust Deed">Trust Deed</option>
                        <option value="Wholesale Contract">Wholesale Contract</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row pad-top-20">
                  <div className="col-md-8 relative" >
                    <div data-role="main" className="price-range ui-content" >
                      <form action="" className="form">
                        <div className="range-slider " data-role="rangeslider">
                          <span className="range">Choose Price Range :</span>
                          {/* <div class="w-100"> */}
                          {/* <Slider
                            name="priceRange"
                            value={this.state.priceRange}
                            onChange={this.handlePriceSlider}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            min={100}
                            max={10000000}
                            step={100}
                          /> */}
                          <div id="sf_price" className="w-100 sf_category B-da" aria-labelledby="headingOne" data-parent="#sf-6" >
                                <div className="card-body">
                                    <label className="w-100 price-rang mt-2">
                                        <div className="flex justify-between">
                                          <div className="flex justify-start align-center">
                                            From :  <input
                                            type="text"
                                            className="form-control P-input"
                                            id="amount"
                                            placeholder="$" 
                                            readonly 
                                            value={this.state.priceRange[0]} 
                                            onChange={this.handleBetween('from')}
                                            />
                                          </div>
                                          <div className="flex justify-end align-center">
                                            To : <input 
                                            type="text" 
                                            className="form-control P-input" 
                                            id="amount1" 
                                            placeholder="$" 
                                            readonly           
                                            value={this.state.priceRange[1]} 
                                            onChange={this.handleBetween('to')}
                                            />
                                          </div>
                                        </div>
                                        
                                          {/* <AirbnbSlider
                                                ThumbComponent={AirbnbThumbComponent}
                                                getAriaLabel={index => (index === 0 ? 'Minimum price' : 'Maximum price')}
                                                defaultValue={[20, 40]}
                                              /> */}
                                    </label>
                                </div>
                            </div>
                          {/* <div class='flex justify-between range ' id='minmaxslider'> */}
                          {/* <span class='abslute'>{this.numToLetters(priceRange[0])}</span> */}
                          {/* <span class='abslute'>{this.numToLetters(priceRange[1])}</span> */}
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="pull-right pro-center">
                      <button className="btn btn-secondary pad-lr-20" onClick={this.handleSearchClick}>Quick Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Common;
