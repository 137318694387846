import React from "react";
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';


class Placeselector extends React.Component {
  constructor(props) {
    super(props);

    window.React = React;
    window.ReactDOM = ReactDOM;
    this.state = {
        location : [
            "All Areas",
            "LA - Downtown", 
            "LA - South LA", 
            "LA - Mid City, Beverly Hills, Culver City",
            "LA - Hollywood, East Hollwood, WeHo",
            "LA - Westside, Santa Monica, Beaches", 
            "LA - North, Glendale, Highland Park, Pasadena, etc", 
            "LA - Eastern Cities",
            "SF East - NoHo, Burbank, Sunland, etc",
            "SF South- Universal City, Studio City, Van Nuys, Encino, Sherman Oaks etc.", 
            "SF Central - Van Nuys, Sepulveda, Reseda, etc",
            "SF North - North Hills, Granada Hills, Northridge, etc.", 
            "SF West - Woodland Hills, Tarzana, Chatsworth, Calabasas, etc.",
            "SB- Inglewood, El Segundo, Hawthorn",
            "SB - West, Beach Cities",
            "SB - Central, Carson, Torrance, etc.",
            "SB - South, Palos Verdes, Rolling Hills, San Pedro, etc",
            "LONG BEACH AREA",  
            "ORANGE COUNTY",
            "RIVERSIDE COUNTY",
            "CANYON COUNTRY AREA",
            "SANTA CLARITA/VALENCIA AREA",
            "PALMDALE AREA",
            "LANCASTER AREA"
        ],
        place: ''
    }
  }


  componentDidMount() {
      console.log("-----this props",this.props)
      const {
        isScriptLoaded,
        isScriptLoadSucceed,
        onPlaceChanged
      } = this.props;
  
        this.setState({ isload: true });
  }


  filterLocation= (searchvalue) => {

    let state = this.state.location

    state.filter(value=>{
        if(value[0].includes(searchvalue)){
        return value
        }  
    })
  }

  onChangeHandle = async (event) => {
    event.preventDefault()
    console.log("edfsdfdds", event.target.value)
    if (event.target.value === 'All Areas') {
      this.setState({ place: "" }, () => {
      })
    }else {
      this.setState({ place: event.target.value }, () => {
        this.props.onPlaceChanged(this.state.place)
      })
    }

    // this.props.onPlaceChanged(this.state.place)
  }

  render() {
    const {placeholder} = this.props
    const {location} = this.state
    return (
        <select
            name = "location"
            className="form-control site-input" id="propsqfootage"
            value={this.state.place}
            onChange={this.onChangeHandle}
            defaultValue={{ label: "All Areas", value: '' }}
            placeholder={placeholder ? placeholder : "Enter your address"}
            type="text" 
        >
         {location.map((x,y) => <option key={y}>{x}</option>)}
        </select>  
    );
  }
}

export default Placeselector;

