import React, {Component} from 'react';
import { Link,Redirect,Route } from 'react-router-dom';
import './Cards.css';
import Loader from '../../components/Loader/Loader';
import SellerSearch from '../../components/Cards/Search/SellerSearch';
//Using on Seller ashboard 

import {subscribeToTimer} from '../../util/socket';
import Timer from '../Timer/Timer';
import Bidhistory from '../Bidhistory/Bidhistory'
import Canceldeeds from '../Deed/Canceldeeds';
import Popup from '../Popup/Popup'
import axios from 'axios'
import {NOTIFICATION} from '../../actions/index'
import moment from 'moment'
class ViewOne extends Component {
    constructor(props) {
        super(props);
        subscribeToTimer((err, timestamp) => this.setState({
            timestamp
        }));
    }

    state = {
        click:true,
        buyerData:[],
        timestamp: "Loading..",
        bidHisroty: false,
        product_id: 1,
        deedModel: false,
        closeDeal:{toggle:false,data:{}}
    };

    bidHistoryClick = (event) =>{
        this.setState({
            bidHisroty : !this.state.bidHisroty,
            product_id: event ? event.target.id : 0
        },()=>{
            if(this.state.bidModal){
                document.body.style.overflow = "hidden";  
                document.body.style.paddingRight = "15px";
            }
            else{ 
                document.body.style.overflow = "auto";  
                document.body.style.paddingRight = "0px";
            }
        })
    }
    
  

    cancelDeedsHandle = (event) => {
        this.setState({
            deedModel : !this.state.deedModel,
            product_id: event && event.target ? event.target.id : 0
        },()=>{
            if(this.state.deedModel){
                document.body.style.overflow = "hidden";  
                document.body.style.paddingRight = "15px";
            }
            else{ 
                document.body.style.overflow = "auto";  
                document.body.style.paddingRight = "0px";
                if(event === 1) this.props.history.push("/dashboard/seller");
            }
        })
    }
    closeDeal = (property) => event => {
        let closedDeal = this.state['closeDeal'];
        closedDeal.toggle = true;
        closedDeal.data = property;
        this.setState({
            closeDeal : closedDeal
        })
    }
    popupHandle = (data, value) => {
        let soldPrice = value;
        let closedDeal = this.state['closeDeal'];
        closedDeal.toggle = false;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let completeUrl = `${url}mobileapi/closepocketlisting`;
        let payload = {
            id: this.state.closeDeal ? this.state.closeDeal.data.pid : '',
            auctionid: this.state.closeDeal ? this.state.closeDeal.data.auctionid : '',
            soldPrice : soldPrice
        }
        let self=this;
        axios.post(completeUrl, payload).then(response => {
            if(response.data.success==='yes'){
                this.props.dispatch(NOTIFICATION({msg:'Deal Closed Successfully'}))
            }
            closedDeal.data = {}
            this.setState({
                closeDeal : closedDeal
            },()=>{self.props.loadDashboard(self.props.market_status,true)})
        }).catch(error=>console.log(error))
    }
  render(){
      console.log("valueee",this.props)
      let { projToDisplay } = this.props;
      let { bidHisroty, deedModel } = this.state;
      let BidHistory = (props) => {
          return <button className="btn btn-blury" id={props.pid} onClick={this.bidHistoryClick}> Bid
        History</button>
      }
      let ViewDetails = (props) => {
          return <Link to={"/product/view/" + props.pid + "/" + props.auctionid}>
              <button className="btn btn-secondary">ViewDetails</button>
          </Link>
      }
      let Match = (props) => {
        return <Link to={"/dashboard/match/" + props.pid + "/" + props.auctionid}>
            <button className="btn btn-secondary">Match buyer list</button>
        </Link>
    }
      console.log('project to display',projToDisplay)
    return (
        // <div>
        // {this.state.click?
        <div>
            {projToDisplay.length  > 0 ? projToDisplay.map((projToDisplay, index) => (

                <div className="bids-data" key={"k_" + index}>
                    <div className="row">
                        <div className="col-md-12 dashboard-card">
                            <div className="col-md-3 pad-left-0 pro-card-hgt">
                                <Link to={"/product/view/"+projToDisplay.pid+"/"+projToDisplay.auctionid}>
                                    <figure>
                                        <img src={(projToDisplay.image && projToDisplay.image!='') ? projToDisplay.image : "/images/No_Image_Available.jpg" }  alt="my-bids-img"
                                            className="img-responsive" />
                                    </figure>
                                </Link>
                            </div>
                            <div className="col-md-9">
                                <div className="bids-details">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="flex justify-between bids-tit">
                                                <span className="">Status : {projToDisplay.market_status === 'seller' ? 'In progress' : projToDisplay.market_status}  <b
                                                    className="text-blue fw-400"></b></span>
                                                {projToDisplay.market_status !== "sold" &&
                                                <>
                                                    <span ><span className="text-blue">{projToDisplay.auctiontype==='listing'?' POSTED ': ((new Date(projToDisplay.date_added).getTime() - new Date().getTime()) <= 0) ? 'AUCTION ENDS IN :' : 'AUCTION STARTS IN'} </span>
                                                        <b>
                                                            <Timer auctiontype={projToDisplay.auctiontype} closeTime={projToDisplay.auctiontype==='listing'?projToDisplay.date_added:projToDisplay.closed_date} date_added={projToDisplay.date_added} serverTime={this.state.timestamp.dTime} />
                                                        </b>
                                                        <span className="text-blue">{projToDisplay.auctiontype==='listing'?' AGO ':''}</span>
                                                    </span> 
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-8 span-block">
                                            <span className="">Property Type : <b
                                                className="fw-400">{projToDisplay.PropertyType}</b></span>
                                            <div className="flex twin-bath">
                                                <span>Beds : <b>{projToDisplay.beds}</b></span>
                                                <span>Baths : <b>{projToDisplay.baths}</b></span>
                                            </div>
                                            <span>Open House Date : <b>{projToDisplay.openhousedate ? (projToDisplay.openhousedate === '0000-00-00' || projToDisplay.openhousedate === '0000-00-00 00:00:00') ? "TBD" : moment(projToDisplay.openhousedate).format('MM-DD-YYYY HH:mm:ss') : 'TBD'}</b></span>
                                            <span className="">Location : <b className="fw-400">
            
                                                {projToDisplay.location}</b></span>
                                                {this.props.user&&this.props.user.userdata&&this.props.user.userdata.role==='realtor'&&projToDisplay.market_status==='sold'&&projToDisplay.iprice!="0.00"?<span>Sold Price : ${projToDisplay.iprice}</span>:''}
                                                {/* <span className="">Pref. Transaction Type: <b
                                                className="fw-400">{projToDisplay.saletype}</b><span>
                                                </span>
                                           </span> */}

                                        </div>

                                    </div>
                                    <div className="row sell-rw">
                                        <div className="col-md-6">
                                            <span className="flex">
                                                { projToDisplay.auctiontype === "auction" &&<b className="karla">Starting Bid : ${projToDisplay.startingBid}</b>}
                                            </span>

                                            {(projToDisplay.market_status === "sold") ? 
                                                <>
                                                <ViewDetails pid={projToDisplay.pid} auctionid={projToDisplay.auctionid} /> 
                                                </>
                                                : 
                                                (projToDisplay.auctiontype === "offers" && (projToDisplay.market_status === "open" || projToDisplay.market_status === "draft")) ? 
                                                <button className="btn btn-secondary" id={projToDisplay.pid} onClick={this.cancelDeedsHandle}>
                                                    Cancel Contract
                                                </button> : projToDisplay.auctiontype === "auction" ?
                                                <BidHistory pid={projToDisplay.pid}/> : ''}
                                        </div>
                                        <div className="col-md-6 award-img mora-split text-center pad-lft-20">
                                            {projToDisplay.market_status !== "sold" &&
                                                <div>
                                                    <span className="flex justify-center">
                                                        { projToDisplay.auctiontype === "auction" && <b className="karla">Current Bid : ${projToDisplay.currentBid}
                                                        </b>}
                                                    </span>
                                                    {this.props.user&&this.props.user.userdata&&this.props.user.userdata.role==='realtor'&&projToDisplay.market_status==='open'?
                                                    <>
                                                    <ViewDetails pid={projToDisplay.pid} auctionid={projToDisplay.auctionid} />
                                                    <a href='javascript:void(0)'>
                                                        <button className="btn btn-secondary" onClick={this.closeDeal(projToDisplay)}>Close the Deal</button>
                                                    </a>
                                                    </>
                                                    :
                                                    <ViewDetails pid={projToDisplay.pid} auctionid={projToDisplay.auctionid} />}</div>}
                                            {projToDisplay.market_status === "sold" && 
                                                <img src="/images/icons/awarded.svg"
                                                    className="awarded img-responsive" alt="" />}
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Match pid={projToDisplay.pid} auctionid={projToDisplay.auctionid} />
                </div>
                
            )) : <div className="flex justify-center center">
            <img style={{height:'150px',width:'auto',objectFit:'cover'}} src="/images/no-data.png"></img>
        </div>}
            { bidHisroty && <Bidhistory product_id={this.state.product_id} close={this.bidHistoryClick}/> }
            { deedModel && <Canceldeeds product_id={this.state.product_id} close={this.cancelDeedsHandle} user={this.props.user} />}
            {this.state.closeDeal.toggle && <Popup color="green"
                                title="Close the Deal"
                                description = "Please enter your Sold price"
                                testBox={true}
                                yesTo="Cancel" // btn 1
                                noTo="Accept" // btn 2
                                btnCount={2} // 1 or 2
                                popupHandle={this.popupHandle}
                                extradata={{propertyArray:this.props.projToDisplay}}
                                
                            /> }
        </div>
        //:
        // {this.state.click?
//         <div className="col-md-9">
//                 <div class="flex justify-between pre-txt">
//                           <span class="karla fw-600 text-black">SAVED BUYERS</span>
//                      </div>
//                   <div>
//                     <SellerSearch data={this.state.buyerData} url={this.state.url} from={"savedbuyer"}/>
                  
//                   {/* { !this.state.isLoading && <Loader/>} */}
//                   </div>
//         </div>
//   }
//   </div>
    )
        

  }
}

export default ViewOne;