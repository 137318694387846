import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './Productpost.css';
import us_states from '../../util/us_states'
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import { LOGIN } from '../../actions';
import { NOTIFICATION,PATHNAMEUPDATE } from '../../actions/index';
import Placeselector from '../../components/Placeselector/Placeselector';



import axios from 'axios';
import moment from 'moment';

//Component Import
import Imageview1 from '../../components/Imageview/Imageview1';
import Deed from '../../components/Deed/Deed';
import Stripe from '../../components/Payment/Stripe';
import Autocomplete from '../../components/Autocomplete/Autocomplete';

import Popup from '../../components/Popup/Popup';
import DateTime from '../../util/Datetime'
const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: "#6A6A6A",
        color: "white",
        width: 300,
        height: 150,
        fontSize: theme.typography.pxToRem(22),
        border: "1px solid #dadde9",

    }
}))(Tooltip);
class Productpost extends Component {
    state = {
        error: '',
        imgSrc: [],
        title: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        description: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        saletype: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        sellertype: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        propertycondition:  {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        pricerangemax: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        pricerangemin: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertype: {
            data: [],
            required: true,
            errorMsg: "Required"
        },
        location: {
            data: [],
            required: true,
            errorMsg: "Required"
        },
        bathroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bedroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        min_property_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        min_land_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        auctiontype: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bprice: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        start_date: {
            data: '',
            required: false,
            errorMsg: "Required",
            changed:false
        },
        end_date: {
            data:'',
            required: false,
            errorMsg: "Required",
            changed:false
        },
        dualAgent: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertyzone:{
            data:'',
            required:false,
            errorMsg:'Required'
        },
        openHouseDate:{
            data:'',
            required:false,
            errorMsg:'Required'  ,
            changed:false
        },
        deedData: '',
        deedModel: false,
        required: {},
        formError: {},
        selectedFile: [],
        doc_selectedFile: [],
        market_status: { data: "draft" },
        multiple: true,
        avatar: "",
        payModel: false,
        popupModel: false,
        stripeModal:false,
        customerid: '',
        paymentid: '',
        mob_up_flag:false,
        date: "1990-06-05",
        format: "YYYY-MM-DD",
        inputFormat: "DD/MM/YYYY",
        mode: "date",
        addalisting : false,
        termsToggle:false,
        termsMsg : "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi vero dignissimos eius officia illum. Perspiciatis distinctio maxime quo, officiis dignissimos delectus sed fuga aspernatur iusto nisi nihil modi placeat nesciunt."
    }

    handleValidation() {
        let self = this;
        Object.keys(this.state).map(function (key) {
            if (self.state[key] && self.state[key].required && self.state[key].data === "") {
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
            }
            if(!self.state['addalisting'] && key==='addalisting'){
                console.log('aaaaaaaaaaaaaaaaaaaaaaa')
                self.state.formError[key] = 'Required'
            }

        });

        self.setState({
            formError: self.state.formError
        },()=>{console.log(self.state.formError)});
    }

    formSubmit(e) {
        e.preventDefault();
        const{loggedIn} = this.props;
        if(!loggedIn){
            this.props.props_.history.push('/login');
            this.props.props_.dispatch(NOTIFICATION({msg:'Kindly Login As a Seller'}))
            return false;
        }
        this.handleValidation();
        const data = new FormData()
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        if ((Object.keys(self.state.formError)).length > 0) {
            return false;
        }
        Object.keys(self.state).map(function (key) {
            if(key==='start_date'||key==="openHouseDate")
                return (self.state[key] && self.state[key].changed) ? data.append(key, self.state[key].data) : '';
            return (self.state[key]) ? data.append(key, self.state[key].data) : '';
        });
        if (this.state.selectedFile) {
            this.state.selectedFile.map(function (file, index) {
                data.append('uploaderfile', file, file.name);
            });
        }

        if (this.state.doc_selectedFile) {
            this.state.doc_selectedFile.map(function (file, index) {
                data.append('inspect_document', file, file.name);
            });
        }

        if (self.state.deedData != "") {
            Object.keys(self.state.deedData).map(function (key) {
                return (self.state.deedData[key]) ? data.append(key, self.state.deedData[key].data) : '';
            });
            [self.state.deedData.selectedFile].map(function (file, index) {
                data.append('deed_document', file, file.name);
            });
        }

        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url + 'mobileapi/sellerpostprojectlive', data)
            .then(function (response) {
                if (response.data.success == "no") {
                    self.setState({
                        error: response.data.failedreason
                    })
                    return false;
                }
                let msg = (self.state.auctiontype.data === 'offers') ? 'Deed posted successfully' : 'Property posted successfully';
                self.props.dispatch(NOTIFICATION({ msg: msg }))
                
                if(self.state.auctiontype.data === 'offers'){
                    self.props.dispatch(PATHNAMEUPDATE({pathName:"/dashboard/mylistings"}))
                    self.props.history.push("/dashboard/mylistings");
                    
                }else{
                    self.props.history.push("/dashboard/seller")
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });
    }
    handleDateChange = (returnlabel, val) => {
        var returnlabel = returnlabel.toString()
         if(this.state.formError[returnlabel]){delete this.state.formError[returnlabel]}
        var prestate = this.state[returnlabel];
        prestate.data = val;
        prestate.changed = true;
        this.setState({
            [returnlabel]: prestate
        })
    }
    handleChange = name => event => {
        let preState = this.state[name];
        if (this.state.formError[name]) { delete this.state.formError[name] }
        switch (name) {
            case 'propertype':
                delete this.state.formError['location'];
            case 'location':
                delete this.state.formError['propertype']
                let limit = (name === 'location') ? 3 : 2
                if (this.state.formError[name]) {
                    delete this.state.formError[name]
                }

                if (preState.data.length <= limit) {
                    if (preState.data.indexOf(event.target.value) < 0 && event.target.value!=='') {
                        this.state.selectedOptionClass = "selectedOption"
                        preState.data.push((event.target.value==='Contract' && name==='propertype')?'Trust Deed and Contract':event.target.value); 
                    }
                }
                else
                    this.state.formError[name] = (limit === 3) ? "You can only choose up to four types" : "You can only choose up to three types"
                break;
            case 'sellertype':
                this.state.auctiontype.data = (event.target.value === "Pocket Listing") ? event.target.value : this.state.auctiontype.data

            default:
                preState.data = event.target.value;
                this.state.selectedOptionClass = ""
                delete this.state.formError['propertype']
                delete this.state.formError['location']
                break;
        }
        // if(name === "start_date" || name === 'end_date' || name==='openHouseDate'){
        //     preState.data = moment( preState.data).format('YYYY-MM-DD HH:mm:ss');
        //     preState.changed = true;
        // }
        if(event.target.value === 'auction' && name === 'auctiontype'){
            this.state.start_date.required = false;
            this.state.end_date.required = false;
        }
        if (event.target.value === 'listing' && name === 'auctiontype')
            this.state.end_date.data = moment(moment(new Date()).add(10, 'years')).format('YYYY-MM-DD HH:mm:ss')

        if (name === 'propertype' && event.target.value === 'Contract' && this.state.paymentid === ''){

            this.state.stripeModal = true
        }
        else
            this.state.stripeModal = false
        
        if(this.state.paymentid != '' && this.state.customerid === '' && name === 'propertype' && event.target.value === 'Contract')
            this.state.popupModel = true
        else
            this.state.popupModel = false

        this.setState({
            [name]: preState,
            formError: this.state.formError,
            error: '',
            selectedOptionClass: this.state.selectedOptionClass,
            auctiontype: this.state.auctiontype,
            start_date:this.state.start_date,
            end_date:this.state.end_date,
            popupModel : this.state.popupModel
        });
    };

    onPlaceChanged = (data) => {
        const preState = this.state.location;
        if (preState.data.indexOf(data) < 0) {
            this.state.selectedOptionClass = "selectedOption"
            // data = data.replace(/,/g,".")
            preState.data.push(data); 
        }
        this.setState({
            location: preState,
            selectedOptionClass: this.state.selectedOptionClass,
        });
    }

    onChange(type) {
        let files = this.refs.file.files
        let doc_files = (this.refs.document) ?  this.refs.document.files : ''
        if (type === "inspect") {
            delete this.state.formError['auctiontype']
            this.state.doc_selectedFile = []
            Object.keys(doc_files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(doc_files[index]);
                this.state.doc_selectedFile.push(doc_files[index])
                reader.onloadend = function (e) {
                    this.setState({
                        doc_selectedFile: this.state.doc_selectedFile,
                        formError: this.state.formError,
                        mob_up_flag:true
                    })
                }.bind(this);
            })
        }
        if (type === "img") {
            Object.keys(files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(files[index]);
                reader.onloadend = function (e) {
                    files[index]['imgsrc'] = reader.result;
                    this.state.selectedFile.push(files[index])
                    this.state.imgSrc.push(reader.result)
                    this.setState({
                        imgSrc: this.state.imgSrc,
                        selectedFile: this.state.selectedFile,
                        mob_up_flag:true
                    })
                }.bind(this);
            });
        }
    }
    removeFromArray = (name, ind) => event => {

        let prestate = this.state[name]
        let limit = (name === "location") ? 4 : 3
        if (prestate.data.length <= limit) {
            if (this.state.formError[name])
                delete this.state.formError[name]
        }
        prestate.data.splice(ind, 1)
        this.setState({
            [name]: prestate
        })
    } 
    handleClick = (name, flag) => event => {

        if (this.state.formError[name]) { delete this.state.formError[name] }
        let prestate = this.state[name];
        prestate.data = flag
        this.setState({
            [name]: prestate
        })
    }

    deedCloseHandle = () => {
        this.state.auctiontype.data = "";
        this.state.propertype.data.splice(this.state.propertype.data.indexOf('Contract'),1)
        this.setState({
            auctiontype: this.state.auctiontype,
            propertype:this.state.propertype
        }, () => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        })
    }

    deedSubmitHandle = (data) => {
        this.setState({
            deedModel: true,
            deedData: data
        }, () => {
            document.body.style.overflow = "auto";
            document.body.style.paddingRight = "0px";
        })
    }

    validateUser = () => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user && this.props.user.userdata?self.props.user.data:'';
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {
            "userid": self.props.user && self.props.user.userdata ? self.props.user.userdata.id:''
        }
        let auctiontype = this.state['auctiontype']
        auctiontype.data = (this.props.user && this.props.user.userdata) ? this.props.user.userdata.role === 'realtor' ? 'pocketlisting' : '' : ''
        axios.post(url + 'mobileapi/userdata', payload)
            .then(function (response) {
                if (response.data.success === "yes") {
                    self.setState({ customerid: 
                                    (response.data.data.customerid && response.data.data.paymentid) 
                                    ? response.data.data.customerid : response.data.data.paypal_order_id 
                                    ? response.data.data.paypal_order_id : '', paymentid: response.data.data.customerid 
                                    ? response.data.data.customerid : response.data.data.paypal_subscribtion_id 
                                    ? response.data.data.paypal_subscribtion_id : ''})
                } else {
                    self.setState({ error: "Try again!" })
                }
            })
            .catch(function (error) {
                self.setState({ error: "Try again!" })
            });
    }

    componentDidMount = () => {
        this.props.dispatch(PATHNAMEUPDATE({pathName: (this.props.user&& this.props.user.userdata) ? this.props.user.userdata.role==='realtor' ? "/dashboard/pocketlisting":"/dashboard/post":''}))
        this.validateUser();

    }

    StripeBoxHandle = () => {
        this.setState({
            payModel: !this.state.payModel,
        }, async () => {
            await this.validateUser();
        })
    }  

    StripeBoxCloseHandle = () => {
        this.state.auctiontype.data = "";
        let Index = this.state.propertype.data.findIndex(ele=>ele==='Contract');
        this.state.propertype.data.splice(Index,1);
        this.setState({
            auctiontype: this.state.auctiontype,
            propertype:this.state.propertype,
            stripeModal: false
        })
    } 

    popupHandle = (data) => {

        console.log(data,'DATA')
        if (data == "2") {
            this.subscription()
        } else {
            if(data=="0" || data=="1"){
                let Index = this.state.propertype.data.findIndex(ele=>ele==='Contract');
                this.state.propertype.data.splice(Index,1);
            }
            console.log(data,data=="0")
            this.state.auctiontype.data = "";
            this.setState({
                auctiontype: this.state.auctiontype,
                propertype:this.state.propertype,
                popupModel : (data=='0' || data=="1") ? false : true
            });
        }
    }

    subscription = () => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {
            "source": null,
            "project_id": 0,
            "amount": 30
        }
        axios.post(url + 'mobileapi/charge/1', payload)
            .then(async function (response) {
                if (response.data.success === "yes") {
                    await self.validateUser();
                    self.setState({ popupModel: !self.state.popupModel })
                } else {
                    self.setState({ error: "Try again!" })
                }
            })
            .catch(function (error) {
                self.setState({ error: "Try again!" })
            });
    }

    priceBlur = (pricerange) => event => {
        let minPrice = this.state.pricerangemin.data ? this.state.pricerangemin.data : 0,
            maxPrice = this.state.pricerangemax.data ? this.state.pricerangemax.data : 0;
        switch (pricerange) {
            case 'pricerangemin':
                if (maxPrice && Number(minPrice) > Number(maxPrice)) {
                    this.state.formError['pricerangemin'] = 'Minimum price should be lesser than Maximum price';
                }
                break;
            case 'pricerangemax':
                if (minPrice && Number(minPrice) > Number(maxPrice)) {
                    this.state.formError['pricerangemax'] = 'Maximum price should be greater than Minimum price';
                }
                break;
            default:
                break;
        }
        this.setState({
            formError: this.state.formError
        })
    }

    imageDelete = (index) => {
        let imgSrc = this.state.imgSrc;

        let selectedFileIndex = this.state.selectedFile.findIndex((sfile) => {
            return sfile.imgsrc === imgSrc[index];
        });
        this.state.selectedFile.splice(selectedFileIndex, 1);
        imgSrc.splice(index, 1)
        this.setState({
            imgSrc: imgSrc,
            selectedFile: this.state.selectedFile
        })
    }
    deleteInspectiondocument = () => {
        this.setState({
            doc_selectedFile: []
        })
    }
    checkboxClick = (type) => event =>{
        if(this.state.formError[type]){
            delete this.state.formError[type]
        }
        this.setState({
            [type] : (this.state.addalisting) ? false : true,
            formError : this.state.formError
        })
    }
    termsToggle=()=>{
        this.setState({termsToggle:true})
    }
    popupHandleTerms=()=>{
        this.setState({termsToggle:false})
    }
    render() {
        const {user,loggedIn} = this.props;
        const role = user && user.userdata ? user.userdata.role : '';
        const {date, format, mode, inputFormat} = this.state;
        return (
            <main>
                <div className={loggedIn?"col-md-9":"row"}>
                    <div className="col-md-12">
                        <h3 className="karla fw-600 text-black"> {role==='realtor' ? "Post A Realtor Listing" : 'ADD A LISTING'}</h3>
                        <div className="main-pro">
                            <div className="content-main box-shadow pad-30">
                                <form className="site-form" onSubmit={this.formSubmit.bind(this)}>
                                    <div className="row">
                                      {/*  <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Add Property Address</label>
                                                <Autocomplete onPlaceChanged={this.onPlaceChanged} />
                                                <div className="pan-block">
                                                    {this.state.location.data.length > 0 && this.state.location.data.map((ele, ind) => {
                                                        return <span className="token close" data-dismiss="modal" aria-label="Close">
                                                            {ele}
                                                            <span aria-hidden="true" onClick={this.removeFromArray('location', ind)}>
                                                                <i className="fas fa-times"></i>
                                                            </span>
                                                        </span>
                                                    })}
                                                </div>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["location"]}</span>
                                        </div> */}

                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Add location</label>
                                                <Placeselector onPlaceChanged={this.onPlaceChanged} placeholder = {' Choose A Search Area'}/>
                                                <div className="pan-block">
                                                    {this.state.location.data.length > 0 && this.state.location.data.map((ele, ind) => {
                                                        return <span className="token close" data-dismiss="modal" aria-label="Close">
                                                            {ele}
                                                            <span aria-hidden="true" onClick={this.removeFromArray('location', ind)}>
                                                                <i className="fas fa-times"></i>
                                                            </span>
                                                        </span>
                                                    })}
                                                </div>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["location"]}</span>
                                        </div>
                                        {role === 'seller' || !loggedIn ?
                                            <>
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label >Listing Type</label>
                                                        <select className="form-control site-input" id="" name="auctiontype" value={this.state.auctiontype.data} onChange={this.handleChange('auctiontype')}> 
                                                            <option value="">Choose Listing Type</option>
                                                            <option value="offers">Listing</option>
                                                            <option value="auction">Auction</option>
                                                        </select>
                                                    </div>
                                                    <span style={{ color: "red" }}>{this.state.formError["auctiontype"]}</span>
                                                </div>
                                            </>
                                            :

                                            <div className="col-md-6">
                                                <div className="form-group ">
                                                    <label >Listing Type</label>
                                                    <input type="text" className="form-control site-input" id="propsqfootage"
                                                        name="min_land_sq_footage"
                                                        placeholder={this.state.auctiontype.data === 'pocketlisting' ? "Realtor Listing" : ''}
                                                        readOnly={true}
                                                    />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Property Type</label>
                                                <select className="form-control site-input"
                                                    name="propertype"
                                                    value={this.state.propertype.data}
                                                    onChange={this.handleChange('propertype')}>
                                                    <option value="">Choose Property Type</option>
                                                    <option className={this.state.propertype.data.indexOf("Single Family") >= 0 && this.state.selectedOptionClass}
                                                        value="Single Family">Single Family</option>
                                                    <option className={this.state.propertype.data.indexOf("Multi-Family") >= 0 && this.state.selectedOptionClass}
                                                        value="Multi-Family">Multi-Family</option>
                                                    <option className={this.state.propertype.data.indexOf("Condo") >= 0 && this.state.selectedOptionClass}
                                                        value="Condo">Condo</option>
                                                    <option className={this.state.propertype.data.indexOf("Commercial") >= 0 && this.state.selectedOptionClass}
                                                        value="Commercial">Commercial</option>
                                                    <option className={this.state.propertype.data.indexOf("Vacant Land") >= 0 && this.state.selectedOptionClass}
                                                        value="Vacant Land">Vacant Land</option>
                                                    <option className={this.state.propertype.data.indexOf("Development Deal") >= 0 && this.state.selectedOptionClass}
                                                        value="Development Deal">Development Deal</option>
                                                    <option className={this.state.propertype.data.indexOf("Contract") >= 0 && this.state.selectedOptionClass}
                                                        value="Contract">Trust Deed </option>
                                                    <option className={this.state.propertype.data.indexOf("Contract") >= 0 && this.state.selectedOptionClass}
                                                        value="Contract">Wholesale Contract</option>
                                                </select>
                                                <div className="pan-block">
                                                    {this.state.propertype.data.length > 0 && this.state.propertype.data.map((property_Type, propertyInd) => {
                                                        return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                            {property_Type}
                                                            <span aria-hidden="true" onClick={this.removeFromArray('propertype', propertyInd)}>
                                                                <i className="fas fa-times"></i>
                                                            </span>
                                                        </span>
                                                    })}
                                                </div>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["propertype"]}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Choose Preferred Type Of Buyer Financing</label>
                                                <select className="form-control site-input"
                                                    name="saletype"
                                                    value={this.state.saletype.data}
                                                    onChange={this.handleChange('saletype')}>
                                                    {/* <option value="">Choose the Buyer Financing</option> */}
                                                    <option value="all">Any</option>
                                                    <option value="All Cash Only">All Cash Only </option>
                                                    <option value="Conventional">Conventional</option>
                                                    <option value="FHA">FHA</option>
                                                    <option value="VA">VA</option>
                                                </select>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["saletype"]}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label > Select Your Property's Condition</label>
                                                <select className="form-control site-input" id="" name="propertycondition" value={this.state.propertycondition.data} onChange={this.handleChange('propertycondition')}>
                                                    <option value="">Choose a condition of the Property</option>
                                                    <option value="Excellent - Completely Updated">Excellent - Completely Updated </option>
                                                    <option value="Good - Moderate updates">Good - Moderate updates </option>
                                                    <option value="Average - Few updates. Mostly Original">Average - Few updates. Mostly Original </option>
                                                    <option value="Poor - Repairs and updates needed">Poor - Repairs and updates needed</option>
                                                    <option value="Investor grade - major repairs and updates needed">Investor grade - major repairs and updates needed </option>
                                                    <option value="Land Value only">Land Value only </option>
                                                    <option value="Development Deal">Development Deal </option>
                                                </select>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["propertycondition"]}</span>
                                        </div>
                                        <div className="col-md-6 cl-bot">
                                            <div className="form-group">
                                                <div className=" prc-rang ">
                                                    <div className="form-inline ">
                                                    <label class="w-100">Open House Date
                                                    <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {"Date will be set after working with our team to set asking price, activate the listing and find interested buyers."}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                    </label>
                                                        <div className="form-group margin-finne">
                                                            <DateTime placeholder="Open House Date" value={this.state.openHouseDate.data} handleChange={this.handleDateChange} returnLabel="openHouseDate" />
                                                        </div>
                                                        <p style={{ color: "red" }}>{this.state.formError["openHouseDate"]}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                        {/* {this.state.auctiontype.data==='auction' || this.state.auctiontype.data==='pocketlisting' ?
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group site-form  date-mark">
                                                    <label style={{"width":"100%"}}>Auction Start Date
                                                    <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {"The Auction Start Date and End Date will be determined after working with our team to activate your listing."}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                    </label>
                                                    <DateTime placeholder="Auction Start Date" handleChange={this.handleDateChange} value={this.state.start_date.data} returnLabel="start_date" />
                                                </div>
                                                <span style={{ color: "red" }}>{this.state.formError["start_date"]}</span>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group site-form  date-mark">
                                                    <label  style={{"width":"100%"}}>Auction End Date
                                                    <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {"The Auction Start Date and End Date will be determined after working with our team to activate your listing."}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                    </label>
                                                    <DateTime placeholder="Auction End Date" handleChange={this.handleDateChange} value={this.state.end_date.data} returnLabel="end_date" />
                                                </div>
                                                <span style={{ color: "red" }}>{this.state.formError["end_date"]}</span>
                                            </div>
                                        </div>
                                            :''} */}
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Bedroom(s)</label>
                                                <select className="form-control site-input" id="" name="bedroom" value={this.state.bedroom.data} onChange={this.handleChange('bedroom')}>
                                                    <option value="">Choose the number of bedrooms</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10+">10+</option>
                                                </select>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["bedroom"]}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group selectdiv">
                                                <label >Bathroom(s)</label>
                                                <select className="form-control site-input" id="" name="bathroom" value={this.state.bathroom.data} onChange={this.handleChange('bathroom')}>
                                                    <option value="">Choose the number of bathrooms</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10+">10+</option>
                                                </select>
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["bathroom"]}</span>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group ">
                                                <label >Living Area (Sq ft) (optional)</label>
                                                <input type="text" className="form-control site-input" id="propsqfootage"
                                                    name="min_property_sq_footage"
                                                    value={this.state.min_property_sq_footage.data}
                                                    onChange={this.handleChange('min_property_sq_footage')}
                                                    placeholder="Living Area (Sq ft) (optional)"
                                                    min={100}
                                                    max={10000}
                                                />
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["min_property_sq_footage"]}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group ">
                                                <label >Lot Size (Sq ft) (optional)</label>
                                                <input type="text" className="form-control site-input" id="propsqfootage"
                                                    name="min_land_sq_footage"
                                                    value={this.state.min_land_sq_footage.data}
                                                    onChange={this.handleChange('min_land_sq_footage')}
                                                    placeholder="Lot Size (Sq ft) (optional)"
                                                    min={100}
                                                    max={30000}
                                                />
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["min_land_sq_footage"]}</span>
                                        </div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Zoning Type
                                                <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {" If you don't know zoning, this info can be added after working with out team to complete and activate your listing. "}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                </label>
                                                <input type="text" className="form-control site-input" id="zone"
                                                    name="Zoning type"
                                                    value={this.state.propertyzone.data}
                                                    onChange={this.handleChange('propertyzone')}
                                                    placeholder="Zoning Type"
                                                />
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["description"]}</span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label >Property Description</label>
                                                <textarea type="textarea" className="form-control site-input" id="propsqfootage"
                                                    name="description"
                                                    value={this.state.description.data}
                                                    onChange={this.handleChange('description')}
                                                    placeholder=" Highlight the key facts about your property."
                                                />
                                            </div>
                                            <span style={{ color: "red" }}>{this.state.formError["description"]}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group margin-finne">
                                            <label class='w-100'>{this.state.auctiontype && (this.state.auctiontype.data === 'pocketlisting' || this.state.auctiontype.data === 'listing') ? 'Asking Price' : 'Set Opening Bid'}
                                            <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {"Take your best guess. The final price will be set after receiving FREE SALES COMPS and a PHYSICAL INSPECTION REPORT from our team. "}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                            </label>

                                                            <div className=" prc-rang justify-between">
                                                            <div className="">
                                                                <div className="form-group widthauto">
                                                                    <div className="input-group ">
                                                                        <input type="text" className="form-control site-input" id="pricerangemin" name="pricerangemin" value={this.state.pricerangemin.data} onChange={this.handleChange('pricerangemin')} onBlur={this.priceBlur('pricerangemin')}
                                                                            placeholder="Price" />
                                                                        <div className="input-group-addon">$</div>
                                                                    </div>
                                                                </div>
                                                                <span style={{ color: "red" }}>{this.state.formError["pricerangemin"]}</span>
                                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6" style={{padding:0,margin:0}}>
                                            <Imageview1 multiple={this.state.multiple} imgSrc={this.state.imgSrc} avatar={this.state.avatar} imageDelete={this.imageDelete} multi_alias={this.state['mob_up_flag']} />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="">
                                                <div className="form-group post-up" style={{ "display": "inline-grid" }}>
                                                    <label htmlFor="exampleInputEmail1">Choose Image(s) to Upload</label>
                                                    <div className="upload-btn-wrapper ">
                                                        <button className="btn btn-primary font-15 karla">Upload Images <i className="flaticon-upload-button"></i>
                                                        <input
                                                            ref="file"
                                                            type="file"
                                                            multiple={true}
                                                            accept="application/image"
                                                            onChange={this.onChange.bind(this, "img")}
                                                        />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {role==='realtor'?
                                        <div className="row">
                                            <div className="commission-question-r">
                                                <label for="exampleInputEmail1" class="commission-question">
                                                   <p>  Are you willing to work with Buyers who are represented by a Buyer's agent?  </p>
                                                                    <span className="check flex justify-start" style={{ "margin": "-0.6em 0em 0em 0em" }}>
                                                        <label className="con">Yes
                                                                        <input type="radio" name="radio"
                                                                onClick={this.handleClick('dualAgent', 1)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                        <label className="con">No
                                                                        <input type="radio" name="radio"
                                                                onClick={this.handleClick('dualAgent', 0)} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </span>
                                                </label>
                                                <span style={{ color: "red" }}>{this.state.formError["dualAgent"]}</span>
                                            </div>
                                        </div>
                                                    :''}
                                                    {role==='seller'? 
                                    <div className="row">
                                                <div className="commission-question-div">
                                                        <label for="exampleInputEmail1" class="commission-question commission-question-div">
                                                        <p>Are you willing to accept offers from Buyers who are represented by agents, or do you want your Assigned Agent to act as a Dual Agent for both you and all Buyer?
                                                        {/* <Link to='/pricing/seller'>(link)</Link> */}
                                                        
                                                            <span 
                                                                data-toggle="tooltip" 
                                                                data-placement="right" 
                                                                title="" 
                                                                data-original-title="Are you comfortable with one agent managing a transaction between you and a Seller or would you prefer a dedicated buyer's agent? " 
                                                                className="red-tooltip d-inline-block"
                                                            > 
                                                                <svg 
                                                                    version="1.1" 
                                                                    id="Capa_1" 
                                                                    width="15px" 
                                                                    height="15px" 
                                                                    x="0px" 
                                                                    y="0px" 
                                                                    viewBox="0 0 23.625 23.625" 
                                                                    style={{"enable-background":"new 0 0 23.625 23.625","margin": "-1px 7px","cursor":"pointer"}}
                                                                    data-toggle="tooltip" title="aa"
                                                                >
                                                                    <g>
                                                                        <HtmlTooltip  placement="left"
                                                                        title={
                                                                                <React.Fragment>
                                                                                    <em> {"A Dual Agent is a single agent who acts neutrally, managing all aspects of the purchase contract for both the Buyer and Seller, without favoring either side. On our platform, sellers pay a lower commission for Dual Agency. Buyers pay nothing and receive a rebate at close of escrow. "}</em>
                                                                                    
                                                                                </React.Fragment>
                                                                            }>
                                                                        <path 
                                                                            style={{"fill":"#6A6A6A"}}
                                                                            d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                            S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                            c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                            l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                            c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                            c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                            c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                            c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                            c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                            c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                            c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                        </path>
                                                                        </HtmlTooltip>
                                                                    </g>
                                                                </svg>
                                                            </span></p>
                                                            <span className="check flex justify-start">
                                                                <label className="con">Yes
                                                                <input type="radio"  name="radio" 
                                                                onClick={this.handleClick('dualAgent',1)}/>
                                                                <span className="checkmark"></span>
                                                                </label>
                                                                <label className="con">No
                                                                <input type="radio"  name="radio"  
                                                                onClick={this.handleClick('dualAgent',0)}/>
                                                                <span className="checkmark"></span>
                                                                </label>
                                                            </span>
                                                        </label>
                                                        <span style={{ color: "red" }}>{this.state.formError["dualAgent"]}</span>
                                                    </div>
                                                </div>
                                                    :''}
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="pro-chk">

                                                <label className="show-check karla">{role === 'realtor' ? 
                                                <span>Realtor attests that Realtor has a valid salesperson’s or broker’s license in the state of California. For any transaction initiated on this site,  Realtor also agrees to the DoorRise <a target="_blank"   href="/terms">terms of service</a>, which includes paying the publicly-posted cooperating broker’s commission (no less than 2%) to DoorRise’s assigned Buyer broker.</span>
                                                    : <span>I agree to the <Link href='javascript:void(0)' onClick={this.termsToggle}> Terms and Conditions </Link> of DoorRise. This includes agreeing to be represented by the company’s preferred real estate broker or brokers for any transaction initiated on this platform.</span>}
                                                    <input className="check-height" type="checkbox" checked={this.state.addlisting} onClick={this.checkboxClick('addalisting')} />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <span style={{ color: "red" }}>{this.state.formError["addalisting"]}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-center pad-tb-30">
                                        <button type="submit" className="btn btn-secondary ">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.stripeModal && this.state.customerid === '' && this.state.paymentid === '' && !this.state.payModel && 
                    <Stripe 
                        project_id={0} 
                        onChangeHandle={this.StripeBoxHandle}
                        StripeBoxCloseHandle={this.StripeBoxCloseHandle}
                        amount={50000}
                        subscription={true}
                        dispatch = {this.props.dispatch}
                        PaypalSubscribeModel = {true}
                    /> 
                }    
                { this.state.popupModel  && this.state.customerid === '' && this.state.paymentid !== ''  && 
                <Popup color="green"
                    title="Confirmation"
                    description = "If accept $34 will be debited for monthly subscription"
                    yesTo="Cancel" // btn 1
                    noTo="Accept" // btn 2
                    btnCount={2} // 1 or 2
                    popupHandle={this.popupHandle}
                /> }
                {this.state.termsToggle ? <Popup color={"black"} title={"Terms and Conditions"} description={this.state.termsMsg} popupHandle={this.popupHandleTerms}/>:''}      
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Productpost); 