import React, {Component} from 'react';
import { connect } from 'react-redux';
import './RealtorPocketListing.css';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import axios from 'axios';
import moment from 'moment';
import{NOTIFICATION} from '../../actions/index'
import Imageview1 from '../../components/Imageview/Imageview1';
const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: "#6A6A6A",
      color:"white",
      width: 300,
      height: 150,
      fontSize: theme.typography.pxToRem(22),
      border: "1px solid #dadde9",
      
    }
  }))(Tooltip);
class RealtorPocketListing extends Component {
    state = {
        error: '',
        imgSrc:[],
        title: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        description: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertyAddress: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertyAskingPrice: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        freeCompsReport: {
            data: 0,
            required: true,
            errorMsg: "Required"
        },
        buyerFinancingType: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertycondition: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertype: {
            data: [],
            required: true,
            errorMsg: "Required"
        },
        propertyZone: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bathroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        bedroom: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        min_property_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        min_land_sq_footage: {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        puechaseTimeline: {
            data: "",
            required: true,
            errorMsg: "Required"
        }, 
        dualAgent: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        auctiontype: {
            data: "pocketlisting",
            required: false,
            errorMsg: "Required"
        },
        start_date: {
            data: moment().format('YYYY-MM-DD HH:mm:ss'),
            required: true,
            errorMsg: "Required"
        },
        end_date: {
            data: moment().add(5,"days").set({hour:23,minutes:59,second:0}).format('YYYY-MM-DD HH:mm:ss'),
            required: true,
            errorMsg: "Required"
        },
        required: {},
        formError: {},
        selectedFile: [],
        agents: [],
        doc_selectedFile : [],
        market_status: {data:"draft"},
        multiple : true,
        avatar:"",
        selectedOptionClass:"",
        mob_up_flag:false
    }
    handleValidation(){
        let self = this;
        Object.keys(this.state).map(function(key) { 
            
            if(self.state[key] && self.state[key].required && self.state[key].data === ""){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
            }
            self.setState({
                formError: self.state.formError
            })
        });
    }
    formSubmit = (e) => {
        e.preventDefault();
        const {loggedIn} = this.props
        if(!loggedIn){
            this.props.props_.history.push('/login');
            this.props.props_.dispatch(NOTIFICATION({msg:"Kindly Login as Realtor"}))
            return false
        }
         this.handleValidation();
         
         const data = new FormData()
         let self = this;
         axios.defaults.headers.common['Authorization'] = this.props.user ? this.props.user.data:'';
        if ((Object.keys(self.state.formError)).length > 0 ){
            return false;
        }
        Object.keys(self.state).map(function(key) { 
            return (self.state[key]) ? data.append(key,self.state[key].data) : '';
        });
        if(this.state.selectedFile){
            this.state.selectedFile.map(function(file,index) { 
                data.append('uploaderfile', file, file.name);
            });
        }
        if(this.state.doc_selectedFile){
            this.state.doc_selectedFile.map(function(file,index) { 
                data.append('inspect_document', file, file.name);
            });
        }
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/sellerpostprojectlive', data)
            .then(function (response) {
                if (response.data.success === "no") {
                    self.setState({
                        error: response.data.failedreason
                    })
                    return false;
                }
                self.props.history.push("/dashboard/realtor");
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });
    }
    handleClick = (name,flag) => event => {
        // console.log(name,flag)
        if(this.state.formError[name]){ delete this.state.formError[name] }
        let prestate = this.state[name];
        prestate.data = flag
        this.setState({
            [name] : prestate
        })
    }
    handleChange = name => event => {

        let preState = this.state[name];
        if(this.state.formError[name]){ delete this.state.formError[name] }
        // preState.data = event.target.value;   
        switch(name){
            case 'propertype' :
                delete this.state.formError['location'];
            case 'location'   :
                delete this.state.formError['propertype']
                let limit = (name==='location') ? 3 : 2
                if(this.state.formError[name])
                    delete this.state.formError[name]
                    
                if(preState.data.length<=limit){
                    if(preState.data.indexOf(event.target.value)<0)
                    {
                        this.state.selectedOptionClass = "selectedOption"
                        preState.data.push(event.target.value)
                    }
                }
                else
                    this.state.formError[name] = (limit === 3) ? "You Can only choose up to four types" : "You Can only choose up to three types" 
            break;
            default :
                preState.data = event.target.value;
                this.state.selectedOptionClass = ""
                delete this.state.formError['propertype']
                delete this.state.formError['location']
            break;
        }     
        this.setState({
            [name]: preState,
            formError: this.state.formError,
            selectedOptionClass : this.state.selectedOptionClass,
            error: ''
        },()=>{console.log(this.state,"pppppppppp")});
    };
    onChange(type) {
        let files = this.refs.file.files
        let doc_files = (this.refs && this.refs.document) ? this.refs.document.files : ""
        if (type === "inspect") {
            delete this.state.formError['auctiontype']
            this.state.doc_selectedFile = []
            Object.keys(doc_files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(doc_files[index]);
                this.state.doc_selectedFile.push(doc_files[index])
                reader.onloadend = function (e) {
                    this.setState({
                        doc_selectedFile: this.state.doc_selectedFile,
                        formError: this.state.formError,
                        mob_up_flag:true
                    });
                }.bind(this);
            })
        }
        if (type === "img") {
            Object.keys(files).map((key, index) => {
                var reader = new FileReader();
                reader.readAsDataURL(files[index]);
                reader.onloadend = function (e) {
                    files[index]['imgsrc'] = reader.result;
                    this.state.selectedFile.push(files[index])
                    this.state.imgSrc.push(reader.result)
                    this.setState({
                        imgSrc: this.state.imgSrc,
                        selectedFile: this.state.selectedFile,
                        mob_up_flag:true
                    })
                }.bind(this);
            });
        }
    }
    removeFromArray = (name,ind) => event =>{
        
        let prestate = this.state[name]
        let limit = (name==="location") ? 4 : 3
        if(prestate.data.length <= limit){
            if(this.state.formError[name])
                delete this.state.formError[name]
        }
        prestate.data.splice(ind,1)
        this.setState({
            [name] : prestate
        })
    } 
    getAggent = () => {
        let self = this;
        axios.defaults.headers.common['Authorization'] = self.props.user ? self.props.user.data:'';
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let payload = {}
        axios.post(url + 'mobileapi/getagent', payload)
        .then(function (response) {
            if (response.data.success === "yes") {
                self.setState({ agents: response.data.data})
            } else {
                self.setState({ error: "Try again!" })
            }
        })
        .catch(function (error) {
            self.setState({ error: "Try again!" })
        });
    }

    componentDidMount = () => { 
        this.getAggent()
    }
    imageDelete = (index) => {
        let imgSrc = this.state.imgSrc;
        let selectedFileIndex = this.state.selectedFile.findIndex((sfile) => {
            return sfile.imgsrc === imgSrc[index];
        });
        this.state.selectedFile.splice(selectedFileIndex, 1);
        imgSrc.splice(index, 1)
        this.setState({
            imgSrc: imgSrc
        })
    }
    deleteInspectiondocument = () => {
        this.setState({
            doc_selectedFile: []
        })
    }
    render() {
        const {loggedIn} = this.props;
        return (
            <main>
                <div className="center-part pad-tb-30">
                    <div className="container">
                        <div className="flex justify-center">
                            <div className="col-md-12">
                                <h3 className="karla fw-600 text-black text-center">POCKET LISTINGS</h3>
                                <div className="main-pro">
                                    <div className="content-main box-shadow pad--both-100">
                                        <form className="site-form">
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label >Property Title </label>
                                                                <input type="text" className="form-control site-input" id="propsqfootage"
                                                                    name="title"
                                                                    value={this.state.title.data}
                                                                    onChange={this.handleChange('title')}
                                                                    placeholder="Title"
                                                                />
                                                            </div>
                                                            <span style={{ color: "red" }}>{this.state.formError["title"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label >Property Description</label>
                                                                <textarea type="textarea" className="form-control site-input" id="propsqfootage"
                                                                    name="description"
                                                                    value={this.state.description.data}
                                                                    onChange={this.handleChange('description')}
                                                                    placeholder="Description"
                                                                />
                                                            </div>
                                                            <span style={{ color: "red" }}>{this.state.formError["description"]}</span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label htmlFor="exampleInputEmail1">Property Address</label>
                                                                <input type="text" className="form-control site-input" placeholder="Property Address" 
                                                                value={this.state.propertyAddress.data}
                                                                onChange={this.handleChange('propertyAddress')}/>
                                                                <span style={{color: "red"}}>{this.state.formError["propertyAddress"]}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group ">
                                                                <label htmlFor="exampleInputEmail1">Property Asking Price</label>
                                                                <input type="number" className="form-control site-input" placeholder="Property Asking Price" 
                                                                value={this.state.propertyAskingPrice.data}
                                                                onChange={this.handleChange('propertyAskingPrice')}/>
                                                                <span style={{color: "red"}}>{this.state.formError["propertyAskingPrice"]}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="exampleInputEmail1">Free Comps Report?</label>
                                                            <select name="" className="form-control site-input" id=""
                                                            value={this.state.freeCompsReport.data}
                                                            onChange={this.handleChange('freeCompsReport')}>
                                                                    <option value="1">Yes</option>
                                                                    <option value="0">No</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label htmlFor="exampleInputEmail1">Preferred Buyer Financing Type</label>
                                                            <select name="" className="form-control site-input" id=""
                                                            value={this.state.buyerFinancingType.data}
                                                            onChange={this.handleChange('buyerFinancingType')}>
                                                                <option value="">Any</option>
                                                                <option value="All Cash">All Cash</option>
                                                                <option value="Conventional">Conventional</option>
                                                                <option value="FHA/VA">FHA/VA</option>
                                                                <option value="Buyer Assistance Program">Buyer Assistance Program</option>
                                                            </select>
                                                            
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertyAskingPrice"]}</span>
                                                    </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                            <label >Preferred Condition</label>
                                                            <select  className="form-control site-input" id="" name="propertycondition" value={this.state.propertycondition.data} onChange={this.handleChange('propertycondition')}>
                                                                <option value="">Choose a condition of the Property</option>
                                                                <option value="Any">Any </option>
                                                                <option value="Excellent - Completely Updated">Excellent - Completely Updated </option>
                                                                <option value="Good - Moderate updates">Good - Moderate updates </option>
                                                                <option value="Average - Few updates. Mostly Original">Average - Few updates. Mostly Original </option>
                                                                <option value="Poor - Repairs and updates needed">Poor - Repairs and updates needed</option>
                                                                <option value="Investor grade - major repairs and updates needed">Investor grade - major repairs and updates needed </option>
                                                                <option value="Land Value only">Land Value only </option>
                                                                <option value="Development Deal">Development Deal </option>
                                                            </select>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertycondition"]}</span>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group selectdiv">
                                                            <label >Preferred Property Type(s)</label>
                                                            <select className="form-control site-input" 
                                                                name="propertype" 
                                                                value={this.state.propertype.data} 
                                                                onChange={this.handleChange('propertype')}>
                                                                    <option value=""> { (this.state.propertype.data.length==0)?"Choose Property Type" : this.state.propertype.data.join(",")}</option>
                                                                    <option className={this.state.propertype.data.indexOf("Single Family")>=0 && this.state.selectedOptionClass} 
                                                                            value="Single Family">Single Family</option>
                                                                    <option className={this.state.propertype.data.indexOf("Multi-Family")>=0 && this.state.selectedOptionClass} 
                                                                    value="Multi-Family">Multi-Family</option>
                                                                    <option className={this.state.propertype.data.indexOf("Condo")>=0 && this.state.selectedOptionClass} 
                                                                    value="Condo">Condo</option>
                                                                    <option  className={this.state.propertype.data.indexOf("Commercial")>=0 && this.state.selectedOptionClass} 
                                                                    value="Commercial">Commercial</option>
                                                                    <option className={this.state.propertype.data.indexOf("Vacant Land")>=0 && this.state.selectedOptionClass}  
                                                                    value="Vacant Land">Vacant Land</option>
                                                                    <option className={this.state.propertype.data.indexOf("Development Deal")>=0 && this.state.selectedOptionClass}  
                                                                    value="Development Deal">Development Deal</option>
                                                                    <option className={this.state.propertype.data.indexOf("Contract")>=0 && this.state.selectedOptionClass}  
                                                                    value="Contract">Contract </option>
                                                                </select>
                                                            <div className="pan-block">
                                                                {this.state.propertype.data.length> 0 && this.state.propertype.data.map((property_Type,propertyInd)=>{
                                                                   return <span className="token close" data-dismiss="modal" aria-label="Close" key={propertyInd}>
                                                                        {property_Type}
                                                                        <span aria-hidden="true"  onClick={this.removeFromArray('propertype',propertyInd)}>
                                                                            <i className="fas fa-times"></i>
                                                                        </span>
                                                                    </span>
                                                                })}
                                                            </div>
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["propertype"]}</span>
                                                    </div>
                                                
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label htmlFor="exampleInputEmail1">Bedrooms</label>
                                                        <select name="" className="form-control site-input" id="" 
                                                        value={this.state.bedroom.data}
                                                        onChange={this.handleChange('bedroom')}>
                                                            <option value="">Choose the number of bedroom</option>
                                                            
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="Above 5">Above 5</option>
                                                        </select>
                                                    </div>
                                                    <span style={{color: "red"}}>{this.state.formError["bedroom"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label htmlFor="exampleInputEmail1">Bathrooms</label>
                                                        <select name="" className="form-control site-input" id=""
                                                         value={this.state.bathroom.data}
                                                         onChange={this.handleChange('bathroom')}>
                                                            <option value="City">Choose the number of bath</option>
                                                            
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="Above 5">Above 5</option>
                                                        </select>
                                                    </div>
                                                    <span style={{color: "red"}}>{this.state.formError["bathroom"]}</span>
                                                </div>
                                            </div>

                                            <div className="row">
                                            <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Min.Property Sq. Footage(Optional)</label>
                                                            <input type="number" 
                                                                className="form-control site-input" 
                                                                id="propsqfootage" 
                                                                name="min_property_sq_footage" 
                                                                value={this.state.min_property_sq_footage.data}
                                                                onChange={this.handleChange('min_property_sq_footage')}
                                                                placeholder="10-10000"
                                                                min={100}
                                                                max={10000}
                                                            />
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["min_property_sq_footage"]}</span>
                                                    </div>
                                                <div className="col-md-6">
                                                        <div className="form-group ">
                                                            <label >Min.Land Sq. Footage(Optional)</label>
                                                            <input type="number" className="form-control site-input" id="propsqfootage" 
                                                            name="min_land_sq_footage" 
                                                            value={this.state.min_land_sq_footage.data}
                                                            onChange={this.handleChange('min_land_sq_footage')}
                                                            placeholder="10-30000"
                                                            min={100}
                                                            max={30000}
                                                             />
                                                        </div>
                                                        <span style={{color: "red"}}>{this.state.formError["min_land_sq_footage"]}</span>
                                                    </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label htmlFor="exampleInputEmail1">Property Zoning</label>
                                                        <select name="" className="form-control site-input" id=""
                                                        value={this.state.propertyZone.data}
                                                        onChange={this.handleChange('propertyZone')}>
                                                            <option value="">Select preferred Property Zoning</option>
                                                            <option value="Arizona">Arizona</option>
                                                            <option value="California">California</option>
                                                        </select>
                                                    </div>
                                                    <span style={{color: "red"}}>{this.state.formError["propertyZone"]}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group selectdiv">
                                                        <label htmlFor="exampleInputEmail1">Timeline for Selling</label>
                                                        <select name="" className="form-control site-input" id=""
                                                        value={this.state.puechaseTimeline.data}
                                                        onChange={this.handleChange('puechaseTimeline')}>
                                                            <option value="">Set a Purchase Timeline</option>
                                                            <option value="Immediately">Immediately </option>
                                                            <option value="Next 1 to 3 Months">Next 1 to 3 Months</option>
                                                            <option value="Next 3 to 6 Months"> Next 3 to 6 Months </option>
                                                            <option value="Next 6 to 12 Months">Next 6 to 12 Months </option>
                                                            <option value="1 Year or more">1 Year or more</option>
                                                        </select>
                                                    </div>
                                                    <span style={{color: "red"}}>{this.state.formError["puechaseTimeline"]}</span>
                                                </div>
                                            </div>
                                            <div className="row pad-tb-20"> 
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label htmlFor="exampleInputEmail1">
                                                                    Some of our Sellers require that Buyers work with our assigned Dual Agents. Would you be comfortable being in a dual agent transaction?
                                                                    <span id="tooltip"
                                                                        data-toggle="tooltip" 
                                                                        data-placement="right"
                                                                        title="" 
                                                                        data-original-title="A Dual Agent is a single Agent who acts neutrally, managing s all aspects of the purchase transaction for both the Buyer and the Seller, without favoring either side. On our platform, Sellers pay a lower commission for Dual Agency. Buyers pay nothing, and receive a rebate at close of escrow."
                                                                        className="red-tooltip">
                                                                            <svg 
                                                                                version="1.1" 
                                                                                id="Capa_1" 
                                                                                width="15px" 
                                                                                height="15px" 
                                                                                x="0px" 
                                                                                y="0px"
                                                                                viewBox="0 0 23.625 23.625" 
                                                                                style={{"enableBackground":"new 0 0 23.625 23.625"}}>
                                                                                    <g>
                                                                                    <HtmlTooltip  placement="left"
                                                                            title={
                                                                                    <React.Fragment>
                                                                                        <em> {"A Dual Agent is a single Agent who acts neutrally, managing s all aspects of the purchase transaction for both the Buyer and the Seller, without favoring either side. On our platform, Sellers pay a lower commission for Dual Agency. Buyers pay nothing, and receive a rebate at close of escrow."}</em>
                                                                                        
                                                                                    </React.Fragment>
                                                                                }>
                                                                            <path 
                                                                                style={{"fill":"#6A6A6A"}}
                                                                                d="M11.812,0C5.289,0,0,5.289,0,11.812s5.289,11.813,11.812,11.813s11.813-5.29,11.813-11.813
                                                                                S18.335,0,11.812,0z M14.271,18.307c-0.608,0.24-1.092,0.422-1.455,0.548c-0.362,0.126-0.783,0.189-1.262,0.189
                                                                                c-0.736,0-1.309-0.18-1.717-0.539s-0.611-0.814-0.611-1.367c0-0.215,0.015-0.435,0.045-0.659c0.031-0.224,0.08-0.476,0.147-0.759
                                                                                l0.761-2.688c0.067-0.258,0.125-0.503,0.171-0.731c0.046-0.23,0.068-0.441,0.068-0.633c0-0.342-0.071-0.582-0.212-0.717
                                                                                c-0.143-0.135-0.412-0.201-0.813-0.201c-0.196,0-0.398,0.029-0.605,0.09c-0.205,0.063-0.383,0.12-0.529,0.176l0.201-0.828
                                                                                c0.498-0.203,0.975-0.377,1.43-0.521c0.455-0.146,0.885-0.218,1.29-0.218c0.731,0,1.295,0.178,1.692,0.53
                                                                                c0.395,0.353,0.594,0.812,0.594,1.376c0,0.117-0.014,0.323-0.041,0.617c-0.027,0.295-0.078,0.564-0.152,0.811l-0.757,2.68
                                                                                c-0.062,0.215-0.117,0.461-0.167,0.736c-0.049,0.275-0.073,0.485-0.073,0.626c0,0.356,0.079,0.599,0.239,0.728
                                                                                c0.158,0.129,0.435,0.194,0.827,0.194c0.185,0,0.392-0.033,0.626-0.097c0.232-0.064,0.4-0.121,0.506-0.17L14.271,18.307z
                                                                                    M14.137,7.429c-0.353,0.328-0.778,0.492-1.275,0.492c-0.496,0-0.924-0.164-1.28-0.492c-0.354-0.328-0.533-0.727-0.533-1.193
                                                                                c0-0.465,0.18-0.865,0.533-1.196c0.356-0.332,0.784-0.497,1.28-0.497c0.497,0,0.923,0.165,1.275,0.497
                                                                                c0.353,0.331,0.53,0.731,0.53,1.196C14.667,6.703,14.49,7.101,14.137,7.429z">
                                                                            </path>
                                                                            </HtmlTooltip>
                                                                            </g>
                                                                            </svg>
                                                                        </span>
                                                                        
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                <span className="check flex justify-start">
                                                        <label className="con">Yes
                                                        <input type="radio"  name="radio" 
                                                        onClick={this.handleClick('dualAgent',1)}/>
                                                        <span className="checkmark"></span>
                                                        </label>
                                                        <label className="con">No
                                                        <input type="radio"  name="radio"  
                                                        onClick={this.handleClick('dualAgent',0)}/>
                                                        <span className="checkmark"></span>
                                                        </label>
                                                    </span>
                                        </div>
                                        <span style={{color: "red"}}>{this.state.formError["dualAgent"]}</span>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <Imageview1  multiple={this.state.multiple} imgSrc={this.state.imgSrc} avatar={this.state.avatar} imageDelete={this.imageDelete} multi_alias={this.state['mob_up_flag']}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group" style={{"display": "inline-grid","marginTop": "4em"}}>
                                                            <label htmlFor="exampleInputEmail1">Choose Image(s) to Upload</label>
                                                            <div className="upload-btn-wrapper ">
                                                                <button className="btn btn-primary font-15 karla">Upload Image <i className="flaticon-upload-button"></i></button>
                                                                <input 
                                                                ref="file" 
                                                                type="file" 
                                                                multiple={true}
                                                                onChange={this.onChange.bind(this,"img")}
                                                            />
                                                            </div>
                                                    </div>
                                                    {this.state.freeCompsReport.data =='1' && false && 
                                                    <div className="row pad-lr-15">
                                                        <div className="form-group" style={{display:"inline-grid","marginBottom": "1em"}}>
                                                            <label htmlFor="exampleInputEmail1">Upload Inspection Report </label>
                                                            
                                                            <div className="upload-btn-wrapper ">
                                                                <button className="btn btn-primary font-15 karla">Upload <i className="flaticon-upload-button"></i></button>
                                                                <input  type="file" 
                                                                        name="myfile" 
                                                                        ref="document"
                                                                        onChange={this.onChange.bind(this,"inspect")} />
                                                            </div>
                                                            <br></br>
                                                                {this.state.doc_selectedFile.length>0 && <label style={{color:"green"}}>{this.state.doc_selectedFile[0].name}<span  style={{color:"red",cursor:'pointer'}} onClick={this.deleteInspectiondocument}>x</span></label>}
                                                            <br></br>
                                                        </div>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="text-center pad-tb-30">
                                                <button type="submit" className="btn btn-secondary " onClick={this.formSubmit}>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(RealtorPocketListing);