import React, { Component } from 'react';

//Router import
import BuyerSearch from './Buyer/Search';
import SellerSearch from './Seller/Search';

class Search extends Component {

    render() {
        return (
            this.props.match.params.iam === "buyer" ?
                <BuyerSearch />
                :
                <SellerSearch />
        );
    }
}

export default (Search);
