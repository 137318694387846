import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {connect} from 'react-redux';
import Propductpopup from "../../../components/Productpopup/Productpopup";
import {properResult} from '../../../util/capsAndSpaces';
import {NOTIFICATION} from '../../../actions/index';
import ReportUser from '../../ReportBuyer/ReportBuyerPopup'
class SellerSearch extends Component {
  state = {
    poopupshow  : false,
    reportBuyerPopup : false,
    popupdata   : [],
    id          : "",
    reportUserId:"",
    savedSearchClass : "watch_f",
    saveddataClass :"watch_f",
    wishlist:new Array()
  }
  getSellerProjects=(id) => e =>{
    let self = this; 
    let url = process.env.REACT_APP_BASE_URL ? 
                                              process.env.REACT_APP_BASE_URL 
                                              : 
                                              "http://showpads.auctionsoftware.com/";
    let data = new Object();
    data['seller'] = this.props.user.userdata.id
    axios.post(url+'mobileapi/getsellerprojects', data).then(function(response){
      self.state["popupdata"] = properResult(response.data.data)
      self.setState({
        poopupshow : true,
        popupdata : self.state.popupdata,
        id:id
      });
      
    })
  } 
  close=()=>{
    this.setState({
      poopupshow : false
    });
  }
  sendProjectsToBuyers=(ids)=>{
    let self = this;
    let url = process.env.REACT_APP_BASE_URL ? 
                                              process.env.REACT_APP_BASE_URL 
                                              : 
                                              "http://showpads.auctionsoftware.com/";
    let data = new Object();
    data['ids'] = ids;
    data['user_id'] = this.state.id;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    axios.post(url+'mobileapi/sendprojectstobuyer',data).then(function(response){
      self.props.dispatch(NOTIFICATION({msg:"Successfully sent"}));
      if(response.data.success==="yes"){
        self.setState({
          poopupshow : false
        })
      }
    })
  }
  reportUserOpen=(id)=>e=>{
    this.setState({
      reportBuyerPopup : true,
      reportUserId:id
    })
  }
  reportBuyerClose=(type)=>{
    switch(type){
      case 'close':
        break;
      case 'save':
        this.props.dispatch(NOTIFICATION({msg:"Report sent to admin"}))
      break;
    }
    this.setState({
      reportBuyerPopup : false
    }) 
  }
  reportUserSubmit=()=>{
    console.log("submit report user")
  }
  addWatchlist = (projectId)=>event=>{
    event.preventDefault();
    let self = this;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let params = new Object();
    params["projectId"] = projectId;
    params["buyer"] = 1;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    axios.post(`${url}mobileapi/watchlist`,params).then(function(response){
      let index = self.props.data.findIndex((e) => (e.id === projectId) );
      self.props.data[index].watchdelete = 0;
      self.setState({
        wishlist : self.props.data,
      });
      
    })
  }
  removeWatchlist=(projectId)=>event=>{
    var self = this;
    axios.defaults.headers.common['Authorization'] = this.props.user.data;
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let params = new Object();
    params["projectId"] = projectId;
    params["buyer"] = 1;
    axios.post(`${url}mobileapi/removeFwatchlist`,params).then(function(response){
      let index = self.props.data.findIndex((e) => (e.id === projectId) );
      self.props.data[index].watchdelete = 1;
      if(self.props.from === "savedbuyer"){
        self.props.data.splice(index,1);
      }
      self.setState({
        wishlist : self.props.data,
      });
      
    })
  }
  render() {
    let { data, url } = this.props;
    let url1 = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com:7085/";
    let value = this.state.wishlist.length > 0 ? this.state.wishlist : data;
    console.log(value);
    let {loggedIn} = this.props;
    return (
      <div class="main-pro">
        {value.length > 0 ? value.map((data) => {
          return <div class="list-pro">
            <div class="row">
              <div class="col-md-3 col-sm-3 col-xs-4">
                <div class="contact">
                  <div class="profile ">
                   {loggedIn&&<span class="tt">
                      { ( data.watchdelete === 1 || data.watchdelete === "")
                          ? 
                          <b className={`watch_s`} onClick={this.addWatchlist(data.id)}></b>
                          :
                          ( data.watchproject === data.id) && data.watchdelete !== 1 
                          ? <b className={"watch_s "+this.state.saveddataClass} onClick={this.removeWatchlist(data.id)}></b> 
                          :
                          <b className={`watch_s`} onClick={this.addWatchlist(data.id)}></b>
                      }
                        
                    </span>}
                    <div class="div">
                      <span>A.C.</span>
                      <span>Buyer</span>
                    </div>
                  </div>
                  
                    {this.props.loggedIn ? 
                      <button 
                            class="btn btn-secondary" 
                            data-toggle="modal" 
                            data-target="#buy-sch"
                            onClick={this.getSellerProjects(data.id)}>Contact Buyer </button> 
                      :
                       <Link to={"/login"}> 
                        <button 
                              class="btn btn-secondary" 
                              data-toggle="modal" 
                              data-target="#buy-sch"
                              >Contact Buyer </button> 
                             </Link> 
                      }
                  
                </div>
              </div>
              <div class="col-md-9 col-sm-9 col-xs-8">
                <div class="pro-data">
                  <div class="product">
                    <div class="row">
                      <div class="col-md-7 col-sm-7 col-xs-7">
                        <div class="data-list">
                          {/* <span class="text-left ">Name :<b className="text-uppercase">{data.first_name[0]+'.'+data.last_name[0]}</b></span> */}
                          {/* <span class="text-left">Buyer's Preferred Financing :<b>{data.saletype}</b></span> */}
                          <span class="text-left"><b class="text-light ">Pref.Location:</b> <b>{data.location}</b></span>
                          <span class="text-left"><b class="text-light ">Pref.Property Type:</b> <b>{data.propertype}</b></span>
                          <span class="text-left"><b class="text-light ">Pref.Condition:</b> <b>{data.propertycondition}</b></span>
                          <span class="text-left"><b class="text-light ">Buyer's Preferred Financing:</b><b>{data.saletype}</b></span>
                        </div>
                      </div>
                      <div class="col-md-5 col-sm-5 col-xs-5">
                        <div class="data-list">
                    
                          <span ><b style={{color:"#929eaa"}}>{data.preqalified ===1 ? 'Financing Pre-Approved' : 'Financing Pre-Approval Pending' }</b></span>
                          <span class=""><b class="text-light">Budget:</b><b>${data.pricerangemin} to ${data.pricerangemax}</b></span>
                          <span class=""><b class="text-light "> Min. Living Area:</b> <b>{data.min_property_sq_footage}</b></span>
                          <span class=""> <b class="text-light ">Min. Lot Size:</b> <b>{data.min_land_sq_footage}</b></span>
                          <span class=""> <b class="text-light ">Pref: zoning:</b> <b>{data.propertyzone ? data.propertyzone : '-'}</b></span>
                          <span class="">
                            <ul class="ul-list">
                              <li class="pull-right" data-toggle="modal" data-target="#report">
                                {this.props.loggedIn ?
                                <a href="javascript:void(0)"  onClick={this.reportUserOpen(data.id)}>
                                  <img src={url1+"images/icons/flag.svg"} alt="img-responsive" />&nbsp;&nbsp;Report User
                                </a> 
                                :
                                <Link to="/login">
                                  <img src={url1+"images/icons/flag.svg"} alt="img-responsive" />&nbsp;&nbsp;Report User
                                </Link>
                                }
                              </li>
                            </ul>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }) : 
          <div className="flex justify-center center">
            <img style={{height:'150px',width:'auto',objectFit:'cover'}} src="/images/no-data.png"></img>
          </div>}
        {/* <!-- Modal --> */}
          {this.state.poopupshow && <Propductpopup 
            popupdata            =  {this.state.popupdata} 
            close                =  {this.close}
            sendProjectsToBuyers =  {this.sendProjectsToBuyers}
            />
          }
        {/* <!-- Model End --> */}
        {/* report User popup */}
        {this.state.reportBuyerPopup && <ReportUser 
           reportBuyerClose={this.reportBuyerClose}
           reportUserSubmit={this.reportUserSubmit}
           user={this.props.user}
           reportUserId={this.state.reportUserId}/>}
        
      </div>
    )
  }
}



function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(SellerSearch);