/**
 * @param {String} letter
 * @return {String}
 */
function capsAndSpaces(letter){
    try{
         var locationString = letter.split('_')
         .map(function(locationResult,locationIndex)
         {
           var uppercaseFirstLetter = locationResult.charAt(0).toUpperCase();
           var slicedElement = locationResult.slice(1);
           var locationElement = uppercaseFirstLetter+slicedElement
           return locationElement.replace(/[^A-Z0-9|^,]+/ig, " ")   
        }).join(' ');
       }catch(e){
           console.log(e)
        }
        try{
            var locationString = locationString.split(',')
            .map(function(locationResult,locationIndex)
            {
              var uppercaseFirstLetter = locationResult.charAt(0).toUpperCase();
              var slicedElement = locationResult.slice(1);
              var locationElement = uppercaseFirstLetter+slicedElement
              return locationElement.replace(/[^A-Z0-9|^,]+/ig, " ")   
           }).join(',');
          }catch(e){
              console.log(e)
           }

return locationString;
}

function properResult(result){
    var data =  result.map(function(searchResult,searchResultIndex){
        searchResult.location  =(searchResult.location) ?searchResult.location: "";
        searchResult.PropertyType=(searchResult.PropertyType)?capsAndSpaces(searchResult.PropertyType): "";
        searchResult.propertype=(searchResult.propertype)?capsAndSpaces(searchResult.propertype): "";
        searchResult.propertycondition=(searchResult.propertycondition)?capsAndSpaces(searchResult.propertycondition):"";
        searchResult.saletype=(searchResult.saletype)?capsAndSpaces(searchResult.saletype):"";
        searchResult.saletype_optional=(searchResult.saletype_optional)?capsAndSpaces(searchResult.saletype_optional):"";
        searchResult.sellertype=(searchResult.sellertype)?capsAndSpaces(searchResult.sellertype):"";
        return searchResult
    });
    
    return data;
}


export {properResult}