import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import SideNav from '../../components/SideNav/SideNav'
import axios from 'axios';
import { connect } from 'react-redux';
import Imageview1 from '../../components/Imageview/Imageview1';
import { string } from 'prop-types';
import {NOTIFICATION} from '../../actions/index';

class Profile extends Component {
    state = {
        first_name : {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        last_name : {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        // aboutme: {
        //     data: "",
        //     required: false,
        //     errorMsg: "Required"
        // },
        phone: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        reEnterPhone : {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        // zip : {
        //     data: "",
        //     required: true,
        //     errorMsg: "Required"
        // },
        // city : {
        //     data: "",
        //     required: true,
        //     errorMsg: "Required"
        // },
        email : {
            data: "",
            required: false,
            errorMsg: "Required"
        },
        intrested : {
            data: "0",
            required: true,
            errorMsg: "Required"
        },
        propertype : {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        propertyOwned : {
            data : "",
            required : true,
            errorMsg : "Required"
        },
        moreProperty : {
            data :"",
            required : false,
            errorMsg : "Required"
        },
        agency_affiliation:{
            data: "0",
            required: true,
            errorMsg: "Required"
        },
        license_number :{
            data: "",
            required: false,
            errorMsg: "Required"
        },
        count:0,
        pid:'',
        aid:'',
        selectedFile: [],
        imgSrc:[],
        multiple:Boolean,
        resp:false,
        avatar:"",
        error:'',
        required: {},
        formError: {},
        buyerpopup:false,
    }

    handleValidation(){
        let self = this;
        Object.keys(this.state).map(function(key) { 
            
            if(self.state[key] && self.state[key].required && self.state[key].data === ""){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required!";
            }
            self.setState({
                formError: self.state.formError
            })
        });
    }

    formSubmit(e) {
        e.preventDefault();
        this.handleValidation();
         const data = new FormData()
        console.log( data ," form data " )

        let self = this;
        if ((Object.keys(self.state.formError)).length > 0 ){
            return false;
        }
        Object.keys(self.state).map(function(key) { 
            return (self.state[key]) ? data.append(key,self.state[key].data) : '';
        });
        if(this.state.selectedFile){
            this.state.selectedFile.map(function(file,index) { 
                data.append('uploaderfile', file, file.name);
            });
            // data.append('imageuploads[]', this.state.selectedFile);
        }
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        
        axios.post(url+'mobileapi/dashboardProfileUpdate', data)
            .then(function (response) {
                if (response.data.success == "no") {
                    self.props.dispatch(NOTIFICATION({msg : response.data.failedreason , msgColor:"#D9534F"}))
                    return false;
                }
                
                if(response.data.success==="yes"){
                    self.props.dispatch(NOTIFICATION({msg : response.data.data}))
                }
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });
    }

    handleChange = name => event => {
        
        if(this.state.formError[name]){ delete this.state.formError[name]}
        if(name === 'agency_affiliation'){
            console.log(event.target.value,"lklklk")
            if(event.target.value==='0'){
                this.state.license_number.data = '';
                this.state.license_number.required = false;
            }else{
                this.state.license_number.required = true;
            }
        }
        let preState = this.state[name];
        this.state[name].data = event.target.value
        this.setState({
            [name] : preState,
            license_number : this.state.license_number,
            formError : this.state.formError
        });
    };
    getpropertycount  =   () =>  {
        let self    =   this;
        let user_id = this.props.user.userdata.id;
        let payload={
          id:this.props.user.userdata.id,
          profile:"1"
        };
        console.log("valuee",payload)
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"; 
        let completeUrl = `${url}mobileapi/property/`
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        axios.post(completeUrl,payload).then((response)=>{
            console.log("pro++++",response.data.data);
            
            self.setState({
               count:response.data.data,
               pid:response.data.pid,
               aid:response.data.aid
            })

        }) 
    }
    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let self_q = this
        axios.get(url+'mobileapi/profile/').then(function(response){
            if(response.data.success === 'yes'){
                console.log(response,"onload")
                //self_q.state.avatar = response.data.data.avatar
                Object.keys(response.data.data).map((ele,ind)=>{
                     if(self_q.state[ele]){
                        self_q.state[ele].data = (response.data.data[ele]) ? response.data.data[ele] : ""
                        if(ele === 'agency_affiliation') 
                            self_q.state[ele].data = (response.data.data[ele]) ? response.data.data[ele] : 0
                            
                        self_q.setState(
                            {[ele] : self_q.state[ele]}    
                            ) 
                     }else if(ele==="avatar"){
                        self_q.setState(
                            {avatar : response.data.data[ele], resp:true } ,
                           
                            ) 
                     }
                     switch(ele){
                         case 'propertyowned' :
                             self_q.state['propertyOwned'].data =  response.data.data["propertyowned"]
                             self_q.setState({
                                 propertyOwned : self_q.state.propertyOwned,
                             });
                         break;
                         case 'moreproperty':
                                self_q.state['moreProperty'].data =  response.data.data["moreproperty"];
                                self_q.state['intrested'].data =  response.data.data["moreproperty"]
                                self_q.setState({
                                    moreProperty : self_q.state.moreProperty,
                                    intrested : self_q.state.intrested
                                });
                         break;
                         case 'propertype' :
                             self_q.state['propertype'].data = response.data.data["propertype"].split("_").map((ele)=>{
                                ele=ele.charAt(0).toUpperCase() + ele.slice(1);
                                return ele
                             }).join(" ");
                         break;
                     }
                    
                })
            }   
        })
        this.getpropertycount();
    }
    
    onChange (event){
        let files = this.refs.file.files
        this.state.multiple = event.target.multiple
        Object.keys(files).map((key,index)=>{
            var reader = new FileReader();
                reader.readAsDataURL(files[index]);
                this.state.selectedFile.push(files[index])
            reader.onloadend = function (e) {
                if(this.state.multiple){
                     this.state.imgSrc.push(reader.result)}
                else{
                    this.state.imgSrc.shift()
                    this.state.imgSrc.push(reader.result)
                }
                
                this.setState({
                    imgSrc: this.state.imgSrc,
                    selectedFile: this.state.selectedFile,
                    multiple : this.state.multiple
                });
            }.bind(this);
        })
    }
    render() {
        return (
            
            <div className="col-md-9">
            
                <div class="flex justify-between pre-txt">
                    <span class="karla fw-600 text-black text-uppercase">Profile</span>
                </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">
                        <form className="site-form" onSubmit={this.formSubmit.bind(this)}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="first_name">First Name</label>
                                        <input type="text" className="form-control site-input" id="first_name"
                                            placeholder="Enter Your First Name"
                                            name="first_name"
                                            value={this.state.first_name.data}
                                            onChange={this.handleChange('first_name')} />
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["first_name"]}</span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Last Name</label>
                                        <input type="text" className="form-control site-input" id="exampleInputEmail1"
                                            placeholder="Enter Your Last Name"
                                            name="last_name"
                                            value={this.state.last_name.data}
                                            onChange={this.handleChange('last_name')} />
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["last_name"]}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Enter Your Mobile Number</label>
                                        <input type="number" className="form-control site-input" id="exampleInputEmail1"
                                            placeholder="Mobile Number"
                                            name="userPhone"
                                            value={this.state.phone.data}
                                            onChange={this.handleChange('phone')} />
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["phone"]}</span>
                                </div>
                                {/* <div className="col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="exampleInputEmail1">Re-Enter Mobile Number</label>
                                        <input type="number" className="form-control site-input" id="exampleInputEmail1" 
                                        placeholder="Re-Enter Mobile Number" 
                                        name="address" 
                                        value={(this.state.reEnterPhone.data && this.state.reEnterPhone.data!="undefined" ) ? this.state.reEnterPhone.data : ""} 
                                        onChange={this.handleChange('reEnterPhone')}/>
                                    </div>
                                    <span style={{color: "red"}}>{this.state.formError["reEnterPhone"]}</span>
                                </div> */}
                                <div class="col-md-6">
                                    <div class="form-group ">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text" className="form-control site-input" id="email"
                                                placeholder="Email"
                                                name="email"
                                                value={this.state.email.data}
                                                disabled />
                                        </div>
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["propertyOwned"]}</span>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group selectdiv">
                                        <label >Preferred Property Type(s)</label>
                                        <select class="form-control site-input"
                                            name="propertype"
                                            value={this.state.propertype.data}
                                            onChange={this.handleChange('propertype')}>
                                            <option value="">Choose Property Type</option>
                                            <option class={this.state.propertype.data.indexOf("Single Family") >= 0 && this.state.selectedOptionClass}
                                                value="Single Family">Single Family</option>
                                            <option class={this.state.propertype.data.indexOf("Multi-Family") >= 0 && this.state.selectedOptionClass}
                                                value="Multi Family">Multi-Family</option>
                                            <option class={this.state.propertype.data.indexOf("Condo") >= 0 && this.state.selectedOptionClass}
                                                value="Condo">Condo</option>
                                            <option class={this.state.propertype.data.indexOf("Commercial") >= 0 && this.state.selectedOptionClass}
                                                value="Commercial">Commercial</option>
                                            <option class={this.state.propertype.data.indexOf("Vacant Land") >= 0 && this.state.selectedOptionClass}
                                                value="Vacant Land">Vacant Land</option>
                                            <option class={this.state.propertype.data.indexOf("Development Deal") >= 0 && this.state.selectedOptionClass}
                                                value="Development Deal">Development Deal</option>
                                            <option class={this.state.propertype.data.indexOf("Contract") >= 0 && this.state.selectedOptionClass}
                                                value="Contract">Contract </option>
                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["propertype"]}</span>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group selectdiv">
                                        <label for="exampleInputEmail1">Number of properties owned.</label>
                                        <select
                                            name="propertyOwned"
                                            class="form-control site-input"
                                            id=""
                                            value={this.state.propertyOwned.data}
                                            onChange={this.handleChange('propertyOwned')}>
                                            <option value="">Choose An option</option>
                                            <option value="3">1-3</option>
                                            <option value="8">4-8</option>
                                            <option value="9">9+</option>
                                        </select>
                                    </div>
                                    <span style={{ color: "red" }}>{this.state.formError["propertyOwned"]}</span>
                                </div>
                            </div>
                            <div class='row'>
                                {this.props.user.userdata.role === 'realtor' ?
                                    <div class="col-md-6">
                                        <div className="form-group selectdiv">
                                        <label >Agency Affiliation</label>
                                        <select className="form-control site-input" id="type" name="role" value={this.state.agency_affiliation.data} onChange={this.handleChange('agency_affiliation')}>
                                            <option name="agency_affiliation" value="1">Yes</option>
                                            <option name="agency_affiliation" value="0">No</option>

                                        </select>
                                        <span style={{ color: "red" }}>{this.state.formError["agency_affiliation"]}</span>
                                    </div></div> : ''}
                                {(this.state.agency_affiliation.data === '1') || this.state.agency_affiliation.data === 1 ?
                                    <div class="col-md-6"><div className="form-group">
                                        <label >License number</label>
                                        <input type="text" name="license_number" className="form-control site-input" id="license_number" value={this.state.license_number.data} onChange={this.handleChange('license_number')}
                                            placeholder="Retype Your Password" />
                                        <span style={{ color: "red" }}>{this.state.formError["license_number"]}</span>
                                    </div></div> : ''}
                            {this.state.error ?
                                <div className="alert alert-success" role="alert">
                                    {this.state.error}
                                </div>
                                : ''}
                            </div>
                            <div className="text-center pad-tb-30">
                                <button type="submit" className="btn btn-secondary " >Save</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">
                    <form className="site-form">
                <div className="row">
            
                
                    <label>Matching <strong>Preferred Property Type: Separate Buyer/Seller Agents</strong> Property Details:</label>
                    
            
    </div>
    <div className="row">
            <div className="col-md-6">
                    <br></br>
                    <label>Listing Count:  </label><span> <strong>{this.state.count}</strong></span>
                    
            </div>
            <div className="col-md-6">
        {this.state.count!='1'?
            <Link to={"/dashboard/matchtransactiontype"}><button type="submit" className="btn btn-secondary " >View Listing</button></Link>:
            <Link to={"/product/view/" + this.state.pid+ "/" + this.state.aid}><button type="submit" className="btn btn-secondary " >View Listing</button></Link>} 
             </div>
    </div>
    </form>
            </div>
            </div>
    </div>
         
        )
    }
}


function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Profile);