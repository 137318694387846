import React, { Component } from 'react';
import './Bidhistory.css';

import axios from 'axios';

class Bidhistory extends Component {
    constructor(props){
        super(props);
        this.state = {
            tableData : [],
            error: '',
            count: 0
        }
    }

    loadData = () => {
        let self = this;
        let product_id = this.props.product_id;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.get(url+'mobileapi/getbiddetails/'+product_id,{})
            .then(function (response) {
                if(response && response.data){
                    self.setState({
                        tableData : response.data.results || [],
                        error: ''
                    })
                }
            })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        }); 
    }

    componentDidMount() {
        this.loadData();
    }

    close = () =>{
        this.props.close()
    }

    validate = (targetId) => {
        let set = []
        this.state.tableData.map((data,index)=>{
            (data.custom_awarded === "1") && !(data.bidid ===targetId) && set.push(data) ;
        })
        return set.length;
    }

    selectHandler = (targetId, custom_awarded) => async (event) => {
        let totalLength = await this.validate(targetId);
        if (totalLength !== 3) {
            let self = this;
            let param = {
                id: targetId,
                custom_awarded: custom_awarded === "1" ? "0" : "1"
            }
            let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            axios.post(url + 'mobileapi/updateawarded/', param)
                .then(async (response) => {
                    if (response.data && response.data.success === "yes") {
                        await this.loadData();
                    }
                })
                .catch(function (error) {
                    self.setState({
                        error: "Try later"
                    })
                });
        }else{
            this.setState({
                error: "Only You can chooses three bids!"
            })
        }
    }

    render() {
        let { tableData } = this.state;
        return (
            <div className="modal fade mod-place-bid in modal-open d_block" id="bidhis" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                <div className="modal-dialog top-10" role="document">
                    <div className="modal-content show-model bid-hiy">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.close}><span aria-hidden="true">&times;</span></button>
                            <h3 className="text-center karla fw-600"><span>BID HISTORY</span></h3>
                        </div>
                        <div className="modal-body ">
                            <div className="row">
                                <div className="table-responsive" id="style-roll">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Amount($)</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            <tr className="wt">
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                           {tableData.length > 0 && tableData.map((data,index)=>(
                                                <tr key={"tableData-"+index} 
                                                    id={data.bidid} 
                                                    onClick={this.props.isAwardEnable ? this.selectHandler(data.bidid,data.custom_awarded) : ''} 
                                                    style={( this.props.isAwardEnable && data.custom_awarded === "1")? {background:"#cbe4dc"} : {}}
                                                >
                                                <td>{data.namesec}</td>
                                                <td>{data.date_add.split(' ')[0]}</td>
                                                <td>{data.date_add.split(' ')[1]}</td>
                                                <td>${data.proposed_amount}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="text-center"><span style={{ color: "red" }}>{this.state.error}</span></div>
                                    {this.props.isAwardEnable && <p className="text-center"> 
                                        <b>Here, You can chooses the preferred 3 bids.</b> 
                                    </p>}
                                    {tableData.length === 0 && <p className="text-center"> 
                                        <b>Auction not yet started.</b> 
                                    </p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Bidhistory;
