import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import './Sellerdashboard.css';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import {PATHNAMEUPDATE} from '../../../actions/index'
import axios from 'axios';
import ReactDOM from 'react-dom'
import Loader from '../../../components/Loader/Loader';
import {properResult} from '../../../util/capsAndSpaces';
const ViewOne = React.lazy(() => import('../../../components/Cards/ViewOne'));

let productToObject = (market_status,PropertyType,image,beds,baths,location,startingBid,currentBid,closed_date,date_added,auctionid,pid,auctiontype,openhousedate,saletype)=>{
    return { market_status,PropertyType,image,beds,baths,location,startingBid,currentBid,closed_date,date_added,auctionid,pid,auctiontype,openhousedate,saletype }
}
/**
 * 
 * 
 * @class Sellerdashboard
 * @extends {Component}
 */
class Sellerdashboard extends Component {
    state = {
        error: '',
        data: [],
        dataToSend: [],
        market_status: "all",
        preMarketStatus: "all",
        page_no: 1,
        limit: 3,
        totalpage: 0,
        isLoading: true
    }

    tabeChange = (name) => event => {
            let market_status = "all"
            event.preventDefault()
            this.state.dataToSend = this.state.data
            this.all_menu.className = "active"
            this.active_menu.className = ""
            this.sold_menu.className  = ""
            switch(name){
                case "active" :
                        this.all_menu.className =   "";
                        this.active_menu.className  =   "active";
                        this.sold_menu.className    =   "";
                        market_status   =   "open";
                break;
                case "sold" :
                        this.all_menu.className =   ""
                        this.active_menu.className  =   ""
                        this.sold_menu.className    =   "active"
                        market_status   =   "sold";
                break;
            }
            this.setState({
                dataToSend : [],
                market_status : market_status ,
                page_no : 1
            } , ()  =>  {
                this.getDashboardProductDetails(this.state.market_status)
            });
        
    }
    getDashboardProductDetails  =   (market_status) =>  {
        let self    =   this;
        let user_id = this.props.user.userdata.id;
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url     = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        let completeUrl =   `${url}mobileapi/mylistings/${market_status}/${user_id}?limit=${this.state.limit}&&page_no=${this.state.page_no}`
        
        axios.get(completeUrl,{})
            .then(async function (response) {
                console.log(response,'------------------- get dashboard -----------------------')
                if(self.state.market_status !== self.state.preMarketStatus){
                    response.data.results = [];
    
                }
                if (response.data.mybids.length > 0) {
                    let row = [];
                    response.data.mybids.map((product, index) => {
                        product.wprice = product.currentbid ? product.wprice : "0.00";
                        console.log(product.saletype,"------------- seller dashboard")
                        self.state.dataToSend.push(productToObject( product.market_status,product.propertype,product.avatarorg,product.bedroom,product.bathroom,product.location,product.startprice,product.wprice,product.date_closed,product.date_added,product.auctionid,product.pid,product.auctiontype,product.openhousedate,product.saletype ))
                    })
                    console.log(self.state.dataToSend,"------------- seller dashboard1")
                    response.data.results = self.state.dataToSend;
                }
                let products =  (response.data && response.data.results) ?  await properResult(response.data.results) : self.state.data; 
                
                self.setState({
                    data: products,
                    dataToSend: products ,
                    totalpage : Math.ceil(response.data.pagination/self.state.page_no) ,
                    isLoading : true,
                    preMarketStatus: self.state.market_status
                })
            })
        .catch(function (error) {
            self.setState({
                dataToSend : self.state.data
            })
        }); 
    }
    componentDidMount() {
        this.props.dispatch(PATHNAMEUPDATE({pathName:"/dashboard/seller"}))
        let market_status = this.state.market_status
        window.addEventListener('scroll', this.onScroll, false);
        this.getDashboardProductDetails(market_status)
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, false);
    }
    onScroll = () => {
        let self = this.state;
        let page_no = self.page_no ? self.page_no : 2;
        let cardDiv = ReactDOM.findDOMNode(this).querySelector('.content-main')
        
        if(cardDiv.getBoundingClientRect().bottom + 200 <= window.innerHeight && 
            self.isLoading 
            && self.totalpage > page_no
            ){
            this.setState({
              page_no   :   page_no+1,
              isLoading :   false
            },()=>{
                this.getDashboardProductDetails(this.state.market_status)
                })  
        }
      }
    render() {
        return (
            <div className="col-md-9">
                <h3 className="karla fw-600 text-black">MY AUCTIONS</h3>
                <div className="main-pro">
                    <div className="content-main box-shadow pad-30">
                        <div className="tab-connect">
                            <ul className="nav nav-tabs" >
                                <li className="active" ref={el => this.all_menu = el}><a data-toggle="tab" href="#" onClick={this.tabeChange('all')}>ALL</a></li>
                                <li ref={el => this.active_menu = el}><a data-toggle="tab" href="#menu1" onClick={this.tabeChange('active')}>ACTIVE</a></li>
                                <li ref={el => this.sold_menu = el}><a data-toggle="tab" href="#menu2" onClick={this.tabeChange('sold')}>SOLD</a></li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div id="home" className="tab-pane fade in active">
                               
                                {!this.state.isLoading && <Loader></Loader>}
                                <Suspense fallback={<Loader/>}>
                        
                                <ViewOne projToDisplay={this.state.dataToSend} history={this.props.history} user={this.props.user}/>
                                {!this.state.isLoading && <Loader></Loader>}
                            </Suspense>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}

export default connect(mapStateToProps)(Sellerdashboard);