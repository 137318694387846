import React from 'react';
import './Buyer.css';
import { Link } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@material-ui/core/Modal';
import {NOTIFICATION} from '../../actions/index';

function rand() {
    return Math.round(Math.random() * 20) - 10;
  }
function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
  
    return {
      top: '50%',
      left: '50%',
      position:'absolute',
      transform: 'translate(-50%, -50%)',
      outline:0
    };
  }

  // afterLoggedInmsg = (type)=>e=>{
  //   let msg = ''
  //   if(type==='seller'){
  //       msg='If you are interested in selling a property, please log into your Seller Account or Create a Seller Account.'
  //   }
  //   if(type==='buyer'){
  //       msg='To look for properties, you must login in as a Buyer or Create a Buyer account.'
        
  //   }
  //   if(type==='realtor'){
  //       msg='If you are interested in Pocket Listing a property, please log into your Realtor Account or Create a Realtor Account.'
  //   }
  // }

  const useStyles = makeStyles(theme => ({
    paper: {
      position: "absolute",
      width: 620,
    //   backgroundColor: theme.palette.background.paper,
      border: "none",
    //   boxShadow: theme.shadows[5],
    //   padding: theme.spacing(1, 3, 1, 3)
    }
  }));

const Realtor =(props)=>{
    let {loggedIn,msg,role} = props
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    

 
    return (

                    <div className="sta-tabs new-pop" id="messages">  

                      <div className="flex justify-between align-center flex-wrap">
                              <h4 className="text-left">REALTOR FAQ</h4>
                              <button type="button" className="vid-eo" onClick={handleOpen}>Watch Realtor Videos</button>
                      </div>
                            <div className="static-accordient">
                                <div className="tabs">
                                    <div className="tab">
                                        <input type="radio" id="rd1" name="rd" />
                                        <label className="tab-label" for="rd1">Who can see my Pocket Listings?</label>
                                        <div className="tab-content">
                                        <p>Your Pocket listings are visible to any Buyer searching for listings that match your Listing’s profile. </p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd2" name="rd" />
                                        <label className="tab-label" for="rd2">Can I find my own Buyers on this site?  </label>
                                        <div className="tab-content">
                                        <p>We don’t allow Agents to find buyers on the site. We allow buyers to find you and reach out if interested.  </p>
                                        
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd3" name="rd" />
                                        <label className="tab-label" for="rd3">Am I able to Auction Pocket Listings? </label>
                                        <div className="tab-content">
                                        <p>Yes, as long as you provide a Private Open House for all interested Buyers before the Auction period begins.  </p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd4" name="rd" />
                                        <label className="tab-label" for="rd4">Am I required to obtain an inspection report for my Pocket Listings? </label>
                                        <div className="tab-content">
                                        <p>You are not required to, but we highly advise it.  Imagine the frustration of your Seller, and Buyer, at opening escrow, then the deal falls apart based on the visual inspection and physical inspection.  Pocket Listings are hard enough. Best to disclose, disclose disclose.</p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd5" name="rd" />
                                        <label className="tab-label" for="rd5">Am I required to use your service providers? </label>
                                        <div className="tab-content">
                                        <p>No, but we only offer rebates to the Seller for using our preferred providers.  </p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd6" name="rd" />
                                        <label className="tab-label" for="rd6">Can I operate as a Dual agent with my Pocket Listing? </label>
                                        <div className="tab-content">
                                        <p>Yes, as long as the Buyer is comfortable with a Dual Agent arrangement. If not, one of our Assigned Agents will represent the Buyer. </p>
                                        
                                        
                                        {/* <div className="text-center">
                                        <button className="btn btn-primary"> Watch Video for More</button>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd7" name="rd" />
                                        <label className="tab-label" for="rd7">What if a deal doesn’t close?</label>
                                        <div className="tab-content">
                                        <p>You can continue to list it at the current price, reduce the price, or you can cancel the listing at any time.</p>
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd8" name="rd" />
                                        <label className="tab-label" for="rd8">Where else can my Pocket Listings be seen? </label>
                                        <div className="tab-content">
                                        <p>We don’t syndicate Pocket Listings currently. </p>
                                        {/* <div className="text-center">
                                        <button className="btn btn-primary"> Watch Video for More</button>
                                        </div> */}
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd9" name="rd" />
                                        <label className="tab-label" for="rd9">What is the commission to be paid for selling on the site?</label>
                                        <div className="tab-content">
                                        <p>Standard 25% of listing agent’s gross commission.  </p>
                                       
                                        </div>
                                    </div>
                                    <div className="tab">
                                        <input type="radio" id="rd10" name="rd" />
                                        <label className="tab-label" for="rd10">What if I find my own Buyer, who’s not on the site?</label>
                                        <div className="tab-content">
                                        <p>Congrats! That deal is yours completely.  You owe us no referral commission.   </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center static-btn">
                                <h4>Any other questions, <Link to={{pathname:'/',state:{scrollTo:true}}}>EMAIL US</Link></h4>
                                <p> <b> OR </b></p>
                                {((loggedIn  && role != 'seller' && role != 'buyer') || (!loggedIn || (!loggedIn && role ==='realtor')))
                                      ? 
                                      <button className="btn btn-primary"><Link to={loggedIn?'/dashboard/post':"/productpost"}>POST POCKET LISTING</Link></button>
                                      :
                                      
                                      <button className="btn btn-primary" onClick={('realtor')}>POST POCKET LISTING</button>
                                   
                                  }
                                {/* <Link to={loggedIn?"/dashboard/pocketlisting":"/realtorproductpost"}> <button className="btn btn-primary">Post pocket Listing</button></Link> */}
                            </div>
                            <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="new-st"
                    open={open}
                    onClose={handleClose}
                >
                    <div style={modalStyle} className={classes.paper}>
                    {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/bGZvc16PnQA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    <iframe width="560" height="315" className="ifram-wth ssss" src="https://www.youtube.com/embed/bGZvc16PnQA?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </Modal>  
                      </div>
                              
          )}      

          export default Realtor;