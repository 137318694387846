import React, {Component} from 'react';

//Router import
import Header from './views/Header/Header';
import Footer from './views/Footer/Footer';
import Home from './views/Home/Home';
import Search from './views/Search/Search';
import Register from './views/Register/Register';
import Preregister from './views/Preregister/Register';
import Login from './views/Login/Login';
import Dashboard from './views/Dashboard/Dashboard';
import Productview from './views/Productview/Productview';
import forget_password from './views/Forget Password/forget_password';
import Email_forgetPassword from './views/Email forget password/email_forgetpassword';
import RealtorPocketListing from './views/RealtorPocketListing/RealtorPocketListing';
import DeedResponse from './views/DeedResponse/DeedResponse';

import InfoVideo from './views/StaticPages/InfoVideo';
import Priceing from './views/StaticPages/Priceing';
import OurTeam from './views/StaticPages/OurTeam';
import Termsofservice from './views/StaticPages/Termsofservice';
import Privacy from './views/StaticPages/Privacy';
import Disclaimer from './views/StaticPages/Disclaimer';
import Blog from './components/Blog/Blog';

import ChangePassword from './views/ChangePassword/change_password';
import Editpost from './views/Productpost/Editpost';
import Productpost_before from './views/Productpost/Productpost_before';
import Realtorpocketlisting_before from './views/RealtorPocketListing/Realtor_pocketlisting_before';
import Message from './views/Messages/Message';
import EmailSubscription from './components/EmailSubscription/EmailSubscription';
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux'

let dashboardRoutes = [
  {
    path:'/realtorproductpost',
    component:Realtorpocketlisting_before
  },
  {
    path:'/productpost',
    component:Productpost_before
  },
  {
    path: "/blog",
    component: Blog
  },
  {
    path: "/ourteam",
    component: OurTeam
  },
  {
    path: "/message/:id/:username/:role",
    component: Message
  },
  {
    path: "/editpost/:id/:auction_id",
    component: Editpost
  },
  {
    path: "/terms",
    component: Termsofservice,
    isAuthWant:false
  },
  {
    path: "/privacy",
    component: Privacy,
    isAuthWant:false
  },
  {
    path: "/disclaimer",
    component: Disclaimer,
    isAuthWant:false
  },
  {
    path: "/infovideo/:type",
    component: Priceing,
    isAuthWant:false
  },
  {
    path: "/pricing/:type",
    component: InfoVideo,
    isAuthWant:false
  },
  {
    path: "/product/pocketListing",
    component: RealtorPocketListing,
    isAuthWant:false
  },
  {
    path: "/product/view/:id/:auction_id",
    component: Productview,
    isAuthWant:true 
  },
  {
    path: "/deeds/update/:proposal/:state",
    component: DeedResponse,
    isAuthWant:false
  },
  {
    path: "/email_forget_password/:token",
    component: Email_forgetPassword,
    isAuthWant:false
  },
  {
    path: "/subscribeaccept/:token",
    component: EmailSubscription,
    isAuthWant:false 
  },
  {
    path: "/forget_password/",
    component: forget_password,
    isAuthWant:false 
  },
  {
    path: "/dashboard/",
    component: Dashboard,
    isAuthWant:true 
  },
  {
    path: "/search/:iam",
    component: Search,
    isAuthWant:false
  },
  {
    path: "/login",
    component: Login,
    isAuthWant:false 
  },
  {
    path: "/register",
    component: Register,
    isAuthWant:false
  },
  {
    path: "/preregister",
    component:Preregister,
    isAuthWant:false
  },
  {
    path: "/",
    component: Home,
    isAuthWant:false
  },
  { 
    redirect: true, 
    path: "/", 
    to: "/home", 
    navbarName: "Redirect" ,
    isAuthWant:false
  }
];

const SwitchRoutes = (event) => (
  <Switch>
    {dashboardRoutes.map((prop, key) => {
      if(!event.props.loggedIn && prop.isAuthWant)
        return <Redirect from={prop.path} to={"/login"} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;          
    })}
  </Switch>
);
class App extends Component {
  render () {
    return (
      <div className="App">
        <Header user={this.props.user} history={this.props.history} loggedIn={this.props.loggedIn}/>
        <SwitchRoutes props={this.props}/>
        <Footer user={this.props.user} loggedIn={this.props.loggedIn}/>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(App);
