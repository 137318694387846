import React, {Component} from 'react';
import {connect} from 'react-redux'
import { Link } from 'react-router-dom';

import './Static.css';
import Buyer_pricing from '../../components/StaticContent/Buyer_pricing';
import { Helmet } from 'react-helmet';
import {NOTIFICATION} from '../../actions/index'
class InfoVideo extends Component {
  state={
    statictabs:'buyer'
  }
  componentDidMount() {
    let { type: params } = this.props.match.params
    this.setState({
      statictabs:params
    })
  }
  changeTabs =(value)=>{
    console.log(value)
    this.setState({
      statictabs:value
    })
  }
  afterLoggedInmsg = (type)=>e=>{
    let msg = ''
    if(type==='seller'){
        msg='If you are interested in selling a property, please log into your Seller Account or Create a Seller Account.'
    }
    if(type==='buyer'){
        msg='To look for properties, you must login in as a Buyer or Create a Buyer account.'
        
    }
    if(type==='realtor'){
        msg='If you are interested in Pocket Listing a property, please log into your Realtor Account or Create a Realtor Account.'
    }
    this.props.dispatch(NOTIFICATION({msg:msg}))
}
  render(){
    let currenttab = this.state.statictabs
    console.log(currenttab)
    let {loggedIn,user} = this.props
    let buyer = ( this.state.statictabs === 'buyer') ? 'active':''
    let seller = (currenttab === 'seller') ? 'active':''
    let realtor = (currenttab === 'realtor') ? 'active':''

    if(loggedIn && user)
      var {userdata:{role}} = user 
    return (
      <main>
        <Helmet><title>{"DoorRise | Pricing"}</title></Helmet>
        <div className="center-part">
          <div className="">
            <div className="flex justify-center" style={{"background":"#00ced514"}}>
              <div className="col-md-10">
                <div className="pricing text-center">
                  <div className="before-cont">
                  <h4 className="text-center">Pricing </h4>                    
                  </div>
                    <ul class="nav nav-tabs" role="tablist">
                      <li className={( this.state.statictabs === 'buyer') ? 'active':''}><a  aria-controls="home" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('buyer')}>PRICING FOR BUYERS </a></li>
                      <li className={seller}><a aria-controls="profile" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('seller')}>PRICING FOR SELLERS </a></li>
                      <li className={realtor}><a aria-controls="messages" role="tab" data-toggle="tab" onClick={e=>this.changeTabs('realtor')}>PRICING FOR REALTORS </a></li>
                    </ul>
                    <div className="tab-content">
                    {currenttab === 'buyer' ? <Buyer_pricing 
                       loggedIn={loggedIn}
                       role={role}
                       msg={this.afterLoggedInmsg}
                    /> : currenttab === 'seller' ? 
                      <div  id="profile">
                        <h4 className="text-left">SELLER'S PRICING</h4>
                        <div className="static-accordient">
                            <div className="tabs">
                              <ul>
                                <li>DoorRise is FREE for Sellers to use</li>
                                <li>Commission for traditional 2-Agent transaction: 4% (Traditional agencies charge 5% to 6%)</li>
                                <li>Commission for a single “Dual” Agent 2-Agent transaction: 3% (Traditional agencies charge 5% to 6%)</li>
                                <li>$39 per month fee per Trust Deed or Wholesale Contract</li>
                                <li>$500 credit toward Closing Costs (Escrow, title, insurance, etc)</li>
                             
                              </ul>
                            </div>
                        </div>
                        <div className="text-center static-btn">                       
                            {((loggedIn  && role != 'realtor' && role != 'buyer') || (!loggedIn || (!loggedIn && role ==='seller')))
                                      ? 
                                      <button className="btn btn-primary"><Link to={loggedIn?'/dashboard/post':"/productpost"}>START SELLING</Link></button>
                                      :
                                      <button className="btn btn-primary" onClick={this.afterLoggedInmsg('seller')}>START SELLING</button>
                                  
                                  }
                        </div>
                      </div>
                   : <div  id="messages">
                            <h4 className="text-left">REALTOR'S PRICING</h4>
                            <div className="static-accordient">
                                <div className="tabs">
                                  <ul>
                                    <li>DoorRise is FREE for Realtors to post your listings </li>
                                    <li>Realtor/Broker pays DoorRise Buyer-Broker the publicly-posted cooperating broker fee (no less than 2%)</li>
                                    {/* <li>$500 credit to both Buyer and Seller to be used for closing costs (Escrow, title, insurance, etc)  </li> */}
                                   
                                  </ul>
                                </div>
                            </div>
                            <div className="text-center static-btn">
                            {((loggedIn  && role != 'seller' && role != 'buyer') || (!loggedIn || (!loggedIn && role ==='realtor')))
                                      ? 
                                      <button className="btn btn-primary"><Link to={loggedIn?'/dashboard/post':"/productpost"}>POST LISTING</Link></button>
                                      :
                                      <button className="btn btn-primary" onClick={this.afterLoggedInmsg('realtor')}>POST LISTING</button>
                                  
                                  }
                            </div>
                      </div>
                       }
                     
                     </div>
                </div>
                {/* <div className="videos-info">
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4>BUYER</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4>SELLER</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                  <div className="video-card">
                    <figure>
                      <img src="/images/video-1.png" className="img-responsive" alt="video-1" />
                    </figure>
                    <div className="video-body">
                      <h4 >LIST</h4>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(InfoVideo);