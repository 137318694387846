import ReactDOM from "react-dom";

import React, {Component} from 'react';

import { Link } from 'react-router-dom'
import SideNav from '../../components/SideNav/SideNav'
import axios from 'axios';
import { connect } from 'react-redux';
import './change_password.css'

class ChangePassword extends Component {
    state = {
        currentpassword: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        newpassword: {
            data: "",
            required: true,
            errorMsg: "Required"
        },
        confirmnewpassword: {
            data: "",
            required: true,
            errorMsg: "Required",
            mismatchMsg: "Password doesn't match"
        },
        error:'',
        required: {},
        formError: {},
        success: ''
    }

    handleValidation(){
        let self = this;
        
        Object.keys(this.state).map(function(key) {
            if(self.state[key] && self.state[key].required && self.state[key].data === ""){
                self.state.formError[key] = self.state[key].errorMsg ? self.state[key].errorMsg : "Required";
            }
            self.setState({
                formError: self.state.formError
            })
        });
    }

    formSubmit(e) {
        e.preventDefault();
        this.handleValidation();
        const data = new FormData()
        let self = this;

        let password = self.props.user.userdata.password;

        /* New Password and Confirm New Password Checking */
        if(self.state['newpassword'].data && self.state['confirmnewpassword'].data && (self.state['newpassword'].data !== self.state['confirmnewpassword'].data)) {
            self.state.formError['confirmnewpassword'] = self.state['confirmnewpassword'].mismatchMsg ? self.state['confirmnewpassword'].mismatchMsg : "Password doesn't match";
            self.setState({
                formError: self.state.formError
            });
        }

        if ((Object.keys(self.state.formError)).length > 0 ){
            return false;
        }
        Object.keys(self.state).map(function(key) {
            if(key !== 'required' && key != 'formError')
                return (self.state[key]) ? data.append(key,self.state[key].data) : '';
        });

        data.append('uid', self.props.user.userdata.id);
        
        new Response(data).text().then(console.log);
        axios.defaults.headers.common['Authorization'] = this.props.user.data;
        let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
        axios.post(url+'mobileapi/change_password', data)
        .then(function (response) {
            console.log(response,"change password")
            if (!response.data.success) {
                self.setState({
                    error: response.data.status_text
                })
                return false;
            } else if(response.data.success) {
                self.setState({
                    success: response.data.status_text
                })
                //window.location.reload();
            }
        })
        .catch(function (error) {
            self.setState({
                error: "Try later!"
            })
            return false;
        });
    }

    handleChange = name => event => {
        let preState = this.state[name];
        if(this.state.formError[name]){ delete this.state.formError[name] }
        preState.data = event.target.value; 
        
        this.setState({
            [name]: preState,
            formError: this.state.formError,
            error: ''
        });
    };

    render() {
        return (
            <div>
                <div className="col-md-9">
                    <div className="flex justify-between pre-txt">
                        <span className="karla fw-600">CHANGE PASSWORD</span>
                    </div>
                    <div className="main-pro">
                        <div className="content-main box-shadow flexcalss pad-30">
                            <div className="alpha-form1 alpha-form1_support">
                                <form className=" " onSubmit={this.formSubmit.bind(this)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group text-lable">
                                                <label >Current Password</label>
                                                <input type="password" name="currentpassword" className="form-control site-input" id="currentpassword" placeholder="Enter Your Current Password" value={this.state.currentpassword.data} onChange={this.handleChange('currentpassword')} />
                                                <span style={{color: "red"}}>{this.state.formError["currentpassword"]}</span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >New Password</label>
                                                <input type="password" name="newpassword" className="form-control site-input" id="newpassword" value={this.state.newpassword.data} onChange={this.handleChange('newpassword')}
                                                    placeholder="Enter Your New Password" />
                                                <span style={{color: "red"}}>{this.state.formError["newpassword"]}</span>     
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label >Confirm New Password</label>
                                                <input type="password" name="confirmnewpassword" className="form-control site-input" id="confirmnewpassword" value={this.state.confirmnewpassword.data} onChange={this.handleChange('confirmnewpassword')}
                                                    placeholder="Retype Your New Password" />
                                                <span style={{color: "red"}}>{this.state.formError["confirmnewpassword"]}</span>     
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.state.error ?
                                            <div className="alert alert-danger" role="alert">
                                                {this.state.error}
                                            </div>
                                            : ''}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.state.success ?
                                            <div className="alert alert-success" role="alert">
                                                {this.state.success}
                                            </div>
                                            : ''}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group text-center pad-btm-20 pad-top-30">
                                                <button type="submit" className="btn btn-secondary">Submit</button>
                                            </div>                                         
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                    </div>
                <div class="clearfix"></div>
            </div>
       )
    }
}

function mapStateToProps(state) {
    const { user,loggedIn } = state.login;
    return { user,loggedIn };
}
  
export default connect(mapStateToProps)(ChangePassword);