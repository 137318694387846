import React, { Component, lazy, Suspense } from 'react';
import './SavedBuyer.css';


import axios from 'axios';
import Common from '../../components/Cards/Search/Common'
import Filter from '../../components/Cards/Search/Filter';
import Loader from '../../components/Loader/Loader';
import SellerSearch from '../../components/Cards/Search/SellerSearch'
import  SellerFeature from '../../components/Cards/Search/SellerFeature'
import { connect } from 'react-redux'
class SavedBuyer extends Component {
  state = {
    SearchPayload: {},
    totalpage: {},
    isLoading: true,
    itemTit: 'CATEGORIES',
    location: 'LOCATION',
    priceRange: 'PRICE RANGE',
    sort: [] ,
    page_no:1,
    buyerData : [],
    role : "seller",
    url:process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/"
  }
  getBuyers(data){
    let self = this;
    let params = this.props.match.params;
    let user_id = this.props.user.userdata.id
    let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
    axios.get(url+'mobileapi/getprodetails/'+params.id+'/'+params.auction_id+'/1/'+user_id,{})
        .then(function (response) {
            let payload = {
                "location":response.data.results["location"],
                "bedroom":response.data.results["bedroom"],
                "pricerangemin":response.data.results["pricerangemin"],
                "propertype":response.data.results["propertype"],
                "propertycondition":response.data.results["propertycondition"],
                // "saletype":this.state.saletype,
                "role":"seller",
                
            }
            console.log("propertype",payload)
           let url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "http://showpads.auctionsoftware.com/";
            axios.post(url+'mobileapi/searchfilter', payload)
            .then(function (response) {
                let totalpage = response.data.length ? Math.ceil(response.data.length/12) : 0;
        let products = !self.state.isLoading ? self.state.buyerData.concat(response.data.data) : response.data.data;
            products = products.length > 0  ? products : [];
        let maxPrice = products.length > 0 ? Math.max.apply(Math, products.map(function(o) { return o.wprice; })) : 1000;
        
        self.setState({
          totalpage: totalpage,
          buyerData : products,
          isLoading: true,
          maxPrice: maxPrice
        },()=>{
          document.body.style.overflow = "auto";  
          document.body.style.paddingRight = "0px";
        })
            })
            .catch(function (error) {
                self.setState({
                    error: "Try later!"
                })
                return false;
            });

        })
        .catch(function (error) {
            self.setState({
                error : "Try later"
            })
        });
    
         
  }

  changestate(data) {
    this.setState({
        buyerData: data
      },()=>{
        this.getBuyers(data);
      })
  }


  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    this.getBuyers(null);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll = () => {
    let self = this.state;
    let prePage = self.SearchPayload["page_no"] ? self.SearchPayload["page_no"] : 2;
    if (
      (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 500) && 
      this.state.isLoading && Number(self.totalpage) > Number(prePage)) {
        document.body.style.overflow = "hidden";  
        document.body.style.paddingRight = "15px";
        self.SearchPayload["page_no"] = prePage+1;
        this.setState({
          isLoading: false,
          SearchPayload: this.state.SearchPayload
        },()=>{
          this.getBuyers();
      })
    }
  }

  filterOnChange  =  event =>  {
    let name = event.target.value;
    let prestate = this.state["SearchPayload"];
    prestate["endDate"]   = (name == 'endDate')   ? 1 : 0;
    prestate["priceLow"]  = (name == 'priceLow')  ? 1 : 0;
    prestate["priceHigh"] = (name == 'PriceHigh') ? 1 : 0;
    prestate["Recent"] = (name == 'Recent') ? 1 : 0;
    prestate["Old"] = (name == 'Old') ? 1 : 0;
    this.setState({
      SearchPayload : prestate
    },()=>{
      this.getBuyers()});
  }

  render() {
    //Dummy data(Feature search)
    let data2 = [1, 2, 3]

    return (
                
                <div className="col-md-9">
                <div class="flex justify-between pre-txt">
                          <span class="karla fw-600 text-black">MATCH BUYERS</span>
                     </div>
                  <div>
                  <Suspense fallback={<Loader/>}>
                    <SellerSearch data={this.state.buyerData} url={this.state.url}/>
                  </Suspense>
                  { !this.state.isLoading && <Loader/>}
                  </div>
                </div>
            
    )
  }
}


function mapStateToProps(state) {
  const { user,loggedIn } = state.login;
  return { user,loggedIn };
}

export default connect(mapStateToProps)(SavedBuyer);